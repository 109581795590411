const Edit = ({ className = "custom-icon", color = "#000000", size = 18 }) => (
  <svg
    className={className}
    fill="none"
    height={size}
    viewBox={`0 0 ${size} ${size}`}
    width={size}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.44592 12.6266L5.6149 9.80174L12.7854 2.65039C13.7291 3.58845 14.6799 4.53721 15.6164 5.47526L8.44592 12.6266Z"
      fill={color}
    />
    <path
      d="M13.2816 2.15075L15.4371 0L18.2717 2.82844L16.1127 4.98276L13.2816 2.15075Z"
      fill={color}
    />
    <path
      d="M5.20677 10.1934L8.06282 13.0468L3.79126 14.4592L5.20677 10.1934Z"
      fill={color}
    />
    <path
      d="M8.50594 0.993398V2.30233H1.39649V16.574H15.7V9.4804H17.0542V9.94486C17.0542 12.1405 17.0965 14.2939 17.0542 16.4473C17.0542 17.4607 16.5464 17.9674 15.5308 17.9674C10.8758 18.0096 6.22076 18.0096 1.52344 17.9674C0.507808 17.9674 0 17.4607 0 16.4473V2.51345C0 1.45785 0.507798 0.951172 1.56575 0.951172H8.50594V0.993398Z"
      fill={color}
    />
  </svg>
);

export default Edit;
