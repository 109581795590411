// @packages
import React, { useEffect } from "react";
import { withRouter } from "react-router-dom";
import { useDispatch } from "react-redux";
// @components
import ConfirmationContract from "../components/ConfirmationContract";
import PageWrapper from "../components/PageWrapper";
import { addViewport } from "../utils";
import { getAdminTemplate } from "../store/reducers/templatesAdmin/actions";

const ConfirmationPage = ({ match }) => {
  const dispatch = useDispatch();
  const { ProductId: templateId, ContractId } = match.params;

  useEffect(() => {
    dispatch(getAdminTemplate(templateId, true));
    addViewport({ vieportWidth: "device-width", clear: true });
  }, []);

  return (
    <PageWrapper isFullEditor>
      <ConfirmationContract templateId={templateId} contractId={ContractId} />
    </PageWrapper>
  );
};

export default withRouter(ConfirmationPage);
