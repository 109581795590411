// @components
import axiosInstance from "../utils/axiosInstance";
import { API } from "../constants/variables";
import { setRequestHeaders } from "../store/utils";

const FullEditorService = {
  save: (templateId, content, logo, parties, position) => {
    return axiosInstance.post(
      `${API}template/full_editor/save`,
      {
        data: {
          template_id: templateId,
          content,
          logo,
          parties,
          logo_position: position,
        },
      },
      setRequestHeaders()
    );
  },
  blockFormBuilder: (templateId) => {
    return axiosInstance.post(
      `${API}template/full_editor/set_flag`,
      {
        data: { template_id: templateId },
      },
      setRequestHeaders()
    );
  },
  getFullEditorFlag: (templateId) => {
    return axiosInstance.get(
      `${API}template/${templateId}/full_editor/flag`,
      setRequestHeaders()
    );
  },
  getLogo: (templateId) => {
    return axiosInstance.get(
      `${API}template/${templateId}/get_footer_logo`,
      setRequestHeaders()
    );
  },
};

export default FullEditorService;
