const Download = ({
  className = "custom-icon",
  color = "black",
  size = 18,
}) => (
  <svg
    className={className}
    fill="none"
    height={size}
    viewBox={`0 0 ${size} ${size}`}
    width={size}
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_536_1677)">
      <path
        d="M0.785286 15.9995C0.49674 15.9136 0.241067 15.7754 0.113229 15.4802C0.0547895 15.342 0.00365457 15.1888 0.00365457 15.0394C-0.00365041 13.7916 7.41507e-07 12.5438 7.41507e-07 11.2959C0.00104754 11.2746 0.00348843 11.2534 0.00730617 11.2324H1.85912V14.0792H12.1263V11.2324H13.9963V14.8302C13.9963 15.5213 13.8356 15.7567 13.2111 15.9995H0.785286Z"
        fill={color}
      />
      <path
        d="M5.59927 7.40652V1.31311C5.59927 0.894681 5.76728 0.726562 6.17271 0.726562H7.81999C8.22906 0.726562 8.39708 0.894682 8.39708 1.30938V7.40652H8.56509C9.10201 7.40652 9.64258 7.41025 10.1831 7.40652C10.406 7.40278 10.5886 7.47376 10.6835 7.68671C10.7785 7.89967 10.7091 8.0902 10.563 8.25832L7.3963 11.9607C7.14427 12.2558 6.85208 12.2558 6.60371 11.9644C5.54448 10.7315 4.49256 9.49493 3.43334 8.26206C3.28724 8.0902 3.21419 7.90714 3.30916 7.69045C3.40412 7.47376 3.5904 7.40278 3.8132 7.40652C4.40125 7.41025 4.9893 7.40652 5.59927 7.40652Z"
        fill={color}
      />
    </g>
    <defs>
      <clipPath id="clip0_536_1677">
        <rect
          width="14"
          height="15.2727"
          fill="white"
          transform="translate(0 0.726562)"
        />
      </clipPath>
    </defs>
  </svg>
);

export default Download;
