const PenDrawUnderline = ({
  className = "custom-icon",
  color = "#000000",
  width = 14,
  height = 17,
}) => (
  <svg
    className={className}
    width={width}
    height={height}
    viewBox="0 0 14 17"
    fill="none" xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4.40834 11.4552L1.85938 8.89236L8.31547 2.4043C9.16513 3.25535 10.0212 4.11611 10.8644 4.96716L4.40834 11.4552Z"
      fill={color}
    />
    <path
      d="M14 16.6125H0V14.8457H14V16.6125Z"
      fill={color}
    />
    <path
      d="M8.76172 1.95127L10.7024 0L13.2546 2.5661L11.3107 4.52061L8.76172 1.95127Z"
      fill={color}
    />
    <path
      d="M1.48933 9.24805L4.06082 11.8368L0.214844 13.1182L1.48933 9.24805Z"
      fill={color}
    />
  </svg>
);

export default PenDrawUnderline;
