const Chest = ({ className = "custom-icon", color = "#A5A5A5", size = 23 }) => (
  <svg
    className={className}
    fill="none"
    height={size}
    viewBox={`0 0 ${size} ${size}`}
    width={size}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M22.3962 6.4038V19.6322C22.3962 20.5649 22.1573 20.8038 21.2246 20.8038H2.7526C1.85402 20.8038 1.60379 20.5536 1.60379 19.6664V6.41518L1.3308 6.38105C0.0909897 6.33556 0 6.23318 0 4.982V1.05783C0 0.284366 0.284363 0 1.0692 0H22.9536C23.727 0 24 0.28436 24 1.08057V5.11848C24 6.18768 23.8294 6.3583 22.7716 6.38105L22.3962 6.4038ZM20.7697 19.2V6.41518H3.23033V19.2H20.7697ZM1.61516 4.77725H22.3735V1.62655H1.61516V4.77725Z"
      fill={color}
    />
    <path
      d="M12 8.82666H15.0597C15.6512 8.83803 15.981 9.1224 15.981 9.60013C15.981 10.0779 15.6512 10.3736 15.0711 10.3736H8.94027C8.3488 10.3736 8.01895 10.0892 8.01895 9.60013C8.01895 9.11103 8.34881 8.83803 8.9289 8.82666H12Z"
      fill={color}
    />
  </svg>
);

export default Chest;
