//@packages
import axios from "axios";

//@constants
import { API } from "../constants/variables";

export const setShowModalFlag = async (id, status) => {
  const resp = await axios.put(`${API}user/${id}/set_show_modal_flag`, {
    show_again_modal: status,
  });
  console.log("setShowModalFlag: ", resp);
  return { data: resp.data, success: resp.status === 200 };
};
