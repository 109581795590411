const Trash = ({
  className = "custom-icon",
  color = "#7A7A7A",
  size = 33,
  onClick = Function.prototype,
  pointer = false,
  style = {},
}) => (
  <svg
    className={className}
    fill="none"
    height={size}
    width={size}
    viewBox={`0 0 ${size} ${size}`}
    xmlns="http://www.w3.org/2000/svg"
    style={pointer ? { cursor: "pointer" } : {}}
    onClick={onClick}
  >
    <circle cx="16.5" cy="16.5" r="16" stroke={color} />
    <g clipPath="url(#clip0_0_1)">
      <path
        d="M16.941 14.3026C18.6699 14.3026 20.3976 14.297 22.1266 14.3054C22.8948 14.3082 23.0707 14.5137 22.9765 15.2878C22.5903 18.4726 22.2068 21.6589 21.8026 24.8423C21.6296 26.2061 20.7741 26.9717 19.4216 26.9886C17.7618 27.0083 16.1021 27.0069 14.4437 26.9886C13.1037 26.9745 12.2371 26.192 12.0654 24.8339C11.6598 21.6279 11.2736 18.4192 10.8846 15.2104C10.8029 14.5376 10.9981 14.3125 11.6848 14.3097C13.4359 14.2998 15.1884 14.3068 16.9396 14.3068L16.941 14.3026ZM12.1014 15.4792C12.1014 15.6818 12.089 15.7986 12.1028 15.9126C12.4544 18.7963 12.8019 21.6814 13.1632 24.5637C13.2836 25.5221 13.6643 25.8486 14.6333 25.8542C16.0855 25.8627 17.539 25.8585 18.9911 25.8571C20.3076 25.8571 20.5997 25.5911 20.7616 24.2583C21.0413 21.9558 21.325 19.6534 21.6005 17.351C21.6739 16.7374 21.7251 16.1223 21.7902 15.4792H12.1014Z"
        fill={color}
      />
      <path
        d="M13.4366 9.67969C13.377 8.15693 13.6733 7.7502 15.059 7.35755C15.2583 7.30125 15.4576 7.24214 15.6584 7.18726C17.1022 6.79038 17.5023 6.95786 18.283 8.33989C18.8326 8.18648 19.4001 8.01901 19.9732 7.86842C21.4226 7.48703 22.6117 8.2667 22.8914 9.75006C22.9827 10.2356 22.8249 10.5129 22.3625 10.6423C18.6097 11.6866 14.8569 12.728 11.1068 13.7821C10.5614 13.9356 10.2914 13.7357 10.1364 13.2108C9.70586 11.757 10.308 10.6015 11.7532 10.1638C12.3028 9.99776 12.8565 9.84576 13.4352 9.67969H13.4366ZM21.6593 9.65436C21.3991 8.96194 20.927 8.80009 20.3622 8.9549C17.5396 9.72614 14.7198 10.5129 11.9041 11.3108C11.3573 11.4656 11.086 11.8569 11.1497 12.5803C14.6852 11.5951 18.1557 10.6297 21.6607 9.65295L21.6593 9.65436ZM14.6243 9.356C15.4784 9.11393 16.2758 8.88876 17.2102 8.62417C16.4308 7.74317 15.7968 8.47921 15.1794 8.52144C14.7987 8.54818 14.4554 8.71002 14.6243 9.356Z"
        fill={color}
      />
      <path
        d="M18.8511 23.569C19.0172 21.3975 19.1695 19.3709 19.3273 17.3443C19.3411 17.1585 19.3453 16.9558 19.4242 16.7968C19.4851 16.6743 19.6692 16.5195 19.773 16.5364C19.9198 16.5618 20.0679 16.7123 20.1689 16.846C20.2257 16.922 20.2008 17.0726 20.1925 17.188C20.0167 19.4708 19.8381 21.7535 19.6581 24.0348C19.6485 24.1502 19.6485 24.298 19.582 24.3684C19.4671 24.488 19.2885 24.6456 19.1612 24.6259C19.0311 24.6062 18.8539 24.4036 18.8317 24.26C18.7902 23.9898 18.8456 23.7027 18.8525 23.569H18.8511Z"
        fill={color}
      />
      <path
        d="M14.8105 20.7346C14.8936 21.8056 15.0002 22.8766 15.0389 23.949C15.0473 24.1784 14.8092 24.4162 14.6846 24.6498C14.5309 24.4556 14.2624 24.2727 14.243 24.0672C14.0367 21.7648 13.8595 19.4581 13.7114 17.15C13.6989 16.9488 13.9426 16.7306 14.0658 16.5195C14.2194 16.7137 14.4838 16.8953 14.506 17.1036C14.6375 18.3097 14.7122 19.5228 14.805 20.7346H14.8078H14.8105Z"
        fill={color}
      />
      <path
        d="M17.3548 20.5709C17.3548 21.694 17.3769 22.8185 17.3368 23.9402C17.3285 24.1808 17.0959 24.413 16.9658 24.6495C16.8163 24.4102 16.5394 24.1724 16.5366 23.9289C16.5034 21.7067 16.5034 19.4831 16.5366 17.2608C16.5408 17.0131 16.8024 16.7683 16.9436 16.522C17.0807 16.7725 17.3243 17.0174 17.334 17.2721C17.3783 18.3698 17.352 19.4704 17.352 20.5709H17.3548Z"
        fill={color}
      />
    </g>
    <defs>
      <clipPath id="clip0_0_1">
        <rect width="13" height="20" fill={color} transform="translate(10 7)" />
      </clipPath>
    </defs>
  </svg>
);

export default Trash;
