const searchTextFormat = (a) => {
  let formated = a.toString();
  formated = formated.toLowerCase();
  return formated;
};

const compare = (title, text) => {
  title = title ? searchTextFormat(title) : '';
  text = searchTextFormat(text);
  return title.search(text) >= 0;
};

export const searchBarFilter = (contracts, text) => {
  return contracts.filter((item) => compare(item.title, text));
};
