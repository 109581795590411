const ArrowRight = ({
  className = "custom-icon",
  color = "black",
  size = 18,
}) => (
  <svg
    className={className}
    fill="none"
    height={size}
    viewBox={`0 0 ${size} ${size}`}
    width={size}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.5098 7.94804C8.29611 7.92856 6.18984 7.96754 4.18029 8.66883C2.53611 9.24351 1.31104 10.2857 0.343875 11.6006C0.248323 11.7464 0.132781 11.8806 0 12C0.601791 9.7013 1.67641 7.62663 3.52477 5.94156C5.37313 4.2565 7.75881 3.41883 10.4776 3.40909V0L18 6.14609L10.5098 11.8441V7.94804Z"
      fill={color}
    />
  </svg>
);

export default ArrowRight;
