// @Packages
import React, { useEffect, useState } from "react";
import { Button, Modal } from "antd";
import classNames from "classnames";
import { useSelector } from "react-redux";

// @Constants
import {
  BODY_BRIEFCASE,
  BODY_BRIEFCASE_2,
  BODY_BRIEFCASE_MOBILE,
  BODY_BRIEFCASE_3,
  BODY_BRIEFCASE_4,
  CLOSE_MODAL,
  FOOTER_BRIEFCASE,
  PROJECT_BRIEFCASE,
} from "../../../constants/staticTexts";
import { MOBILE_RESOLUTION } from "../../../constants/variables";

// @Utils
import { useMediaQuery } from "../../utils";
import { setShowModalFlag } from "../../../utils/user";

// @Styles
import classes from "./styles.module.scss";

const InitialModal = () => {
  const [visible, setVisible] = useState(false);

  const user = useSelector((store) => store.user);

  useEffect(() => {
    const { show_again_modal } = JSON.parse(JSON.stringify(user));
    if (Boolean(show_again_modal)) {
      setVisible(true);
    }
  }, [user]);

  const handleCancel = () => {
    setVisible(false);
  };

  const onDontShowAgainClick = async () => {
    const { extra } = JSON.parse(JSON.stringify(user));
    const { success } = await setShowModalFlag(extra, 0);
    setVisible(false);
  };

  const isMobile = useMediaQuery(MOBILE_RESOLUTION);

  return (
    <Modal
      visible={visible}
      title={PROJECT_BRIEFCASE}
      onCancel={handleCancel}
      centered
      key={"initialModal-key"}
      footer={[
        <Button key="back" onClick={handleCancel}>
          {CLOSE_MODAL}
        </Button>,
        <p
          key="dontShowAgain-text"
          onClick={() => onDontShowAgainClick()}
          className={classes.showButton}
        >
          {FOOTER_BRIEFCASE}
        </p>,
      ]}
      className={classNames(classes.briefcaseModal, {
        [classes.mobileContainer]: isMobile,
      })}
    >
      <p className={classes.textModal}>{BODY_BRIEFCASE}</p>
      {isMobile ? (
        <p className={classes.textModal}>{BODY_BRIEFCASE_MOBILE}</p>
      ) : (
        <p className={classes.textModal}>{BODY_BRIEFCASE_2}</p>
      )}
      <p className={classes.textModal}>{BODY_BRIEFCASE_3}</p>
      <p className={classes.textModal}>{BODY_BRIEFCASE_4}</p>
    </Modal>
  );
};

export default InitialModal;
