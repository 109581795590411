// @packages
import React from "react";
import { Col, Row } from "antd";
// @styles
import "./styles.module.scss";

const PartiCol = ({ contractImages, index, partiesLenght, party }) => {
  const partyImages = getPartiImages(contractImages)[`party-${index}`];
  const date = createElement(partyImages?.date);
  const signature = createElement(partyImages?.signature || partyImages?.sign);
  const partyData = getPartyData(party);
  return (
    <Col className="PartiesSection-column" xs={partiesLenght === 1 ? 24 : 12}>
      {partyData.title && (
        <p className="PartiesSection-column-title">
          <b>{partyData.title}</b>
        </p>
      )}
      {signature && <p>{signature}</p>}
      {partyData.name && (
        <p className="PartiesSection-column-by">By: {partyData.name}</p>
      )}
      {date && <p>Date: {date}</p>}
      {partyData.address && <p>Address: {partyData?.address}</p>}
      {partyData.email && <p>Email: {partyData.email}</p>}
    </Col>
  );
};

const PartiesSection = ({ parties, contractImages }) => {
  return (
    <Row id="FullEditor-PartiesSection">
      {parties.map((party, index) => (
        <PartiCol
          contractImages={contractImages}
          index={index}
          key={`party-${index}`}
          partiesLenght={parties.length}
          party={party}
        />
      ))}
    </Row>
  );
};

const getPartiImages = (contractImages) => {
  const response = {};
  let partieImages = [];
  // Loading images
  for (let index = 0; index < contractImages.length; index++) {
    const contractImage = contractImages[index];
    for (let j = 0; j < contractImage.length; j++) {
      partieImages.push(contractImage[j]);
    }
  }
  // Format parties images
  for (let index = 0; index < partieImages.length; index++) {
    const image = partieImages[index];
    const party = image.getAttribute("party");
    if (!response.hasOwnProperty(`party-${party}`)) {
      response[`party-${party}`] = {};
    }
    response[`party-${party}`][image.getAttribute("alt").toLowerCase()] = image;
  }
  return response;
};

const createElement = (node) => {
  if (!node) {
    return null;
  }
  return React.createElement(
    "img",
    {
      className: "quill-field quill-field-disabled",
      id: node?.id,
      src: node?.src,
      alt: node?.alt,
      type: node.getAttribute("type"),
      party: node.getAttribute("party"),
      draggable: node?.draggable,
    },
    null
  );
};

const getPartyData = (party) => {
  const partyData = {};
  party.forEach((item) => {
    if (item.title.toLowerCase() === "who is signing?") {
      partyData.name = item.val;
    } else if (item.is_predefined === 3) {
      partyData.title = item.title;
    } else {
      partyData[item.title.toLowerCase()] = item.val;
    }
  });
  return partyData;
};

export default PartiesSection;
