//@packages
import React from "react";
import { Draggable, Droppable } from "react-beautiful-dnd";
import { Menu } from "antd";
import { useHistory, useLocation } from "react-router-dom";

//@constants
import {
  DEFAULT_TITLE_GENERAL,
  URL_GENERAL,
} from "../../../constants/variables";

//@icons
import StackFoldersIcon from "../../atoms/icons/StackFolders";

//@styles
import classes from "../../organisms/SideBar/styles.module.scss";
import styles from "./styles.module.scss";
import classNames from "classnames";

const items = [{ id: 0, title: DEFAULT_TITLE_GENERAL, is_archived: 0 }];

const MenuGeneralItem = ({ validateItemDesign }) => {
  const history = useHistory();
  const { hash } = useLocation();

  const handleGeneral = () => {
    history.push(URL_GENERAL);
  };

  return (
    <div className={styles.container}>
      <Droppable droppableId="projectDroppable1" type="contractList">
        {(provided, snapshotMain) => (
          <div
            ref={provided.innerRef}
            {...provided.draggableProps}
            className={classNames(
              classes.contractContainer,
              classes.generalContainer
            )}
          >
            {Boolean(items.length) &&
            items.map((project, index) => {
              const projectId = project?.id?.toString();
              return (
                <div
                  key={projectId}
                  {...provided.draggableProps}
                  ref={provided.innerRef}
                  onClick={handleGeneral}
                  className={classNames(
                    snapshotMain.isDraggingOver &&
                    styles.itemDraggingOver,
                    classes.contractContainerItem
                  )}
                >
                  <Droppable
                    droppableId={projectId}
                    type="any"
                  >
                    {(provided, snapshot) => {
                      return (
                        <div
                          ref={provided.innerRef}
                          {...provided.droppableProps}
                          role="project"
                        >
                          <Draggable
                            draggableId={projectId}
                            index={index}
                            type="any"
                          >
                            {(provided, snapshot) => {
                              return (
                                <div
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  className="container-items"
                                >
                                  <Menu
                                    className={classes.menu}
                                    defaultOpenKeys={["1"]}
                                    defaultSelectedKeys={["1"]}
                                    mode="inline"
                                  >
                                    <Menu.Item
                                      key="1"
                                      role="title"
                                      onClick={handleGeneral}
                                      className={
                                        classNames(
                                          validateItemDesign(
                                            hash,
                                            "general"
                                          )
                                            ? "projectMainItemSelected"
                                            : "projectMainItemNotSelected")
                                      }
                                    >
                                      <StackFoldersIcon
                                        className={classes.iconSpace}
                                      />
                                      <div
                                        style={{ display: "none" }}
                                        {...provided.dragHandleProps}
                                      />
                                      General
                                    </Menu.Item>
                                  </Menu>
                                </div>
                              );
                            }}
                          </Draggable>
                          {provided.placeholder}
                        </div>
                      );
                    }}
                  </Droppable>
                  {provided.placeholder}
                </div>
              );
            })}
          </div>
        )}
      </Droppable>
    </div>
  );
};

export default MenuGeneralItem;
