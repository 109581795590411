// @packages
import { Component } from "react";
import { Helmet } from "react-helmet";
import { connect } from "react-redux";
import { message, Spin, Alert } from "antd";
// @components
import PageWrapper from "../components/PageWrapper";
import ProjectTable from "../components/organisms/ProjectTable";
import CategoryTable from "../components/organisms/CategoryTable";
import AdminContractsTable from "../components/organisms/AdminContratcsTable";
// @store
import {
  attachContractByTempId,
  getAdminTemplates,
  getContractCategories,
} from "../store/reducers/templatesAdmin/actions";
import {
  loadingUserSelector,
  loadedUserSelector,
  errorUserSelector,
  messageUserSelector,
  isAdminUserSelector,
} from "../store/reducers/user/selectors";
import { errorContractSelector } from "../store/reducers/templatesAdmin/selectors";
import { errorTemplatesInProgressSelector } from "../store/reducers/templatesInProgress/selectors";
import { errorTemplatesSelector } from "../store/reducers/templates/selectors";
import { errorTemplatesSentSelector } from "../store/reducers/templatesSent/selectors";
// @constants
import {
  PROCESSING_COMPLETE_TEXT,
  SEND_DONE_MESSAGE,
  SEND_LATER_MESSAGE,
} from "../constants/staticTexts";
import { LOGIN_PAGE } from "../constants/variables";
// @utils
import { addViewport } from "../utils";
import InitialModal from "../components/atoms/InitialModal";

class StartPage extends Component {
  state = {
    error: null,
    errorInfo: null,
  };

  componentWillMount() {
    addViewport({ vieportWidth: window.innerWidth, clear: true });
  }

  componentDidMount() {
    const {
      attachContract,
      getContractCategories,
    } = this.props;
    const isSendMessage =
      this.props.location &&
      this.props.location.search &&
      this.props.location.search.includes("send=");
    const isAttachTemplate =
      this.props.location &&
      this.props.location.search &&
      this.props.location.search.includes("attach=");

    message.destroy();

    if (isSendMessage) {
      this.props.location.search.includes("send=done") &&
        message.success({
          content: SEND_DONE_MESSAGE,
          className: "success-message",
          duration: 5,
        });
      this.props.location.search.includes("send=later") &&
        message.success({
          content: SEND_LATER_MESSAGE,
          className: "success-message",
          duration: 5,
        });
      this.props.location.search.includes("send=complete") &&
        message.success({
          content: PROCESSING_COMPLETE_TEXT,
          className: "success-message",
          duration: 5,
        });
    }

    if (isAttachTemplate) {
      const str = this.props.location.search;
      const tempId = str.slice(str.lastIndexOf("=") + 1);

      attachContract(tempId);
    }

    getContractCategories();
  }

  componentDidUpdate(prevProps) {
    const {
      userError,
      templatesAdminError,
      templatesError,
      templatesInProgressError,
      templatesSentError,
      userAdmin,
      getAdminTemplates,
    } = this.props;

    if (userAdmin) {
      getAdminTemplates();
    }

    if (prevProps.userError !== this.props.userError && this.props.userError) {
      message.destroy();

      if (this.props.userMessage) {
        return message.error({
          content: this.props.userMessage,
          className: "error-message",
          duration: 0,
        });
      }
    }

    if (
      (prevProps.userError !== userError &&
        userError &&
        userError.status === 401) ||
      (prevProps.templatesAdminError !== templatesAdminError &&
        templatesAdminError &&
        templatesAdminError.status === 401) ||
      (prevProps.templatesError !== templatesError &&
        templatesError &&
        templatesError.status === 401) ||
      (prevProps.templatesInProgressError !== templatesInProgressError &&
        templatesInProgressError &&
        templatesInProgressError.status === 401) ||
      (prevProps.templatesSentError !== templatesSentError &&
        templatesSentError &&
        templatesSentError.status === 401)
    ) {
      return (window.location.href = `${LOGIN_PAGE}/?redirect_to=/bcr`);
    }
  }

  componentDidCatch(error, errorInfo) {
    this.setState({ error, errorInfo });
  }

  componentWillUnmount() {
    message.destroy();
  }

  render() {
    const { userLoading, userLoaded, userAdmin, history } = this.props;
    const { error, errorInfo } = this.state;
    const adminScreen = history.location.hash.includes("admin") ? true : false;

    if (error) {
      return (
        <PageWrapper className="briefcase">
          <Alert type="error" message={error} description={errorInfo} />
        </PageWrapper>
      );
    }

    if (userLoading && !userLoaded) {
      return (
        <div className="loader-wrapper">
          <Spin size="middle" />
        </div>
      );
    }

    return (
      <PageWrapper className="briefcase">
        <Helmet>
          <title>Briefcase - Creators Legal</title>
        </Helmet>
        <InitialModal />
        {userAdmin && adminScreen ? (
          <>
            <CategoryTable />
            <AdminContractsTable />
          </>
        ) : (
          <ProjectTable />
        )}
      </PageWrapper>
    );
  }
}

export default connect(
  (store) => {
    console.log(isAdminUserSelector(store));
    return {
      userLoading: loadingUserSelector(store),
      userLoaded: loadedUserSelector(store),
      userError: errorUserSelector(store),
      userMessage: messageUserSelector(store),
      templatesAdminError: errorContractSelector(store),
      templatesError: errorTemplatesSelector(store),
      templatesInProgressError: errorTemplatesInProgressSelector(store),
      templatesSentError: errorTemplatesSentSelector(store),
      userAdmin: isAdminUserSelector(store),
    };
  },
  {
    attachContract: attachContractByTempId,
    getContractCategories: getContractCategories,
    getAdminTemplates: getAdminTemplates,
  }
)(StartPage);
