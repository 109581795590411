//@packages
import React from "react";

//@components
import { Button, Dropdown } from "antd";
import DropdownMenu from "../../atoms/DropdownMenu";
import SheetIcon from "../../atoms/icons/Sheet";
import { NEW_CONTRACT } from "../../../constants/staticTexts";

//@styles
import classes from "./styles.module.scss";
import classnames from "classnames";

const NewContractDropdown = ({ className, handleClick }) => {
  return (
    <div
      className={classnames(className, classes.contractButton)}
      onClick={handleClick}
    >
      <Dropdown overlay={<DropdownMenu />} trigger={["click"]}>
        <Button icon={<SheetIcon className={classes.sheetIcon} />} size="large">
          {NEW_CONTRACT}
        </Button>
      </Dropdown>
    </div>
  );
};

export default NewContractDropdown;
