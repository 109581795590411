const Action = ({
  className = "custom-icon",
  color = "#7A7A7A",
  size = 24
}) => (
  <svg
    className={className}
    fill="none"
    height={size}
    width={size}
    viewBox={`0 0 ${size} ${size}`}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M24 12.5148C23.978 12.7625 23.9559 13.0102 23.9314 13.2578C23.8065 14.5624 23.4539 15.8057 22.9127 16.995C21.9527 19.0917 20.4906 20.7519 18.5608 22.0001C17.2482 22.8485 15.8327 23.4616 14.2996 23.7804C13.2123 24.0084 12.1151 24.0477 11.0106 23.9496C9.68574 23.8294 8.40738 23.5155 7.18534 22.9859C5.45392 22.2355 3.98698 21.1393 2.80413 19.6582C1.62127 18.177 0.729845 16.4899 0.284132 14.609C0.00984659 13.4589 -0.053827 12.2916 0.041683 11.1146C0.164132 9.6653 0.514335 8.27733 1.11678 6.95312C1.75841 5.53818 2.66453 4.31697 3.78861 3.2527C4.64331 2.44591 5.57392 1.74947 6.62453 1.22224C7.54779 0.761222 8.52493 0.469406 9.53146 0.263417C10.608 0.0421837 11.7083 -0.0402266 12.8057 0.0181936C15.1053 0.138353 17.1845 0.86912 19.0408 2.25464C20.9633 3.68674 22.3739 5.5014 23.2482 7.73539C23.6892 8.86359 23.9368 10.0583 23.9804 11.2691C23.9833 11.3169 23.9899 11.3645 24 11.4113V12.5148ZM22.7976 12.0047C22.8122 6.0458 17.9902 1.20262 12.0221 1.18301C6.05392 1.16339 1.24413 5.9943 1.22699 11.9557C1.20984 17.9171 6.02208 22.7578 11.9241 22.7774C17.9265 22.797 22.7829 17.9833 22.7976 12.0047Z"
      fill={color}
    />
    <path
      d="M6.85701 12.0004C6.85701 12.2255 6.81273 12.4485 6.72671 12.6565C6.64068 12.8644 6.51459 13.0534 6.35564 13.2126C6.19668 13.3718 6.00798 13.4981 5.80029 13.5842C5.59261 13.6704 5.37001 13.7147 5.14522 13.7147C4.69159 13.7134 4.25681 13.5329 3.93535 13.2123C3.61389 12.8918 3.43173 12.4572 3.42847 12.0029C3.42846 11.7768 3.47305 11.553 3.55967 11.3442C3.64628 11.1354 3.77322 10.9458 3.93319 10.7863C4.09317 10.6268 4.28303 10.5005 4.49187 10.4147C4.70072 10.3288 4.92443 10.2852 5.15018 10.2861C5.37493 10.2855 5.59758 10.3294 5.80528 10.4154C6.01299 10.5014 6.20163 10.6277 6.36032 10.7871C6.51901 10.9465 6.6446 11.1357 6.72985 11.344C6.8151 11.5523 6.85832 11.7754 6.85701 12.0004Z"
      fill={color}
    />
    <path
      d="M10.2856 12.0035C10.2855 11.7782 10.3297 11.555 10.4157 11.3467C10.5017 11.1384 10.6278 10.9491 10.7869 10.7897C11.1081 10.4676 11.544 10.2865 11.9987 10.2861C12.4533 10.2858 12.8895 10.4663 13.2112 10.7879C13.533 11.1095 13.7139 11.5459 13.7142 12.001C13.7117 12.9423 12.9402 13.7147 11.9999 13.7147C11.7751 13.715 11.5525 13.671 11.3447 13.5852C11.1369 13.4994 10.948 13.3734 10.7888 13.2145C10.6296 13.0556 10.5032 12.8669 10.4169 12.6591C10.3306 12.4513 10.286 12.2286 10.2856 12.0035Z"
      fill={color}
    />
    <path
      d="M17.1428 11.9967C17.1438 11.542 17.3254 11.1064 17.6476 10.7856C17.9697 10.4648 18.4062 10.2851 18.8608 10.2861C19.3155 10.2871 19.7511 10.4687 20.0719 10.7909C20.3927 11.1131 20.5724 11.5495 20.5714 12.0041C20.5704 12.4588 20.3888 12.8944 20.0667 13.2152C19.7445 13.536 19.308 13.7157 18.8534 13.7147C18.3987 13.7137 17.9631 13.5322 17.6423 13.21C17.3215 12.8878 17.1418 12.4514 17.1428 11.9967Z"
      fill={color}
    />
  </svg>
);

export default Action;
