//@Packages
import axios from "axios";
import Cookies from "universal-cookie";
import createAuthRefreshInterceptor from "axios-auth-refresh";

//@Constants
import { TOKEN_COOKIE, TOKEN_DOMAIN } from "../constants/variables";
import { API } from "../constants/variables";

//@Utils
import { getTokenCookies, setTokenCookies } from "../store/utils";

export const downloadClickHandler = (record, isBlank, setDownloadingState) => {
  const cookies = new Cookies();
  const token = cookies.get(TOKEN_COOKIE);
  const docTitle = `${isBlank ? record.title + "– Blank" : record.title}.pdf`;
  let config = {
    "Content-Type": "application/json",
    Accept: "application/pdf",
    "wp-token": `${token}`,
  };
  const instance = axios.create({
    withCredentials: true,
  });

  setDownloadingState(true);

  axios.interceptors.request.use((request) => {
    request.headers["wp-token"] = getTokenCookies();

    return request;
  });

  const refreshAuthLogic = (failedRequest) =>
    instance.post(TOKEN_DOMAIN).then((tokenRefreshResponse) => {
      const token = tokenRefreshResponse.data.token;

      setTokenCookies(token);
      // failedRequest.response.config.headers['wp-token'] = token

      return Promise.resolve();
    });

  createAuthRefreshInterceptor(axios, refreshAuthLogic);

  axios
    .get(
      `${API}template/${record.id}/${
        isBlank ? "download_template_pdf" : "download_filled_template_pdf"
      }`,
      {
        responseType: "blob",
        headers: config,
      }
    )
    .then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", docTitle);
      document.body.appendChild(link);
      link.click();
      setDownloadingState(false);
    })
    .catch((error) => {
      setDownloadingState(false);
    });
};

export const downloadCertificatePDF = async (
  id,
  title,
  downloadFilePrefix,
  setDownloadingState = () => {}
) => {
  const cookies = new Cookies();
  const token = cookies.get(TOKEN_COOKIE);
  const docTitle = `${title}${
    downloadFilePrefix ? "-" + downloadFilePrefix : ""
  }.pdf`;
  let config = {
    "Content-Type": "application/json",
    Accept: "application/pdf",
    "wp-token": `${token}`,
  };
  const instance = axios.create({
    withCredentials: true,
  });

  setDownloadingState(true);

  axios.interceptors.request.use((request) => {
    request.headers["wp-token"] = getTokenCookies();

    return request;
  });

  const refreshAuthLogic = (failedRequest) =>
    instance.post(TOKEN_DOMAIN).then((tokenRefreshResponse) => {
      const token = tokenRefreshResponse.data.token;

      setTokenCookies(token);
      // failedRequest.response.config.headers['wp-token'] = token

      return Promise.resolve();
    });

  createAuthRefreshInterceptor(axios, refreshAuthLogic);

  try {
    const resp = await axios.put(
      `${API}envelope/${id}/action`,
      {
        action: "download_certificate",
      },
      {
        headers: config,
      }
    );
    const { download_url } = resp.data?.data?.action_result?.data;

    axios
      .get(download_url, {
        responseType: "blob",
        headers: config,
      })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", docTitle);
        document.body.appendChild(link);
        link.click();
        setDownloadingState(false);
      })
      .catch((error) => {
        console.log(error);
        setDownloadingState(false);
      });
  } catch (error) {
    console.log(error);
  }
};

export const downloadTemplatePDF = (
  title,
  url,
  setDownloadingState = () => {}
) => {
  const cookies = new Cookies();
  const token = cookies.get(TOKEN_COOKIE);
  const docTitle = `${title || "template"}.pdf`;
  let config = {
    "Content-Type": "application/json",
    Accept: "application/pdf",
    "wp-token": `${token}`,
  };
  const instance = axios.create({
    withCredentials: true,
  });

  setDownloadingState(true);

  axios.interceptors.request.use((request) => {
    request.headers["wp-token"] = getTokenCookies();

    return request;
  });

  const refreshAuthLogic = (failedRequest) =>
    instance.post(TOKEN_DOMAIN).then((tokenRefreshResponse) => {
      const token = tokenRefreshResponse.data.token;

      setTokenCookies(token);

      return Promise.resolve();
    });

  createAuthRefreshInterceptor(axios, refreshAuthLogic);

  axios
    .get(`${url}`, {
      responseType: "blob",
      headers: config,
    })
    .then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", docTitle);
      document.body.appendChild(link);
      link.click();
      setDownloadingState(false);
    })
    .catch((error) => {
      setDownloadingState(false);
    });
};
