//@packages
import React from "react";

//@icons
import RoundedDottedArrowIcon from "../components/atoms/icons/RoundedDottedArrow";
import RoundedCartArrowIcon from "../components/atoms/icons/RoundedCartArrow";
import PenRoundedOutlinedIcon from "../components/atoms/icons/PenRoundedOutlined";
import MoveIcon from "../components/atoms/icons/Move";
import PencilWriting from "../components/atoms/icons/PencilWriting";
import Resend from "../components/atoms/icons/Resend";
import DownloadWithBar from "../components/atoms/icons/DownloadWithBar";
import FolderIcon from "../components/atoms/icons/Folder";
import ForwardIcon from "../components/atoms/icons/Forward";
import DeleteIcon from "../components/atoms/icons/Delete";
import Edit from "../components/atoms/icons/Edit";
import Pen from "../components/atoms/icons/Pen";
import DownloadFileIcon from "../components/atoms/icons/DownloadFile";
import PenDrawUnderline from "../components/atoms/icons/PenDrawUnderLine";
import PaperIcon from "../components/atoms/icons/Paper";
import ShareIcon from "../components/atoms/icons/Share";

const showCloneContract = false;
const showSendForSignature = false;

const resendToOption = (option) => {
  try {
    if (!option) return {};
    let data = {
      label: "Resend to",
      icon: <Resend />,
      children: Object.keys(option).map((key, i) => {
        const userLabel = option[ key ];
        return {
          label: userLabel.split("(")[ 0 ],
          subLabel: userLabel.split("(")[ 1 ].replace(")", ""),
          data: {
            flag: "Resend to",
            action: key,
          },
        };
      }),
    };

    return data;
  } catch (error) {
    console.error(error);
  }
};

const moveToOption = (folders) =>
  folders.map((folder) => {
    return {
      label: folder.title,
      isSelectMenu: true,
      data: { folder },
    };
  });

//purchased not populate - subscriber
export const MENU1 = (project, folders) => [
  { label: "Edit Contract", icon: <PenDrawUnderline /> },
  project?.status !== 0 && { label: "Add again", icon: <PaperIcon /> },
  showCloneContract && { label: "Clone Contract", icon: <PaperIcon /> },
  Boolean(!!project?.download_template || 0) && {
    label: "Download Template",
    icon: <DownloadFileIcon />,
  },
  {
    label: "Rename",
    isMobile: true,
    icon: <PenRoundedOutlinedIcon />,
  },
  {
    label: "Move to",
    isMobile: true,
    isSelectMenu: true,
    icon: <MoveIcon />,
    children: moveToOption(folders),
  },
  Boolean(
    (project?.is_form_builder_completed === 1 || project?.full_editor_flag) &&
    project?.can_be_used
  ) && {
    label: "Download PDF",
    icon: <DownloadWithBar />,
  },
  Boolean(
    project?.is_form_builder_completed === 1 || project?.full_editor_flag
  ) && {
    label: "Send for Review", icon: <ShareIcon />
  },
  Boolean(
    showSendForSignature && (project?.is_form_builder_completed === 1 || project?.full_editor_flag)
  ) && { label: "Send for Signature", icon: <ShareIcon /> },
];

//purchased not populate - non subscriber
export const MENU2 = (project, folders) => [
  { label: "Edit Contract", icon: <PenDrawUnderline /> },
  { label: "Buy again", icon: <RoundedCartArrowIcon /> },
  showCloneContract && { label: "Clone Contract", icon: <PaperIcon /> },
  Boolean(!!project?.download_template || 0) && {
    label: "Download Template",
    icon: <DownloadFileIcon />,
  },
  {
    label: "Rename",
    isMobile: true,
    icon: <PenRoundedOutlinedIcon />,
  },
  {
    label: "Move to",
    isMobile: true,
    isSelectMenu: true,
    icon: <MoveIcon />,
    children: moveToOption(folders),
  },
  Boolean(
    (project?.is_form_builder_completed === 1 || project?.full_editor_flag) &&
    project?.can_be_used
  ) && {
    label: "Download PDF",
    icon: <DownloadWithBar />,
  },
  Boolean(
    project?.is_form_builder_completed === 1 || project?.full_editor_flag
  ) && {
    label: "Send for Review", icon: <ShareIcon />
  },
  Boolean(
    showSendForSignature && (project?.is_form_builder_completed === 1 || project?.full_editor_flag)
  ) && { label: "Send for Signature", icon: <ShareIcon /> },
];

// populate regular builder
export const MENU3 = (folders, project) => [
  {
    label: "Edit Contract",
    icon: <PenDrawUnderline />,
    ...(!Boolean(project?.full_editor_flag) && {
      children: [
        {
          label: "Edit with Formbuilder",
          icon: <Pen />,
        },
        {
          label: "Open Full Editor",
          icon: <Edit />,
        },
      ],
    }),
  },
  { label: "Download PDF", icon: <DownloadWithBar /> },
  showCloneContract && { label: "Clone Contract", icon: <PaperIcon /> },
  Boolean(!!project?.download_template || 0) && {
    label: "Download Template",
    icon: <DownloadFileIcon />,
  },
  { label: "Send for Review", icon: <ShareIcon /> },
  Boolean(
    showSendForSignature && (project?.is_form_builder_completed === 1 || project?.full_editor_flag)
  ) && { label: "Send for Signature", icon: <ShareIcon /> },
  project?.status !== 0 && { label: "Add again", icon: <PaperIcon /> },
  {
    label: "Rename",
    isMobile: true,
    icon: <PenRoundedOutlinedIcon />,
  },
  {
    label: "Move to",
    isMobile: true,
    isSelectMenu: true,
    icon: <MoveIcon />,
    children: moveToOption(folders),
  },
];

// populate regular builder non subscriber
export const MENU4 = (folders, project) => [
  {
    label: "Edit Contract",
    icon: <PenDrawUnderline />,
    ...(!Boolean(project?.full_editor_flag) && {
      children: [
        {
          label: "Edit with Formbuilder",
          icon: <Pen />,
        },
        {
          label: "Open Full Editor",
          icon: <Edit />,
        },
      ],
    }),
  },
  { label: "Download PDF", icon: <DownloadWithBar /> },
  showCloneContract && { label: "Clone Contract", icon: <PaperIcon /> },
  Boolean(!!project?.download_template || 0) && {
    label: "Download Template",
    icon: <DownloadFileIcon />,
  },
  { label: "Send for Review", icon: <ShareIcon /> },
  { label: "Buy again", icon: <RoundedCartArrowIcon /> },
  {
    label: "Rename",
    isMobile: true,
    icon: <PenRoundedOutlinedIcon />,
  },
  {
    label: "Move to",
    isMobile: true,
    icon: <MoveIcon />,
    isSelectMenu: true,
    children: moveToOption(folders),
  },
];

// invitation sent (user party 1) - subscriber
export const MENU5 = (actions, folders, status) => {
  try {
    return [
      !!actions?.sign && {
        label: "Sign",
        icon: <PencilWriting color="#000000" />,
      },
      !!actions?.Resend && resendToOption(actions?.Resend),
      { label: "Recall", icon: <RoundedDottedArrowIcon /> },
      status !== 0 && { label: "Add again", icon: <PaperIcon /> },
      showCloneContract && { label: "Clone Contract", icon: <PaperIcon /> },
      {
        label: "Rename",
        isMobile: true,
        icon: <PenRoundedOutlinedIcon />,
      },
      {
        label: "Move to",
        isMobile: true,
        icon: <MoveIcon />,
        isSelectMenu: true,
        children: moveToOption(folders),
      },
    ];
  } catch (error) {
    console.log("MENU5", error);
  }
};

//purchased not populate - non subscriber
export const MENU6 = (actions, folders) => {
  return [
    !!actions?.sign && {
      label: "Sign",
      icon: <PencilWriting color="#000000" />,
    },
    resendToOption(actions.Resend),
    { label: "Recall", icon: <RoundedDottedArrowIcon /> },
    { label: "Buy again", icon: <RoundedCartArrowIcon /> },
    showCloneContract && { label: "Clone Contract", icon: <PaperIcon /> },
    {
      label: "Rename",
      isMobile: true,
      icon: <PenRoundedOutlinedIcon />,
    },
    {
      label: "Move to",
      isMobile: true,
      icon: <MoveIcon />,
      isSelectMenu: true,
      children: moveToOption(folders),
    },
  ];
};

// populate regular builder
export const MENU7 = (actions, folders, status) => {
  return [
    resendToOption(actions.Resend),
    { label: "Recall", icon: <RoundedDottedArrowIcon /> },
    status !== 0 && { label: "Add again", icon: <PaperIcon /> },
    showCloneContract && { label: "Clone Contract", icon: <PaperIcon /> },
    {
      label: "Rename",
      isMobile: true,
      icon: <PenRoundedOutlinedIcon />,
    },
    {
      label: "Move to",
      isMobile: true,
      isSelectMenu: true,
      icon: <MoveIcon />,
      children: moveToOption(folders),
    },
  ];
};

// populate regular builder non subscriber
export const MENU8 = (actions, folders) => [
  resendToOption(actions.Resend),
  { label: "Recall", icon: <RoundedDottedArrowIcon /> },
  { label: "Buy again", icon: <RoundedCartArrowIcon /> },
  showCloneContract && { label: "Clone Contract", icon: <PaperIcon /> },
  {
    label: "Rename",
    isMobile: true,
    icon: <PenRoundedOutlinedIcon />,
  },
  {
    label: "Move to",
    isMobile: true,
    isSelectMenu: true,
    icon: <MoveIcon />,
    children: moveToOption(folders),
  },
];

// executed contract party 1 and subscriber
export const MENU9 = (folders, handleArchiveContract, status) => [
  { label: "Download Certified Contract", icon: <DownloadWithBar /> },
  {
    label: "Forward to",
    icon: <ForwardIcon />,
    isInputChildren: true,
  },
  showCloneContract && { label: "Clone Contract", icon: <PaperIcon /> },
  status !== 0 && { label: "Add again", icon: <PaperIcon /> },
  { label: "Archive", icon: <FolderIcon />, action: handleArchiveContract },
  {
    label: "Rename",
    isMobile: true,
    icon: <PenRoundedOutlinedIcon />,
  },
  {
    label: "Move to",
    isMobile: true,
    isSelectMenu: true,
    icon: <MoveIcon />,
    children: moveToOption(folders),
  },
];

export const MENU10 = (folders, handleArchiveContract) => [
  { label: "Download Certified Contract", icon: <DownloadWithBar /> },
  {
    label: "Forward to",
    icon: <ForwardIcon />,
    isInputChildren: true,
  },
  showCloneContract && { label: "Clone Contract", icon: <PaperIcon /> },
  { label: "Buy again", icon: <RoundedCartArrowIcon /> },
  { label: "Archive", icon: <FolderIcon />, action: handleArchiveContract },
  {
    label: "Rename",
    isMobile: true,
    icon: <PenRoundedOutlinedIcon />,
  },
  {
    label: "Move to",
    isMobile: true,
    isSelectMenu: true,
    icon: <MoveIcon />,
    children: moveToOption(folders),
  },
];

export const MENU11 = [
  {
    label: "Rename",
    icon: <PenRoundedOutlinedIcon />,
    data: { isProject: true },
  },
  { label: "Archive", icon: <FolderIcon />, data: { isProject: true } },
];

export const MENU12 = [
  { label: "Restore to General", icon: <RoundedDottedArrowIcon /> },
  { label: "Delete Permanently", icon: <DeleteIcon /> },
];

export const MENU13 = [
  { label: "Restore", icon: <RoundedDottedArrowIcon /> },
  { label: "Delete", icon: <DeleteIcon /> },
];
