import React from 'react';

const MeatBallsMenu = ({
 width = 18,
 height = 4,
 color = "#F8F8FF",
 className = 'custom-icon'
}) => {
  return (
    <svg
      width={width}
      height={height}
      className={className}
      viewBox="0 0 18 4"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.42854 1.71429C3.42854 1.93942 3.38427 2.16233 3.29824 2.37032C3.21221 2.57831 3.08613 2.76729 2.92717 2.92647C2.76822 3.08566 2.57951 3.21193 2.37183 3.29808C2.16414 3.38423 1.94155 3.42857 1.71675 3.42857C1.26313 3.42727 0.82834 3.24674 0.506881 2.92621C0.185422 2.60568 0.00326636 2.17105 0 1.71678C-2.1275e-06 1.4907 0.0445843 1.26685 0.1312 1.05808C0.217815 0.849301 0.344753 0.659715 0.504727 0.500202C0.664701 0.340688 0.85456 0.214387 1.0634 0.128551C1.27225 0.0427156 1.49597 -0.00096534 1.72171 1.6181e-05C1.94646 -0.000643845 2.16911 0.0432832 2.37682 0.129262C2.58452 0.21524 2.77316 0.341566 2.93185 0.500949C3.09054 0.660331 3.21614 0.849613 3.30139 1.05787C3.38664 1.26612 3.42985 1.48922 3.42854 1.71429Z"
        fill={color}
      />
      <path
        d="M6.85693 1.71739C6.85677 1.49202 6.90095 1.26883 6.98695 1.06055C7.07295 0.852279 7.19909 0.663001 7.35816 0.503526C7.67942 0.181453 8.11532 0.000329792 8.56998 4.49919e-07C9.02464 -0.000328892 9.4608 0.180163 9.78253 0.50177C10.1042 0.823377 10.2852 1.25976 10.2855 1.71491C10.283 2.65618 9.51147 3.42857 8.57122 3.42857C8.34642 3.4289 8.12376 3.38489 7.91595 3.29908C7.70814 3.21326 7.51925 3.0873 7.36006 2.92841C7.20088 2.76951 7.07451 2.58078 6.98819 2.37299C6.90186 2.1652 6.85726 1.94243 6.85693 1.71739Z"
        fill={color}
      />
      <path
        d="M13.7144 1.71056C13.7153 1.25591 13.8969 0.820266 14.2191 0.499474C14.5413 0.178682 14.9777 -0.000982907 15.4324 4.0448e-06C15.887 0.000990996 16.3227 0.182549 16.6435 0.504737C16.9642 0.826925 17.1439 1.26335 17.1429 1.71801C17.1419 2.17266 16.9604 2.6083 16.6382 2.9291C16.316 3.24989 15.8796 3.42955 15.4249 3.42857C14.9703 3.42758 14.5346 3.24602 14.2138 2.92383C13.893 2.60165 13.7134 2.16522 13.7144 1.71056Z"
        fill={color}
      />
    </svg>

  );
};

export default MeatBallsMenu;
