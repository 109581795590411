//@packages
import React, { useState } from 'react';
import classNames from "classnames";

//@components
import Popover from "../../atoms/Popover";
import MenuContentPopover from "../MenuContentPopover";
import MeatBallsMenuIcon from "../../atoms/icons/MeatBallsMenu";

//@constants
import { MENU11, MENU13 } from "../../../constants/actionsMenu";
import { MIN_SWIPE_DISTANCE } from "../../../constants/variables";

//@context
import { useMobileHeaderProvider } from "../../../context/mobileHeader";
import { useProjectProvider } from "../../../context/projects";
import { useContractsProvider } from "../../../context/contracts";

//@styles
import classes from "./styles.module.scss";

const MobileProjectHeaderTitle = ({ iconContainerClassName, selectorClassName }) => {
  const { input } = useProjectProvider();
  const { visible, setVisible } = useContractsProvider();

  const {
    setToggleArchived,
    setToggleGeneral,
    setShowGeneralOptions,
    setShowArchivedOptions,
    titleGeneral,
    titleArchived,
    isItemGeneralSelected,
    isItemArchivedSelected,
    showAllOptions,
    setShowAllOptions
  } = useMobileHeaderProvider();

  const [touchStart, setTouchStart] = useState(null);
  const [touchEnd, setTouchEnd] = useState(null);

  const handleClose = () => {
    setToggleGeneral(false);
    setShowGeneralOptions(false);
    setToggleArchived(false);
    setShowArchivedOptions(false);
    setShowAllOptions(false);
  }

  const handleOpen = () => {
    setToggleGeneral(true);
    setShowGeneralOptions(true);
    setToggleArchived(true);
    setShowAllOptions(true)
  }

  const onTouchStart = (e) => {
    setTouchEnd(null);
    setTouchStart(e.targetTouches[ 0 ].clientY);
  }

  const onTouchMove = (e) => {
    setTouchEnd(e.targetTouches[ 0 ].clientY);
  }

  const onTouchEnd = () => {
    if (!touchStart || !touchEnd) return;
    const distance = touchStart - touchEnd;
    const isUpSwipe = distance > MIN_SWIPE_DISTANCE;
    const isDownSwipe = distance < -MIN_SWIPE_DISTANCE;
    if (isUpSwipe || isDownSwipe) {
      if (isUpSwipe) handleClose();
      else handleOpen();
    }
  }

  const itemSelectedClasses = classNames(
    classes.itemSelected,
    isItemGeneralSelected && classes.itemGeneralSelected,
    isItemArchivedSelected && classes.itemArchivedSelected
  );

  return (
    <div
      onTouchStart={onTouchStart}
      onTouchMove={onTouchMove}
      onTouchEnd={onTouchEnd}
      className={classNames(itemSelectedClasses, selectorClassName)}
    >
      {isItemGeneralSelected && (
        <>
          <span>{titleGeneral}</span>
          {!showAllOptions && <span className={classes.swiper} />}

          <Popover
            content={
              <MenuContentPopover
                project={{ title: input.value, item: input.item }}
                items={MENU11}
              />
            }
            visible={input?.item?.id === visible.item && visible.visible}
            handleVisibleChange={(value) => setVisible({ item: input?.item?.id, visible: value })}
            iconContainerClassName={iconContainerClassName}
            icon={<MeatBallsMenuIcon />}
          />
        </>
      )}
      {isItemArchivedSelected && (
        <>
          <span>{titleArchived}</span>

          <Popover
            content={
              <MenuContentPopover
                project={{ title: input.value, item: input.item }}
                items={MENU13}
              />}
            visible={input?.item?.id === visible.item && visible.visible}
            handleVisibleChange={(value) => setVisible({ item: input?.item?.id, visible: value })}
            iconContainerClassName={iconContainerClassName}
            icon={<MeatBallsMenuIcon color={'var(--selector-text)'} />}
          />
        </>
      )}
    </div>
  );
};

export default MobileProjectHeaderTitle;
