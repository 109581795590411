// @packages
import { Helmet } from "react-helmet";
import { connect } from "react-redux";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
// @components
import FullEditor from "../components/organisms/FullEditor";
import PageWrapper from "../components/PageWrapper";
import { addViewport } from "../utils";
import { getAdminTemplate } from "../store/reducers/templatesAdmin/actions";
import { titleContractSelector } from "../store/reducers/templatesAdmin/selectors";

const FullEditorPage = ({ getAdminTemplateProp, productTitle }) => {
  const { ContractId } = useParams();

  useEffect(() => {
    if (ContractId) {
      getAdminTemplateProp(ContractId, true);
    }
  }, [ContractId]);

  useEffect(() => {
    addViewport({ vieportWidth: "device-width", clear: true });
  }, []);

  return (
    <PageWrapper isFullEditor className="product-page">
      <Helmet>
        <title>
          {productTitle ? productTitle : "Product"} - Creators Legal
        </title>
      </Helmet>
      <FullEditor />
    </PageWrapper>
  );
};

export default connect(
  (store) => {
    return {
      productTitle: titleContractSelector(store),
    };
  },
  {
    getAdminTemplateProp: getAdminTemplate,
  }
)(FullEditorPage);
