// @packages
import ReactDOM from "react-dom";
import ReactQuill, { Quill } from "react-quill";
import classnames from "classnames";
import { Component } from "react";
// @components
import DividerBlot from "../../../AdminEditor/EditorDividerBlot";
import FieldBlot from "../../../AdminEditor/EditorFieldBlot";
import FooterContainer from "../FooterContainer";
import FullEditorService from "../../../../services/fullContractEditor";
import PDFFooter from "../../../molecules/PDFFooter";
import QuillToolbar from "../QuillToolbar";
import { CONFIRMATION_PAGE, PRODUCT_PAGE } from "../../../../constants/siteMap";
import { PDF_STYLE } from "../../../../constants/variables";
import { NoEditableTableBlot } from "./quillParts";
import { handleTableInsert, initialValues } from "./helpers";
// @styles
import "react-quill/dist/quill.snow.css";
import classes from "./styles.module.scss";

const Parchment = Quill.import("parchment");

const config = {
  theme: "snow",
  modules: {
    toolbar: {
      container: "#toolbar",
      handlers: {},
    },
    clipboard: {
      matchVisual: false,
      matchers: [["TABLE", handleTableInsert]],
    },
    history: {
      delay: 2000,
      maxStack: 100,
      userOnly: true,
    },
  },
  formats: [
    "header",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "script",
    "list",
    "bullet",
    "indent",
    "align",
    "link",
    "image",
    "field",
    "divider",
    "table",
  ],
};

export default class QuillContainer extends Component {
  constructor(props) {
    super(props);

    Quill.register({ "formats/field": FieldBlot }, true);
    Quill.register({ "formats/divider": DividerBlot }, true);
    Quill.register({ "formats/table": NoEditableTableBlot }, true);

    this.state = {
      docStepData: [],
      editorHtml: props.contractContent,
      steps: props.contractSteps.map((item) => item.toJS()),
    };
    this.reactQuillRef = null;
    this.quillRef = null;
  }

  attachQuillRefs = () => {
    if (typeof this.reactQuillRef.getEditor !== "function") return;
    if (this.quillRef != null) return;
    const quillRef = this.reactQuillRef.getEditor();
    if (quillRef != null) this.quillRef = quillRef;
    this.quillRef.on("selection-change", this.onSelectionChange);
  };

  onSelectionChange = (range) => {
    if (range) {
      this.setState({
        editorRange: range,
      });
    }
  };

  onEditorChange = (editorHtml) => {
    this.setState({
      editorHtml,
    });
  };

  goToConfirmation = () => {
    const { templateId, productId } = this.props;
    const { history } = this.props;
    history.push(
      `${PRODUCT_PAGE}/${productId}${CONFIRMATION_PAGE}/${templateId}`
    );
    window.scrollTo(0, 0);
  };

  onSubmit = async (callback) => {
    const { form, logo, parties, position, templateId, setPartyLabelWithMissingFields } = this.props;
    const { editorHtml } = this.state;
    let template = PDF_STYLE + editorHtml;

    const partiesFilledControl = parties.map((values) =>
      values.reduce(
        (acc, currentItem) => 
          acc + ( ( (currentItem.is_predefined === 1 || currentItem.is_predefined === 2 || currentItem.is_predefined === 4) && currentItem.val && currentItem.val !== '' ) ? 1 : 0 )
          , 0
      )
    );
    console.log('partiesFilledControl', partiesFilledControl); 

    const filterParties = parties.map((values) =>
      values.filter(
        (item) => item.is_predefined === 1 || item.is_predefined === 2 || item.is_predefined === 4
      )
    );

    // Adding parties to the template
    template += document.getElementById("FullEditor-PartiesSection").outerHTML;
    template = template.replace(/[“”]/gi, '"');

    let continueSendingData = true;

    setPartyLabelWithMissingFields(false);
    console.log('partiesFilledControl length', partiesFilledControl.length); 
    for(let i = 0; i < partiesFilledControl.length; i++) {
      let partyItemFilledCount = partiesFilledControl[i];
      console.log('partyItemFilledCount', partyItemFilledCount); 
      if(partyItemFilledCount < 3){
        let partyLabel = parties[i].filter(
          (item) => item.is_predefined === 3
        );
        setPartyLabelWithMissingFields(partyLabel[0].title);
        console.log('partyLabel', partyLabel[0].title); 
        continueSendingData = false;
        break;
      }
    }

    // Validating form
    try {
      await form.validateFields();
      if(!continueSendingData){
        return false;
      }

      FullEditorService.save(
        templateId,
        template,
        logo,
        filterParties,
        position
      ).then(() => {
        if (callback) {
          callback();
        } else {
          this.goToConfirmation();
        }
      });
    } catch (errorInfo) {}
  };

  handleFieldClick = (data, text = false, insertText = false) => {
    const Delta = Quill.import("delta");
    const fieldData = data.detail.data;
    let id = fieldData.id;
    let node = document.getElementById(id);
    let blot = Parchment.find(node);
    if (blot && node.className.search("quill-field") > -1) {
      let index = blot.offset(this.quillRef.scroll);
      const replaceDelta = new Delta().retain(index).insert("").delete(1);
      if (text && insertText) {
        this.quillRef.updateContents(replaceDelta);
        this.quillRef.insertText(index, text);
      }
      if (!insertText) {
        this.quillRef.updateContents(replaceDelta);
        this.quillRef.setSelection(index);
      }
    }
  };

  componentDidMount() {
    const { steps } = this.state;
    this.attachQuillRefs();
    initialValues(steps, this);
    ReactDOM.findDOMNode(this).addEventListener(
      "field-clicked",
      this.handleFieldClick
    );
  }

  handleReplaceContentNew = (data) => {
    const { steps } = this.state;
    data.map((field) => {
      const step = steps.filter((x) => x.fields.hasOwnProperty(field.id)); // .filter(item => item.fields[key])
      const replacedField = step[0].fields[field.id];
      const aux = {
        detail: {
          data: {
            id: replacedField.id,
          },
        },
      };
      this.handleFieldClick(aux, field.value, true);
    });
  };

  componentDidUpdate(_, prevState) {
    const { docStepData, steps } = this.state;
    const { contractSteps } = this.props;
    const newContractSteps = contractSteps.map((item) => item.toJS());
    if (prevState.docStepData !== docStepData) {
      this.handleReplaceContentNew(docStepData);
    }
    if (JSON.stringify(newContractSteps) !== JSON.stringify(steps)) {
      this.setState({
        steps: newContractSteps,
      }, () => {
        initialValues(newContractSteps, this);
      });
    }
  }

  componentWillUnmount() {
    ReactDOM.findDOMNode(this).removeEventListener(
      "field-clicked",
      this.handleFieldClick
    );
  }

  render() {
    const { editorHtml } = this.state;
    const { contractSKU, parties, position, contractImages } = this.props;
    return (
      <div className={classes.container}>
        <div
          className={classnames(
            classes.quillContainer,
            position === "top" && classes.quillTop
          )}
        >
          <div className={classes.borderTop}/>
          <QuillToolbar />
          {position === "top" && (
            <PDFFooter contractSKU={contractSKU} placement="top" />
          )}
          <ReactQuill
            formats={config.formats}
            modules={config.modules}
            onChange={this.onEditorChange}
            ref={(element) => {
              this.reactQuillRef = element;
            }}
            theme={config.theme}
            value={editorHtml}
          />
          <PDFFooter
            contractSKU={contractSKU}
            contractImages={contractImages}
            parties={parties}
            position={position}
          />
        </div>

        <FooterContainer onSave={this.onSubmit} />
      </div>
    );
  }
}
