const OutlineUndo = ({
  className = "custom-icon",
  color = "#5F5F5F",
  size = 19,
}) => (
  <svg
    className={className}
    fill="none"
    height={size}
    viewBox={`0 0 ${size} ${size}`}
    width={size}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4.9062 7.02365H7.4347L4.09123 10.4852L0.737305 7.04173H3.27626C3.40164 4.46587 4.63454 2.45941 7.12125 1.08562C8.93926 0.0733578 10.9453 -0.206823 13.0768 0.1547C17.0994 0.841595 19.9936 4.34837 19.4921 7.78285C18.8965 11.841 14.8217 14.1276 11.4469 14.0011V12.7176C13.9336 12.5911 15.8979 11.6602 17.1412 9.75315C17.9473 8.55121 18.1833 7.12572 17.7995 5.77639C16.9949 2.82998 13.8813 1.01332 10.3916 1.42907C7.30932 1.79963 4.77037 4.34837 4.9062 7.02365Z"
      fill={color}
    />
    <path d="M7.41347 11.207V12.4904H0.757874V11.207H7.41347Z" fill={color} />
    <path
      d="M16.9741 5.39584L15.6472 5.86582C15.5531 5.65795 15.4591 5.47718 15.3442 5.26027L16.6607 4.78125C16.7651 4.98009 16.8487 5.16989 16.9741 5.39584Z"
      fill={color}
    />
    <path
      d="M17.2253 6.73438V7.34897H15.867C15.8565 7.15013 15.8461 6.96937 15.8356 6.73438H17.2253Z"
      fill={color}
    />
    <path
      d="M15.3545 8.79428C15.4485 8.5864 15.5321 8.40564 15.647 8.17969L16.9635 8.64967L16.671 9.26426L15.3545 8.79428Z"
      fill={color}
    />
    <path
      d="M13.464 3.6155L12.7535 3.36243L13.2968 2.22363L14.0073 2.4767L13.464 3.6155Z"
      fill={color}
    />
    <path
      d="M6.14935 4.77246L7.46584 5.25148L7.18373 5.86607L5.87769 5.40513L6.14935 4.77246Z"
      fill={color}
    />
    <path
      d="M12.7432 10.6928L13.4536 10.4307C13.6417 10.8012 13.8089 11.1537 14.0074 11.5604L13.3074 11.8316L12.7432 10.6928Z"
      fill={color}
    />
    <path
      d="M10.078 3.35364L9.36747 3.61575L8.82416 2.48599L9.5242 2.21484L10.078 3.35364Z"
      fill={color}
    />
    <path d="M11.0807 1.98047H11.7703V3.18253H11.0807V1.98047Z" fill={color} />
    <path d="M11.7601 12.0663H11.0705V10.8643H11.7601V12.0663Z" fill={color} />
    <path
      d="M8.03079 4.55516L7.04865 3.66039L7.52927 3.22656L8.53231 4.09422L8.03079 4.55516Z"
      fill={color}
    />
    <path
      d="M14.7689 4.49262L14.3614 4.04072L15.3435 3.23633L15.7928 3.67919L14.7689 4.49262Z"
      fill={color}
    />
    <path
      d="M15.2392 10.8012L14.3511 9.93356L14.8421 9.54492L15.7616 10.4487L15.2392 10.8012Z"
      fill={color}
    />
  </svg>
);

export default OutlineUndo;
