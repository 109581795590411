//@packages
import axios from "axios";

//@constants
import { API } from "../constants/variables";

export const getCategories = async () => {
  const resp = await axios.get(`${API}categories/list`, {});
  return { data: resp.data, success: resp.status === 200 };
};

export const updateCategory = async (category) => {
  const resp = await axios.put(`${API}categories/${category.id}/update`, {
    name: category.name,
    url: category.url,
    is_active: category.is_active,
  });
  return { data: resp.data, success: resp.status === 200 };
};

export const createCategory = async (category) => {
  const resp = await axios.post(`${API}categories/create`, {
    name: category.name,
    url: category.url,
  });
  return { data: resp.data, success: resp.status === 200 };
};

export const deleteCategory = async (id) => {
  const resp = await axios.delete(`${API}categories/${id}/delete`);
  return { data: resp.data, success: resp.status === 200 };
};
