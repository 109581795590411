const Archive = ({
  className = "custom-icon",
  color = "#7A7A7A",
  size = 24,
  onClick = Function.prototype,
  pointer = false,
  style = {},
}) => (
  <svg
    className={className}
    fill="none"
    height={size}
    width={size}
    viewBox={`0 0 ${size} ${size}`}
    xmlns="http://www.w3.org/2000/svg"
    style={pointer ? { cursor: "pointer" } : {}}
    onClick={onClick}
  >
    <path
      d="M25.2615 16.4956L24.9105 17.3314C23.9173 19.7074 22.9227 22.0821 21.9268 24.4554C21.8364 24.6688 21.7286 24.8744 21.6043 25.0699C21.192 25.7172 20.5798 25.9793 19.8369 25.9793C16.3552 25.9834 12.8695 25.9671 9.38779 25.9875C7.97552 25.9957 7.2898 25.0699 7.0898 24.0457C7.03395 23.7445 7.00661 23.4386 7.00817 23.1322C7 19.4657 7.00408 15.7992 7 12.1327V11.9156L7.21633 11.9033H13.3185C13.4736 11.9033 13.5552 11.9402 13.6123 12.1C13.7634 12.5178 13.9389 12.9234 14.0981 13.3371C14.1552 13.4887 14.2287 13.5543 14.4042 13.5502C15.2722 13.542 16.143 13.542 17.0165 13.5502C17.1327 13.558 17.2432 13.6041 17.3308 13.6813C17.792 14.1237 18.241 14.5825 18.694 15.0372L18.8043 15.1642H17.8002C16.3022 15.1642 14.8042 15.1601 13.3103 15.1683C13.1389 15.1724 13.0736 15.111 13.0164 14.9635C12.8531 14.5347 12.6817 14.1046 12.5021 13.6731C12.4776 13.6116 12.3878 13.5338 12.3225 13.5338C11.1021 13.5256 9.87759 13.5256 8.62452 13.5256C8.62043 13.6075 8.61635 13.6772 8.61635 13.7509V23.7057C8.61635 24.0539 8.75921 24.2629 9.0286 24.3284C9.17919 24.3626 9.33687 24.3448 9.47614 24.2779C9.6154 24.211 9.72809 24.0989 9.79596 23.9597C9.96331 23.6074 10.1102 23.251 10.2694 22.8946L13.0001 16.6841C13.0531 16.5612 13.1021 16.4915 13.2613 16.4915C17.2001 16.4997 21.139 16.4956 25.0778 16.4997L25.2615 16.4956ZM22.837 18.122L22.6492 18.1138H14.3062C14.1675 18.1138 14.1021 18.163 14.0491 18.2859C13.1892 20.2495 12.3266 22.2132 11.4613 24.1768C11.4368 24.2301 11.4205 24.2874 11.3919 24.3653H11.6368C14.3021 24.3653 16.9675 24.353 19.6328 24.3776C20.09 24.3817 20.3349 24.2178 20.4655 23.8122L20.49 23.7549L22.6206 18.6669L22.837 18.122Z"
      fill={color}
    />
    <path
      d="M16.832 11.199L17.9423 10.093L20.0607 12.2437V7H21.6688V12.1658L21.7219 12.1986L23.8158 10.093L24.922 11.2072C23.575 12.5591 22.2199 13.9233 20.877 15.267L16.832 11.199Z"
      fill={color}
    />
    <circle cx="16.5" cy="16.5" r="16" stroke={color} />
  </svg>
);

export default Archive;
