//@packages
import React from "react";
import { Divider } from "antd";
import { useSelector } from "react-redux";

//@ icons
import Icon, { CloseOutlined, EyeOutlined } from "@ant-design/icons";

// @constants
import { MOBILE_RESOLUTION } from "../../../constants/variables";

//@ styles
import classes from "./styles.module.scss";

//@utils
import { useMediaQuery } from "../../utils";
import classNames from "classnames";

const handleStatus = {
  "CREATE": "created",
  "SENT": "sent",
  "VIEWED": 'viewed',
  "COMPLETED": "completed"
};

const NotificationsCard = ({ data, className, handleVisible }) => {
  const onClick = () => {
    handleVisible(false);
  };

  const { userName, userEmail } = useSelector(store => store.user.toJS())

  const isMobile = useMediaQuery(MOBILE_RESOLUTION);

  return (
    <div className={classNames(className, classes.notificationsContainer)}>
      <h1 className={classes.title}>Notifications</h1>
      {!isMobile && <CloseOutlined className={classes.closeIcon} onClick={onClick} />}
      <div className={classes.notificationContainer}>
        {Boolean(data.length) ? data.map((notification, index, arr) => {
          return (
            <div className={classes.subTitleContainer}>
              <p className={classes.subTitle}>{notification.title}</p>
              <div className={classes.subTitleBodyContainer}>
                <Icon component={EyeOutlined} />
                <p className={classes.subTitleBody}>
                  {
                    notification.event !== "CREATE" ?
                      `Envelope ${handleStatus[ notification.event ]} by ${notification.user_name} (${notification.user_email})`
                      : 'Was added to your briefcase'
                  }
                </p>
              </div>
              {arr.length - 1 === index ? null : <Divider />}
            </div>
          );
        }) : <p className={classes.subTitleBody}> There are no notifications </p>}
      </div>
    </div>
  );
};

export default NotificationsCard;
