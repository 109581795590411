//@packages
import React from 'react';

const PenRoundedOutlined = ({
  size = 11,
  color= "#000000",
  className = 'custom-icon'
}) => {
  return (
    <svg
      className={className}
      width={size}
      height={size}
      viewBox="0 0 11 11"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.10989 0C9.74718 0.0236369 10.177 0.322053 10.5386 0.741608C10.8261 1.07252 11.0129 1.44481 11.001 1.89391C10.9892 2.34301 10.7995 2.74188 10.4853 3.06098C9.60491 3.95623 8.71566 4.83965 7.82641 5.72604L5.37209 8.15768C4.77926 8.7427 4.19236 9.32771 3.59953 9.90977C3.54 9.97105 3.46691 10.0176 3.38611 10.0457L0.510879 10.9705C0.359707 11.0177 0.226319 11.0207 0.107753 10.9055C-0.0108131 10.7902 -0.0167418 10.6514 0.0277205 10.4977C0.309315 9.56703 0.59091 8.63633 0.863613 7.70267C0.920187 7.50939 1.02646 7.33421 1.17189 7.19448C2.23898 6.13968 3.29718 5.07898 4.35835 4.02123C5.51734 2.86302 6.67039 1.70481 7.83234 0.552512C8.19693 0.19205 8.64748 0 9.10989 0ZM3.57878 8.94361L3.63806 8.88747L9.19289 3.38599C9.21738 3.36701 9.23498 3.34057 9.24301 3.3107C9.25104 3.28083 9.24905 3.24917 9.23735 3.22053C9.00911 2.47892 8.52003 1.98254 7.79677 1.71072C7.7197 1.68117 7.66931 1.69299 7.61299 1.75209C7.27903 2.09285 6.94211 2.43066 6.60222 2.76552L2.35162 7.0172C2.23009 7.13834 2.11152 7.26244 1.98703 7.38948C2.71325 7.71745 3.24976 8.22269 3.57878 8.94361ZM3.0067 9.43112C2.84663 8.79588 2.12634 8.09268 1.51573 7.98041C1.28749 8.69838 1.05925 9.41635 0.825079 10.1639L3.0067 9.43112ZM8.25029 1.12866C9.10989 1.60731 9.26403 1.7639 9.82425 2.75074C10.0199 2.56165 10.1859 2.35778 10.2689 2.10073C10.3327 1.90886 10.3178 1.69961 10.2274 1.51867C10.1236 1.30594 9.9695 1.13457 9.79461 0.980931C9.26403 0.511148 8.65638 0.700243 8.25029 1.12866Z"
        fill={color}
      />
    </svg>

  );
};

export default PenRoundedOutlined;
