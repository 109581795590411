// @packages
import axios from "axios";
import createAuthRefreshInterceptor from "axios-auth-refresh";

// @components
import { TOKEN_DOMAIN } from "../constants/variables";
import { getTokenCookies, setTokenCookies } from "../store/utils";

const axiosInstance = axios.create({
  withCredentials: true,
  headers: {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
  }
});

axios.interceptors.request.use((req) => {
  req.headers['wp-token'] = getTokenCookies()

  return req;
});

const refreshAuthLogic = () =>
  axiosInstance.post(TOKEN_DOMAIN).then((tokenRefreshResponse) => {
    const token = tokenRefreshResponse.data.token;
    setTokenCookies(token);
    return Promise.resolve();
  });

createAuthRefreshInterceptor(axios, refreshAuthLogic);

export default axios;
