//@packages
import React, { Fragment, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useSelector, useDispatch, connect } from "react-redux";
import { Button, Form, Input, Menu, Modal, message } from "antd";
import hellosignEmbedded from "hellosign-embedded";

//@components
import SendForReviewModal from "../SendForReviewModal";
import DeleteWarningModal from "../../atoms/deleteWarningModal";
import FullEditorConfirmationModal from "../../FullEditorConfirmationModal";

//@context
import { useContractsProvider } from "../../../context/contracts";
import { useProjectProvider } from "../../../context/projects";
import { useMobileHeaderProvider } from "../../../context/mobileHeader";

//@store
import { deleteTemplatesFolderGlobal } from "../../../store/reducers/templates/actions";
import { handleActionSentTemplate } from "../../../store/reducers/templatesSent/actions";
import { sendForReview } from "../../../store/reducers/templatesAdmin/actions";
import { updateContractSignStatus } from "../../../store/reducers/templatesAdmin/actions";
import { helloSignOriginTemplatesInProgressSelector } from "../../../store/reducers/templatesInProgress/selectors";
import { clearTemplateHS } from "../../../store/reducers/templatesInProgress/actions";

// @constants
import {
  APP_DOMAIN,
  DEFAULT_TITLE_ARCHIVED,
  DEFAULT_TITLE_GENERAL,
  LOCALHOST,
  MOBILE_RESOLUTION,
  UNKNOWN_ERROR,
  URL_GENERAL,
} from "../../../constants/variables";

//@styles
import classes from "./styles.module.scss";

//@utils
import {
  downloadClickHandler,
  downloadCertificatePDF,
  downloadTemplatePDF,
} from "../../../utils/pdfDownloader";
import {
  addContractToFolder,
  forwardTo,
  removeContractFromFolder,
  resendTo,
} from "../../../utils/contract";
import { useMediaQuery } from "../../utils";

//@constants
import { PRODUCT_PAGE } from "../../../constants/siteMap";
import { FORM_VALIDATE_MESSAGES } from "../../../constants/staticErrors";
import { CANCEL, DELETE } from "../../../constants/staticTexts";
import ThankYouSendForReviewModal from "../ThankYouSendForReviewModal";

const MenuContentPopover = ({
  items,
  project,
  archiveFnStructure,
  updateContractStatus,
  clearTemplateHelloSignData,
}) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const isLoading = useSelector((store) => store.templatesAdmin.loading);

  const location = useLocation();
  const { hash } = location;
  const [visibleModal, setVisibleModal] = useState(false);
  const [isDownloadingPDF, setIsDownloadingPDF] = useState();
  const [isOpen, setIsOpen] = useState(false);
  const [openThankYou, setOpenThankYou] = useState(false);
  const [thankYouOptions, setThankYouOptions] = useState({
    message: "",
    title: "",
  });
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  // Forward to
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isModalSuccess, setIsModalSuccess] = useState(false);
  const [modalRecord, setModalRecord] = useState(null);
  const [auxProject, setAuxProject] = useState(null);
  const [isForwardError, setIsForwardError] = useState({
    status: false,
    message: "",
  });
  const [openFullEditorConfirmation, setOpenFullEditorConfirmation] =
    useState(false);

  const {
    setToggleArchived,
    setToggleGeneral,
    setTitleGeneral,
    setTitleArchived,
    setIsItemGeneralSelected,
    setIsItemArchivedSelected,
    setRevert,
    setShowAllOptions,
    setShowGeneralOptions,
    setShowArchivedOptions,
    toggleArchived,
    toggleGeneral,
    showGeneralOptions,
  } = useMobileHeaderProvider();

  const {
    archiveFolder,
    projectData,
    setProjectData,
    unarchiveFolder,
    setContractName,
    setShowInput,
    archiveContract,
    unarchiveContract,
    setRefreshData,
    setFoldersSorted,
    deleteContract,
    recallContractById,
    signContractById,
    loadGeneralData,
    cloneContracts,
    projectMapInfo,
    visible: visibleAction,
    setVisible: setVisibleAction,
    sendForSignature
  } = useContractsProvider();

  const {
    input,
    setInput,
    folders,
    setFolders,
    foldersMapped,
    setFoldersMapped,
  } = useProjectProvider();

  const activeForwardToModal = () => {
    setIsModalVisible(true);
  };

  const hideModal = () => {
    setIsModalVisible(false);
    setIsModalSuccess(false);
    setModalRecord(null);
    setIsForwardError({
      status: false,
      message: "",
    });
  };

  const onSendForwardTo = async () => {
    const mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

    if (modalRecord && modalRecord.match(mailformat)) {
      setIsForwardError({ status: false, message: "" });
      const [status, data] = await forwardTo(project, modalRecord);
      if (status) {
        setIsModalSuccess(true);
      } else {
        setIsForwardError({
          status: true,
          message: data?.errors[0] || UNKNOWN_ERROR,
        });
      }
    } else {
      setIsForwardError({ status: true, message: "Email formal is not valid" });
    }
  };

  const redirectTo = (link, href = false) => {
    if (href) {
      const urlLocation =
        window.location.hostname === "localhost" ? LOCALHOST : APP_DOMAIN;
      window.location.href = urlLocation + link;
    } else {
      history.push(link);
    }
  };

  const handleOpenSendForReview = () => setIsOpen(true);
  const handleCloseModal = () => setIsOpen(false);
  const handleOpenThankYouModal = () => setOpenThankYou(true);
  const handleCloseThankYouModal = () => {
    setOpenThankYou(false);
  };

  const handleSendForReview = (emails) => {
    if (Boolean(emails) && Array.isArray(emails) && emails.length > 0) {
      const data = {
        template_id: project.id,
        email_list: emails,
      };
      dispatch(sendForReview({ data }));
      handleCloseModal();
      setThankYouOptions({
        message: "Your contract has been sent successfully!",
        title: "Thank You!",
      });
      handleOpenThankYouModal();
    }
  };

  const handleRenameContract = (project, isProject) => {
    if (!isProject) {
      setContractName(project.title);
      setShowInput({ item: project.id, show: true });
    } else {
      setInput({
        ...input,
        showInput: true,
        isEdit: true,
        value: project.title,
      });
    }
  };

  const archiveContractFlow = (project, isProject) => {
    if (!isProject) {
      if (project.folder_id === 0) {
        archiveContract(project.id, project.status);
      } else {
        // Get out project

        // Archive
        archiveContract(project.id, project.status);
      }
    } else {
      archiveFolder(hash);
      const id = hash.replace("#", "");
      const foldersFiltered = folders.map((folder) => {
        if (folder.id === Number(id)) folder.is_archived = 1;
        return folder;
      });
      setFolders(foldersFiltered);
      setInput({ ...input, showInput: false, value: null });
      setTitleGeneral(DEFAULT_TITLE_GENERAL);
      setIsItemGeneralSelected(false);
      setToggleGeneral(true);
      if (showGeneralOptions) setToggleArchived(true);
      if (toggleArchived) setShowArchivedOptions(true);
      if (toggleGeneral) setShowGeneralOptions(true);
      history.push(URL_GENERAL);
    }
  };

  const unArchiveProject = () => {
    const id = hash.replace("#", "");
    const foldersFiltered = folders.map((folder) => {
      if (folder.id === Number(id)) folder.is_archived = 0;
      return folder;
    });
    unarchiveFolder(hash);
    setInput({ ...input, showInput: false, value: null });
    setRevert(false);
    setIsItemArchivedSelected(false);
    setTitleArchived(DEFAULT_TITLE_ARCHIVED);
    setToggleArchived(false);
    setToggleGeneral(true);
    setFolders(foldersFiltered);
    history.push(URL_GENERAL);
  };

  const restoreContract = async (project) => {
    let withoutFolder = true;
    let data = {};
    if (project.folder_id !== 0) {
      data = archiveFnStructure(project.folder_id, project.id);
      const { success } = await removeContractFromFolder(
        project.id,
        data.status,
        data.structure
      );
      withoutFolder = success;
      if (!success) {
        return false;
      }
    }

    if (withoutFolder && project.is_archived === 1) {
      const resp = await unarchiveContract(project.id, 3);
      if (resp?.success) {
        setRefreshData(true);
      } else {
        return false;
      }
    } else {
      setRefreshData(true);
    }
  };

  const handleMoveContract = async (folder, project) => {
    try {
      let folderFound;
      let folderTemplates;
      const contract = project;
      let folderId;
      let type;

      let folderIdList =
        folder && folder.title !== DEFAULT_TITLE_GENERAL
          ? projectMapInfo[folder.title].ids
          : [];

      if (!contract.status) {
        folderFound = folderIdList[0];
        folderId = folderIdList[0]?.id;
        type = "template";
      } else if (contract.status === 4) {
        folderFound = folderIdList[2];
        folderId = folderIdList[2]?.id;
        type = "envelope";
      } else {
        folderFound = folderIdList[1];
        folderId = folderIdList[1]?.id;
        type = "envelope";
      }

      folderFound = {
        ...folderFound,
        templates: folderFound
          ? projectMapInfo[folder.title].templates
          : projectData,
      };

      folderTemplates = folderFound.templates.map((t) => {
        return {
          id: t.id,
          type,
        };
      });

      const contractStatus =
        contract.status === "completed" || contract.status === 4
          ? 4
          : contract.status;

      await addContractToFolder(
        folderId ?? 0,
        contract.id,
        contractStatus,
        folderId ? folderTemplates : []
      );

      await loadGeneralData();
      setShowAllOptions(false);
      setVisibleAction({ item: null, visible: false });

      if (folderId && folder.id) {
        setIsItemGeneralSelected(true);
        setTitleGeneral(folder.title);
        setToggleGeneral(false);
        history.push(`/#${folder.id}`);
      } else {
        setIsItemGeneralSelected(false);
        setToggleGeneral(true);
        history.push(`/#general`);
      }
    } catch (e) {
      console.log(e.message);
    }
  };

  const handleDeleteFolder = (project) => {
    setVisibleModal(true);
    if (visibleModal) {
      dispatch(deleteTemplatesFolderGlobal(project.item));
      setInput({ ...input, showInput: false, value: null });
      setVisibleModal(false);
      const newFolders = foldersMapped.filter((f) => f.id !== project.item.id);
      setFoldersMapped(newFolders);
      setFoldersSorted(newFolders);
      setIsItemArchivedSelected(false);
      setTitleGeneral(DEFAULT_TITLE_GENERAL);
      setToggleArchived(false);
      setToggleGeneral(true);
      history.push(URL_GENERAL);
    }
  };

  const onDeleteProjectClick = async () => {
    try {
      setShowDeleteModal(false);
      let project = auxProject;
      // Delete child contracts
      const { success } = await deleteContract(project.id, project.status);

      if (success) {
        setProjectData((prevState) =>
          prevState.filter((item) => item.id !== project.id)
        );
      }

      setAuxProject(null);
    } catch (error) {
      console.log(error);
    }
  };

  const handleDeleteContract = async (project) => {
    setShowDeleteModal(true);
    setAuxProject(project);
  };

  const handleRecallContract = async (project) => {
    try {
      const { success } = await recallContractById(project.id, project.status);
      if (success) {
        loadGeneralData("Recall");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleSignContract = async (project) => {
    const resp = await signContractById(project.id, project.status);

    if (resp?.success) {
      const hs_key = resp?.data?.data?.action_result?.original?.data?.hs_key;
      const hs_url = resp?.data?.data?.action_result?.original?.data?.hs_url;
      const helloSignClient = new hellosignEmbedded({
        clientId: hs_key,
      });

      showContractModal(helloSignClient, hs_url);

      helloSignClient.on("sign", () => {
        updateContractStatus(
          resp?.data?.data?.action_result?.original?.data?.envelope_id
        );
        helloSignClient.close();
        loadGeneralData();
      });

      helloSignClient.on("close", () => {
        helloSignClient.close();
        clearTemplateHelloSignData();
      });
    }
  };

  const showContractModal = (helloSignClient, signingUrl) => {
    helloSignClient.open(signingUrl, {
      testMode: true,
      skipDomainVerification: true,
      locale: "EN_US",
      whiteLabelingOptions: {
        page_background_color: "#F7F8F9",
        header_background_color: "#060357",
        primary_button_color: "#FE7C38",
        primary_button_color_hover: "#FF3C61",
        secondary_button_color: "#DAF3D6",
        secondary_button_text_color: "#4F4F4F",
        secondary_button_color_hover: "#48C333",
        legal_version: "terms2",
      },
    });
  };

  const handleResendTo = async (project, data) => {
    const resp = await resendTo(project.id, project.status, data.action);
    if (resp.success) {
      let _message = resp?.data?.data?.action_result?.messages[0];
      message.info(_message, 3);
    } else {
      message.error("Failed to forward message", 3);
    }
  };

  const downloadTemplate = async (project) => {
    await downloadTemplatePDF(
      project?.download_file_name,
      project?.download_template_url,
      setIsDownloadingPDF
    );
  };

  const handleCloneContract = async (project) => cloneContracts(project.id);

  // Open full editor
  const onHandleFullEditor = (project) => {
    if (!Boolean(project?.full_editor_flag)) {
      setOpenFullEditorConfirmation(true);
    } else {
      goToFullEditor(project);
    }
  };

  const handleOnOkFullEditor = () => {
    goToFullEditor();
  };

  const goToFullEditor = () => {
    redirectTo(
      `${PRODUCT_PAGE}/${project.id}/full-editor/${project.wp_product_id}`,
      true
    );
  };

  const handleCloseConfirmation = () => {
    setOpenFullEditorConfirmation(false);
  };

  const addAgainContract = async (project) => {
    const templateId =
      project.status === 0
        ? project?.parent_template_id
        : project?.template_data?.parent_template_id;
    const wp_product_id =
      project.status === 0
        ? project?.wp_product_id
        : project?.template_data?.wp_product_id;
    redirectTo(`${PRODUCT_PAGE}/${templateId}/${wp_product_id}`, true);
  };

  const handleSendForSignature = (project) => {
    sendForSignature(project.id);
    history.push(URL_GENERAL);
    loadGeneralData();
  }

  const methods = (label, data) => {
    if (data?.flag === "Resend to") {
      label = data.flag;
    }

    switch (label) {
      case "Download PDF":
        downloadClickHandler(project, false, setIsDownloadingPDF);
        break;
      case "Download Template":
        downloadTemplate(project);
        break;
      case "Edit with Formbuilder":
        redirectTo(
          `${PRODUCT_PAGE}/${project.id}/${project.wp_product_id}?edit`,
          true
        );
        break;
      case "Open Full Editor":
        onHandleFullEditor(project);
        break;
      case "Edit Contract":
        redirectTo(
          `${PRODUCT_PAGE}/${project.id}/${project.wp_product_id}?edit`,
          true
        );
        break;
      case "Buy again":
        redirectTo(
          `${PRODUCT_PAGE}/${project?.parent_template_id}/${project?.wp_product_id}`,
          true
        );
        break;
      case "Send for Review":
        handleOpenSendForReview();
        break;
      case "Download Certified Contract":
        downloadCertificatePDF(project.id, project.title, "Certificate");
        break;
      case "Forward to":
        activeForwardToModal();
        break;
      case "Rename":
        handleRenameContract(project, data && data.isProject);
        break;
      case "Move to":
        handleMoveContract(data.folder, project);
        break;
      case "Add again":
        addAgainContract(project);
        break;
      case "Archive":
        archiveContractFlow(project, data && data.isProject);
        break;
      case "Restore":
        unArchiveProject();
        break;
      case "Restore to General":
        restoreContract(project);
        break;
      case "Delete":
        handleDeleteFolder(project);
        break;
      case "Delete Permanently":
        handleDeleteContract(project);
        break;
      case "Recall":
        handleRecallContract(project);
        break;
      case "Sign":
        handleSignContract(project);
        break;
      case "Resend to":
        handleResendTo(project, data);
        break;
      case "Clone Contract":
        handleCloneContract(project);
        break;
      case "Send for Signature":
        handleSendForSignature(project);
      default:
        break;
    }
  };

  const isMobile = useMediaQuery(MOBILE_RESOLUTION);
  return (
    <>
      <div className={classes.contentContainer}>
        <Menu>
          {items.map((item, index) => {
            if (item.isMobile && !isMobile) return null;
            return (
              <Fragment key={`F-submenu-${project?.id}-${index}`}>
                {item.children ? (
                  <Menu.SubMenu
                    title={item.label}
                    icon={item.icon}
                    className={classes.submenu}
                    key={`submenu-${project?.id}-${index}`}
                  >
                    {item.children.map((itemChildren, i) => (
                      <Menu.Item
                        onClick={() => {
                          let label =
                            item.label === "Move to"
                              ? item.label
                              : itemChildren.label;
                          methods(label, itemChildren.data);
                        }}
                        key={`item-${project?.id}-${i}`}
                        icon={itemChildren.icon}
                        className={item.isSelectMenu && classes.selectItem}
                      >
                        {itemChildren.label}
                        {itemChildren.subLabel && (
                          <p>{itemChildren.subLabel}</p>
                        )}
                      </Menu.Item>
                    ))}
                  </Menu.SubMenu>
                ) : (
                  <Menu.Item
                    onClick={() => {
                      methods(item.label, item.data);
                      setVisibleAction({
                        ...visibleAction,
                        visible: false,
                        label: item.label,
                      });
                    }}
                    className={classes.menuItem}
                    key={`key2-${project?.id}-${index}`}
                    icon={item.icon}
                  >
                    <span style={{ height: "300px !important" }}>
                      {item.label}
                    </span>
                  </Menu.Item>
                )}
              </Fragment>
            );
          })}
        </Menu>
        <SendForReviewModal
          isLoading={isLoading}
          open={isOpen}
          onClose={handleCloseModal}
          onSave={handleSendForReview}
          key={"Modal-" + project.id}
        />
        <ThankYouSendForReviewModal
          open={openThankYou}
          onClose={handleCloseThankYouModal}
          {...thankYouOptions}
        />
        <Modal
          visible={isModalVisible}
          className={"modal"}
          title={isModalSuccess ? "Thank you!" : "Forward Contract"}
          onCancel={hideModal}
          key={"SendForReviewModal" + project.id}
          footer={
            isModalSuccess
              ? [
                  <Button
                    key="close"
                    type="primary"
                    style={{
                      backgroundColor: `rgba(51, 49, 177, 0.2)`,
                      borderColor: `rgba(51, 49, 177, 0.2)`,
                      color: `white`,
                    }}
                    onClick={() => {
                      setIsModalSuccess(false);
                      setIsModalVisible(false);
                    }}
                  >
                    Go back
                  </Button>,
                ]
              : [
                  <Button
                    key="submit"
                    type="primary"
                    style={{
                      width: `100%`,
                      maxWidth: `100%`,
                      display: "flex",
                      justifyContent: "center",
                      color: "white",
                    }}
                    onClick={onSendForwardTo}
                  >
                    Send
                  </Button>,
                ]
          }
        >
          {isModalSuccess ? (
            <h3>Your contract has been sent successfully!</h3>
          ) : (
            <Form
              layout="vertical"
              name="forward-form"
              validateTrigger="onSubmit"
              validateMessages={FORM_VALIDATE_MESSAGES}
            >
              <Form.Item
                name="email"
                label="Email address"
                rules={[
                  {
                    required: true,
                    whitespace: true,
                    type: "email",
                  },
                ]}
              >
                <Input
                  autoFocus
                  autoComplete="off"
                  placeholder="Enter valid email"
                  type={"email"}
                  required
                  onChange={(e) => setModalRecord(e.target.value)}
                />
                {isForwardError.status && (
                  <div>
                    <p
                      style={{
                        color: "#FE5D4B",
                        textAlign: "left",
                        width: "100%",
                        paddingTop: "1em",
                      }}
                    >
                      {isForwardError.message}
                    </p>
                  </div>
                )}
              </Form.Item>
            </Form>
          )}
        </Modal>
      </div>

      <Modal
        wrapClassName={classes.modalDelete}
        className={classes.modalDelete}
        visible={visibleModal}
        onCancel={() => setVisibleModal(false)}
        centered
        footer={[
          <Button
            className={classes.buttonDelete}
            key="back"
            onClick={() => handleDeleteFolder(project)}
          >
            {DELETE}
          </Button>,
          <Button
            className={classes.buttonCancel}
            key="back"
            onClick={() => setVisibleModal(false)}
          >
            {CANCEL}
          </Button>,
        ]}
      >
        <div className={classes.deleteTextContainer}>
          <p className={classes.deleteTextModal}>
            Are you sure you want to delete this project permanently?
          </p>
        </div>
      </Modal>

      <DeleteWarningModal
        showModal={showDeleteModal}
        setShowModal={setShowDeleteModal}
        onHandleDelete={onDeleteProjectClick}
        onCancel={() => setAuxProject(null)}
        type="contract"
      />
      <FullEditorConfirmationModal
        open={openFullEditorConfirmation}
        onOk={handleOnOkFullEditor}
        onClose={handleCloseConfirmation}
      />
    </>
  );
};

export default connect(
  (store) => {
    return {
      templateHelloSign: helloSignOriginTemplatesInProgressSelector(store),
    };
  },
  {
    handleTemplateAction: handleActionSentTemplate,
    updateContractStatus: updateContractSignStatus,
    clearTemplateHelloSignData: clearTemplateHS,
  }
)(MenuContentPopover);
