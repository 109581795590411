//@packages
import React from "react";

//@components
import { Popover as AntPopover } from "antd";

const Popover = ({
  content,
  visible,
  icon,
  handleVisibleChange,
  iconContainerClassName,
}) => {
  return (
    <AntPopover
      placement="bottomRight"
      content={content}
      trigger="click"
      visible={visible}
      onVisibleChange={handleVisibleChange}
    >
      <div className={iconContainerClassName}>{icon}</div>
    </AntPopover>
  );
};

export default Popover;
