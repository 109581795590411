import classnames from "classnames";
import { Button, Dropdown, Menu, Pagination, Skeleton, Space, Spin } from "antd";
import { Fragment, useEffect, useState } from "react";
import { connect, useSelector } from "react-redux";
import {
    CaretRightOutlined, CopyOutlined, DeleteOutlined, DownloadOutlined, EditOutlined,
} from "@ant-design/icons";
import axios from 'axios'

// @components
import InputSearch from "../../atoms/InputSearch";
import Table from "../../molecules/Table";
import { PROJECT_SEARCH } from "../../../constants/staticTexts";
// @utils
import { parseDate } from "../../../utils";
import { usePrevious } from "../../../utils/hooks";
// @styles
import classes from "./styles.module.scss";
import { roleUserSelector } from "../../../store/reducers/user/selectors";
import { categoriesContractSelector, errorContractSelector, idContractSelector, loadedContractSelector, loadingContractSelector, messageContractSelector, templatesContractSelector, updatedContractSelector } from "../../../store/reducers/templatesAdmin/selectors";
import { Link, Redirect, withRouter } from "react-router-dom";
import { API, TOKEN_COOKIE, TOKEN_DOMAIN } from "../../../constants/variables";
import { cloneContract, deleteContract } from "../../../store/reducers/templatesAdmin/actions";
import Cookies from "universal-cookie";
import { getTokenCookies, setTokenCookies } from "../../../store/utils";
import createAuthRefreshInterceptor from "axios-auth-refresh";
import { CONTRACT_PAGE } from "../../../constants/siteMap";
import { searchBarFilter } from "../../../utils/serarchBar";

const headCells = [
    { title: "Title" },
    { title: "Category" },
    { title: "Status" },
    { title: "Last Updated" },
    { title: "Actions" },
];

let PageSize = 10;

const AdminContractsTable = (props) => {

    const [currentPage, setCurrentPage] = useState(1);

    const { cloneTemplate,
        deleteTemplate,
        templatesLoading,
        templatesLoaded,
        templatesUpdated,
        contractID, } = props;

    const contractsAdminEntities = useSelector((store) =>
        templatesContractSelector(store)
    )
    const categoriesEntities = useSelector((store) =>
        categoriesContractSelector(store)
    );

    const userRole = useSelector((store) =>
        roleUserSelector(store)
    )

    const firstPageIndex = (currentPage - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;

    const [downloadLoading, setdownloadLoading] = useState(false)

    const [projectData, setProjectData] = useState([]);
    const [copyprojectData, setCopyProjectData] = useState([]);

    const previousAdminEntities = usePrevious(contractsAdminEntities);

    const onSearchBarChange = (ev) => {
        const val = ev.target.value;
        console.log(val)
        if (val === "") {
            setProjectData(copyprojectData);
        } else {
            const auxFiltered = searchBarFilter(copyprojectData, val);
            setProjectData(auxFiltered);
        }
        setCurrentPage(1);
    };

    useEffect(() => {
        if ((
            previousAdminEntities &&
            previousAdminEntities.length !== contractsAdminEntities.length &&
            contractsAdminEntities.length
        ) || (
                projectData &&
                previousAdminEntities?.length !== copyprojectData.length &&
                previousAdminEntities?.length
            )) {
            setProjectData(contractsAdminEntities);
            setCopyProjectData(contractsAdminEntities);
        }
    }, [contractsAdminEntities]);

    if (templatesLoaded && !templatesLoading && templatesUpdated && contractID) {
        return <Redirect to={`${CONTRACT_PAGE}/${contractID}`} />
    }


    return (
        <>
            <div className={classes.table}>
                <Table>
                    <thead>
                        <Table.Row>
                            {headCells.map((header, index) => (
                                <Table.Header key={index} className={classnames(
                                    header.title === "Actions" && classes.tableActions
                                )}
                                >
                                    <div
                                        className={classnames(
                                            header.title === "Title" && classes.tableFind,
                                        )}
                                    >
                                        {header.title}
                                        {header.title === "Title" && (
                                            <InputSearch
                                                byProject
                                                className={classes.search}
                                                placeholder={PROJECT_SEARCH}
                                                handleSearch={onSearchBarChange}
                                            />
                                        )}
                                    </div>
                                </Table.Header>
                            ))}
                        </Table.Row>
                    </thead>
                    <tbody className={classes.tablePosition}>
                        {(templatesLoading || downloadLoading) ?
                            <tr>
                                <td colSpan={5} style={{ padding: '4rem 0rem' }}>
                                    <div className="loader-wrapper">
                                        <Spin size="middle" />
                                    </div>
                                </td>
                            </tr>
                            :
                            (projectData
                                .slice(firstPageIndex, lastPageIndex)
                                .map((project) => {
                                    const contractCategoryName = categoriesEntities.filter(item => item.id === project.category_id)
                                    return (
                                        <Fragment key={project.id}>
                                            <Table.Row>
                                                <Table.Cell>
                                                    <Link
                                                        to={`contract/${project.id}`}
                                                        style={{ color: project.title ? 'var(--black-secondary)' : 'var(--grey)' }}
                                                        className={classes.title}
                                                    >
                                                        {
                                                            project.title ?
                                                                project.title :
                                                                'Untitled'
                                                        }
                                                    </Link>
                                                </Table.Cell>
                                                <Table.Cell>
                                                    {contractCategoryName.length > 0 &&
                                                        <span style={{ color: 'var(--grey)' }}>{contractCategoryName[0].title}</span>
                                                    }
                                                </Table.Cell>
                                                <Table.Cell>
                                                    {project.status === 1 ?
                                                        <span className={classes.statusMarker}>
                                                            Published
                                                        </span> :
                                                        <span>
                                                            Draft
                                                        </span>}
                                                </Table.Cell>
                                                <Table.Cell>{parseDate(project.updated_at)}</Table.Cell>
                                                <Table.Cell>
                                                    <Space size='middle' align='end'>
                                                        {
                                                            <>
                                                                <Dropdown
                                                                    trigger={['click']}
                                                                    placement='bottomRight'
                                                                    overlay={() => MoreActionsMenu(project, userRole, setdownloadLoading, cloneTemplate, deleteTemplate)}>
                                                                    <Button className={classes.actionButtom}>
                                                                        Actions <CaretRightOutlined className={classes.actionButtomCaret} />
                                                                    </Button>
                                                                </Dropdown>
                                                            </>
                                                        }
                                                    </Space>
                                                </Table.Cell>
                                            </Table.Row>
                                        </Fragment>
                                    );
                                }))}
                    </tbody>
                </Table>
                {projectData.length > 0 && (
                    <Table.Pagination
                        currentPage={currentPage}
                        totalCount={projectData.length}
                        pageSize={PageSize}
                        onPageChange={page => setCurrentPage(page)}
                    />
                )}
            </div>
        </>
    );
};

const MoreActionsMenu = (record, userRole, setdownloadLoading, cloneTemplate, deleteTemplate) => {


    const downloadClickHandler = (record) => {
        const cookies = new Cookies()
        const token = cookies.get(TOKEN_COOKIE)
        const docTitle = `${record.title + '– Blank'}.pdf`
        let config = {
            'Content-Type': 'application/json',
            'Accept': 'application/pdf',
            'wp-token': `${token}`,
        }
        const instance = axios.create({
            withCredentials: true,
        })

        setdownloadLoading(true);

        // Use interceptor to inject the token to requests
        axios.interceptors.request.use(request => {
            request.headers['wp-token'] = getTokenCookies()

            return request
        })

        // Function that will be called to refresh authorization
        const refreshAuthLogic = failedRequest => instance.post(TOKEN_DOMAIN)
            .then(tokenRefreshResponse => {
                const token = tokenRefreshResponse.data.token

                setTokenCookies(token)
                // failedRequest.response.config.headers['wp-token'] = token

                return Promise.resolve()
            })

        createAuthRefreshInterceptor(axios, refreshAuthLogic)

        axios.get(`${API}template/${record.id}/download_template_pdf`,
            {
                responseType: 'blob',
                headers: config
            })
            .then((response) => {
                const url = window.URL.createObjectURL(new Blob([response.data]))
                const link = document.createElement('a')
                link.href = url
                link.setAttribute('download', docTitle)
                document.body.appendChild(link)
                link.click()
                setdownloadLoading(false);
            })
            .catch((error) => {
                console.log(error)
                setdownloadLoading(false);
            })
    }
    const deleteClickHandler = (data) => {
        deleteTemplate(data.id)
    }

    const cloneClickHandler = (data) => {
        cloneTemplate(data.id)
    }
    return <Menu className={classes.dropDown}>
        <Menu.Item key='000'
        >
            <EditOutlined />
            <Link to={`contract/${record.id}`}>Edit Contract</Link>
        </Menu.Item>

        <Menu.Item key='1'
            onClick={() => cloneClickHandler(record)}
        >
            <CopyOutlined />
            Clone
        </Menu.Item>
        {
            userRole === 'um_super-admin-cl' &&
            <Menu.Item key='2'
                onClick={() => deleteClickHandler(record)}
                style={{ color: 'var(--delete-data)' }}>
                <DeleteOutlined color='#EB5757' />
                Delete
            </Menu.Item>
        }
    </Menu>
}

export default connect(
    store => {
        return {
            userRole: roleUserSelector(store),
            contractID: idContractSelector(store),
            contractCategories: categoriesContractSelector(store),
            templatesEntities: templatesContractSelector(store),
            templatesLoaded: loadedContractSelector(store),
            templatesLoading: loadingContractSelector(store),
            templatesError: errorContractSelector(store),
            templatesUpdated: updatedContractSelector(store),
            templatesMessage: messageContractSelector(store),
        }
    },
    {
        deleteTemplate: deleteContract,
        cloneTemplate: cloneContract,
    }
)(withRouter(AdminContractsTable));
