// @packages
import ProductDynamicForm from "./ProductDynamicForm";
import moment from "moment";
import { DatePicker, Form, Input, Tabs, Tooltip } from "antd";
import { Link, useParams } from "react-router-dom";
import { QuestionCircleOutlined } from "@ant-design/icons";

// @constants
import {
  EMAILS_MATCH_ERROR,
  FORM_VALIDATE_MESSAGES,
} from "../../constants/staticErrors";
import { PRODUCT_PAGE, FULL_EDITOR } from "../../constants/siteMap";
import { PRODUCT_STEP_NO_DATA } from "../../constants/staticTexts";

// @styles
import styles from "./product.module.scss";

const { TabPane } = Tabs;
const { TextArea } = Input;

const ProductForm = ({
  isPartiesData,
  form,
  onChangeValues,
  onChangeTab,
  activeTab,
  contractParties,
  matcherId,
  contractPostLoading,
  onFocus,
  stepData,
  isEditTemplate,
  handleOpenConfirmationModal,
}) => {
  const { ContractId, ProductId } = useParams();

  const handleFocus = (original, id) => {
    onFocus(`${original}-${id}`);
  };

  if (isPartiesData) {
    return (
      <>
        <Form
          form={form}
          layout="vertical"
          name="product-step-party-data"
          autoComplete="off"
          className={`${styles.form} ${styles.partyForm}`}
          validateMessages={FORM_VALIDATE_MESSAGES}
          validateTrigger="onSubmit"
          onValuesChange={onChangeValues}
        >
          <Tabs type="card" activeKey={activeTab} onChange={onChangeTab}>
            {contractParties.map((party, i) => {
              const partyTitle = party.filter(
                (party) => party.is_predefined === 3
              );
              const tabTitle = partyTitle[0]
                ? partyTitle[0].title
                : `Party ${i + 1}`;

              return (
                <TabPane key={i} tab={tabTitle}>
                  {party.length > 0 ? (
                    party.map((field, i) => {
                      let isEmailCheck;

                      if (field.title === "Email") {
                        if (field.party_number > 0) {
                          isEmailCheck = true;
                        } else {
                          isEmailCheck = false;
                          matcherId = field.id;
                        }
                      }

                      return (
                        field.is_predefined !== 3 && (
                          <Form.Item
                            key={field.id}
                            name={field.id}
                            label={field.title}
                            initialValue={field.val}
                            dependencies={[isEmailCheck ? matcherId : ""]}
                            rules={[
                              {
                                required: true,
                                whitespace: true,
                                type:
                                  field.title === "Email" ? "email" : "string",
                              },
                              ({ getFieldValue }) => ({
                                validator(rule, value, callback) {
                                  if (isEmailCheck) {
                                    if (
                                      getFieldValue(matcherId)
                                        .toString()
                                        .toLowerCase() ===
                                      value.toString().toLowerCase()
                                    ) {
                                      // return Promise.resolve()
                                      callback(EMAILS_MATCH_ERROR);
                                    }

                                    // return Promise.reject(new Error(EMAILS_MATCH_ERROR))
                                  }

                                  return callback();
                                },
                              }),
                            ]}
                          >
                            <Input
                              disabled={contractPostLoading}
                              onFocus={() =>
                                handleFocus(field.original_id, field.id)
                              }
                              // ref={(input) => {
                              //   input && i === 0 && input.focus()
                              // }}
                              // placeholder={`Enter ${field.title}`}
                            />
                          </Form.Item>
                        )
                      );
                    })
                  ) : (
                    <p className="ant-row">{PRODUCT_STEP_NO_DATA}</p>
                  )}
                </TabPane>
              );
            })}
          </Tabs>
        </Form>
        <p className={styles.linkToFullContract}>
          Use the Full-Editor to <b>Customize Your Contract</b> Quickly and
          Easily.
          <label
            className={styles.link}
            onClick={handleOpenConfirmationModal}
          >
            Skip to Full Editor
          </label>
        </p>
      </>
    );
  }

  return (
    <>
      <Form
        form={form}
        layout="vertical"
        name="product-step-data"
        autoComplete="off"
        className={styles.form}
        validateMessages={FORM_VALIDATE_MESSAGES}
        validateTrigger="onSubmit"
        onValuesChange={onChangeValues}
      >
        {Object.entries(stepData.fields).length > 0 ? (
          Object.entries(stepData.fields).map((field, i) => {
            const item = field[1];
            const dateFieldRules = [
              {
                required: item.is_required === 1,
                message: `"${item.title}" is required`,
              },
            ];
            const deliverableFieldRules = [{}];
            const numberFiledRules = [
              {
                required: item.is_required === 1,
                whitespace: true,
                message: `"${item.title}" is required`,
              },
              {
                pattern: /^(?:\d*)$/,
                message: `"${item.title}" should contain just numbers`,
              },
              item.maxlength
                ? {
                    max: item.maxlength,
                    message: `"${item.title}" cannot be greater than ${item.maxlength} symbols`,
                  }
                : {},
            ];
            const textFieldRules = [
              {
                required: item.is_required === 1,
                whitespace: true,
                message: `"${item.title}" is required`,
              },
              item.maxlength
                ? {
                    max: item.maxlength,
                    message: `"${item.title}" cannot be greater than ${item.maxlength} symbols`,
                  }
                : {},
              {
                type: item.validation_type === 4 ? "email" : "string",
                message: item.validation_error_text
                  ? item.validation_error_text
                  : null,
              },
              item.validation_type === 2
                ? {
                    pattern: /^[A-Za-z]*$/,
                    message: `"${item.title}" should contain just text`,
                  }
                : {},
            ];
            const deliverableValues = form.getFieldValue(
              `deliverable-${item.id}`
            );

            // console.log( deliverableValues )

            return (
              <Form.Item
                key={item.id}
                name={item.id}
                initialValue={
                  item.val && item.field_type === 4
                    ? moment(item.val, "MM/DD/YYYY")
                    : item.val
                    ? item.val
                    : null
                }
                label={
                  <span>
                    {item.title}
                    {item.tooltip && (
                      <Tooltip placement="right" title={item.tooltip}>
                        <QuestionCircleOutlined />
                      </Tooltip>
                    )}
                  </span>
                }
                rules={
                  item.field_type === 4
                    ? dateFieldRules
                    : item.field_type === 3
                    ? deliverableFieldRules
                    : item.validation_type === 3
                    ? numberFiledRules
                    : textFieldRules
                }
              >
                {
                  // switch - case (?)
                  item.field_type === 1 ? (
                    <Input
                      disabled={contractPostLoading}
                      onFocus={() => handleFocus(item.original_id, item.id)}
                      // ref={(input) => {
                      //   input && i === 0 && input.focus()
                      // }}
                      // placeholder={`Enter ${item.title}`}
                    />
                  ) : item.field_type === 2 ? (
                    <TextArea
                      rows={4}
                      disabled={contractPostLoading}
                      onFocus={() => handleFocus(item.original_id, item.id)}
                      // ref={(textarea) => {
                      //   textarea && i === 0 && textarea.focus()
                      // }}
                      // placeholder={`Enter ${item.title}`}
                    />
                  ) : item.field_type === 3 ? (
                    <ProductDynamicForm
                      form={form}
                      item={item}
                      disabled={contractPostLoading}
                      isEdit={isEditTemplate}
                      onFocus={onFocus}
                    />
                  ) : item.field_type === 4 ? (
                    <DatePicker
                      disabled={contractPostLoading}
                      onFocus={() => handleFocus(item.original_id, item.id)}
                      // ref={(date) => {
                      //   date && i === 0 && date.focus()
                      // }}
                      format="MM/DD/YYYY"
                      placeholder=""
                      // placeholder={`Select ${item.title}`}
                    />
                  ) : null
                }
              </Form.Item>
            );
          })
        ) : (
          <p className="ant-row">{PRODUCT_STEP_NO_DATA}</p>
        )}
      </Form>
      <p className={styles.linkToFullContract}>
        Use the Full-Editor to <b>Customize Your Contract</b> Quickly and
        Easily.
        <label
            className={styles.link}
            onClick={handleOpenConfirmationModal}
          >
            Skip to Full Editor
        </label>
      </p>
    </>
  );
};

export default ProductForm;
