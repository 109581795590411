// @packages
import classnames from "classnames";
import { useFullEditorProvider } from "../../../context/fullEditor";
// @components
import LogoCreators from "../../atoms/LogoCreators";
import PartiesSection from "../PartiesSection";
// @styles
import classes from "./styles.module.scss";

const BOTTOM = "bottom";
const TOP = "top";

const PDFFooter = ({
  contractSKU,
  position = BOTTOM,
  placement = BOTTOM,
  parties,
  contractImages,
}) => {
  const { logo } = useFullEditorProvider();
  let img = "";
  if (position === BOTTOM || (position === TOP && placement === TOP)) {
    img =
      logo && logo !== "NO_CONTENT" ? (
        <img className={classes.previewImg} src={logo} alt="Logo preview" />
      ) : (
        <LogoCreators />
      );
  }

  return (
    <div
      className={classnames(
        classes.contractFooter,
        placement === TOP && classes.logoTop,
        position !== BOTTOM && placement === BOTTOM && classes.noLogoBottom,
        placement === BOTTOM && position === TOP && classes.skuLonly
      )}
    >
      {placement !== TOP && (
        <PartiesSection contractImages={contractImages} parties={parties} />
      )}
      <div className={classes.footerContainer}>
        {img}
        {placement !== TOP && <span>{contractSKU}</span>}
      </div>
    </div>
  );
};

export default PDFFooter;
