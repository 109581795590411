import React from 'react';
import classnames from 'classnames';
import { usePagination } from './usePagination';
import classes from "./styles.module.scss";
import { CaretLeftOutlined, CaretRightOutlined } from '@ant-design/icons';

const Pagination = ({
    onPageChange,
    totalCount,
    siblingCount = 1,
    currentPage,
    pageSize,
    className
}) => {

    const paginationRange = usePagination({
        currentPage,
        totalCount,
        siblingCount,
        pageSize
    });

    if (currentPage === 0 || paginationRange.length < 2) {
        return null;
    }

    const onNext = (e) => {
        if (lastPage !== currentPage) {
            onPageChange(currentPage + 1);
        }
    };

    const onPrevious = (e) => {
        if (currentPage !== 1) {
            onPageChange(currentPage - 1);
        }
    };

    let lastPage = paginationRange[paginationRange.length - 1];


    return (
        <div
            className={classnames(classes.paginationContainer, { [className]: className })}
        >
            {
                (currentPage !== 1) &&
                <div
                    className={classnames(classes.iconColor, classes.paginationItemContainer, classes.paginationItem, {
                        disabled: currentPage === 1
                    })}
                    onClick={onPrevious}
                >
                    <CaretLeftOutlined />
                </div>
            }

            <div className={classes.paginationItemContainer}>
                {paginationRange.map((pageNumber, index) => {
                    return (
                        <div
                            key={index}
                            className={classnames({
                                [classes.selected]: pageNumber === currentPage
                            }, classes.paginationItem)}
                            onClick={() => onPageChange(pageNumber)}
                        >
                            {pageNumber}
                        </div>
                    );
                })}
            </div>
            {
                (lastPage !== currentPage) &&
                <div
                    className={classnames(classes.iconColor, classes.paginationItemContainer, classes.paginationItem, {
                        disabled: currentPage === lastPage
                    })}
                    onClick={onNext}
                >
                    <CaretRightOutlined />
                </div>
            }

        </div>
    );
};

export default Pagination;
