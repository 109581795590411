// @Packages
import React from "react";
import { Button, Modal } from "antd";
import classNames from "classnames";

// @constants
import { MOBILE_RESOLUTION } from "../../../constants/variables";

// @utils
import { useMediaQuery } from "../../utils";

import classes from "./styles.module.scss";

const DeleteWarningModal = ({
  onHandleDelete,
  showModal,
  setShowModal,
  onCancel,
  type = "project",
}) => {
  const handleCancel = () => {
    setShowModal(false);
    onCancel();
  };

  const isMobile = useMediaQuery(MOBILE_RESOLUTION);

  return (
    <Modal
      visible={showModal}
      onCancel={handleCancel}
      centered
      footer={[
        <Button
          key="back"
          onClick={() => onHandleDelete()}
          className={classes.button}
        >
          Delete
        </Button>,
      ]}
      className={classNames(classes.briefcaseModal, {
        [classes.mobileContainer]: isMobile,
      })}
    >
      <p className={classes.textModal}>
        Are you sure you want to delete this {type} permanently?
      </p>
    </Modal>
  );
};

export default DeleteWarningModal;
