// @packages
import classnames from "classnames";
// @styles
import classes from "./styles.module.scss";

const LogoPositionContainer = ({ title, className, children }) => (
  <div className={classnames(classes.mainContainer, className && className)}>
    <h2 className={classes.title}>{title}</h2>
    {children}
  </div>
);
export default LogoPositionContainer;
