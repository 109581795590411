import classNames from "classnames";
// @components
import TableCell from "./TableCell";
import TableHeader from "./TableHeader";
import TableRow from "./TableRow";
import TablePagination from './TablePagination'
// @styles
import classes from "./styles.module.scss";

const Table = ({ children, className, ...rest }) => {
  return (
    <table
      {...rest}
      className={classNames(classes.tableBriefcaseCustom, className)}
    >
      {children}
    </table>
  );
};

Table.Cell = TableCell;
Table.Header = TableHeader;
Table.Row = TableRow;
Table.Pagination = TablePagination

export default Table;
