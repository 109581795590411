export const CREATION_IN_PROGRESS = "The creation of envelope in progress...";
export const LOADING_DOCUMENT = "Loading document";

export const FOOTER_SUBSCRIPTION_TITLE = "Subscribe to Our Newsletter";
export const FOOTER_COPYRIGHT_TEXT =
  "Creators Legal is not a law firm and cannot provide legal advice. We are a self-help online and software service and do not participate in any legal representation. We are not a substitute for a lawyer, law firm or professional legal advice. We are not a ‘lawyer referral service’. Use of CreatorsLegal.com is subject to our Terms and Conditions and Privacy Policy.";
export const FOOTER_SUBSCRIPTION_TEXT =
  "Join our community and subscribe to the newsletter for updates, useful info and special offers.";

export const VALIDATION_TEXT = "Validation...";
export const PUBLISHING_TEXT = "Publishing...";
export const PROCESSING_COMPLETE_TEXT = "Processing complete!";

export const PRODUCT_STEP_TITLE = "Contract Name";
export const PRODUCT_STEP_DESCRIPTION =
  "Name the Contract so it can be found in your Project Briefcase";
export const PRODUCT_STEP_DESCRIPTION_NOTE = "(Only you will see this name)";
export const PRODUCT_STEP_NO_DATA = "No data for this Step";

export const THANK_YOU_WITH_SEND_TEXT =
  "Your contract is now ready to sign. It has been automatically saved in your briefcase. You can now send to all parties for signature, download a PDF version, go back and use the Full Editor to make changes.";
export const THANK_YOU_COMPLETE_TEXT =
  "Your contract is now ready to sign. It has been automatically saved in your briefcase. You can now send to all parties for signature, share with someone for review, download a PDF, or go back and edit either on the Formbuilder or the Full Editor to make changes.";

export const SEND_DONE_MESSAGE = "Contract was successfully sent to Parties.";
export const SEND_LATER_MESSAGE = "Contract was successfully saved as draft.";
export const SIGNED_DONE_MESSAGE = "Contract was successfully signed.";

export const BEFORE_EXIT_TITLE =
  "Do you want to save changes to this contract before exit?";
export const BEFORE_EXIT_TEXT = "If you don’t save, your changes will be lost.";

export const BEFORE_LEAVE_PAGE_TITLE = "Do you want to leave?";
export const BEFORE_LEAVE_PAGE_AUTH_TEXT =
  "You are about to leave the current contract. All your progress will be saved and you can find the draft of this contract in your Briefcase.";
export const BEFORE_LEAVE_PAGE_NO_AUTH_TEXT =
  "You are about to leave the current contract. Please note that your changes will not be saved if you are not logged in. If you would like to access the draft of your contract please login in or register before leaving this page.";

export const TERMS_TITLE = "Terms and Conditions";
export const TERMS_TEXT =
  "Creators Legal is not a law firm and cannot provide legal advice. We are a self-help online and software service and do not participate in any legal representation." +
  "" +
  "We are not a substitute for a lawyer, law firm or professional legal advice. We are not a ‘lawyer referral service’. Use of CreatorsLegal.com is subject to our Terms and Conditions and Privacy Policy.";

export const SIDER_TITLE = "My Projects";

export const NEW_PROJECT = "+ New Project";

export const NEW_CONTRACT = "+ New Contract";

export const NEW_CATEGORY = "+ New Category";

export const NEW_TEMPLATE = "+ New Template";

export const RENAMED_PROJECT = "Write your project name";

export const GLOBAL_SEARCH = "Find a Contract in your Briefcase";

export const PROJECT_SEARCH = "Find a Contract in this Folder";

export const ADMIN_SCREEN = "Admin Contracts";

// modal
export const PROJECT_BRIEFCASE = "Project Briefcase";

export const BODY_BRIEFCASE =
  "This is the place where all your contracts will be stored.";

export const BODY_BRIEFCASE_2 =
  "You can classify them by project to stay more organized, it's as easy as dragging the contracts and placing them in your created projects in the left sidebar of your briefcase.";
export const BODY_BRIEFCASE_MOBILE =
  "You can classify them by project to stay more organized, it's as easy as dragging the contracts and placing them in your created projects.";
export const BODY_BRIEFCASE_3 =
  "Or if you prefer, you can keep them on the General folder.";
export const BODY_BRIEFCASE_4 = "Keep doing what you love. Create!";

export const FOOTER_BRIEFCASE = "Don't show this again";
export const CLOSE_MODAL = "Close";
export const DELETE = "Delete";
export const CANCEL = "Cancel";
