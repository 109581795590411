// @packages
import { Row, Col } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
// @components
import FullEditorService from "../../../services/fullContractEditor";
import LogoPosition from "./LogoPosition";
import QuillContainer from "./QuillContainer";
import { getAdminTemplate } from "../../../store/reducers/templatesAdmin/actions";
import { preFormatDocument } from "./QuillContainer/helpers";
import { useFullEditorProvider } from "../../../context/fullEditor";
import { usePrevious } from "../../../utils/hooks";
// @styles
import classes from "./styles.module.scss";
import Parties from "../../molecules/Parties";

const FullEditor = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { ProductId, ContractId } = useParams();
  const { form, logo, position, setLogo, setPosition, setContextParties } =
    useFullEditorProvider();
  const [parties, setParties] = useState([]);
  const [parsedContract, setParsedContract] = useState({
    content: false,
    images: [],
  });
  const [partyLabelWithMissingFields, setPartyLabelWithMissingFields] = useState(false);

  const contractContent = useSelector((store) => store.templatesAdmin.content);
  const contractParentContent = useSelector(
    (store) => store.templatesAdmin.parentContent
  );
  const contractParties = useSelector((store) =>
    store.templatesAdmin.parties.valueSeq().toArray()
  );
  const prevContractParties = usePrevious(contractParties);
  const contractSteps = useSelector((store) =>
    store.templatesAdmin.steps.valueSeq().toArray()
  );
  const contractTitle = useSelector((store) => store.templatesAdmin.title);
  const contractSKU = useSelector((store) => store.templatesAdmin.sku);

  const handleChangeParties = (value) => {
    setParties(value);
  };

  useEffect(() => {
    //console.log({contractParties, prevContractParties});

    //console.log(`${contractParties?.length > 0} && (${!prevContractParties} || (${!!prevContractParties} && ${prevContractParties ? prevContractParties[0]?.length : 0} < ${contractParties[0]?.length})`);
    if (
      contractParties?.length > 0 &&
      (!prevContractParties ||
        (prevContractParties &&
          prevContractParties[ 0 ]?.length !== contractParties[ 0 ]?.length))
    ) {
      setParties([...contractParties]);
      //console.log('entro');
    }
  }, [contractParties]);

  useEffect(() => {
    if (ContractId) {
      FullEditorService.getLogo(ContractId).then((response) => {
        //response.data.is_form_builder_used
        if (response.data.data.template_logo) {
          setLogo(response.data.data.template_logo);
        }
        if (response.data.data.logo_position) {
          setPosition(response.data.data.logo_position);
        }
      });
      const res = FullEditorService.blockFormBuilder(ContractId);
      res.then(() => dispatch(getAdminTemplate(ContractId)));
    }
  }, [ContractId]);

  useEffect(() => {
    setContextParties(parties);
  }, [parties]);

  useEffect(() => {
    if (contractContent) {
      setParsedContract(preFormatDocument(contractContent));
    }
  }, [contractContent]);

  return (
    <div className={classes.container}>
      <div className={classes.header}>
        <h2 className={classes.contractTitle}>{contractTitle}</h2>
      </div>
      <div className={classes.mainContainer}>
        <div className={classes.detailsContainer}>
          <h1 className={classes.title}>Full Editor</h1>
          <p className={classes.paragraph}>
            Use the wordstyle editor to add, edit or delete any of the text in
            your contract. You can also upload your company logo and correct any
            issues with recipients information.
          </p>
        </div>
      </div>

      <div className={classes.orderOfContract}>
        {/* TODO:  parte de arriba */}
        <div>
          <div className={classes.stikyContainer}>
            <LogoPosition />
            <Parties
              data={parties}
              className={classes.tabs}
              onChangeParties={handleChangeParties}
              partyLabelWithMissingFields={partyLabelWithMissingFields}
              setPartyLabelWithMissingFields={setPartyLabelWithMissingFields}
            />
          </div>
        </div>
        {/* TODO:  parte de abajo de contract */}
        <div className={classes.rowEditor}>
          {parsedContract.content && (
            <QuillContainer
              contractContent={parsedContract.content}
              contractImages={parsedContract.images}
              contractParentContent={contractParentContent}
              contractParties={contractParties}
              contractSKU={contractSKU}
              contractSteps={contractSteps}
              form={form}
              history={history}
              logo={logo}
              parties={parties}
              position={position}
              productId={ProductId}
              setLogo={setLogo}
              templateId={ContractId}
              setPartyLabelWithMissingFields={setPartyLabelWithMissingFields}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default FullEditor;
