//@packages
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

//@Icons
import LoupeIcon from "../../atoms/icons/Loupe";
import AddIcon from "../../atoms/icons/Add";
import RocketIcon from "../../atoms/icons/Rocket";

//@components
import Popover from "../../atoms/Popover";
import { Button } from "antd";
import InputSearch from "../../atoms/InputSearch";
import NotificationsPopover from "../NotificationsPopover";
import NewContractDropdown from "../NewContractDrodown";
import LogoMobile from "../../atoms/icons/LogoMobile";

//@scripts
import { useProjectProvider } from "../../../context/projects";

// @constants
import { GLOBAL_SEARCH, SIDER_TITLE } from "../../../constants/staticTexts";

//@context
import { useContractsProvider } from "../../../context/contracts";
import { useNotificationsProvider } from "../../../context/notifications";
import { useMobileHeaderProvider } from "../../../context/mobileHeader";

//@store
import { getTemplatesNotifications } from "../../../store/reducers/templatesAdmin/actions";

//@utils
import { searchBarFilter } from "../../../utils/serarchBar";

//@styles
import classes from "./styles.module.scss";
import classnames from "classnames";

const MobileHeader = () => {
  const dispatch = useDispatch();
  const [visible, setVisible] = useState(false);
  const [showNotifications, setShowNotifications] = useState(false);
  const [showInputSearch, setShowInputSearch] = useState(false);
  const [showNewProjectButton, setShowNewProjectButton] = useState(true);
  const [search, setSearch] = useState("");
  const [globalSearch, setGlobalSearch] = useState(false);
  const [auxProjectData, setAuxProjectData] = useState([]); // APD
  const [projectDataRollBack, setProjectDataRollback] = useState([]); // PDRB

  const { input, setInput, setInputFocus } = useProjectProvider();
  const { notificationsList, handleOpenNotifications } =
    useNotificationsProvider();

  const { isGlobalSearching, setIsGlobalSearching } = useMobileHeaderProvider();

  const {
    projectData,
    setProjectData,
    preloadedGenCont,
    preloadedProjects,
    setIsSearching,
    loadGeneralData,
  } = useContractsProvider();

  const handleCreateProject = () => {
    setVisible(false);
    setInput({ ...input, showInput: true, value: "" });
    setInputFocus();
  };

  const handleVisibleChange = (visible) => {
    setShowNotifications(visible);
    if (!visible) handleOpenNotifications();
  };

  const onChangeProjectName = () => {
    setShowInputSearch(false);
    setIsGlobalSearching(false);
  };

  const handleChangePopover = (visible) => {
    setVisible(visible);
    setShowNewProjectButton(true);
  };

  const onGlobalSearchStatus = (status) => {
    setGlobalSearch(status);
  };

  const showSearch = () => {
    setGlobalSearch(!globalSearch);
  };

  const onSearchBarChange = (ev) => {
    const val = ev.target.value;
    setIsSearching(true);
    if (val === "") {
      setProjectData(auxProjectData);
    } else {
      const auxFiltered = searchBarFilter(auxProjectData, val);
      setProjectData(auxFiltered);
    }
  };

  const onChangeGlobalSearch = (e) => {
    if (!isGlobalSearching) setIsGlobalSearching(true);

    setSearch(e.target.value);
    onSearchBarChange(e);
  };

  useEffect(() => {
    if (globalSearch && Boolean(projectDataRollBack.length)) {
      const fullData = [...preloadedGenCont, ...preloadedProjects];
      setProjectData(fullData);
      setAuxProjectData(fullData);
    }
  }, [projectDataRollBack]);

  useEffect(() => {
    if (globalSearch) {
      setProjectDataRollback(projectData);
    } else if (!globalSearch && search === "") {
      setProjectData(projectDataRollBack);
      setProjectDataRollback([]);
    }
  }, [globalSearch]);

  useEffect(() => {
    dispatch(getTemplatesNotifications());
  }, [dispatch]);

  useEffect(() => {
    loadGeneralData();
  }, []);

  const handleChangeSearchIcon = () => {
    setShowInputSearch(true);
    setGlobalSearch(true);
  };

  const content = (
    <div className={classes.contentContainer}>
      {showNewProjectButton && (
        <Button
          icon={<RocketIcon color="white" className={classes.iconButton} />}
          className={classes.newProjectButton}
          onClick={handleCreateProject}
        >
          + New Project
        </Button>
      )}
      <NewContractDropdown
        handleClick={() => setShowNewProjectButton(false)}
        className={classnames(
          classes.newContractButton,
          !showNewProjectButton && classes.projectHidden
        )}
      />
    </div>
  );

  return (
    <div className={classes.headerContainer}>
      {!showInputSearch && (
        <div className={classes.headerTitle}>
          <span className={classes.icon}>
            <LogoMobile />
          </span>
          <h2 className={classes.title}>{SIDER_TITLE}</h2>
        </div>
      )}

      <div className={classes.headerActions}>
        <div
          className={classnames(
            classes.headerActions,
            showInputSearch && classes.headerSearch
          )}
        >
          {!showInputSearch ? (
            <LoupeIcon
              pointer
              color="var(--purple)"
              onClick={handleChangeSearchIcon}
              className={classes.actionIcons}
            />
          ) : (
            <InputSearch
              className={classnames(
                !notificationsList.length && classes.inputSearch
              )}
              placeholder={GLOBAL_SEARCH}
              handleSearch={onChangeGlobalSearch}
              value={search}
              showSearch={showSearch}
              setSearch={setSearch}
              onBlur={onChangeProjectName}
              onPressEnter={onChangeProjectName}
              setGlobalSearch={onGlobalSearchStatus}
            />
          )}

          <NotificationsPopover
            iconClassName={classes.actionIcons}
            notificationsSize={notificationsList.length}
            visible={showNotifications}
            handleVisible={setShowNotifications}
            data={notificationsList}
            onVisibleChange={handleVisibleChange}
          />

          <Popover
            icon={<AddIcon className={classes.actionIcons} />}
            visible={visible}
            content={content}
            handleVisibleChange={handleChangePopover}
            iconContainerClassName={classes.addIcon}
          />
        </div>
      </div>
    </div>
  );
};

export default MobileHeader;
