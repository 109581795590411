/* eslint-disable react-hooks/exhaustive-deps */
import React, { Fragment, useEffect, useRef, useState } from "react";
import classnames from "classnames";
import { Spin, Switch } from "antd";

// @components
import Table from "../../molecules/Table";

// @utils
import useOutsideClick from "../../../utils/useOutsideClick";
import {
  createCategory,
  deleteCategory,
  getCategories,
  updateCategory,
} from "../../../utils/categoryAPI";

// @styles
import classes from "./styles.module.scss";

// @Context
import { useNewCategoryProvider } from "../../../context/newCategory";

// @Constants
import { SITE_DOMAIN } from "../../../constants/variables";
import TrashAdmin from "../../atoms/icons/TrashAdmin";

const headCells = [
  { title: "Category" },
  { title: "Link", className: "tableHeaderLink" },
  { title: "Status" },
];

const initialState = {
  name: "",
  url: "",
};

const CategoryTable = () => {
  const [categoriesEntities, setCategoriesEntities] = useState([]);
  const [loading, setLoading] = useState(false);

  const [createDataCategory, setcreateDataCategory] = useState(initialState);

  const handleCreateCategory = (ev) => {
    ev.persist();
    setcreateDataCategory((prevState) => ({
      ...prevState,
      [ev.target.name]: ev.target.value,
    }));
  };

  const handleUpdateCategory = (cat, ev) => {
    const Category = {
      name: cat.name,
      url: cat.url,
      id: cat.id,
      is_active: +ev,
    };
    setLoading(true);
    updateCategory(Category).then((data) => {
      if (data.success) {
        categorieCall();
        setLoading(false);
      }
    });
  };

  const categorieCall = () => {
    getCategories().then((data) => {
      setLoading(true);
      const resp = data.data.data.categories;
      if (data.success) {
        setCategoriesEntities(resp);
        setAddCategory(false);
        setLoading(false);
      }
    });
  };

  useEffect(() => {
    categorieCall();
  }, []);

  const { addCategory, inputRef, setAddCategory, setInputFocus } =
    useNewCategoryProvider();

  const ref = useRef();

  useEffect(() => {
    setInputFocus();
  }, [setInputFocus]);

  useOutsideClick(ref, () => {
    if (addCategory) setAddCategory(false);
  });

  const handleSubmit = (event) => {
    setLoading(true);
    createCategory(createDataCategory).then((data) => {
      if (data.success) {
        categorieCall();
        setLoading(false);
        setcreateDataCategory(initialState);
      }
    });
    event.preventDefault();
  };

  const onHandleDelete = async (id) => {
    console.log(id);
    setLoading(true);
    const resp = await deleteCategory(id);
    if (resp.success) {
      categorieCall();
    }
    setLoading(false);
  };

  return (
    <div className={classes.table}>
      <form onSubmit={handleSubmit}>
        <Table>
          <thead>
            <Table.Row>
              {headCells.map((header, index) => (
                <Table.Header key={index} className={classes.tableCategory}>
                  {header.title}
                </Table.Header>
              ))}
            </Table.Row>
          </thead>

          <tbody className={classes.tablePosition}>
            {!categoriesEntities.length || loading ? (
              <tr>
                <td colSpan={3} style={{ padding: "4rem 0rem" }}>
                  <div className="loader-wrapper">
                    <Spin size="middle" />
                  </div>
                </td>
              </tr>
            ) : (
              categoriesEntities.map((category, index) => {
                return (
                  <Fragment key={`${index}-${category.id}`}>
                    <Table.Row>
                      <Table.Cell className={classes.categoryText}>
                        {category.name}
                      </Table.Cell>
                      <Table.Cell className={classes.categoryText}>
                        {`${SITE_DOMAIN}/${category.url}`}
                      </Table.Cell>
                      <Table.Cell>
                        <div className={classes.statusCnt}>
                          <Switch
                            checked={!!category.is_active}
                            className={classnames(classes.tableSwitch)}
                            onClick={(ev) => handleUpdateCategory(category, ev)}
                          />
                          <TrashAdmin
                            pointer
                            onClick={() => onHandleDelete(category.id)}
                          />
                        </div>
                      </Table.Cell>
                    </Table.Row>
                  </Fragment>
                );
              })
            )}
            {addCategory && (
              <Table.Row refer={ref}>
                <Table.Cell>
                  <input
                    ref={inputRef}
                    name="name"
                    value={createDataCategory.name}
                    onChange={handleCreateCategory}
                    required
                  />
                </Table.Cell>
                <Table.Cell>
                  <input
                    name="url"
                    value={createDataCategory.url}
                    onChange={handleCreateCategory}
                    required
                    className={classes.inputUrl}
                  />
                  <input type="submit" hidden />
                </Table.Cell>
                <Table.Cell>
                  <Switch className={classnames(classes.tableSwitch)} />
                </Table.Cell>
              </Table.Row>
            )}
          </tbody>
        </Table>
      </form>
    </div>
  );
};

export default CategoryTable;
