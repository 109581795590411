// @packages
import React, { Component } from "react";
import { Switch, BrowserRouter as Router, Route } from "react-router-dom";
// @components
import ConfirmationPage from "./ConfirmationPage";
import ContractPage from "../pages/ContractPage";
import ErrorPage from "../pages/ErrorPage";
import FullEditorPage from "../pages/FullEditorPage";
import LogoutPage from "../pages/LogoutPage";
import ProductPage from "../pages/ProductPage";
import SignPage from "../pages/SignPage";
import StartPage from "../pages/StartPage";
import ThankYouPage from "../pages/ThankYouPage";
import { FullEditorProvider } from "../context/fullEditor";
import { getIsDocumentHidden } from "../components/utils";
// @constants
import {
  CONFIRMATION_PAGE,
  CONTRACT_PAGE,
  FULL_EDITOR,
  LOGOUT_PAGE,
  PRODUCT_PAGE,
  SIGN_PAGE,
  START_PAGE,
  THANK_YOU_PAGE,
} from "../constants/siteMap";

export default class AppRouter extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isPageVisible: getIsDocumentHidden(),
    };
  }

  render() {
    return (
      <FullEditorProvider>
        <Router>
          <Switch>
            <Route path={START_PAGE} exact strict component={StartPage} />

            <Route path={CONTRACT_PAGE} exact component={ContractPage} />
            <Route
              path={`${CONTRACT_PAGE}/:ContractId`}
              exact
              component={ContractPage}
            />
            <Route
              path={`${PRODUCT_PAGE}/:ContractId/${FULL_EDITOR}/:ProductId`}
              exact
              component={FullEditorPage}
            />
            <Route
              path={`${PRODUCT_PAGE}/:ContractId/${THANK_YOU_PAGE}/:ProductId`}
              exact
              component={ThankYouPage}
            />
            <Route
              path={`${PRODUCT_PAGE}/:ContractId/:ProductId`}
              exact
              component={ProductPage}
            />
            <Route
              path={`${PRODUCT_PAGE}/:ContractId${CONFIRMATION_PAGE}/:ProductId`}
              exact
              component={ConfirmationPage}
            />

            <Route path={`${SIGN_PAGE}/:TempId`} exact component={SignPage} />

            <Route path={LOGOUT_PAGE} exact strict component={LogoutPage} />
            <Route component={ErrorPage} />
          </Switch>
        </Router>
      </FullEditorProvider>
    );
  }
}
