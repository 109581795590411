import { message as antdMessage } from "antd";

export const showErrorMessage = (status) => {
  let message
  switch (status) {
    case 400:
      message = 'The data you are sending is incorrect'
      break;
    case 401:
      message = 'User session expired'
      break;
    case 404:
      message = 'Endpoint not found'
      break;
    case 403:
      message = 'User has no permissions to proceed'
      break;
    case 500:
      message = 'Server Error'
      break;
    default:
      message = 'An unexpected error has occurred'
  }

  antdMessage.error({
    content: message,
    className: 'error-message',
    duration: 0
  })
}
