// @packages
import { BiArrowToBottom, BiArrowToTop } from "react-icons/bi";
import { Col, Row } from "antd";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
// @components
import FullEditorService from "../../../../services/fullContractEditor";
import LogoCreators from "../../../atoms/LogoCreators";
import LogoPositionContainer from "../../../atoms/LogoPositionContainer";
// @styles
import classes from "./styles.module.scss";

const logoPositions = {
  top: "Header once",
  bottom: "Footer in every page",
  none: "No logo",
};

const LogoPreview = () => {
  const [position, setPosition] = useState("bottom");
  const [logo, setLogo] = useState("");
  const templateId = useSelector((store) => store.templatesAdmin.id);
  let logoHTMl =
    logo && logo !== "NO_CONTENT" ? (
      <img alt="Logo-preview" src={logo} className={classes.logo} />
    ) : (
      <LogoCreators />
    );

  if (position === "none") {
    logoHTMl = "";
  }

  useEffect(() => {
    if (templateId) {
      FullEditorService.getLogo(templateId).then((response) => {
        if (response.data.data.template_logo) {
          setLogo(response.data.data.template_logo);
        }
        if (response.data.data.logo_position) {
          setPosition(response.data.data.logo_position);
        }
      });
    }
  }, [templateId]);

  return (
    <LogoPositionContainer
      title="Your Company Logo"
      className={classes.mainContainer}
    >
      <Row className={classes.logoPreview}>
        <Col xs={12}>{logoHTMl}</Col>
        <Col xs={12} className={classes.placementContainer}>
          <p className={classes.placement}>Placement</p>
          <p className={classes.text}>
            {position === "top" && <BiArrowToTop />}
            {position === "bottom" && <BiArrowToBottom />}
            {logoPositions[position]}
          </p>
        </Col>
      </Row>
    </LogoPositionContainer>
  );
};

export default LogoPreview;
