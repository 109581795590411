// @packages
import ImgCrop from "antd-img-crop";
import { useState } from "react";
import { BiArrowToBottom, BiArrowToTop } from "react-icons/bi";
import { Button, Col, Radio, Row, Upload } from "antd";
// @components
import LogoPositionContainer from "../../../atoms/LogoPositionContainer";
import { getBase64 } from "../../../utils";
import { useFullEditorProvider } from "../../../../context/fullEditor";
import { useMediaQuery } from "../../../utils";

// @constants
import { MOBILE_RESOLUTION } from "../../../../constants/variables";

// @styles
import classes from "./styles.module.scss";
import classNames from "classnames";


const LogoPosition = () => {
  const { position, setPosition, setLogo } = useFullEditorProvider();
  const [showMessage, setShowMessage] = useState(false);
  const [logoUpdated, setLogoUpdated] = useState(false);
  const isMobile = useMediaQuery(MOBILE_RESOLUTION);

  const onChangeLogo = async (event) => {
    if (event.file.validation) {
      const preview = await getBase64(event.file.originFileObj);
      setLogo(preview);
    }
  };

  return (
    <LogoPositionContainer
      title="Add Your Company Logo"
      className={classes.mainContainer}
    >
      <div className={classes.content}>
        <Row className={classes.rowUpload}>
          <Col md={12}>
            <ImgCrop aspect={1.7}>
              <Upload
                accept="image/png, image/jpeg, image/jpg, image/pdf"
                beforeUpload={file => {
                  const isLt500k = (file.size / 1024 / 1024) < 0.5;
                  file.validation = isLt500k;
                  setShowMessage(!isLt500k);
                  setLogoUpdated(isLt500k);
                  return isLt500k;
                }}
                className={classes.upload}
                onChange={onChangeLogo}
              >
                <div className={classes.uploadContent}>
                  <Button className={classes.uploadButton}>Upload Logo</Button>
                </div>
                {showMessage && (
                  <p className={classes.fileTooLong}>
                    File is too big Max. filesize: 500 KB
                  </p>
                )}
                {logoUpdated && (
                  <p className={classes.logoUpdated}>Logo loaded correctly</p>
                )}
              </Upload>
            </ImgCrop>
            {isMobile ? (
              <p className={classNames(classes.helpText, classes.showTextTop)}>
                Only JPEG, PNG and PDF files. <br /> Maximum upload file size:
                500 KB.
              </p>
            ) : (
              <p className={classNames(classes.helpText, classes.showTextTop)}>
                Only JPEG, PNG and PDF files. Maximum upload file size: 500 KB.
              </p>
            )}
          </Col>
          <Col md={12} className={classes.rowOptions}>
            <p className={classes.placement}>Placement</p>
            <Radio.Group
              className={classes.radioGroup}
              onChange={(e) => setPosition(e.target.value)}
              value={position}
            >
              <Radio value="top">
                <BiArrowToTop /> Header once
              </Radio>
              <Radio value="bottom">
                <BiArrowToBottom /> Footer in every page
              </Radio>
              <Radio value="none">No logo</Radio>
            </Radio.Group>
          </Col>
        </Row>

        <p className={classes.helpText}>
          Only JPEG, PNG and PDF files. Maximum upload file size: 500 KB.
        </p>
      </div>
    </LogoPositionContainer>
  );
};

export default LogoPosition;
