const HamburgerMenu = ({ className = "custom-icon", size = 14 }) => (
  <svg
    className={className}
    fill="none"
    height={size}
    viewBox={`0 0 ${size} ${size}`}
    width={size}
    xmlns="http://www.w3.org/2000/svg"
  >
    <line y1="0.5" x2="14" y2="0.5" stroke="black" strokeOpacity="0.4" />
    <line y1="4.5" x2="14" y2="4.5" stroke="black" strokeOpacity="0.4" />
    <line y1="8.5" x2="14" y2="8.5" stroke="black" strokeOpacity="0.4" />
  </svg>
);

export default HamburgerMenu;
