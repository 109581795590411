const Eye = ({
  className = "custom-icon",
  color = "#FF9900",
  width = 18,
  height = 11,
  isMobile = false,
}) => {
  if (isMobile) {
    return (
      <svg
        className={className}
        width="10"
        height="6"
        viewBox="0 0 10 6"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M4.6577 6C3.98024 5.99037 3.34818 5.80743 2.74838 5.50414C2.01596 5.13465 1.37912 4.63277 0.81158 4.04424C0.559474 3.78307 0.327679 3.50024 0.093495 3.22222C-0.0319609 3.07177 -0.0295722 2.92614 0.0911045 2.77329C0.825918 1.83212 1.6838 1.03658 2.75077 0.488962C3.25379 0.2302 3.78429 0.0629068 4.34944 0.0135614C4.94326 -0.0381911 5.51558 0.0605003 6.07117 0.269917C6.5957 0.467299 7.07362 0.748927 7.52168 1.08351C8.16149 1.56376 8.73312 2.12986 9.22071 2.76607C9.34617 2.92855 9.34617 3.06696 9.21832 3.22944C8.50741 4.13811 7.67701 4.91079 6.65663 5.4584C6.17512 5.71596 5.66493 5.89529 5.12129 5.96269C4.96716 5.98195 4.81183 5.98796 4.6577 6ZM0.738697 2.99234C0.754229 3.0116 0.762593 3.02363 0.773347 3.03567C1.10984 3.44002 1.48261 3.81229 1.88692 4.14774C2.38874 4.56297 2.9288 4.9144 3.54293 5.13586C4.04595 5.31759 4.56092 5.3886 5.09381 5.30917C5.5801 5.23695 6.03055 5.06244 6.45829 4.82293C7.16682 4.42576 7.7702 3.8974 8.31623 3.29804C8.40465 3.20055 8.48948 3.09946 8.5767 2.99956C8.56117 2.9803 8.55161 2.96586 8.53967 2.95262C8.23187 2.584 7.89417 2.24182 7.53004 1.92961C7.02941 1.50114 6.49055 1.13527 5.87402 0.894557C5.33636 0.683936 4.78196 0.596077 4.20606 0.686343C3.6887 0.76698 3.21436 0.960754 2.76511 1.22433C2.13305 1.59382 1.58463 2.06802 1.08639 2.60239C0.968102 2.72997 0.855789 2.86115 0.738697 2.99234Z"
          fill={color}
        />
        <path
          d="M2.80664 2.99707C2.80664 2.75183 2.85461 2.50899 2.94782 2.28244C3.04102 2.05588 3.17763 1.85004 3.34984 1.67669C3.52205 1.50333 3.72648 1.36586 3.95146 1.27212C4.17643 1.17838 4.41754 1.13021 4.661 1.13037C5.68137 1.13158 6.50819 1.96684 6.50938 2.99587C6.51058 4.0249 5.67898 4.86377 4.65502 4.86257C3.63106 4.86136 2.80664 4.0249 2.80664 2.99707ZM3.45423 2.99105C3.44945 3.65661 3.98712 4.20664 4.64547 4.21145C5.30381 4.21626 5.85701 3.67828 5.86179 3.01031C5.86657 2.34234 5.33129 1.7851 4.66817 1.78149C4.50955 1.78038 4.35227 1.81081 4.20535 1.87104C4.05843 1.93127 3.92475 2.02012 3.81198 2.13248C3.69921 2.24485 3.60956 2.37853 3.54817 2.52586C3.48678 2.67319 3.45486 2.83127 3.45423 2.99105Z"
          fill={color}
        />
      </svg>
    );
  }
  return (
    <svg
      className={className}
      fill="none"
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      width={width}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.59869 11C7.35667 10.9823 6.1979 10.647 5.09827 10.0909C3.75549 9.41353 2.58796 8.49341 1.54747 7.41443C1.08527 6.93562 0.660316 6.4171 0.230978 5.9074C0.000975244 5.63159 0.00535463 5.3646 0.226595 5.08437C1.57375 3.35889 3.14653 1.90039 5.10265 0.896431C6.02485 0.422033 6.99743 0.115329 8.03354 0.0248626C9.12222 -0.070017 10.1715 0.110917 11.19 0.494849C12.1517 0.856715 13.0279 1.37303 13.8493 1.98644C15.0223 2.8669 16.0703 3.90474 16.9642 5.07113C17.1942 5.36901 17.1942 5.62276 16.9598 5.92063C15.6565 7.58654 14.1341 9.00312 12.2634 10.0071C11.3806 10.4793 10.4453 10.808 9.4486 10.9316C9.16603 10.9669 8.88126 10.9779 8.59869 11ZM1.41385 5.48595C1.44232 5.52126 1.45766 5.54333 1.47737 5.56539C2.09428 6.30671 2.77768 6.9892 3.51892 7.6042C4.43893 8.36544 5.42903 9.00974 6.55495 9.41574C7.47715 9.74892 8.42126 9.8791 9.39822 9.73347C10.2898 9.60108 11.1156 9.28114 11.8998 8.84205C13.1987 8.1139 14.3049 7.14524 15.306 6.0464C15.4681 5.86768 15.6236 5.68234 15.7835 5.4992C15.7551 5.46389 15.7375 5.43741 15.7156 5.41314C15.1513 4.73734 14.5322 4.11 13.8647 3.53761C12.9468 2.7521 11.9589 2.08132 10.8286 1.64002C9.84289 1.25388 8.8265 1.09281 7.77068 1.25829C6.82219 1.40613 5.95256 1.76138 5.12894 2.24461C3.97016 2.922 2.96472 3.79136 2.05128 4.77105C1.83442 5.00494 1.62852 5.24545 1.41385 5.48595Z"
        fill={color}
      />
      <path
        d="M5.20557 5.49455C5.20557 5.04494 5.29352 4.59974 5.46439 4.18439C5.63527 3.76903 5.88572 3.39167 6.20144 3.07385C6.51715 2.75603 6.89194 2.504 7.30439 2.33214C7.71685 2.16028 8.15887 2.07198 8.60522 2.07227C10.4759 2.07447 11.9917 3.60578 11.9939 5.49234C11.9961 7.3789 10.4715 8.91683 8.59427 8.91462C6.71701 8.91242 5.20557 7.3789 5.20557 5.49455ZM6.39282 5.48351C6.38405 6.70371 7.36978 7.71208 8.57675 7.72091C9.78371 7.72974 10.7979 6.74343 10.8067 5.51882C10.8154 4.29421 9.83409 3.2726 8.61837 3.26598C8.32756 3.26395 8.03922 3.31974 7.76987 3.43016C7.50051 3.54059 7.25543 3.70347 7.04868 3.90947C6.84194 4.11547 6.67758 4.36055 6.56503 4.63066C6.45248 4.90076 6.39396 5.19058 6.39282 5.48351Z"
        fill={color}
      />
    </svg>
  );
};

export default Eye;
