const Pen = ({ className = "custom-icon", color = "black", size = 18 }) => (
  <svg
    className={className}
    fill="none"
    height={size}
    viewBox={`0 0 ${size} ${size}`}
    width={size}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5.90601 13.3192L2.91946 10.3391L10.4839 2.79492C11.4794 3.78451 12.4824 4.78539 13.4704 5.77497L5.90601 13.3192Z"
      fill={color}
    />
    <path
      d="M11.0076 2.2689L13.2814 0L16.2717 2.98381L13.9941 5.25648L11.0076 2.2689Z"
      fill={color}
    />
    <path
      d="M2.48937 10.7539L5.5023 13.7641L0.996094 15.2541L2.48937 10.7539Z"
      fill={color}
    />
  </svg>
);

export default Pen;
