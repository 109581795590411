const Share = ({
 className = "custom-icon",
 color = "#000000",
 width = 17,
 height = 11
}) => (
  <svg
    className={className}
    width={width}
    height={height}
    viewBox="0 0 17 11"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.92596 7.2857C7.83522 7.26785 5.84596 7.30357 3.94805 7.94643C2.39521 8.47322 1.2382 9.42856 0.324771 10.6339C0.234527 10.7675 0.125404 10.8905 0 11C0.568358 8.89286 1.58328 6.99107 3.32895 5.44643C5.07462 3.90179 7.32776 3.13393 9.89552 3.125V0L17 5.63392L9.92596 10.8571V7.2857Z"
      fill={color}
    />
  </svg>


);

export default Share;
