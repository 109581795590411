//@packages
import { createContext, useContext, useEffect, useState } from "react";

//@utils
import { getNotifications, readAllNotifications } from "../utils/notifications";

const initialState = {
  notificationsList: []
}

const Context = createContext(initialState)
const { Provider, Consumer } = Context;

const NotificationsProvider = ({ children }) => {
  const [notificationsList, setNotificationsList] = useState([]);

  const getNotificationsList = async () => {
    const notifications = await getNotifications();
    setNotificationsList(notifications.filter(n => !n.is_read));
  }

  const handleOpenNotifications = async () => {
    const data = notificationsList.map(notification => notification.id);
    await readAllNotifications(data);
    await getNotificationsList();
  }

  useEffect(() => {
    (async () => getNotificationsList())();
  }, [])

  const value = {
    notificationsList,
    getNotificationsList,
    handleOpenNotifications
  }

  return <Provider value={value}>{children}</Provider>
};

const useNotificationsProvider = () => useContext(Context);

export { NotificationsProvider, Context, useNotificationsProvider, Consumer }
