// @packages
import { Quill } from "react-quill";
const Parchment = Quill.import("parchment");
const Delta = Quill.import("delta");

export function markParties(quillRef, parties) {
  const partyData = getPartyData(parties);

  const documentNodes = Array.from(
    document.querySelector(".ql-editor").childNodes
  );
  documentNodes.forEach((node) => {
    let foundNode = {
      party: 0,
      node: null,
    };
    if (node.innerText.indexOf(partyData.party1.email) !== -1) {
      foundNode = {
        party: 1,
        node,
      };
    } else if (node.innerText.indexOf(partyData.party2.email) !== -1) {
      foundNode = {
        party: 2,
        node,
      };
    }
    if (foundNode.node) {
      let blot = Parchment.find(node);
      let index = blot.offset(quillRef.scroll);
      const replaceDelta = new Delta()
        .retain(index)
        .insert("")
        .delete(node.innerText.length);
      quillRef.updateContents(replaceDelta);
      quillRef.insertEmbed(index, "no-editable", {
        id: `party${foundNode.party}-email`,
        text: partyData[`party${foundNode.party}`].email,
      });
      quillRef.insertEmbed(index, "no-editable", {
        id: `party${foundNode.party}-name`,
        text: partyData[`party${foundNode.party}`].name + " ",
      });
    }
  });
}

export function updatePartie(quillRef, parties) {
  const partyData = getPartyData(parties);
  for (const partyKey in partyData) {
    const partyObject = partyData[partyKey];
    for (const key in partyObject) {
      const value = partyObject[key];
      let node = document.getElementById(`${partyKey}-${key}`);
      /* node.innerText = value + (key === "name" ? " " : ""); */
      if (node) {
        let blot = Parchment.find(node);
        let index = blot.offset(quillRef.scroll);
        const replaceDelta = new Delta().retain(index).insert("").delete(1);
        quillRef.updateContents(replaceDelta);
        quillRef.insertEmbed(index, "no-editable", {
          id: `${partyKey}-${key}`,
          text: value + (key === "name" ? " " : ""),
        });
      }
    }
  }
}

export function formatDocumentText(template, parties) {
  const parser = new DOMParser();
  const partyData = getPartyData(parties);
  const html = parser.parseFromString(template, "text/html");

  for (const partyKey in partyData) {
    const partyObject = partyData[partyKey];
    for (const key in partyObject) {
      const value = partyObject[key];
      const element = html.getElementById(`${partyKey}-${key}`);
      if (element) {
        element.removeAttribute("contenteditable");
        element.removeAttribute("class");
        element.innerText = value + (key === "name" ? " " : "");
      }
    }
  }

  return html.querySelector("body").innerHTML;
}

export function handleTableInsert(node, delta) {
  const Delta = Quill.import("delta");
  const tableTagStyles = node.getAttribute("style");
  fixToolbarScrollIssue();
  return new Delta([
    {
      insert: {
        table:
          `<style>#tableId {${tableTagStyles} margin: 0 auto !important; }</style>` +
          delta.ops[0].insert.table,
      },
    },
  ]);
}

export function preFormatDocument(template) {
  const parser = new DOMParser();
  const html = parser.parseFromString(template, "text/html");
  const partiesSection = html.getElementById("FullEditor-PartiesSection");
  const partiesTables = html.querySelectorAll("table");
  const images = [];

  // Delete styles
  html.querySelector("style")?.remove();

  //Load images of parties
  if (partiesTables.length > 0) {
    partiesTables.forEach((table) => {
      const imgs = table.querySelectorAll("img");
      if (imgs) {
        images.push(imgs);
      }
      table.remove();
    });
  }

  // Delete parties
  if (partiesSection) {
    const imgs = partiesSection.querySelectorAll("img");
    if (imgs) {
      images.push(imgs);
    }
    partiesSection.remove();
  }

  return { content: html.querySelector("body").innerHTML, images };
}

export function initialValues(steps, ref) {
  const initialValues = steps.map((step) => {
    const values = Object.entries(step.fields)
      .filter((field, i) => field[1] && field[1].is_predefined !== 3)
      .map((field, i) => {
        return [field[1].id, field[1].val];
      });
    return Object.fromEntries(values);
  });
  initialValues.forEach((item) => handlePostUserData(item, ref));
}

const handlePostUserData = (values, ref) => {
  let formData = [];
  let stepFormData = {
    fields: [],
  };

  Object.entries(values).map(([key, value], index) => {
    const date = value && value._d ? new Date(value._d) : null;

    if (!key.includes("deliverable") && !value)
      return formData.push({ id: key });

    // set deliverable items data
    if (key.includes("deliverable")) {
      const dataItem = formData.filter((item) =>
        key.includes(item.id.toString())
      );

      if (dataItem.length === 0) return;

      if (value) {
        return (dataItem[0].value = JSON.stringify(
          value.map((item) => {
            const itemDate = item.date._d ? new Date(item.date._d) : null;

            return {
              date: itemDate
                ? itemDate.getMonth() +
                  1 +
                  "/" +
                  itemDate.getDate() +
                  "/" +
                  itemDate.getFullYear()
                : "",
              description: item.description,
            };
          })
        ));
      } else {
        return (dataItem[0].value = JSON.stringify([]));
      }
    }

    ref.setState((prevState) => {
      const oldArray = prevState.docStepData;
      const foundIndex = oldArray.findIndex((x) => x.id === key);

      if (foundIndex >= 0 && oldArray[foundIndex]) {
        oldArray[foundIndex].value = date
          ? date.getMonth() +
            1 +
            "/" +
            date.getDate() +
            "/" +
            date.getFullYear()
          : value
          ? value
          : "";
      }

      const docStepData =
        foundIndex >= 0
          ? [...oldArray]
          : [
              ...oldArray,
              {
                id: key,
                value: date
                  ? date.getMonth() +
                    1 +
                    "/" +
                    date.getDate() +
                    "/" +
                    date.getFullYear()
                  : value
                  ? value
                  : "",
              },
            ];
      return { docStepData };
    });

    return stepFormData.fields.push({
      id: key,
      value: date
        ? date.getMonth() + 1 + "/" + date.getDate() + "/" + date.getFullYear()
        : value
        ? value
        : "",
    });
  });

  formData.length !== 0 &&
    formData.reduce((obj, item) => {
      if (!item.value) item.value = "";

      ref.setState((prevState) => {
        const oldArray = prevState.docStepData;
        const foundIndex = oldArray.findIndex((x) => x.id === item.id);

        if (foundIndex >= 0 && oldArray[foundIndex]) {
          oldArray[foundIndex].value = item.value;
        }
        const docStepData =
          foundIndex >= 0 ? [...oldArray] : [...oldArray, item];
        return { docStepData };
      });

      return stepFormData.fields.push(item);
    }, {});
};

const fixToolbarScrollIssue = () => {
  const Y = window.scrollY;
  let timer = setTimeout(() => {
    window.scrollTo(0, Y);
  }, 100);
  return () => {
    clearTimeout(timer);
  };
};

const getPartyData = (parties) => ({
  party1: {
    name: parties[0][0].val,
    email: parties[0][1].val,
  },
  party2: {
    name: parties[1][0].val,
    email: parties[1][1].val,
  },
});
