import PDFViewer from "../PDFViewer";
import { API } from "../../constants/variables";
import StepTemplate from "../templates/StepTemplate";
import styles from "./styles.module.scss";
import React from "react";
import FooterContainer from "../organisms/FullEditor/FooterContainer";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { PRODUCT_PAGE, THANK_YOU_PAGE } from "../../constants/siteMap";
import { useFullEditorProvider } from "../../context/fullEditor";

const ConfirmationContract = ({ templateId, contractId }) => {
  const history = useHistory();
  const title = useSelector((store) => store.templatesAdmin.title);
  const { contextParties: parties } = useFullEditorProvider();

  const goToCongratulation = () => {
    history.push(
      `${PRODUCT_PAGE}/${templateId}/${THANK_YOU_PAGE}/${contractId}?final=1`
    );
    window.scrollTo(0, 0);
  };

  const handleSave = (callback) => {
    if (callback && typeof callback === "function") {
      callback();
    } else {
      goToCongratulation();
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <h2 className={styles.headerTitle}>{title}</h2>
      </div>
      <div className={styles.content}>
        <StepTemplate
          title="Confirmation"
          description="Carefully review your contract. If you would like to correct something, click “Continue Editing” button below. Also check pages and spacing. Generally, signatures should not be on their own page, there should be the full signature block and text above."
          saveMessage="Save & finish"
          withFooter={false}
          parties={parties}
          disabledParties
        >
          <>
            <PDFViewer
              fullEditor
              PDFUrl={`${API}template/${templateId}/pdf_create`}
              key="completedDocument"
              wide
            />
            <FooterContainer withTerms onSave={handleSave} />
          </>
        </StepTemplate>
      </div>
    </div>
  );
};

export default ConfirmationContract;
