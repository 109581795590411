import axios from "axios";
import { getGMTDateTime } from "../store/utils";
import { API } from "../constants/variables";

export class ContractService {
  constructor() {}

  async getHistoryById(id) {
    const dt = getGMTDateTime();
    const url = `${API}envelope/${id}/history?dt=${dt}`;
    const response = await axios.get(url);
    return response.data.data;
  }
}
