//@packages
import React from 'react';

//@components
import NotificationsCard from "../NotificationsCard";
import { Badge, Popover } from "antd";

//@icons
import BellIcon from "../../atoms/icons/Bell";

//@utils
import { useMediaQuery } from "../../utils";

// @constants
import { MOBILE_RESOLUTION } from "../../../constants/variables";

//@styles
import classes from "./styles.module.scss";
import classNames from "classnames";


const NotificationsPopover = ({ data, notificationsSize, handleVisible, visible, iconClassName, onVisibleChange }) => {
  const isMobile = useMediaQuery(MOBILE_RESOLUTION);

  return (
    <Popover
      placement="bottomRight"
      content={
        <NotificationsCard
          data={data}
          className={isMobile ? classes.cardNotificationMobile : classes.cardNotificationDesktop}
          handleVisible={handleVisible}
        />
      }
      trigger="click"
      visible={visible}
      onVisibleChange={onVisibleChange}
    >
      <Badge count={notificationsSize} offset={[-5, 0]}
             className={
               classNames(
                 !notificationsSize && isMobile && classes.badgeFilled,
                 !notificationsSize && !isMobile && classes.badgeEmpty,
                 classes.badge
               )}
      >
        <BellIcon className={iconClassName} />
      </Badge>
    </Popover>
  );
};

export default NotificationsPopover;
