import uuid from "react-uuid";
import { Button, Input, Modal } from "antd";
import { DeleteOutlined } from "@ant-design/icons";
import { Fragment, useState } from "react";
// @styles
import styles from "./styles.module.scss";

const emailRegex =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const MAX_EMAILS = 6;

const SendForReviewModal = ({ isLoading, onClose, onSave, open }) => {
  const [emails, setEmails] = useState([{ value: "", index: uuid() }]);

  const verifyEmails =
    emails.filter(({ value }) => Boolean(value) && value.match(emailRegex))
      .length > 0;

  const handleChangeEmail = (e, index) => {
    const { value } = e.target;
    const newEmails = [...emails].map((email) => {
      if (email.index === index) return { ...email, value };
      else return email;
    });
    setEmails(newEmails);
  };

  const handleAddEmail = () => {
    if (emails.length + 1 <= MAX_EMAILS) {
      setEmails((prev) => [
        ...prev,
        {
          value: "",
          index: uuid(),
        },
      ]);
    }
  };

  const handleRemoveEmail = (index) => {
    const newEmails = [...emails].filter((email) => email.index !== index);
    if (newEmails.length > 0) setEmails(newEmails);
  };

  const handleClose = () => {
    setEmails([{ value: "", index: uuid() }]);
    onClose();
  };

  const handleSend = () => {
    const emailList = [...emails].map(({ value }) => value);
    onSave(emailList);
    setEmails([{ value: "", index: uuid() }]);
  };

  return (
    <Modal
      title={
        <div className="custom-header">
          <p>
            Enter the email(s) to share this contract prior to signing.{" "}
            <span>You can send for review to 6 people at a time</span>
          </p>
        </div>
      }
      visible={open}
      footer={
        <Button
          disabled={!verifyEmails}
          onClick={handleSend}
          loading={isLoading}
        >
          Send
        </Button>
      }
      onCancel={handleClose}
      className="SendForReviewModal"
    >
      <form className={styles.form}>
        {emails.map(({ value, index }) => (
          <Fragment key={"send-for-review-modal-" + index}>
            <Input
              type="text"
              autoComplete="ÑÖcompletes"
              className="email"
              placeholder="Enter Email here"
              value={value}
              name={index}
              id={index}
              onChange={(e) => handleChangeEmail(e, index)}
              suffix={
                emails.length > 1 && (
                  <DeleteOutlined
                    className={styles.remove}
                    onClick={() => handleRemoveEmail(index)}
                  />
                )
              }
            />
          </Fragment>
        ))}

        {emails.length < MAX_EMAILS && (
          <div className={styles.addEmail} onClick={handleAddEmail}>
            + Add Another Email
          </div>
        )}
      </form>
    </Modal>
  );
};

export default SendForReviewModal;
