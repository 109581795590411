const ArchiveBox = ({
  className = "custom-icon",
  color = "#A5A5A5",
  size = 25,
}) => (
  <svg
    className={className}
    fill="none"
    height={size}
    viewBox={`0 0 ${size} ${size}`}
    width={size}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M28.9284 8.12078V24.5733C28.9284 25.7333 28.6199 26.0304 27.4152 26.0304H3.55544C2.39478 26.0304 2.07156 25.7191 2.07156 24.6157V8.13494L1.71895 8.0925C0.117528 8.03591 0 7.90859 0 6.35246V1.47189C0 0.509921 0.367302 0.15625 1.38105 0.15625H29.6483C30.6474 0.15625 31 0.509915 31 1.50018V6.52221C31 7.85199 30.7796 8.0642 29.4133 8.0925L28.9284 8.12078ZM26.8275 24.0357V8.13494H4.17252V24.0357H26.8275ZM2.08625 6.09782H28.899V2.17922H2.08625V6.09782Z"
      fill={color}
    />
    <path
      d="M15.5016 11.1328H19.4537C20.2177 11.147 20.6437 11.5006 20.6437 12.0948C20.6437 12.6889 20.2177 13.0568 19.4684 13.0568H11.5494C10.7854 13.0568 10.3594 12.7031 10.3594 12.0948C10.3594 11.4865 10.7854 11.147 11.5347 11.1328H15.5016Z"
      fill={color}
    />
  </svg>
);

export default ArchiveBox;
