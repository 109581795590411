import { createContext, useContext, useState } from "react";

const initialState = {
  contextParties: [],
  form: null,
  logo: "",
  position: "bottom",
};
const Context = createContext(initialState);
const { Provider, Consumer } = Context;

const FullEditorProvider = ({ children }) => {
  const [form, setForm] = useState(initialState.form);
  const [logo, setLogo] = useState(initialState.logo);
  const [position, setPosition] = useState(initialState.position);
  const [contextParties, setContextParties] = useState(
    initialState.contextParties
  );

  const value = {
    contextParties,
    form,
    logo,
    position,
    setContextParties,
    setForm,
    setLogo,
    setPosition,
  };
  return <Provider value={value}>{children}</Provider>;
};

const useFullEditorProvider = () => useContext(Context);

export { FullEditorProvider, Context, Consumer, useFullEditorProvider };
