const DoubleCheck = ({
  className = "custom-icon",
  color = "#38E25D",
  width = 19,
  height = 12,
  isMobile = false,
}) => {
  if (isMobile) {
    return (
      <svg
        className={className}
        width="10"
        height="6"
        viewBox="0 0 10 6"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M5.99647 0.17877L5.96541 0.150483C5.85589 0.051096 5.70753 -0.00286056 5.55347 0.000116997C5.39941 0.00273399 5.25346 0.0630062 5.14884 0.166499L1.98938 3.3052L1.92726 3.32938L1.86677 3.30407L1.0829 2.47036C0.969323 2.35013 0.806632 2.27831 0.632575 2.27199C0.458441 2.26567 0.289288 2.3263 0.166289 2.43795C-0.0298745 2.61697 -0.0556338 2.89869 0.105806 3.10456L1.72292 5.16688C1.81486 5.28413 1.96322 5.35334 2.12139 5.35334H2.26237C2.52843 5.35334 2.7773 5.23502 2.92809 5.03587L6.06834 0.905293C6.24004 0.67936 6.20898 0.373381 5.99647 0.17877Z"
          fill={color}
        />
        <path
          d="M9.08632 0.17877L9.05526 0.150483C8.94573 0.051096 8.79737 -0.00286056 8.64331 0.000116997C8.48925 0.00273399 8.34331 0.0630062 8.23869 0.166499L5.07922 3.3052L5.0171 3.32938L4.95662 3.30407L4.17274 2.47036C4.05917 2.35013 3.89648 2.27831 3.72242 2.27199C3.54828 2.26567 3.37913 2.3263 3.25613 2.43795C3.05997 2.61697 3.03421 2.89869 3.19565 3.10456L4.81276 5.16688C4.9047 5.28413 5.05306 5.35334 5.21123 5.35334H5.35221C5.61827 5.35334 5.86714 5.23502 6.01793 5.03587L9.15818 0.905293C9.32989 0.67936 9.29883 0.373381 9.08632 0.17877Z"
          fill={color}
        />
      </svg>
    );
  }

  return (
    <svg
      className={className}
      fill="none"
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      width={width}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.8597 0.871146L11.7983 0.815201C11.5817 0.618635 11.2882 0.511921 10.9835 0.51781C10.6788 0.522985 10.3902 0.64219 10.1833 0.846877L3.93455 7.05453L3.81169 7.10236L3.69207 7.0523L2.14173 5.4034C1.91711 5.16561 1.59534 5.02356 1.25109 5.01107C0.906695 4.99858 0.572147 5.11848 0.328884 5.33931C-0.0590852 5.69336 -0.110031 6.25056 0.20926 6.65772L3.40755 10.7365C3.58939 10.9684 3.88282 11.1053 4.19564 11.1053H4.47447C5.00068 11.1053 5.49289 10.8713 5.79111 10.4774L12.0018 2.30805C12.3414 1.8612 12.28 1.25604 11.8597 0.871146Z"
        fill={color}
      />
      <path
        d="M17.97 0.871146L17.9086 0.815201C17.692 0.618635 17.3986 0.511921 17.0939 0.51781C16.7892 0.522985 16.5005 0.64219 16.2936 0.846877L10.0449 7.05453L9.92204 7.10236L9.80242 7.0523L8.25208 5.4034C8.02746 5.16561 7.70569 5.02356 7.36144 5.01107C7.01705 4.99858 6.6825 5.11848 6.43924 5.33931C6.05127 5.69336 6.00032 6.25056 6.31961 6.65772L9.51791 10.7365C9.69974 10.9684 9.99317 11.1053 10.306 11.1053H10.5848C11.111 11.1053 11.6032 10.8713 11.9015 10.4774L18.1122 2.30805C18.4518 1.8612 18.3903 1.25604 17.97 0.871146Z"
        fill={color}
      />
    </svg>
  );
};

export default DoubleCheck;
