export const START_PAGE = "/";

export const CONTRACT_PAGE = "/contract";
export const FULL_EDITOR = "full-editor";
export const PRODUCT_PAGE = "/product";
export const THANK_YOU_PAGE = "thank-you";
export const CONFIRMATION_PAGE = "/confirmation";
export const SIGN_PAGE = "/sign";
export const ADMIN_PANEL = "/#admin-screen"

export const EDIT_PAGE = "/edit";

export const LOGOUT_PAGE = "/logout";
