//@packages
import axios from "axios";

//@constants
import { API, SITE_DOMAIN } from "../constants/variables";

import { getGMTDateTime } from "../store/utils";

//@utils
import { showErrorMessage } from "./errorMessageHandler";

export const getDrafts = async () => {
  try {
    const dt = getGMTDateTime();
    const resp = await axios.get(`${API}folder/list/template?${dt}`, { dt });
    if (validateResponse(resp)) {
      return {
        folders: resp.data.data.folders,
        templates: resp.data.data.templates,
        success: true,
      };
    } else {
      return { folders: [], templates: [], success: false };
    }
  } catch (e) {
    showErrorMessage(e.response.status)
  }
};

export const getInProgress = async () => {
  try {
    const dt = getGMTDateTime();
    const resp = await axios.get(`${API}folder/list/envelope_progress?${dt}`, {
      dt,
    });

    if (validateResponse(resp)) {
      return {
        folders: resp.data.data.folders,
        templates: resp.data.data.envelopes,
        success: true,
      };
    } else {
      return { folders: [], templates: [], success: false };
    }
  } catch (e) {
    showErrorMessage(e.response.status)
  }
};

export const getSent = async () => {
  try {
    const dt = getGMTDateTime();
    const resp = await axios.get(`${API}folder/list/envelope_complete?${dt}`, {
      dt,
    });

    if (validateResponse(resp)) {
      return {
        folders: resp.data.data.folders,
        templates: resp.data.data.envelopes,
        success: true,
      };
    } else {
      return { folders: [], templates: [], success: false };
    }
  } catch (e) {
    showErrorMessage(e.response.status)
  }
};

export const forwardTo = async (project, email) => {
  try {
    const resp = await axios.put(`${API}envelope/${project.id}/action`, {
      action: "forward",
      email,
    });
    const err = resp.data.data.action_result.errors;
    return [!Boolean(err.length), resp.data.data.action_result];
  } catch (e) {
    showErrorMessage(e.response.status)
  }
};

export const addAgain = async (project) => {
  try {
    const resp = await axios.post(`${SITE_DOMAIN}/wp-admin/admin-ajax.php`, {
      prod_id: project.wp_product_id,
      action: "create_free_subscription_order_diz",
    });
  } catch (e) {
    showErrorMessage(e.response.status)
  }
};

export const updateContractsIntoFolders = async (data) => {
  try {
    await axios.post(`${API}folder/${data.id}/template/set_positions`, data.data);
  } catch (e) {
    showErrorMessage(e.response.status)
  }
};

export const updateProjectState = async (folderId, data) => {
  try {
    await axios.put(`${API}folder/open_state/${folderId}`, data);
  } catch (e) {
    showErrorMessage(e.response.status)
  }
};

export const updateProjectPosition = async (folderId, data) => {
  try {
    await axios.post(`${API}folder/${folderId}/template/set_positions`, data);
  } catch (e) {
    showErrorMessage(e.response.status)
  }
};

export const deleteContractById = async (id, status) => {
  try {
    const type = status === 0 ? "template" : "envelope";
    const resp = await axios.delete(`${API}${type}/${id}/delete`);
    return { data: resp.data, success: validateResponse(resp) };
  } catch (e) {
    showErrorMessage(e.response.status)
  }
};

export const archiveContractById = async (id, status) => {
  try {
    const type = status === 0 ? "template" : "envelope";
    const resp = await axios.put(`${API}${type}/${id}/archive`, {});
    return { data: resp.data, success: resp.status === 200 };
  } catch (e) {
    showErrorMessage(e.response.status)
  }
};

export const unarchiveContractById = async (id, status) => {
  try {
    const type = status === 0 ? "template" : "envelope";
    const resp = await axios.put(`${API}${type}/${id}/unarchive`, {});
    return { data: resp.data, success: resp.status === 200 };
  } catch (e) {
    showErrorMessage(e.response.status)
  }
};

export const recallContract = async (id, status) => {
  try {
    const type = status === 0 ? "template" : "envelope";
    const resp = await axios.put(`${API}${type}/${id}/action`, {
      action: "cancel",
    });
    return { data: resp.data, success: validateResponse(resp) };
  } catch (e) {
    showErrorMessage(e.response.status)
  }
};

export const signContract = async (id, status) => {
  try {
    const type = status === 0 ? "template" : "envelope";
    const resp = await axios.put(
      `${API}${type}/${id}/action`,
      {
        action: "sign",
      },
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    );
    return { data: resp.data, success: validateResponse(resp) };
  } catch (e) {
    showErrorMessage(e.response.status)
  }
};

export const resendTo = async (id, status, resendData) => {
  try {
    const type = status === 0 ? "template" : "envelope";
    const resp = await axios.put(`${API}${type}/${id}/action`, {
      action: resendData,
    });
    return { data: resp.data, success: resp.status === 200 };
  } catch (e) {
    showErrorMessage(e.response.status)
  }
};

export const cloneContract = async (id) => {
  try {
    const resp = await axios.post(`${API}template/${id}/clone`);
    return { data: resp.data, success: resp.status === 200 };
  } catch (e) {
    showErrorMessage(e.response.status)
  }
};

export const createProject = async (data) => {
  try {
    const resp = await axios.post(`${API}folder/create`, data);
    return { data: resp.data, success: validateResponse(resp) };
  } catch (e) {
    showErrorMessage(e.response.status)
  }
};

export const archiveProject = async (id) => {
  try {
    const resp = await axios.put(`${API}folder/${id}/archive`, {});
    return { data: resp.data, success: resp.status === 200 };
  } catch (e) {
    showErrorMessage(e.response.status)
  }
};

export const unarchiveProject = async (id) => {
  try {
    const resp = await axios.put(`${API}folder/${id}/unarchive`, {});
    return { data: resp.data, success: resp.status === 200 };
  } catch (e) {
    showErrorMessage(e.response.status)
  }
};

export const renameDraftContract = async (id, newName) => {
  try {
    await axios.put(`${API}template/${id}/rename`, {
      data: { name: newName },
    });
  } catch (e) {
    showErrorMessage(e.response.status)
  }
};

export const renameNoDraftContract = async (id, newName) => {
  try {
    await axios.put(`${API}envelope/${id}/rename`, {
      data: { name: newName },
    });
  } catch (e) {
    showErrorMessage(e.response.status)
  }
};

export const renameFolder = async (id, newName) => {
  try {
    const resp = await axios.put(`${API}folder/${id}/rename`, {
      data: { name: newName },
    });

    return { data: resp.data, success: validateResponse(resp) };
  } catch (e) {
    showErrorMessage(e.response.status)
  }
};

export const moveContract = async (data) => {
  try {
    const resp = await axios.post(
      `${API}folder/${data.id}/template/set_positions`,
      data.data
    );
    return { data: resp.data, success: validateResponse(resp) };
  } catch (e) {
    showErrorMessage(e.response.status)
  }
};

export const moveSentContract = async (data) => {
  try {
    const resp = await axios.post(
      `${API}folder/${data.id}/envelope_complete/set_positions`,
      data.data
    );
    return { data: resp.data, success: validateResponse(resp) };
  } catch (e) {
    showErrorMessage(e.response.status)
  }
};

export const moveProgressContract = async (data) => {
  try {
    const resp = await axios.post(
      `${API}folder/${data.id}/envelope_progress/set_positions`,
      data.data
    );
    return { data: resp.data, success: validateResponse(resp) };
  } catch (e) {
    showErrorMessage(e.response.status)
  }
};

export const sendForSignatureContract = async (id) => {
  try {
    const resp = await axios.put(`${API}template/${id}/send`);
    return { data: resp.data, success: validateResponse(resp) };
  } catch (e) {
    showErrorMessage(e.response.status)
  }
};

export const addContractToFolder = async (
  idFolder,
  idContract,
  status,
  previousData = []
) => {
  try {
    const { type, templateType } = getTypeByStatus(status);
    return await axios.post(
      `${API}folder/${idFolder}/${type}/set_positions`,
      [
        {
          id: idContract,
          type: templateType,
        },
        ...previousData,
      ]
    )
  } catch (e) {
    showErrorMessage(e.response.status)
  }
};

export const removeContractFromFolder = async (
  idContract,
  status,
  previousData = []
) => {
  try {
    const { type, templateType } = getTypeByStatus(status);
    const resp = await axios.post(`${API}folder/0/${type}/set_positions`, [
      {
        id: idContract,
        type: templateType,
      },
      ...previousData,
    ]);
    return { data: resp.data, success: resp.status === 200 };
  } catch (e) {
    showErrorMessage(e.response.status)
  }
};

const getTypeByStatus = (status) => {
  let type = "";
  let templateType = "";
  switch (status) {
    case -1:
      type = "envelope_progress";
      templateType = "envelope";
      break;
    case 0:
      type = "template";
      templateType = "template";
      break;
    case 1:
      type = "envelope_progress";
      templateType = "envelope";
      break;
    case 2:
      type = "envelope_complete";
      templateType = "envelope";
      break;
    case 4:
      type = "envelope_complete";
      templateType = "envelope";
      break;
    default:
      break;
  }
  return { type, templateType };
};

export const deleteProject = async (id, global = true) => {
  try {
    const resp = await axios.delete(
      `${API}folder/delete/${id}${global ? "/global" : ""}`
    );
    return { data: resp.data, success: resp.status === 200 };
  } catch (e) {
    showErrorMessage(e.response.status)
  }
};

const validateResponse = (resp) => {
  if (resp.status === 200 && resp?.data?.errors.length === 0) {
    return true;
  } else {
    return false;
  }
};
