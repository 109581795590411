const RoundedDottedArrow = ({ className = "custom-icon", color = "#000000", size = 16 }) => (
  <svg
    className={className}
    width={size}
    height={size}
    viewBox="0 0 16 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_53_8251)">
      <path
        d="M3.54667 7.02316H5.69778L2.85334 10.4848L0 7.04124H2.16C2.26667 4.46538 3.31556 2.45893 5.43111 1.08514C6.97778 0.0728695 8.68445 -0.207312 10.4978 0.154212C13.92 0.841107 16.3822 4.34789 15.9556 7.78236C15.4489 11.8405 11.9822 14.1271 9.11111 14.0006V12.7172C11.2267 12.5906 12.8978 11.6597 13.9556 9.75266C14.6413 8.55072 14.8421 7.12523 14.5156 5.77591C13.8311 2.82949 11.1822 1.01283 8.21334 1.42858C5.59111 1.79914 3.43111 4.34789 3.54667 7.02316Z"
        fill={color}
      />
      <path
        d="M5.6798 11.207V12.4904H0.0175781V11.207H5.6798Z"
        fill={color}
      />
      <path
        d="M13.8134 5.39584L12.6845 5.86582C12.6045 5.65795 12.5245 5.47718 12.4268 5.26027L13.5468 4.78125C13.6356 4.98009 13.7068 5.16989 13.8134 5.39584Z"
        fill={color}
      />
      <path
        d="M14.0265 6.7334V7.34799H12.8709C12.862 7.14915 12.8531 6.96839 12.8442 6.7334H14.0265Z"
        fill={color}
      />
      <path
        d="M12.4355 8.79428C12.5155 8.5864 12.5867 8.40564 12.6844 8.17969L13.8044 8.64967L13.5555 9.26426L12.4355 8.79428Z"
        fill={color}
      />
      <path
        d="M10.8266 3.61501L10.2222 3.36194L10.6844 2.22314L11.2888 2.47621L10.8266 3.61501Z"
        fill={color}
      />
      <path
        d="M4.60441 4.77197L5.7244 5.25099L5.4844 5.86558L4.37329 5.40464L4.60441 4.77197Z"
        fill={color}
      />
      <path
        d="M10.2134 10.6923L10.8178 10.4302C10.9778 10.8007 11.12 11.1532 11.2889 11.5599L10.6934 11.8311L10.2134 10.6923Z"
        fill={color}
      />
      <path
        d="M7.94655 3.35316L7.34211 3.61526L6.87988 2.4855L7.47544 2.21436L7.94655 3.35316Z"
        fill={color}
      />
      <path
        d="M8.7998 1.97949H9.38647V3.18156H8.7998V1.97949Z"
        fill={color}
      />
      <path
        d="M9.37768 12.0658H8.79102V10.8638H9.37768V12.0658Z"
        fill={color}
      />
      <path
        d="M6.2047 4.55516L5.36914 3.66039L5.77803 3.22656L6.63136 4.09422L6.2047 4.55516Z"
        fill={color}
      />
      <path
        d="M11.938 4.49213L11.5913 4.04023L12.4269 3.23584L12.8091 3.67871L11.938 4.49213Z"
        fill={color}
      />
      <path
        d="M12.3378 10.8007L11.5823 9.93307L12 9.54443L12.7823 10.4482L12.3378 10.8007Z"
        fill={color}
      />
    </g>
    <defs>
      <clipPath id="clip0_53_8251">
        <rect
          width="16"
          height="14"
          fill="white"
        />
      </clipPath>
    </defs>
  </svg>
);

export default RoundedDottedArrow;
