//@packages
import React from 'react';

const Folder = ({
size = 16,
color = "#000000",
className = "custom-icon"
}) => {
  return (
    <svg
      className={className}
      width={size}
      height={size}
      viewBox="0 0 15 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15 7.79998L14.7117 8.48643C13.8959 10.4381 13.0789 12.3887 12.2609 14.3381C12.1867 14.5135 12.0981 14.6823 11.996 14.8429C11.6574 15.3745 11.1545 15.5899 10.5443 15.5899C7.68441 15.5933 4.8212 15.5798 1.96134 15.5966C0.801298 15.6034 0.238045 14.8429 0.0737621 14.0016C0.0278842 13.7542 0.00543273 13.5029 0.00670788 13.2512C2.4555e-06 10.2396 0.00335271 7.22793 0 4.21627V4.03793L0.177695 4.02783H5.19C5.3174 4.02783 5.38445 4.05812 5.43139 4.18935C5.55544 4.53258 5.69961 4.86571 5.83036 5.20558C5.8773 5.33008 5.93765 5.38392 6.08182 5.38055C6.79483 5.37382 7.51007 5.37382 8.22755 5.38055C8.32305 5.38702 8.41377 5.42486 8.48571 5.48823C8.86457 5.85165 9.23336 6.22853 9.60551 6.60204L9.69604 6.70636H8.87127C7.64083 6.70636 6.41038 6.70299 5.18329 6.70972C5.04248 6.71308 4.98883 6.66261 4.9419 6.54147C4.80779 6.18927 4.66697 5.83595 4.51946 5.4815C4.49934 5.43103 4.42558 5.3671 4.37193 5.3671C3.36947 5.36037 2.36366 5.36037 1.33438 5.36037C1.33103 5.42767 1.32767 5.48487 1.32767 5.54544V13.7223C1.32767 14.0084 1.44502 14.18 1.6663 14.2338C1.78999 14.2619 1.91951 14.2473 2.0339 14.1924C2.14829 14.1374 2.24086 14.0453 2.29661 13.931C2.43407 13.6416 2.55476 13.3488 2.68552 13.0561L4.92848 7.95476C4.97207 7.85381 5.0123 7.79661 5.14306 7.79661C8.37842 7.80334 11.6138 7.79997 14.8492 7.80334L15 7.79998ZM13.0085 9.13587L12.8543 9.12915H6.00135C5.88736 9.12915 5.83372 9.16952 5.79013 9.27047C5.08383 10.8834 4.37529 12.4964 3.66451 14.1093C3.6444 14.1531 3.63098 14.2002 3.60752 14.2641H3.80868C5.998 14.2641 8.18732 14.254 10.3766 14.2742C10.7521 14.2776 10.9533 14.143 11.0606 13.8098L11.0807 13.7627L12.8308 9.58342L13.0085 9.13587Z"
        fill={color}
      />
      <path
        d="M8.07642 3.4491L8.98835 2.54056L10.7284 4.30717V0H12.0494V4.24323L12.093 4.27016L13.8129 2.54056L14.7215 3.45583C13.6151 4.56628 12.502 5.68682 11.399 6.79053L8.07642 3.4491Z"
        fill={color}
      />
    </svg>

  );
};

export default Folder;
