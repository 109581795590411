const DownloadWithBar = ({ className = "custom-icon", color = "#000000", size = 18 }) => (
  <svg
    className={className}
    width={size}
    height={size}
    viewBox="0 0 12 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4.79866 7.44918C4.79866 5.67434 4.7912 3.89951 4.79866 2.11689C4.79246 1.75577 4.80492 1.39456 4.83597 1.03485C4.88043 0.746165 5.02202 0.483458 5.23536 0.293814C5.44869 0.10417 5.71982 0 6.00011 0C6.28041 0 6.55153 0.10417 6.76487 0.293814C6.97821 0.483458 7.11981 0.746165 7.16426 1.03485C7.18535 1.21563 7.19532 1.39764 7.19411 1.57975V7.55038C7.70902 7.04439 8.14184 6.5851 8.61197 6.17253C8.84383 5.96609 9.12049 5.8218 9.41792 5.75218C9.94776 5.65098 10.4403 5.97015 10.6716 6.46057C10.903 6.95098 10.8059 7.52702 10.388 7.95516C9.07465 9.32521 7.75379 10.6797 6.4404 12.0342C6.31354 12.1665 6.17921 12.2755 6.04489 12.3922L6.0822 12.5012H10.6716C11.4626 12.509 12.0074 13.0228 11.9999 13.7623C11.9925 14.5018 11.4477 14.9922 10.6865 14.9922C7.56723 15.0026 4.44544 15.0026 1.32115 14.9922C0.545057 14.9922 -0.0146334 14.4473 0.000291532 13.7234C0.0152165 12.9994 0.545057 12.509 1.32115 12.5012H5.99265L5.61953 12.0964C4.28375 10.7186 2.94797 9.34855 1.61965 7.96293C0.903255 7.21563 1.17936 6.04021 2.13455 5.77554C2.60469 5.6432 3.02259 5.77553 3.3584 6.13361C3.69421 6.49169 4.21659 7.06773 4.64195 7.53479L4.79866 7.44918Z"
      fill={color}
    />
  </svg>

);

export default DownloadWithBar;
