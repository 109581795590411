import React, { PureComponent } from "react";
import { pdfjs, Document, Page } from "react-pdf";
import { connect } from "react-redux";
import axios from "axios";
import { Alert, Button, Spin } from "antd";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";

import {
  loadingTemplatePdfSelector,
  urlTemplatePdfSelector,
} from "../../store/reducers/templatePdf/selectors";
import { contentPDFSelector } from "../../store/reducers/templatesAdmin/selectors";
import {
  emailUserSelector,
  nameUserSelector,
} from "../../store/reducers/user/selectors";

import styles from "./viewer.module.scss";
import { getTokenCookies, setTokenCookies } from "../../store/utils";
import { LOADING_DOCUMENT } from "../../constants/staticTexts";
import { TOKEN_DOMAIN } from "../../constants/variables";
import { addViewport } from "../../utils";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const PAGE_WIDTH = 824;

class PDFViewer extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      error: null,
      errorInfo: null,
      userToken: getTokenCookies(),
      numPages: null,
      pageNumber: 1,
      pdfURL: null,
    };
  }

  componentDidCatch(error, errorInfo) {
    this.setState({ error, errorInfo });
  }
  
  componentWillUnmount() {
    addViewport({ vieportWidth: "device-width", clear: true });
  }

  componentDidMount() {
    const { PDFUrl, contractPdf, contractPDFUrl } = this.props;
    const { userToken } = this.state;

    this.setState({
      pdfURL:
        userToken && userToken !== "undefined"
          ? {
              url: PDFUrl || contractPdf || contractPDFUrl,
              withCredentials: true,
              httpHeaders: { "wp-token": userToken },
            }
          : PDFUrl || contractPdf || contractPDFUrl,
    });
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { contractPdf, contractPDFUrl } = this.props;
    const { userToken } = this.state;

    if (
      (prevProps.contractPDFUrl !== contractPDFUrl ||
        prevProps.contractPdf !== contractPdf ||
        prevState.userToken !== userToken) &&
      !Array.isArray(contractPdf)
    ) {
      this.setState({
        pdfURL:
          userToken && userToken !== "undefined"
            ? {
                url: contractPDFUrl || contractPdf,
                withCredentials: true,
                httpHeaders: { "wp-token": userToken },
              }
            : contractPDFUrl || contractPdf,
      });
    }
  }

  render() {
    const { contractPDFLoading, fullEditor } = this.props;
    const { error, errorInfo, pageNumber, numPages, pdfURL } = this.state;
    const isMobile = window.matchMedia("(max-width: 576px)").matches;

    if (error) {
      return <Alert type="error" message={error} description={errorInfo} />;
    }

    return (
      <div className={fullEditor ? styles.wrapperFull : styles.wrapper}>
        {(pdfURL && typeof pdfURL === "object" && pdfURL.url) ||
        (pdfURL && typeof pdfURL === "string" && pdfURL) ? (
          <Document
            file={pdfURL}
            loading={LOADING_DOCUMENT}
            error={LOADING_DOCUMENT}
            onLoadSuccess={this.onDocumentLoadSuccess}
            onLoadError={this.onDocumentLoadError}
          >
            <Page
              pageNumber={pageNumber}
              renderTextLayer={false}
              width={PAGE_WIDTH}
              scale={fullEditor ? 1 : 0.8}
            />
          </Document>
        ) : (
          <div className="loader-wrapper">
            <Spin
              size="small"
              spinning={contractPDFLoading}
              style={{ textAlign: "center" }}
            />
          </div>
        )}
        {numPages && (
          <div className={styles.navigation}>
            <Button
              shape="circle"
              disabled={pageNumber <= 1}
              icon={<LeftOutlined />}
              onClick={this.previousPage}
              aria-label="Previous"
              className={styles.button}
            />
            <p className={styles.text}>
              Contract preview. {isMobile && <br />} Page{" "}
              {pageNumber || (numPages ? 1 : "--")} of {numPages || "--"}
            </p>
            <Button
              shape="circle"
              disabled={pageNumber >= numPages}
              icon={<RightOutlined />}
              onClick={this.nextPage}
              aria-label="Next"
              className={styles.button}
            />
          </div>
        )}
      </div>
    );
  }

  onDocumentLoadSuccess = ({ numPages }) => {
    this.setState({
      numPages: numPages,
      pageNumber: 1,
    });
  };

  onDocumentLoadError = (error) => {
    const instance = axios.create({
      withCredentials: true,
    });

    instance
      .post(TOKEN_DOMAIN)
      .then((tokenRefreshResponse) => {
        const token = tokenRefreshResponse.data.token;

        this.setState({
          userToken: token,
        });

        return setTokenCookies(token);
      })
      .catch((error) => {
        console.log({ error });
      });
  };

  changePage = (offset) => {
    this.setState((prevState) => ({
      pageNumber: prevState.pageNumber + offset,
    }));
  };

  previousPage = () => {
    this.changePage(-1);
  };

  nextPage = () => {
    this.changePage(1);
    window.scrollTo({ top: 0, behavior: "smooth" });
  };
}

export default connect((store) => {
  return {
    contractPdf: contentPDFSelector(store),
    contractPDFUrl: urlTemplatePdfSelector(store),
    contractPDFLoading: loadingTemplatePdfSelector(store),
    userName: nameUserSelector(store),
    userEmail: emailUserSelector(store),
  };
})(PDFViewer);
