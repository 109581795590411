const ArrowDown = ({
               className = "custom-icon",
               color = "#FFFFFF",
               width = 11,
               height = 7
             }) => (
  <svg
    className={className}
    width={width}
    height={height}
    viewBox={`0 0 ${width} ${height}`}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.59299 0.300781C7.01224 0.300781 7.24533 0.785747 6.98342 1.11313L4.55713 4.14599C4.35697 4.39619 3.97643 4.39619 3.77626 4.14599L1.34998 1.11313C1.08807 0.785747 1.32116 0.300781 1.74041 0.300781L6.59299 0.300781Z"
      fill={color}
    />
  </svg>

);

export default ArrowDown;
