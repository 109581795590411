import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import { Alert, message, Spin } from "antd";
import { withRouter } from "react-router-dom";
import HelloSign from "hellosign-embedded";

import {
  getContractSignData,
  updateContractSignStatus,
} from "../store/reducers/templatesAdmin/actions";
import {
  hsDataSelector,
  messageContractSelector,
  errorContractSelector,
  updatedContractSelector,
} from "../store/reducers/templatesAdmin/selectors";
import {
  emailUserSelector,
  nameUserSelector,
} from "../store/reducers/user/selectors";

import PageWrapper from "../components/PageWrapper";
import { LOGIN_PAGE, SITE_DOMAIN } from "../constants/variables";
import { PRODUCT_PAGE, START_PAGE, THANK_YOU_PAGE } from "../constants/siteMap";

class SignPage extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      error: null,
      errorInfo: null,
    };

    this.helloSignClient = null;
  }

  componentDidCatch(error, errorInfo) {
    this.setState({ error, errorInfo });
  }

  componentWillUnmount() {
    message.destroy();
  }

  componentDidMount() {
    const { getContractSignData } = this.props;
    const locationId = this.props.match.params.TempId;

    getContractSignData(locationId);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { hsData, pageError, pageMessage, contractUpdated } = this.props;

    if (prevProps.pageError !== pageError && pageMessage) {
      message.destroy();
      pageMessage &&
        message.error({
          content: pageMessage,
          className: "error-message",
          duration: 0,
        });
    }

    if (prevProps.hsData !== hsData && hsData) {
      if (typeof hsData !== "string" && hsData.hs_url) {
        this.helloSignClient = new HelloSign({ clientId: hsData.hs_key });
        this.showContractModal(hsData.hs_url);
      }

      if (typeof hsData === "string") {
        window.location.href = hsData;
      }
    }

    if (this.helloSignClient) {
      this.helloSignClient.on("sign", () => {
        const { updateContractStatus, hsData } = this.props;

        typeof hsData !== "string" && updateContractStatus(hsData.envelope_id);
        this.helloSignClient.close();

        const { userName, userEmail } = this.props;

        if (userName && userEmail) {
          window.location.href = `${SITE_DOMAIN}/${THANK_YOU_PAGE}`;
        } else {
          window.location.href = `${LOGIN_PAGE}/?redirect_to=/bcr`;
        }
      });

      this.helloSignClient.on("close", () => {
        window.location.href = `${START_PAGE}#general`;
        this.helloSignClient.close();

        const { userName, userEmail } = this.props;

        if (userName && userEmail) {
          window.location.href = `${SITE_DOMAIN}`;
        } else {
          window.location.href = `${LOGIN_PAGE}/?redirect_to=/bcr`;
        }
      });
    }

    if (prevProps.contractUpdated !== contractUpdated && contractUpdated) {
      const { hsData } = this.props;
      const redirectURL =
        hsData && typeof hsData !== "string" && hsData.exists
          ? `${PRODUCT_PAGE}/${hsData.hs_key}/${THANK_YOU_PAGE}/0/?name=${hsData.name}&email=${hsData.email}`
          : `${SITE_DOMAIN}/thank-you-new/?user_name=${hsData.name}&user_email=${hsData.email}`;

      window.location.href = redirectURL;
    }
  }

  render() {
    const { error, errorInfo } = this.state;

    if (error) {
      return (
        <PageWrapper className="briefcase">
          <Alert type="error" message={error} description={errorInfo} />
        </PageWrapper>
      );
    }

    return (
      <PageWrapper className="sign-page">
        <Helmet>
          <title>Sign Your Contract - Creators Legal</title>
        </Helmet>
        <h1 className="hidden">Iframe page</h1>
        <div className="loader-wrapper">
          <Spin size="middle" />
        </div>
      </PageWrapper>
    );
  }

  showContractModal = (signingUrl) => {
    this.helloSignClient.open(signingUrl, {
      // allowCancel: false,
      testMode: true,
      skipDomainVerification: true,
      locale: "EN_US",
      whiteLabelingOptions: {
        page_background_color: "#F7F8F9",
        header_background_color: "#060357",
        primary_button_color: "#FE7C38",
        primary_button_color_hover: "#FF3C61",
        secondary_button_color: "#DAF3D6",
        secondary_button_text_color: "#4F4F4F",
        secondary_button_color_hover: "#48C333",
        legal_version: "terms2",
      },
    });
  };
}

export default connect(
  (store) => {
    return {
      hsData: hsDataSelector(store),
      contractUpdated: updatedContractSelector(store),
      pageError: errorContractSelector(store),
      pageMessage: messageContractSelector(store),
      userName: nameUserSelector(store),
      userEmail: emailUserSelector(store),
    };
  },
  {
    getContractSignData: getContractSignData,
    updateContractStatus: updateContractSignStatus,
  }
)(withRouter(SignPage));
