const CircleEdit = ({
  className = "custom-icon",
  color = "#7A7A7A",
  size = 15,
}) => (
  <svg
    className={className}
    fill="none"
    height={size}
    viewBox={`0 0 ${size} ${size}`}
    width={size}
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="7.5" cy="7.5" r="7" stroke="#7A7A7A" strokeOpacity="0.68" />
    <path
      d="M8.38341 5.32178L9.73958 6.69849L9.59574 6.86287L5.85601 10.5821C5.80714 10.6433 5.74436 10.692 5.6729 10.7242C5.60143 10.7564 5.52332 10.771 5.44506 10.767H4.31492C4.31492 10.3149 4.29437 9.86287 4.31492 9.43137C4.32955 9.37107 4.35766 9.31487 4.39712 9.26698L8.32177 5.36287L8.38341 5.32178Z"
      fill={color}
      fillOpacity="0.68"
    />
    <path
      d="M10.1917 6.26717L8.79439 4.89046C9.02042 4.66444 9.24645 4.41786 9.49302 4.21238C9.52195 4.17784 9.55811 4.15005 9.59894 4.13099C9.63976 4.11193 9.68427 4.10205 9.72933 4.10205C9.77439 4.10205 9.8189 4.11193 9.85972 4.13099C9.90055 4.15005 9.93669 4.17784 9.96562 4.21238C10.2738 4.50005 10.5615 4.78772 10.8492 5.09594C10.8842 5.12679 10.9122 5.16474 10.9314 5.20725C10.9506 5.24976 10.9605 5.29587 10.9605 5.34252C10.9605 5.38916 10.9506 5.43527 10.9314 5.47778C10.9122 5.5203 10.8842 5.55824 10.8492 5.58909L10.1917 6.26717Z"
      fill={color}
      fillOpacity="0.68"
    />
  </svg>
);

export default CircleEdit;
