const Bell = ({ className = "custom-icon", color = "#A5A5A5", size = 24 }) => (
  <svg
    className={className}
    height={size}
    viewBox={`0 0 ${size} ${size}`}
    width={size}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.4361 0H12.5611C12.6111 0.00873191 12.6611 0.0199586 12.7124 0.0274431C13.1798 0.0972984 13.6548 0.137216 14.1148 0.240751C16.1073 0.689821 17.796 1.67403 19.1548 3.19837C20.7223 4.95848 21.5447 7.02544 21.591 9.38056C21.6197 10.8662 21.596 12.3532 21.6035 13.8388C21.6035 13.9399 21.6385 14.0546 21.6935 14.1395C22.3172 15.085 22.9485 16.0255 23.5785 16.9673C23.7485 17.2218 23.9297 17.4688 23.9997 17.7744V18.1486C23.9935 18.1574 23.9847 18.1649 23.9822 18.1736C23.7885 18.8572 23.391 19.1566 22.676 19.1566C15.7711 19.1566 8.8674 19.1566 1.96248 19.1566C1.70248 19.1566 1.44123 19.1591 1.18124 19.1566C0.631243 19.1491 0.167498 18.7823 0.0337496 18.2509C0.0249997 18.216 0.0112499 18.1836 0 18.1499V17.7757C0.0687492 17.4813 0.238747 17.2405 0.403745 16.9948C1.04374 16.0418 1.68248 15.0862 2.31622 14.1282C2.36622 14.0521 2.39872 13.9474 2.39872 13.8563C2.40372 12.4268 2.39872 10.9985 2.40372 9.56892C2.40372 9.25707 2.41372 8.94397 2.44747 8.63586C2.69997 6.30194 3.65871 4.30234 5.35369 2.67321C6.85617 1.22621 8.6574 0.372977 10.7261 0.0860717C10.9624 0.0536389 11.1999 0.0286906 11.4361 0Z"
      fill={color}
    />
    <path
      d="M11.5903 23.9998C11.3214 23.9487 11.0451 23.9162 10.7825 23.8414C9.05672 23.3499 7.92749 22.2447 7.386 20.5394C7.3785 20.5145 7.3735 20.4895 7.3685 20.4633C7.36725 20.4533 7.36975 20.4434 7.37225 20.4209H16.6312C16.6175 20.477 16.6087 20.5307 16.5937 20.5831C16.0497 22.4255 14.4215 23.7753 12.5057 23.9724C12.4707 23.9761 12.4369 23.9911 12.4032 23.9998H11.5903V23.9998Z"
      fill={color}
    />
  </svg>
);

export default Bell;
