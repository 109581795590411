//@packages
import React, { useEffect, useState } from "react";
import { Menu } from "antd";

//@constants
import { SITE_DOMAIN } from "../../../constants/variables";

// @Utils
import { getCategories } from "../../../utils/categoryAPI";

const DropdownMenu = () => {

  const [categories, setCategories] = useState([]);

  useEffect(() => {
    try {
      const auxFunction = async () => {
        const _categories = await getCategories();
        setCategories(_categories?.data?.data?.categories || []);
      };
      auxFunction();
    } catch (error) {
      console.log(error);
    }
  }, []);

  const reditectTo = (url) => {
    window.location.href = `${SITE_DOMAIN}/product-category/${url}`
  };

  return (
    <Menu>
      {categories.map((item) => {
        if (item.is_active === 0) return null;
        return (
          <Menu.Item key={item.id} onClick={() => reditectTo(item.url)}>
            {item.name}
          </Menu.Item>
        );
      })}
    </Menu>
  );
};

export default DropdownMenu;
