//@packages
import React, { useState } from 'react';
import { Button } from "antd";
import { useHistory } from "react-router-dom";
import classNames from "classnames";

//@context
import { useMobileHeaderProvider } from "../../../context/mobileHeader";

//@icons
import StackFoldersIcon from "../../atoms/icons/StackFolders";
import ChestIcon from "../../atoms/icons/Chest";

//@constants
import { DEFAULT_TITLE_ARCHIVED, DEFAULT_TITLE_GENERAL, MIN_SWIPE_DISTANCE } from "../../../constants/variables";

//@styles
import classes from './styles.module.scss'

const MobileProjectHeaderItems = (
  {
    type,
    title,
    options,
    showOption,
    containerClassName,
    handleSelectItem,
    handleClose,
    handleOpen
  }
) => {

  const {
    setShowArchivedOptions,
    setShowGeneralOptions,
    setIsItemGeneralSelected,
    setIsItemArchivedSelected,
    setRevert,
    setToggleArchived,
    setToggleGeneral
  } = useMobileHeaderProvider();

  const [touchStart, setTouchStart] = useState(null);
  const [touchEnd, setTouchEnd] = useState(null);
  const history = useHistory();

  const onTouchStart = (e) => {
    setTouchEnd(null);
    setTouchStart(e.targetTouches[ 0 ].clientY);
  }

  const onTouchMove = (e) => {
    setTouchEnd(e.targetTouches[ 0 ].clientY);
  }

  const onTouchEnd = () => {
    if (!touchStart || !touchEnd) return;
    const distance = touchStart - touchEnd;
    const isUpSwipe = distance > MIN_SWIPE_DISTANCE;
    const isDownSwipe = distance < -MIN_SWIPE_DISTANCE;
    if (isUpSwipe || isDownSwipe) {
      if (isUpSwipe) handleClose();
      else handleOpen();
    }
  }

  const handleSelect = () => {
    if (type === DEFAULT_TITLE_GENERAL) {
      setRevert(false);
      setIsItemGeneralSelected(false);
      setIsItemArchivedSelected(false);
      setToggleArchived(false);
      setShowArchivedOptions(false);
      setShowGeneralOptions(false);
      history.push('/#general')
    }
    if (type === DEFAULT_TITLE_ARCHIVED) {
      setRevert(true);
      setToggleArchived(true);
      setToggleGeneral(false);
      setShowGeneralOptions(false);
      setShowArchivedOptions(false);
      setIsItemArchivedSelected(false);
      setIsItemGeneralSelected(false);
      history.push('/#archived')
    }
  }

  return (
    <div
      onClick={handleSelect}
      onTouchStart={onTouchStart}
      onTouchMove={onTouchMove}
      onTouchEnd={onTouchEnd}
      className={classNames(classes.itemContainer, containerClassName)}
    >
      <Button className={classes.buttonItem}>
        <div className={classes.title}>
          {title}
          <span className={classNames(!showOption ? classes.swipeBar : classes.hideSwipeBar)} />
        </div>
      </Button>
      <div className={classes.listContainer}>
        {options.map(option => {
          return (
            <ul className={classNames(showOption ? classes.listItems : classes.hideItems)} key={option.id}>
              <li
                onTouchStart={(e) => e.stopPropagation()}
                onTouchMove={(e) => e.stopPropagation()}
                onTouchEnd={(e) => e.stopPropagation()}
                onMouseDown={() => handleSelectItem(option)}
                className={classNames(
                  classes.listItem,
                  (option.label === 'General' || option.label === 'Archived') && classes.listItemMain
                )}
              >
                <div className={classes.listItemWithIcon}>
                  {option.label === 'General' ? <StackFoldersIcon color="var(--white)" />
                    : option.label === 'Archived' && <ChestIcon color="var(--white)" />}
                  {option.label}
                </div>
              </li>
            </ul>
          )
        })}
      </div>
    </div>
  );
};

export default MobileProjectHeaderItems;
