const ArrowV = ({
  className = "custom-icon",
  color = "#8AB0CC",
  onClick = Function.prototype,
  pointer = false,
}) => (
  <svg
    className={className}
    fill="none"
    height={13}
    viewBox={`0 0 ${7} ${13}`}
    width={7}
    xmlns="http://www.w3.org/2000/svg"
    style={pointer ? { cursor: "pointer" } : {}}
    onClick={onClick}
  >
    <g clipPath="url(#clip0_866_15389)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.68901 0.79201C8.2881 0.40001 7.64174 0.40001 7.24083 0.79201L0.441739 7.44001C0.122648 7.75201 0.122648 8.25601 0.441739 8.56801L7.24083 15.216C7.64174 15.608 8.2881 15.608 8.68901 15.216C9.08992 14.824 9.08992 14.192 8.68901 13.8L2.76537 8.00001L8.69719 2.20001C9.08992 1.81601 9.08992 1.17601 8.68901 0.79201Z"
        fill={color}
      />
    </g>
    <defs>
      <clipPath id="clip0_866_15389">
        <rect
          width="7"
          height="13"
          fill={color}
          transform="translate(7 13) rotate(-180)"
        />
      </clipPath>
    </defs>
  </svg>
);

export default ArrowV;
