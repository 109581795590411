//@packages
import React, { useState, useEffect } from "react";
import classnames from "classnames";
import { Link, useHistory, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { Button, Input, Layout } from "antd";

//@ icons
import { FolderAddOutlined } from "@ant-design/icons";
import StackFoldersIcon from "../../atoms/icons/StackFolders";
import ArchiveBoxIcon from "../../atoms/icons/ArchiveBox";
import UserConfigIcon from "../../atoms/icons/UserConfig";
import LoupeIcon from "../../atoms/icons/Loupe";
import ArchiveIcon from "../../atoms/icons/Archive";
import Unarchive from "../../atoms/icons/Unarchive";
import Trash from "../../atoms/icons/Trash";
import DeleteWarningModal from "../../atoms/deleteWarningModal";

// @component
import InputSearch from "../../atoms/InputSearch";
import MobileProjectHeader from "../../molecules/MobileProjectHeader";
import NotificationsPopover from "../../molecules/NotificationsPopover";
import NewContractDropdown from "../../molecules/NewContractDrodown";

//@ texts
import {
  ADMIN_SCREEN,
  GLOBAL_SEARCH,
  NEW_CATEGORY,
  NEW_TEMPLATE,
  RENAMED_PROJECT,
} from "../../../constants/staticTexts";

//@ styles
import classes from "./styles.module.scss";

// @constants
import { MOBILE_RESOLUTION } from "../../../constants/variables";
import { CONTRACT_PAGE } from "../../../constants/siteMap";

// @utils
import { useMediaQuery } from "../../utils";
import { searchBarFilter } from "../../../utils/serarchBar";

// @context
import { useContractsProvider } from "../../../context/contracts";
import { useNewCategoryProvider } from "../../../context/newCategory";
import { useProjectProvider } from "../../../context/projects";
import { useNotificationsProvider } from "../../../context/notifications";

const { Header } = Layout;

const HeaderBar = () => {
  const history = useHistory();
  const { setAddCategory, addCategory } = useNewCategoryProvider();
  const {
    projectData, // PD
    setProjectData,
    preloadedGenCont,
    preloadedProjects, // PP
    projectMap,
    archiveFolder,
    unarchiveFolder,
    globalSearch,
    setGlobalSearch,
    deleteProjectById,
    deleteContractsFromProject,
  } = useContractsProvider();

  const { newProjectName, newProject, renameProject } = useProjectProvider();

  const { hash } = useLocation();

  //Change Project name states
  const [editTitle, setEditTitle] = useState(false);
  const [project, setProject] = useState({ input: "", output: "General" });
  //Global Search states
  const [projectDataRollBack, setProjectDataRollback] = useState([]); // PDRB
  const [auxProjectData, setAuxProjectData] = useState([]); // APD
  const [search, setSearch] = useState("");
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  //notifications
  const [showNotifications, setShowNotifications] = useState(false);
  //admin user
  const [isAdmin, setIsAdmin] = useState(false);

  const isMobile = useMediaQuery(MOBILE_RESOLUTION);

  const { notificationsList, handleOpenNotifications } =
    useNotificationsProvider();

  const templatesEntities = useSelector((store) =>
    store.templates.templates.valueSeq().toArray()
  );
  const templatesInProgress = useSelector((store) =>
    store.templatesInProgress.templates.valueSeq().toArray()
  );
  const templatesSent = useSelector((store) =>
    store.templatesSent.templates.valueSeq().toArray()
  );

  useEffect(() => {
    history.location.hash.includes("admin")
      ? setIsAdmin(true)
      : setIsAdmin(false);
  }, [history.location.hash]);
  //Change project name function

  const onChangeProject = (e) => {
    setProject({ ...project, input: e.target.value });
  };

  //newCategory
  const onNewCategory = () => {
    setAddCategory(true);
  };

  const onChangeProjectName = (e) => {
    setProject({ ...project, output: e.target.value });
    setEditTitle(false);
    setGlobalSearch(false);
  };

  const toggleInput = () => {
    setEditTitle(!editTitle);
    setProject({ ...project, input: project.output });
  };

  // Change globalSearch function
  const onGlobalSearchStatus = (status) => {
    setGlobalSearch(status);
  };

  const showSearch = () => {
    setGlobalSearch(!globalSearch);
  };

  const onArchiveClick = () => {
    archiveFolder(hash);
  };

  const onUnarchiveClick = () => {
    unarchiveFolder(hash);
  };

  const onDeleteProjectClick = async () => {
    try {
      setShowDeleteModal(false);
      // Delete child contracts
      const { success } = await deleteContractsFromProject(projectData);

      if (!success) return;

      // Delete project
      const result = await deleteProjectById(hash.substring(1));

      // Updating flow
      if (result) {
        history.push("#general");
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (globalSearch && Boolean(projectDataRollBack.length)) {
      // Started a global search, the rollback was filled and now the PD is filled with all the contracts
      const fullData = [...preloadedGenCont, ...preloadedProjects];
      setProjectData(fullData);
      setAuxProjectData(fullData);
    }
  }, [projectDataRollBack]);

  useEffect(() => {
    if (globalSearch) {
      // Transition PD to PDRB
      setProjectDataRollback(projectData);
      if (!!search) {
        onChangeGlobalSearch({
          target: {
            value: search,
          },
        });
      }
    } else {
      // Transition PDRB to PD
      setProjectData(projectDataRollBack);
      // Emptied PDRB
      setProjectDataRollback([]);
    }
  }, [globalSearch]);

  const onChangeGlobalSearch = (e) => {
    setSearch(e.target.value);
    onSearchBarChange(e);
  };

  const onSearchBarChange = (ev) => {
    const val = ev.target.value;
    if (val === "") {
      setProjectData(auxProjectData);
    } else {
      const auxFiltered = searchBarFilter(auxProjectData, val);
      setProjectData(auxFiltered);
    }
  };

  const handleVisibleChange = async (visible) => {
    setShowNotifications(visible);
    if (!visible) handleOpenNotifications();
  };

  const getTitle = () => {
    let title = "Undefined";
    switch (hash.substring(1)) {
      case "general":
        title = "General";
        break;
      case "archived":
        title = "Archived";
        break;
      default:
        title = "General";
        break;
    }

    return title;
  };

  if (isAdmin) {
    return (
      <Header className={classnames("container header", classes.header)}>
        <div className={classes.headerBar}>
          <div className={classes.searchTitle}>
            <UserConfigIcon color="#6418c3" className={classes.stack} />
            <h1 onDoubleClick={toggleInput}> {ADMIN_SCREEN} </h1>
            <FolderAddOutlined className={classes.iconContainer} />
          </div>
          <div className={classes.buttonContainer}>
            <div className={classes.categoryButton}>
              <Button
                size="large"
                onClick={onNewCategory}
                disabled={addCategory}
              >
                {NEW_CATEGORY}
              </Button>
            </div>
            <div
              className={classnames(
                classes.templateButton,
                classes.contractButton
              )}
            >
              <Button size="large">
                <Link to={CONTRACT_PAGE}>{NEW_TEMPLATE}</Link>
              </Button>
            </div>
          </div>
        </div>
      </Header>
    );
  }

  return (
    <>
      {!isMobile ? (
        <Header className={classnames("container header", classes.header)}>
          <div className={classes.headerBar}>
            <div className={classes.searchTitle}>
              {!editTitle ? (
                <>
                  {!Boolean(
                    projectMap[hash?.toString().substring(1)]?.is_archived
                  ) ? (
                    <StackFoldersIcon
                      color="#6418c3"
                      className={classes.stack}
                    />
                  ) : (
                    <ArchiveBoxIcon
                      color="#6418c3"
                      className={classes.stack}
                      size={31}
                    />
                  )}
                  {!newProject && !renameProject ? (
                    <h1>
                      {projectMap[hash?.toString().substring(1)]?.title ||
                        getTitle()}
                    </h1>
                  ) : (
                    <h1
                      className={
                        !!newProjectName
                          ? classes.newProjectTitle
                          : classes.placeholderTitle
                      }
                    >
                      {!!newProjectName
                        ? newProjectName
                        : "Write your project name"}
                    </h1>
                  )}
                  <FolderAddOutlined className={classes.iconContainer} />
                  {hash !== "" &&
                    hash !== "#general" &&
                    hash !== "/" &&
                    hash !== "#archived" && (
                      <>
                        {!Boolean(
                          projectMap[hash?.toString().substring(1)]?.is_archived
                        ) ? (
                          <ArchiveIcon
                            pointer
                            size={33}
                            className={classes.archiveIcon}
                            onClick={onArchiveClick}
                          />
                        ) : (
                          <>
                            <Unarchive
                              pointer
                              size={33}
                              className={classes.archiveIcon}
                              onClick={onUnarchiveClick}
                            />
                            <Trash
                              pointer
                              size={33}
                              className={classes.archiveIcon}
                              onClick={() => setShowDeleteModal(true)}
                            />
                          </>
                        )}
                      </>
                    )}
                </>
              ) : (
                <Input
                  type="text"
                  autoFocus
                  placeholder={RENAMED_PROJECT}
                  bordered={false}
                  defaultValue={project.output}
                  onChange={onChangeProject}
                  value={project.input}
                  onPressEnter={onChangeProjectName}
                  onBlur={toggleInput}
                />
              )}
            </div>
            <div className={classes.buttonContainer}>
              {!globalSearch ? (
                <LoupeIcon pointer onClick={showSearch} />
              ) : (
                <InputSearch
                  placeholder={GLOBAL_SEARCH}
                  handleSearch={onChangeGlobalSearch}
                  value={search}
                  showSearch={showSearch}
                  className={classes.inputSearch}
                  setSearch={setSearch}
                  onPressEnter={onChangeProjectName}
                  setGlobalSearch={onGlobalSearchStatus}
                />
              )}
              <NotificationsPopover
                notificationsSize={notificationsList.length}
                visible={showNotifications}
                handleVisible={setShowNotifications}
                data={notificationsList}
                onVisibleChange={handleVisibleChange}
              />
              <NewContractDropdown />
            </div>
          </div>
        </Header>
      ) : (
        <Header className={classes.headerMobile}>
          <MobileProjectHeader title={project.output} project={project} />
        </Header>
      )}

      <DeleteWarningModal
        showModal={showDeleteModal}
        setShowModal={setShowDeleteModal}
        onHandleDelete={onDeleteProjectClick}
      />
    </>
  );
};

export default HeaderBar;
