//@packages
import axios from "axios";

//@constants
import { API } from "../constants/variables";

//@utils
import { showErrorMessage } from "./errorMessageHandler";

const URI = `${API}notice`;

export const getNotifications = async () => {
  try {
    const resp = await axios.get(`${URI}/list`);
    return resp.data.data.notices;
  } catch (e) {
    showErrorMessage(e.response.status)
    return [];
  }
}

export const readAllNotifications = async (ids) => {
  try {
    await axios.put(`${URI}/mark_as_read`, { data: { ids } })
  } catch (e) {
    showErrorMessage(e.response.status)
  }
}

export const readOneNotification = async (id) => {
  try {
    await axios.put(`${URI}/${id}/mark_as_read`)
  } catch (e) {
    showErrorMessage(e.response.status)
  }
}
