export const TOKEN_DOMAIN = process.env.REACT_APP_PRODUCTION_TOKEN_URL;

export const SITE_DOMAIN = process.env.REACT_APP_PRODUCTION_SITE_URL;

export const APP_DOMAIN = process.env.REACT_APP_PRODUCTION_APP_URL;

export const LOGIN_PAGE = process.env.REACT_APP_PRODUCTION_LOGIN_PAGE;

export const LOGOUT_PAGE = process.env.REACT_APP_PRODUCTION_LOGOUT_PAGE;

export const WP_PRICING_PAGE = process.env.REACT_APP_PRODUCTION_PRICING_PAGE;

export const API = process.env.REACT_APP_PRODUCTION_API;

export const LOCALHOST = "http://localhost:3000";

export const TOKEN_COOKIE = "access_token";

export const TOKEN_COOKIE_TIMEOUT_SEC = 500;

export const MOBILE_RESOLUTION = "(max-width: 576px)";

export const DEFAULT_TITLE_GENERAL = "General";

export const URL_GENERAL = "/#general";

export const TITLE_SEARCH = "Top Result";

export const URL_ARCHIVED = "/#archived";

export const DEFAULT_TITLE_ARCHIVED = "Archived";

export const UNKNOWN_ERROR = "Unknown error";

export const MIN_SWIPE_DISTANCE = 50;

export const FIELDS_COLORS = [
  {
    backgroundColor: "#D5F0D6",
    borderColor: "#48C333",
  },
  {
    backgroundColor: "#C9E4F5",
    borderColor: "#0C87CC",
  },
  {
    backgroundColor: "#FFE4BC",
    borderColor: "#EB9F89",
  },
  {
    backgroundColor: "#FFF5F9",
    borderColor: "#FFB2CE",
  },
  {
    backgroundColor: "#F6FFBC",
    borderColor: "#E9EB89",
  },
  {
    backgroundColor: "#F3F3F3",
    borderColor: "#D0D0D0",
  },
  {
    backgroundColor: "#E1FAEF",
    borderColor: "#89EBB0",
  },
  {
    backgroundColor: "#ECE1FA",
    borderColor: "#A289EB",
  },
];

export const PDF_STYLE = `<style>
    @font-face {
        font-family: 'Verdana';
        src: url('${APP_DOMAIN}/fonts/verdana.ttf') format('truetype');
        font-weight: normal;
        font-style: normal;
    }
    body {font-family: Verdana, Arial, sans-serif; font-weight: 500; font-size: 16px; line-height: 1.5}
    br {display: inline-block; width: 100%; height: 24px;}
    p, ol, ul, pre, h1, h2, h3, h4, h5, h6 {margin: 0; padding: 0}
    h1, h2, h3, h4, h5, h6 {font-weight: 600}
    img {vertical-align: middle}
    hr {opacity: 0; page-break-after: always}
    table {width: 100% !important; max-width: 100% !important; border-collapse: collapse; border-spacing: 0px; table-layout: fixed}
    table, th, td {text-align: left; padding: 4px; border: 1px solid #fff}
    table th {text-align: center}
    ol, ul {padding-left: 1.5em}
    ol > li, ul > li {list-style-type: none}
    ul > li::before {content: '\\2022'}
    li::before {display: inline-block;white-space: nowrap;width: 1.2em}
    li:not(.ql-direction-rtl)::before {margin-left: -1.5em;margin-right: 0.3em;text-align: right}
    li.ql-direction-rtl::before {margin-left: 0.3em;margin-right: -1.5em}
    ol li:not(.ql-direction-rtl),
    ul li:not(.ql-direction-rtl) {padding-left: 1.5em}
    ol li.ql-direction-rtl,
    ul li.ql-direction-rtl {padding-right: 1.5em}
    ol li {counter-reset: list-1 list-2 list-3 list-4 list-5 list-6 list-7 list-8 list-9; counter-increment: list-0}
    ol li:before {content: counter(list-0, decimal) '. '}
    ol li.ql-indent-1 {counter-increment: list-1}
    ol li.ql-indent-1:before {content: counter(list-1, lower-alpha) '. '}
    ol li.ql-indent-1 {counter-reset: list-2 list-3 list-4 list-5 list-6 list-7 list-8 list-9}
    ol li.ql-indent-2 {counter-increment: list-2}
    ol li.ql-indent-2:before {content: counter(list-2, lower-roman) '. '}
    ol li.ql-indent-2 {counter-reset: list-3 list-4 list-5 list-6 list-7 list-8 list-9}
    ol li.ql-indent-3 {counter-increment: list-3}
    ol li.ql-indent-3:before {content: counter(list-3, decimal) '. '}
    ol li.ql-indent-3 {counter-reset: list-4 list-5 list-6 list-7 list-8 list-9}
    ol li.ql-indent-4 {counter-increment: list-4}
    ol li.ql-indent-4:before {content: counter(list-4, lower-alpha) '. '}
    ol li.ql-indent-4 {counter-reset: list-5 list-6 list-7 list-8 list-9}
    ol li.ql-indent-5 {counter-increment: list-5}
    ol li.ql-indent-5:before {content: counter(list-5, lower-roman) '. '}
    ol li.ql-indent-5 {counter-reset: list-6 list-7 list-8 list-9}
    ol li.ql-indent-6 {counter-increment: list-6}
    ol li.ql-indent-6:before {content: counter(list-6, decimal) '. '}
    ol li.ql-indent-6 {counter-reset: list-7 list-8 list-9}
    ol li.ql-indent-7 {counter-increment: list-7}
    ol li.ql-indent-7:before {content: counter(list-7, lower-alpha) '. '}
    ol li.ql-indent-7 {counter-reset: list-8 list-9}
    ol li.ql-indent-8 {counter-increment: list-8}
    ol li.ql-indent-8:before {content: counter(list-8, lower-roman) '. '}
    ol li.ql-indent-8 {counter-reset: list-9}
    ol li.ql-indent-9 {counter-increment: list-9}
    ol li.ql-indent-9:before {content: counter(list-9, decimal) '. '}
    .ql-indent-1:not(.ql-direction-rtl) {padding-left: 3em}
    li.ql-indent-1:not(.ql-direction-rtl) {padding-left: 4.5em}
    .ql-indent-1.ql-direction-rtl.ql-align-right {padding-right: 3em}
    li.ql-indent-1.ql-direction-rtl.ql-align-right {padding-right: 4.5em}
    .ql-indent-2:not(.ql-direction-rtl) {padding-left: 6em}
    li.ql-indent-2:not(.ql-direction-rtl) {padding-left: 7.5em}
    .ql-indent-2.ql-direction-rtl.ql-align-right {padding-right: 6em}
    li.ql-indent-2.ql-direction-rtl.ql-align-right {padding-right: 7.5em}
    .ql-indent-3:not(.ql-direction-rtl) {padding-left: 9em}
    li.ql-indent-3:not(.ql-direction-rtl) {padding-left: 10.5em}
    .ql-indent-3.ql-direction-rtl.ql-align-right {padding-right: 9em}
    li.ql-indent-3.ql-direction-rtl.ql-align-right {padding-right: 10.5em}
    .ql-indent-4:not(.ql-direction-rtl) {padding-left: 12em}
    li.ql-indent-4:not(.ql-direction-rtl) {padding-left: 13.5em}
    .ql-indent-4.ql-direction-rtl.ql-align-right {padding-right: 12em}
    li.ql-indent-4.ql-direction-rtl.ql-align-right {padding-right: 13.5em}
    .ql-indent-5:not(.ql-direction-rtl) {padding-left: 15em}
    li.ql-indent-5:not(.ql-direction-rtl) {padding-left: 16.5em}
    .ql-indent-5.ql-direction-rtl.ql-align-right {padding-right: 15em}
    li.ql-indent-5.ql-direction-rtl.ql-align-right {padding-right: 16.5em}
    .ql-indent-6:not(.ql-direction-rtl) {padding-left: 18em}
    li.ql-indent-6:not(.ql-direction-rtl) {padding-left: 19.5em}
    .ql-indent-6.ql-direction-rtl.ql-align-right {padding-right: 18em}
    li.ql-indent-6.ql-direction-rtl.ql-align-right {padding-right: 19.5em}
    .ql-indent-7:not(.ql-direction-rtl) {padding-left: 21em}
    li.ql-indent-7:not(.ql-direction-rtl) {padding-left: 22.5em}
    .ql-indent-7.ql-direction-rtl.ql-align-right {padding-right: 21em}
    li.ql-indent-7.ql-direction-rtl.ql-align-right {padding-right: 22.5em}
    .ql-indent-8:not(.ql-direction-rtl) {padding-left: 24em}
    li.ql-indent-8:not(.ql-direction-rtl) {padding-left: 25.5em}
    .ql-indent-8.ql-direction-rtl.ql-align-right {padding-right: 24em}
    li.ql-indent-8.ql-direction-rtl.ql-align-right {padding-right: 25.5em}
    .ql-indent-9:not(.ql-direction-rtl) {padding-left: 27em}
    li.ql-indent-9:not(.ql-direction-rtl) {padding-left: 28.5em}
    .ql-indent-9.ql-direction-rtl.ql-align-right {padding-right: 27em}
    li.ql-indent-9.ql-direction-rtl.ql-align-right {padding-right: 28.5em}
    .ql-size-small {font-size: 0.75em}
    .ql-size-large {font-size: 1.5em}
    .ql-size-huge {font-size: 2.5em}
    .ql-align-center {text-align: center}
    .ql-align-justify {text-align: justify}
    .ql-align-right {text-align: right}
    .quill-field.quill-field-disabled { display: none; }
    .PartiesSection-column { padding-right: 10px; margin-bottom: 24px; display: inline-block; width: 48%; }
    .PartiesSection-column p { font-size: 16px; line-height: 1.5; font-weight: 500; font-family: 'Verdana'; margin-bottom: 8px; word-break: break-all; }
    .PartiesSection-column-by { margin-top: 24px; margin-bottom: 24px; }
    .PartiesSection-column-title { min-height: 24px; }
    #FullEditor-PartiesSection { margin-top: 40px; }
    #FullEditor-PartiesSection img { display: none; }
  </style>`;
