// @packages
import { Button, Col, Row } from "antd";
import { ExportOutlined, SaveOutlined } from "@ant-design/icons";
import { Link, useParams } from "react-router-dom";

// @components
import LogoPreview from "./LogoPreview";
import Parties from "../../molecules/Parties";

//constants
import { PRODUCT_PAGE, FULL_EDITOR } from "../../../constants/siteMap";
// @styles
import classes from "./styles.module.scss";

const StepTemplate = ({
  children,
  description,
  disabledButtons,
  disabledParties,
  isLastStep,
  onBack,
  onExit,
  onSaveContinue,
  onSaveLater,
  parties,
  saveMessage,
  title,
  withFooter,
  handleOpenConfirmationModal,
}) => {




  return (
    <div className={classes.content}>
      <h2 className={classes.title}>{title}</h2>
      <p className={classes.description}>{description}</p>

      <Row justify="space-between">
        {!!parties && (
          <Col md={7} xs={24}>
            <div className={classes.stikyContainer}>
              <LogoPreview />
              <Parties
                disabled={disabledParties}
                className={classes.tabs}
                data={parties}
              />
            </div>
          </Col>
        )}
        <Col
          md={!!parties ? 16 : 24}
          xs={24}
          className={classes.documentContainer}
        >
          {children}
          {withFooter && (
            <div className={classes.nav}>
              <div>
                {onBack && (
                  <Button
                    size="small"
                    onClick={onBack}
                    disabled={disabledButtons}
                  >
                    Back
                  </Button>
                )}
              </div>
              <div>
                {onSaveContinue && saveMessage && (
                  <Button
                    type="primary"
                    onClick={onSaveContinue}
                    disabled={disabledButtons}
                  >
                    {saveMessage}
                  </Button>
                )}
                {isLastStep && (
                  <Button
                    onClick={handleOpenConfirmationModal}
                    className={classes.goToFullEditor}
                  >
                    Go to Full Editor
                  </Button>
                )}
                {onBack && (
                  <Button
                    size="small"
                    onClick={onBack}
                    disabled={disabledButtons}
                    className={classes.mobileBtn}
                  >
                    Back
                  </Button>
                )}
                {onSaveLater && (
                  <Button
                    onClick={onSaveLater}
                    icon={<SaveOutlined />}
                    disabled={disabledButtons}
                  >
                    <span>Save & finish later</span>
                  </Button>
                )}
                {onExit && (
                  <Button
                    onClick={onExit}
                    icon={<ExportOutlined />}
                    disabled={disabledButtons}
                  >
                    Exit
                  </Button>
                )}
              </div>
            </div>
          )}
        </Col>
      </Row>
    </div>
  );
};

StepTemplate.defaultProps = {
  isLastStep: false,
  withFooter: true,
};

export default StepTemplate;
