import React from 'react'
import {Button, Modal} from "antd";
import './styles.module.scss'

const ThankYouSendForReviewModal = ({open, onClose, message, title}) => {

  return (
    <Modal
      className="thank-you-modal"
      title={title}
      visible={open}
      onCancel={onClose}
      footer={<Button className="button" onClick={onClose}>Go Back</Button>}
    >
      {message}
    </Modal>
  )

}

export default ThankYouSendForReviewModal
