import React, { useEffect, useState } from "react";
import axios from "axios";
import { Button, Form, Modal, Spin } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useHistory } from "react-router-dom";

import {
  postUserFields,
  updateContractContent,
} from "../../store/reducers/templatesAdmin/actions";
import { getTokenCookies, setTokenCookies } from "../../store/utils";
import PDFViewer from "../PDFViewer";
import DocumentPreviewer from "../DocumentPreviewer";
import FullEditorConfirmationModal from "../FullEditorConfirmationModal";

import {
  PRODUCT_STEP_NO_DATA,
  BEFORE_EXIT_TITLE,
  BEFORE_EXIT_TEXT,
  TERMS_TITLE,
} from "../../constants/staticTexts";
import {
  API,
  WP_PRICING_PAGE,
  SITE_DOMAIN,
  TOKEN_DOMAIN,
  LOGIN_PAGE,
  APP_DOMAIN,
} from "../../constants/variables";
import {
  PRODUCT_PAGE,
  FULL_EDITOR,
  START_PAGE,
  THANK_YOU_PAGE,
} from "../../constants/siteMap";

import StepTemplate from "../templates/StepTemplate";
import ProductForm from "./ProductForm";

const ProductStep = ({
                       step,
                       nextStep,
                       prevStep,
                       isLastStep,
                       currentStep,
                       handleStepsChange,
                       handleDataUpdate,
                       setValidationError,
                       isDataUpdate,
                       productId,
                       setIsSkippingToFullEditor,
                     }) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const history = useHistory();
  const token = getTokenCookies();
  const { ContractId, ProductId } = useParams();

  const userEmail = useSelector((store) => store.user.userEmail);
  const userName = useSelector((store) => store.user.userName);
  const contractId = useSelector((store) => store.templatesAdmin.id);
  const contractTmpId = useSelector((store) => store.templatesAdmin.tmpId);
  const contractContent = useSelector((store) => store.templatesAdmin.content);
  const contractParentContent = useSelector(
    (store) => store.templatesAdmin.parentContent
  );
  const contractParties = useSelector((store) =>
    store.templatesAdmin.parties.valueSeq().toArray()
  );
  const contractSteps = useSelector((store) =>
    store.templatesAdmin.steps.valueSeq().toArray()
  );
  const contractPostLoading = useSelector(
    (store) => store.templatesAdmin.postLoading
  );
  const contractPostLoaded = useSelector(
    (store) => store.templatesAdmin.postLoaded
  );
  const contractLoading = useSelector((store) => store.templatesAdmin.loading);
  const contractDeleteUpdated = useSelector(
    (store) => store.templatesAdmin.updated
  );
  const contractStepLoaded = useSelector(
    (store) => store.templatesAdmin.stepLoaded
  );
  const contractCanBeUsed = useSelector(
    (store) => store.templatesAdmin.canBeUsed
  );

  const [docContent, setDocContent] = useState("");
  const [previewContent, setPreviewContent] = useState("");
  const [docStepData, setDocStepData] = useState([]);
  const [isDocReplaced, setIsDocReplaced] = useState(false);
  const [isContentUpdated, setIsContentUpdated] = useState(false);
  const [isActiveStepSet, setActiveStepSet] = useState(false);
  const [stepData, setStepData] = useState(null);
  const [activeTabIndex, setActiveTabIndex] = useState("0");
  const [activeFieldId, setActiveFieldId] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isTermsModal, setIsTermsModal] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [contractFinishLater, setContractFinishLater] = useState(false);
  const [steps, setSteps] = useState(contractSteps.map((item) => item.toJS()));
  const [openFullEditorConfirmation, setOpenFullEditorConfirmation] =
    useState(false);
  const templateId = contractTmpId || contractId;
  const isEditTemplate = !!contractParentContent;
  let matcherId = "";

  const handleCloseConfirmation = () => {
    setIsSkippingToFullEditor(false);
    setOpenFullEditorConfirmation(false);
  };
  const handleOpenConfirmation = () => {
    setIsSkippingToFullEditor(true);
    handleSaveData(false);
    if (!token || token === "undefined") {
      goToFullEditor();
      return false;
    }
    setOpenFullEditorConfirmation(true);
  };
  const handleOnOkFullEditor = () => {
    goToFullEditor();
  };

  const goToFullEditor = () => {
    const fullEditorRelativeUrl = `${PRODUCT_PAGE}/${ContractId}/${FULL_EDITOR}/${ProductId}`;
    const fullEditorAbsoluteUrl = `${APP_DOMAIN}${fullEditorRelativeUrl}`;

    if (token && token !== "undefined") {
      if (contractCanBeUsed) {
        window.location.reload()
        window.location.assign(`${APP_DOMAIN}${PRODUCT_PAGE}/${ContractId}/${FULL_EDITOR}/${ProductId}`);
      } else {
        window.location.reload()
        window.location.assign(`${SITE_DOMAIN}/new-registration-middle-flow/?prod_id=${productId}&temp_id=${contractTmpId}&template_id=${contractId}&redirect_to=${fullEditorAbsoluteUrl}`);
      }
    } else {
      window.location.reload()
      window.location.assign(`${SITE_DOMAIN}/register_modal?prod_id=${ProductId}&template_id=${ContractId}&temp_id=${contractTmpId}&redirect_to=${fullEditorAbsoluteUrl}`);
    }
  };

  useEffect(() => {
    setDocContent(
      contractParentContent ? contractParentContent : contractContent
    );
    setPreviewContent(
      contractParentContent ? contractParentContent : contractContent
    );
  }, []);

  useEffect(() => {
    const initialValues = steps.map((step) => {
      const values = Object.entries(step.fields)
        .filter((field) => field[ 1 ] && field[ 1 ].is_predefined !== 3)
        .map((field) => {
          return [field[ 1 ].id, field[ 1 ].val];
        });

      return Object.fromEntries(values);
    });

    initialValues.forEach((item) => handlePostUserData(item, false));
  }, [steps]);

  useEffect(() => {
    const templateId = contractTmpId || contractId;

    if (step) {
      setStepData(step.data);

      step.data.is_parties_data !== 0 && onTabChange("0");
    }

    if (isLastStep) {
      handleReplaceContent(docStepData);
      // request in revision
      isDocReplaced &&
      dispatch(updateContractContent({ content: docContent }, templateId));
    } else {
      setDocContent(
        contractParentContent ? contractParentContent : contractContent
      );
      setIsContentUpdated(false);
      setIsDocReplaced(false);
    }

    // after Save Later
    /* isDocReplaced &&
      contractFinishLater &&
      dispatch(updateContractContent({ content: docContent }, templateId)); */
  }, [step, docContent, isDocReplaced]);

  useEffect(() => {
    if (docStepData.length > 0) {
      let activeStep, activeIndex;
      const fieldsWithoutData = docStepData.filter((item) => !item.value);

      if (fieldsWithoutData.length > 0) {
        activeStep = steps.filter((x) =>
          x.fields.hasOwnProperty(fieldsWithoutData[ 0 ].id)
        );
        activeIndex = steps.findIndex((step) => step.id === activeStep[ 0 ].id);
      } else {
        activeIndex = steps.length;
      }

      handleReplacePreviewContent(docStepData);
      !isActiveStepSet && handleSetStartStep(activeIndex);
    }
  }, [docStepData]);

  useEffect(() => {
    contractStepLoaded && setIsContentUpdated(true);
  }, [contractStepLoaded]);

  useEffect(() => {
    if (isDataUpdate) {
      handleSaveData();
    }
  }, [isDataUpdate]);

  useEffect(() => {
    if (!contractLoading && contractDeleteUpdated) {
      window.location.href = `${SITE_DOMAIN}/catalog`;
    }
  }, [contractLoading, contractDeleteUpdated]);

  useEffect(() => {
    if (!contractPostLoading && contractPostLoaded) {
      setSubmitLoading(false);
    }

    if (!contractPostLoading && contractPostLoaded && contractFinishLater) {
      hideModal();
      toggleFinishLaterRedirect();
    }
  }, [contractPostLoading, contractPostLoaded]);

  const handlePostUserData = (values, isSubmit, updateStep = true) => {
    const currentTabStep = Number.parseInt(activeTabIndex);
    const nextTabStep = currentTabStep + 1;
    const partiesFieldsArr = contractParties
      .flat()
      .filter((o) => o.is_predefined !== 3);
    let formData = [];
    let stepFormData = {
      fields: [],
    };

    Object.entries(values).map(([key, value]) => {
      const date = value && value._d ? new Date(value._d) : null;

      if (!key.includes("deliverable") && !value)
        return formData.push({ id: key });

      // set deliverable items data
      if (key.includes("deliverable")) {
        const dataItem = formData.filter((item) =>
          key.includes(item.id.toString())
        );

        if (dataItem.length === 0) return null;

        if (value) {
          return (dataItem[ 0 ].value = JSON.stringify(
            value.map((item) => {
              const itemDate = item.date._d ? new Date(item.date._d) : null;

              return {
                date: itemDate
                  ? itemDate.getMonth() +
                  1 +
                  "/" +
                  itemDate.getDate() +
                  "/" +
                  itemDate.getFullYear()
                  : "",
                description: item.description,
              };
            })
          ));
        } else {
          return (dataItem[ 0 ].value = JSON.stringify([]));
        }
      }

      setDocStepData((oldArray) => {
        const foundIndex = oldArray.findIndex((x) => x.id === key);

        if (foundIndex >= 0 && oldArray[ foundIndex ]) {
          oldArray[ foundIndex ].value = date
            ? date.getMonth() +
            1 +
            "/" +
            date.getDate() +
            "/" +
            date.getFullYear()
            : value
              ? value
              : "";
        }

        return foundIndex >= 0
          ? [...oldArray]
          : [
            ...oldArray,
            {
              id: key,
              value: date
                ? date.getMonth() +
                1 +
                "/" +
                date.getDate() +
                "/" +
                date.getFullYear()
                : value
                  ? value
                  : "",
            },
          ];
      });

      return stepFormData.fields.push({
        id: key,
        value: date
          ? date.getMonth() +
          1 +
          "/" +
          date.getDate() +
          "/" +
          date.getFullYear()
          : value
            ? value
            : "",
      });
    });

    formData.length !== 0 &&
    formData.reduce((obj, item) => {
      if (!item.value) item.value = "";

      setDocStepData((oldArray) => {
        const foundIndex = oldArray.findIndex((x) => x.id === item.id);

        if (foundIndex >= 0 && oldArray[ foundIndex ]) {
          oldArray[ foundIndex ].value = item.value;
        }

        return foundIndex >= 0 ? [...oldArray] : [...oldArray, item];
      });

      return stepFormData.fields.push(item);
    }, {});

    // Parties data saving
    if (
      stepData &&
      stepData.is_parties_data !== 0 &&
      contractParties.length > 0
    ) {
      let partyData = [];

      if (contractParties.length >= nextTabStep) {
        contractParties.forEach((currentValue, index) => {
          isSubmit && onTabChange(index.toString());

          if (index === contractParties.length - nextTabStep) {
            Object.entries(form.getFieldsValue()).map(([key, value]) => {
              setDocStepData((oldArray) => {
                const foundIndex = oldArray.findIndex((x) => x.id === key);

                if (foundIndex >= 0 && oldArray[ foundIndex ]) {
                  oldArray[ foundIndex ].value = value;
                }

                return foundIndex >= 0
                  ? [...oldArray]
                  : [
                    ...oldArray,
                    {
                      id: key,
                      value: value,
                    },
                  ];
              });

              !value && setValidationError("not-all-fields");

              return partyData.push({
                id: key,
                value: value,
              });
            });
          }
        });

        stepFormData.fields = partyData;
      }
    }

    if (stepFormData.fields.length > 0 && isSubmit) {
      if (
        stepData &&
        stepData.is_parties_data !== 0 &&
        partiesFieldsArr.length !== stepFormData.fields.length
      ) {
        if (updateStep) {
          onTabChange(nextTabStep.toString());
        }
        setValidationError("not-all-fields");
      }

      dispatch(postUserFields(stepFormData, templateId, stepData.id));
    }

    setValidationError(null);
  };

  const handleReplaceContent = (data) => {
    if (!data) return;

    data.forEach((field) => {
      if (field.value.trim() !== "") {
        const step = steps.filter((x) => x.fields.hasOwnProperty(field.id)); // .filter(item => item.fields[key])
        const replacedField = step[ 0 ].fields[ field.id ];
        const idRgx = new RegExp(
          `<img[^>]*id="${replacedField.id}"[^>]*>`,
          "g"
        );
        const originalIdRgx = new RegExp(
          `<img[^>]*id="${replacedField.original_id}"[^>]*>`,
          "g"
        );
        let replaceWith =
          replacedField.field_type === 2
            ? `<span style="white-space: pre-wrap">${field.value}</span>`
            : `<span>${field.value}</span>`;

        if (replacedField.field_type === 3) {
          const deliverableData = JSON.parse(field.value);

          if (deliverableData.length !== 0) {
            replaceWith = `<table class="quill-table">
            <colgroup>
              <col span="1" style="width: 80%; text-align: left;">
              <col span="1" style="width: 20%; text-align: center;">
            </colgroup>
            <thead>
              <tr>
                <th>Deliverable</th>
                <th>Date</th>
              </tr>
            </thead>
            <tbody>
              ${deliverableData
              .map(
                (item) => `<tr>
                    <td>${item.description}</td>
                    <td style="text-align: center">${item.date}</td>
                  </tr>`
              )
              .join("")}
            </tbody>
          </table>`;
          } else {
            replaceWith = "";
          }
        }

        if (isEditTemplate) {
          setDocContent((prevContent) => {
            if (prevContent)
              return prevContent.replaceAll(originalIdRgx, replaceWith);
          });
        } else {
          setDocContent((prevContent) => {
            if (prevContent) return prevContent.replaceAll(idRgx, replaceWith);
          });
        }
      }
    });

    return setIsDocReplaced(true);
  };

  const handleSaveData = (updateStep = true) => {
    form
      .validateFields()
      .then((values) => {
        const token = getTokenCookies();

        setSubmitLoading(true);

        if (userEmail && !token) {
          const instance = axios.create({
            withCredentials: true,
          });

          instance
            .post(TOKEN_DOMAIN)
            .then((tokenRefreshResponse) => {
              const token = tokenRefreshResponse.data.token;

              setTokenCookies(token);
              return handlePostUserData(values, true, updateStep);
            })
            .catch((error) => {
              console.log({ error });
            });
        } else {
          handlePostUserData(values, true, updateStep);
        }

        handleDataUpdate(false);
      })
      .catch((info) => {
        const errorFieldId =
          info.errorFields && info.errorFields.length > 0
            ? info.errorFields[ 0 ].name[ 0 ]
            : null;
        const flattenedParties = [].concat.apply([], contractParties);

        if (
          errorFieldId &&
          stepData.is_parties_data !== 0 &&
          contractParties.length > 0
        ) {
          const errorObj = flattenedParties.filter(
            (field) => field.id === errorFieldId
          );
          const errorTab = errorObj[ 0 ].party_number;

          onTabChange(errorTab.toString());
          setValidationError("not-all-fields");
        }

        console.log("Validate Failed:", info);
        setSubmitLoading(false);
        handleDataUpdate(false);
        hideModal();
      });
  };

  const handleValuesChange = (changedValues) => {
    handlePostUserData(changedValues, false);
  };

  const handleReplacePreviewContent = (values) => {
    if (!values) return;
    let content = isEditTemplate
      ? contractParentContent.replace(
        /<style\b[^<]*(?:(?!<\/style)<[^<]*)*<\/style>/g,
        ""
      )
      : contractContent.replace(
        /<style\b[^<]*(?:(?!<\/style)<[^<]*)*<\/style>/g,
        ""
      );

    values.forEach((field) => {
      const step = steps.filter((x) => x.fields.hasOwnProperty(field.id)); // .filter(item => item.fields[key])
      const replacedField = step[ 0 ].fields[ field.id ];
      const idRgx = new RegExp(`<img[^>]*id="${replacedField.id}"[^>]*>`, "g");
      const originalIdRgx = new RegExp(
        `<img[^>]*id="${replacedField.original_id}"[^>]*>`,
        "g"
      );
      let replaceWith =
        replacedField.field_type === 2
          ? `<b style="white-space: pre-wrap">${field.value}</b>`
          : `<b>${field.value}</b>`;

      if (replacedField.field_type === 3 && field.value) {
        const deliverableData = JSON.parse(field.value);

        if (deliverableData.length !== 0) {
          replaceWith = `<table class="quill-table">
            <colgroup>
              <col span="1" style="width: 80%; text-align: left;">
              <col span="1" style="width: 20%; text-align: center;">
            </colgroup>
            <thead>
              <tr>
                <th>Deliverable</th>
                <th>Date</th>
              </tr>
            </thead>
            <tbody>
              ${deliverableData
            .map(
              (item) => `<tr>
                    <td>${item.description}</td>
                    <td style="text-align: center">${item.date}</td>
                  </tr>`
            )
            .join("")}
            </tbody>
          </table>`;
        } else {
          replaceWith = "";
        }
      }

      if (field.value) {
        if (isEditTemplate) {
          content = content.replaceAll(originalIdRgx, replaceWith);
        } else {
          content = content.replaceAll(idRgx, replaceWith);
        }
      }
    });

    setPreviewContent(content);
  };

  const handleSetStartStep = (index) => {
    if (!isActiveStepSet) {
      handleStepsChange(index);
      // nextStep()
    }
    setActiveStepSet(true);
  };

  const showModal = (isTerms) => {
    setIsModalVisible(true);

    isTerms && setIsTermsModal(true);
  };

  const hideModal = () => {
    setIsModalVisible(false);
    setIsTermsModal(false);
  };

  const handleTemplateRemove = () => {
    // dispatch( deleteContract(templateId) )
    history.push({
      pathname: START_PAGE,
      search: "",
    });
    window.scrollTo(0, 0);
  };

  const handleFinishLater = () => {
    if (isLastStep) return toggleFinishLaterRedirect();

    handleSaveData();
    handleReplaceContent(docStepData);
    setContractFinishLater(true);
  };

  const toggleFinishLaterRedirect = () => {
    return token && token !== "undefined"
      ? history.push({
        pathname: START_PAGE,
        search: "",
      })
      : (window.location.href = `${LOGIN_PAGE}/?redirect_to=/bcr/&send=later&attach=${contractTmpId}`);
  };

  const onTabChange = (key) => {
    setActiveTabIndex(key);
  };

  const focusHandler = (id) => {
    setActiveFieldId(id);
  };

  const handleShowModal = () => showModal(true);

  const handleSaveContinue = () => {
    if (currentStep < contractSteps.length) return nextStep();
    if (isLastStep) return handleShowModal();
  };

  const handleCloseModal = () => showModal(false);

  return (
    <>
      <div>
        {!stepData ? (
          <p>{PRODUCT_STEP_NO_DATA}</p>
        ) : (
          <Spin
            size="small"
            spinning={contractLoading || submitLoading}
            style={{ maxHeight: `100%` }}
          >
            {!contractPostLoading && (
              <StepTemplate
                description={stepData.description}
                disabledButtons={submitLoading || contractPostLoading}
                isLastStep={isLastStep}
                onBack={prevStep}
                onExit={handleCloseModal}
                onSaveContinue={handleSaveContinue}
                onSaveLater={handleFinishLater}
                saveMessage={!isLastStep ? "Save & continue" : "Save & finish"}
                title={stepData.title}
                contractCanBeUsed={contractCanBeUsed}
                contractTmpId={contractTmpId}
                handleOpenConfirmationModal={handleOpenConfirmation}
              >
                {isLastStep ? (
                  <PDFViewer
                    wide
                    key="completedDocument"
                    PDFUrl={`${API}template/${templateId}/pdf_create`}
                  />
                ) : (
                  <ProductForm
                    isPartiesData={
                      stepData.is_parties_data !== 0 &&
                      contractParties.length > 0
                    }
                    form={form}
                    onChangeValues={handleValuesChange}
                    onChangeTab={(activeKey) => onTabChange(activeKey)}
                    activeTab={activeTabIndex}
                    contractParties={contractParties}
                    matcherId={matcherId}
                    contractPostLoading={contractPostLoading}
                    onFocus={focusHandler}
                    stepData={stepData}
                    handleOpenConfirmationModal={handleOpenConfirmation}
                  />
                )}
              </StepTemplate>
            )}
          </Spin>
        )}
      </div>
      {!isLastStep && !isContentUpdated && (
        <DocumentPreviewer
          html={previewContent}
          originalHtml={
            isEditTemplate ? contractParentContent : contractContent
          }
          activeFieldId={activeFieldId}
        />
      )}
      <Modal
        visible={isModalVisible}
        title={isTermsModal ? TERMS_TITLE : BEFORE_EXIT_TITLE}
        centered={true}
        keyboard={true}
        maskClosable={true}
        className={isTermsModal ? "ant-modal-terms" : ""}
        onCancel={hideModal.bind(this, { triggerCancel: true })}
        footer={
          isTermsModal
            ? [
              <Button
                key="cancel"
                disabled={submitLoading}
                onClick={hideModal}
              >
                Cancel
              </Button>,
              <Button
                key="agree"
                type="primary"
                loading={submitLoading}
                onClick={() => setSubmitLoading(true)}
                href={
                  contractCanBeUsed
                    ? `${PRODUCT_PAGE}/${ContractId}/${THANK_YOU_PAGE}/${ProductId}`
                    : `${SITE_DOMAIN}/new-registration-middle-flow/?prod_id=${productId}&temp_id=${contractTmpId}&template_id=${contractId}&redirect_to=${APP_DOMAIN}/product/${ContractId}/${THANK_YOU_PAGE}/${ProductId}`
                }
              >
                Agree
              </Button>,
            ]
            : [
              <Button
                key="submit"
                loading={contractPostLoading}
                disabled={contractPostLoading}
                onClick={handleFinishLater}
              >
                <span>Save</span>
              </Button>,
              <Button
                key="delete"
                type="primary"
                loading={contractLoading}
                disabled={contractLoading || contractPostLoading}
                onClick={handleTemplateRemove}
              >
                Don’t Save
              </Button>,
              <Button
                key="cancel"
                type="primary"
                disabled={contractLoading || contractPostLoading}
                onClick={hideModal}
              >
                Cancel
              </Button>,
            ]
        }
      >
        <Spin size="small" spinning={contractPostLoading || contractLoading}>
          <p>
            {isTermsModal ? (
              <>
                <span>
                  Creators Legal is not a law firm and cannot provide legal
                  advice. We are a self-help online and software service and do
                  not participate in any legal representation.
                </span>
                <span>
                  We are not a substitute for a lawyer, law firm or professional
                  legal advice. We are not a ‘lawyer referral service’. Use of
                  CreatorsLegal.com is subject to our{" "}
                  <a
                    href={`${SITE_DOMAIN}/terms-and-conditions/`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    Terms and Conditions
                  </a>{" "}
                  and{" "}
                  <a
                    href={`${SITE_DOMAIN}/privacy-policy/`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    Privacy Policy
                  </a>
                  .
                </span>
              </>
            ) : (
              BEFORE_EXIT_TEXT
            )}
          </p>
        </Spin>
      </Modal>
      <FullEditorConfirmationModal
        open={openFullEditorConfirmation}
        onOk={handleOnOkFullEditor}
        onClose={handleCloseConfirmation}
      />
    </>
  );
};

export default ProductStep;
