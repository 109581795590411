const Paper = ({ className = "custom-icon", color = "black", size = 18 }) => (
  <svg
    className={className}
    fill="none"
    height={size}
    viewBox={`0 0 ${size} ${size}`}
    width={size}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.3388 18H5.64914C5.52545 17.864 5.41001 17.712 5.27807 17.584C3.6124 15.968 1.95497 14.36 0.281055 12.752C0.188018 12.6673 0.114833 12.5641 0.0664622 12.4496C0.0180913 12.335 -0.00433785 12.2117 0.000692247 12.088C0.0116868 9.44266 0.0144334 6.78933 0.00893614 4.128C0.00893614 3.968 0.00893715 3.808 0.025429 3.648C0.0529012 3.28751 0.201779 2.94579 0.449124 2.67546C0.696469 2.40514 1.02856 2.2212 1.39425 2.152C1.63338 2.104 1.88076 2.088 2.16112 2.056C2.09515 1.024 2.56517 0.328 3.57942 0H13.4827C14.3816 0.264 14.9918 0.792 14.9918 1.752C15.0027 5.928 15.0027 10.1013 14.9918 14.272C14.9918 15.168 14.2414 15.88 13.3178 15.928L12.8726 15.952C12.8396 16.24 12.8396 16.512 12.7654 16.76C12.5675 17.464 12.0067 17.8 11.3388 18ZM7.1334 11.104V16.64H10.7204C11.3388 16.64 11.413 16.568 11.413 15.976V4.144C11.413 3.544 11.3223 3.456 10.6956 3.456H2.11989C1.46022 3.456 1.41074 3.504 1.41074 4.128V10.648C1.41074 10.792 1.41899 10.928 1.42723 11.104H7.1334ZM3.59591 2.072H11.4543C12.3696 2.072 12.8066 2.504 12.8313 3.4V14.504C13.4168 14.584 13.557 14.472 13.557 13.96C13.5625 9.976 13.5625 5.992 13.557 2.008C13.557 1.48 13.4828 1.408 12.9385 1.408H4.15663C3.6124 1.408 3.55468 1.472 3.59591 2.072ZM2.13638 12.488L5.68212 15.928V12.488H2.13638Z"
      fill={color}
    />
  </svg>
);

export default Paper;
