import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";

import App from "./App";
import configureStore from "./store";
import history from "./store/history";
import * as serviceWorker from "./serviceWorker";
import { ContractsProvider } from "./context/contracts";
import { ProjectProvider } from "./context/projects";
import { NotificationsProvider } from "./context/notifications";

const store = configureStore();

ReactDOM.render(
  <ContractsProvider>
    <NotificationsProvider>
      <ProjectProvider>
        <Provider store={store}>
          <App history={history} />
        </Provider>
      </ProjectProvider>
    </NotificationsProvider>
  </ContractsProvider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
