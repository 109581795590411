const Resend = ({ className = "custom-icon", color = "#000000", size = 17 }) => (
  <svg
    className={className}
    width={size}
    height={size}
    viewBox="0 0 17 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2.03128 9.42246L3.12426 8.90724C2.91803 9.82655 2.73244 10.665 2.53653 11.5641L0 10.4529L1.06204 9.89727C0.573269 8.44152 0.540949 6.87567 0.969244 5.40174C2.36124 0.552637 8.34167 -1.56885 12.5692 1.29011C12.7033 1.38103 12.8889 1.49215 12.9301 1.62348C12.9713 1.75481 12.9713 2.06798 12.8682 2.16901C12.7816 2.22689 12.6842 2.2674 12.5816 2.28821C12.4789 2.30902 12.3731 2.30971 12.2702 2.29023C12.064 2.23972 11.8887 2.06798 11.7031 1.94676C9.45526 0.582945 6.48567 0.734478 4.34097 2.32054C2.19626 3.9066 1.22702 6.63422 1.91786 9.10929C1.94832 9.2161 1.98621 9.32073 2.03128 9.42246Z"
      fill={color}
    />
    <path
      d="M14.5593 5.53295L13.456 6.05827C13.6519 5.14906 13.8478 4.31057 14.0437 3.40137L16.5699 4.51262L15.6522 4.99753C15.745 5.99766 15.9409 6.93717 15.8894 7.85648C15.7244 10.7558 14.2705 12.9177 11.6309 14.1906C8.99127 15.4635 6.43413 15.2009 3.9801 13.6451C3.68107 13.4633 3.46454 13.2309 3.65014 12.8672C3.83574 12.5035 4.15538 12.5338 4.57814 12.8167C6.19698 13.8673 7.96017 14.2007 9.8471 13.7562C13.4044 12.9177 15.6007 9.34152 14.683 5.90674C14.6498 5.77972 14.6085 5.65488 14.5593 5.53295Z"
      fill={color}
    />
    <path
      d="M8.32112 4.63428H11.6722C12.2599 4.64438 12.4455 4.80602 12.4455 5.38185C12.4559 6.78607 12.4559 8.18018 12.4455 9.5743C12.4455 10.1501 12.2703 10.3219 11.6722 10.3219H4.92877C4.32042 10.3219 4.15544 10.1501 4.15544 9.5743C4.14513 8.17008 4.14513 6.77596 4.15544 5.38185C4.15544 4.80602 4.32042 4.64438 4.92877 4.63428H8.32112ZM8.31081 5.03837H5.06282C4.89784 5.04847 4.74317 5.14949 4.5782 5.2C4.68131 5.36164 4.75348 5.57379 4.89784 5.66471C5.92895 6.33146 6.98068 6.97801 8.03241 7.61445C8.13552 7.67507 8.34174 7.72558 8.43454 7.67507C9.55844 6.99821 10.6824 6.31126 11.7856 5.6041C11.8991 5.53338 11.93 5.33134 12.0022 5.1899C11.8578 5.13939 11.7032 5.03837 11.5588 5.03837H8.31081ZM11.5072 9.88747L9.38316 8.45294C9.08414 8.24079 8.82636 8.06906 8.49641 8.39233C8.42423 8.46304 8.18707 8.47315 8.1252 8.41253C7.754 8.05895 7.47561 8.261 7.15597 8.49335C6.83632 8.7257 6.27952 9.07929 5.84646 9.37225L5.10406 9.88747H11.5072ZM4.5782 9.71573L7.32094 7.87711L4.5782 5.98798V9.71573ZM9.26974 7.87711L11.9815 9.69552V5.99809L9.26974 7.87711Z"
      fill={color}
    />
  </svg>

);

export default Resend;
