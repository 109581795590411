const Send = ({ className = "custom-icon", color = "#5F5F5F", size = 21 }) => (
  <svg
    className={className}
    fill="none"
    height={size}
    viewBox={`0 0 ${size} ${size}`}
    width={size}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0.991077 18C0.98021 17.759 0.958471 17.6107 0.958471 17.4531C0.958471 15.275 0.969341 13.0968 0.947607 10.9279C0.947607 10.5201 1.07801 10.3347 1.55614 10.2884C3.33829 10.1401 5.10957 9.97322 6.89171 9.80639L12.2599 9.28733L14.4984 9.07415V8.93512C13.966 8.86097 13.4335 8.77755 12.8902 8.7312L3.5339 7.87848L1.49094 7.68383C1.18668 7.64676 0.93674 7.58187 0.947607 7.23893C0.958474 4.90319 0.947604 2.56746 0.958471 0.240989C0.968331 0.158654 0.990232 0.0776944 1.02367 0L22.1052 8.99073L0.991077 18Z"
      fill={color}
    />
  </svg>
);

export default Send;
