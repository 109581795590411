// @packages
import React, { useState, useEffect } from "react";
import classnames from "classnames";
import { Button, Dropdown, Menu } from "antd";
import { TiArrowSortedUp } from "react-icons/ti";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useHistory } from "react-router-dom";
// @components
import ArrowRight from "../atoms/icons/ArrowRight";
import Download from "../atoms/icons/Download";
import Edit from "../atoms/icons/Edit";
import FullEditorConfirmationModal from "../FullEditorConfirmationModal";
import FullEditorService from "../../services/fullContractEditor";
import OutlineUndo from "../atoms/icons/OutlineUndo";
import Pen from "../atoms/icons/Pen";
import Send from "../atoms/icons/Send";
import SendForReviewModal from "../molecules/SendForReviewModal";
import ThankYouSendForReviewModal from "../molecules/ThankYouSendForReviewModal";
import { PRODUCT_PAGE, FULL_EDITOR } from "../../constants/siteMap";
import { sendForReview } from "../../store/reducers/templatesAdmin/actions";
import { useMediaQuery } from "../utils";

// @constants
import { MOBILE_RESOLUTION } from "../../constants/variables";

// @styles
import styles from "./style.module.scss";

const CongratulationButtons = ({
                                 disabledLink,
                                 linkTo,
                                 id,
                                 onDownload,
                                 onSendContract,
                               }) => {
  const dispatch = useDispatch();
  const isLoading = useSelector((store) => store.templatesAdmin.loading);
  const [open, setOpen] = useState(false);
  const [showMobileMenu, setShowMobileMenu] = useState(false);
  const [openFullEditorConfirmation, setOpenFullEditorConfirmation] =
    useState(false);
  const [openThankYou, setOpenThankYou] = useState(false);
  const [thankYouOptions, setThankYouOptions] = useState({
    message: "",
    title: "",
  });
  const { ContractId, ProductId } = useParams();
  const history = useHistory();
  const [fullEditorEntered, setFullEditorEntered] = useState(false);
  const isMobile = useMediaQuery(MOBILE_RESOLUTION);

  const handleOpenThankYouModal = () => setOpenThankYou(true);

  const handleOpenConfirmation = () => {
    if (fullEditorEntered) {
      history.push(`${PRODUCT_PAGE}/${ContractId}/${FULL_EDITOR}/${ProductId}`);
    } else {
      setOpenFullEditorConfirmation(true);
    }
  };

  const handleCloseConfirmation = () => setOpenFullEditorConfirmation(false);

  const handleOpenSendForReview = () => setOpen(true);

  const handleCloseThankYouModal = () => {
    setOpenThankYou(false);
    window.location.reload()
  }

  const handleCloseModal = () => setOpen(false);

  const handleOnOkFullEditor = () => {
    history.push(`${PRODUCT_PAGE}/${ContractId}/${FULL_EDITOR}/${ProductId}`);
  };

  const handleBack = () => {
    history.push(`${PRODUCT_PAGE}/${ContractId}/${ProductId}?edit`);
  };

  const handleSendForReview = (emails) => {
    if (Boolean(emails) && Array.isArray(emails) && emails.length > 0) {
      const data = {
        template_id: id,
        email_list: emails,
      };
      dispatch(sendForReview({ data }));
      handleCloseModal();
      setThankYouOptions({
        message: "Your contract has been sent successfully!",
        title: "Thank You!",
      });
      handleOpenThankYouModal();
    }
  };

  const handleOpenMenuMobile = () => {
    if (isMobile) {
      setShowMobileMenu(!showMobileMenu);
    }
  };

  const menu = (
    <Menu className="CongratulationButtons-Dropdown-options">
      {!fullEditorEntered && (
        <Menu.Item
          key="1"
          icon={<Pen className={styles.iconButton} />}
          onClick={handleBack}
        >
          Edit with Formbuilder
        </Menu.Item>
      )}
      <Menu.Item
        key="2"
        icon={<Edit className={styles.iconButton} />}
        onClick={handleOpenConfirmation}
      >
        Open Full Editor
      </Menu.Item>
    </Menu>
  );

  useEffect(() => {
    FullEditorService.getFullEditorFlag(ContractId).then((res) => {
      setFullEditorEntered(parseInt(res.data?.data?.full_editor_flag) === 1);
    });
  }, []);

  return (
    <>
      <Button.Group className={styles.group}>
        <Dropdown overlay={menu}>
          <Button
            icon={<OutlineUndo className={styles.iconButton} />}
            className={classnames(styles.button, styles.edit)}
            onClick={handleOpenMenuMobile}
            onVisibleChange={handleOpenMenuMobile}
          >
            Edit Contract
            <TiArrowSortedUp className={styles.dropdownIcon} />
          </Button>
        </Dropdown>
        <Button
          onClick={onDownload}
          icon={<Download color="#5F5F5F" className={styles.iconButton} />}
          className={classnames(styles.button, styles.download)}
        >
          Download PDF
        </Button>
        <Button
          onClick={handleOpenSendForReview}
          icon={<ArrowRight color="#5F5F5F" className={styles.iconButton} />}
          className={classnames(styles.button, styles.review)}
        >
          Send for Review
        </Button>
        <Button
          onClick={onSendContract}
          icon={<Send className={styles.iconButton} />}
          className={classnames(styles.button, styles.signature)}
        >
          Send For Signature
        </Button>
      </Button.Group>

      <button
        onClick={() => window.location.href = '/#general'}
        className={styles.link}
        disabled={disabledLink}
      >
        Go to Briefcase
      </button>

      {isMobile && showMobileMenu && menu}

      <SendForReviewModal
        isLoading={isLoading}
        open={open}
        onClose={handleCloseModal}
        onSave={handleSendForReview}
      />

      <ThankYouSendForReviewModal
        open={openThankYou}
        onClose={handleCloseThankYouModal}
        {...thankYouOptions}
      />

      <FullEditorConfirmationModal
        open={openFullEditorConfirmation}
        onOk={handleOnOkFullEditor}
        onClose={handleCloseConfirmation}
      />
    </>
  );
};

export default CongratulationButtons;
