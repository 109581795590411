// @packages
import classnames from "classnames";
import { Form, Input, Tabs } from "antd";
import { useEffect, useState } from "react";
// @components
import { useFullEditorProvider } from "../../../context/fullEditor";
// @styles
import classes from "./styles.module.scss";

const { TabPane } = Tabs;

const PartyForm = ({ data, disabled, onChange, partyLabelWithMissingFields, setPartyLabelWithMissingFields }) => {
  const { setForm } = useFullEditorProvider();
  const [form] = Form.useForm();


  const handleChange = ({ target }, item) => {
    const { value } = target;
    onChange({ ...item, val: value });
    setPartyLabelWithMissingFields(false);
  };

  useEffect(() => {
    if (setForm) {
      setForm(form);
    }
  }, [form]);

  return (
    <Form form={form} layout="vertical">
      {data.map((item, i) => item.is_predefined !== 3 && (
        <Form.Item
          initialValue={item.val}
          key={`party-item-${item.id}`}
          label={item.title}
          name={item.title}
          required
          rules={[
            {
              required: true,
              message: `${item.title} is required!`,
            },
            item.rules.type !== "text" ? item.rules : {},
          ]}
          data-asd={JSON.stringify(item.rules)}
        >
          <Input
            className={classnames(item.title === "Address" && classes.address)}
            disabled={disabled}
            onChange={(e) => handleChange(e, item)}
            value={item.val}
          />
        </Form.Item>
      ))}

      { partyLabelWithMissingFields && (
        <p className={classnames(classes.missinFieldsWarning)}>The party {partyLabelWithMissingFields} is missing information. You must fill all fields.</p>
      )}
      
    </Form>
  );
};

const Parties = ({ className, data, disabled, onChangeParties, partyLabelWithMissingFields, setPartyLabelWithMissingFields }) => {
  const [parties, setParties] = useState([]);

  useEffect(() => {
    if (!!data) {
      const formatParties = [...data].map((values) =>
        values.filter(
          (item) =>
            (item.is_predefined > 0 && item.is_predefined < 4) ||
            item.title.toLowerCase() === "address"
        )
      );
      const partiesValue = formatParties.map((value) =>
        value.map((item) => {
          const type = item.title === "Email" ? "email" : "text";
          return { ...item, rules: { type } };
        })
      );
      setParties(partiesValue);
    }
  }, [data]);

  const handleChange = (value) => {
    const newValue = [...data].map((values) =>
      values.map((item) => {
        if (item.id === value.id) return value;
        return item;
      })
    );
    onChangeParties(newValue);
  };

  return (
    <>
      <h3 className={classes.title}>Recipients Information</h3>
      {parties?.length > 0 && (
        <Tabs
          destroyInactiveTabPane={false}
          className={classnames(
            classes.tabs,
            className,
            disabled && classes.disabled
          )}
        >

          {parties.map((element, i) => {
            const partyName = element.reduce((accValue, currentValue) => {
              return currentValue.is_predefined === 3 ? accValue + currentValue.title : accValue + '';
            },'');
            return (
            <TabPane key={`party-tab-${i}`} tab={partyName}>
              <PartyForm
                data={element}
                disabled={disabled}
                onChange={handleChange}
                partyLabelWithMissingFields={partyLabelWithMissingFields}
                setPartyLabelWithMissingFields={setPartyLabelWithMissingFields}
              />
            </TabPane>
          ) } )}
        </Tabs>
      )}
    </>
  );
};

export default Parties;
