const Move = ({ className = "custom-icon", color = "#000000", size = 15 }) => (
  <svg
    className={className}
    width={size}
    height={size}
    viewBox="0 0 15 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_53_8638)">
      <path
        d="M0.614813 15C0.471382 14.9244 0.298479 14.8753 0.193034 14.7658C0.0947934 14.6631 0.0207856 14.4975 0.0188208 14.359C0.0037573 13.2886 0.00834186 12.2182 0.00834186 11.1485C0.00899679 8.39567 0.0207856 5.6435 -0.000172312 2.89132C-0.00344699 2.44554 0.104617 2.12127 0.453698 1.81652C1.04969 1.29707 1.59984 0.728498 2.17029 0.181337C2.29865 0.0579272 2.44208 0 2.63595 0C5.50719 0.00629644 8.37777 0.00251857 11.249 0.00566679C11.733 0.00566679 12.0028 0.28271 12.0048 0.727238C12.0127 2.46128 12.0061 4.19532 12.0114 5.92935C12.0114 6.06095 11.9721 6.14343 11.8574 6.21143C11.6387 6.34177 11.4272 6.48281 11.1933 6.63204V0.79524H2.88679V2.70117H0.804089C0.768068 6.54074 0.820463 10.3709 0.828977 14.2098H11.1947V10.731C11.3427 10.7405 11.4743 10.7631 11.6027 10.7524C11.7337 10.7411 11.862 10.6958 12.0074 10.6618C12.0074 10.7619 12.0074 10.8721 12.0074 10.9822C12.0074 12.1062 12.012 13.2294 12.0048 14.3534C12.0028 14.6682 11.7854 14.9093 11.435 14.9729C11.4134 14.9767 11.3944 14.9906 11.3741 14.9994H0.614813V15Z"
        fill={color}
      />
      <path
        d="M15 7.00794C14.9529 7.18424 14.8193 7.30387 14.683 7.41091C14.5311 7.53054 14.3575 7.62436 14.1709 7.74399C13.8264 7.25035 13.4819 6.75671 13.1328 6.25552C13.3437 6.11951 13.5461 5.9684 13.7674 5.85128C13.9967 5.73039 14.2121 5.75558 14.4093 5.97406C14.6176 6.20451 14.7715 6.4803 14.9444 6.73908C14.9738 6.78316 14.9817 6.84045 15 6.89208V7.00857V7.00794Z"
        fill={color}
      />
      <path
        d="M7.87886 3.92581C8.26658 3.90692 8.64972 3.866 9.03155 3.8767C9.33282 3.88552 9.63736 3.93778 9.82664 4.22301C9.92357 4.36845 9.89082 4.51705 9.87969 4.66816C9.87707 4.7009 9.86594 4.73491 9.84956 4.76387C9.74084 4.96284 9.67208 5.13536 9.84629 5.36266C9.96483 5.51755 9.92357 5.75493 9.83188 5.94445C9.76049 6.09242 9.72054 6.22968 9.76835 6.39654C9.83908 6.64462 9.73953 6.86877 9.59545 7.07025C9.52733 7.16596 9.49852 7.24404 9.52275 7.36934C9.6092 7.81323 9.27846 8.12113 8.80298 8.08272C8.13298 8.0292 7.46494 7.94735 6.79625 7.87998C6.30702 7.83086 5.81712 7.7849 5.33837 7.73894C5.24144 7.9379 5.18773 7.95239 4.98601 7.94105C4.72993 7.9272 4.47254 7.93727 4.21581 7.93664C4.16079 7.93664 4.10578 7.93664 4.04028 7.93664V4.50823C4.40377 4.50823 4.76464 4.50194 5.12486 4.51453C5.1838 4.51642 5.28008 4.59701 5.28925 4.65242C5.3102 4.77583 5.3567 4.75568 5.45953 4.74813C5.82695 4.72105 5.99854 4.49123 6.0791 4.19593C6.15049 3.93463 6.16882 3.6601 6.20485 3.38999C6.24873 3.05753 6.25135 2.7169 6.33452 2.39389C6.42556 2.04003 6.75499 1.9204 7.08377 2.11307C7.5658 2.39578 7.73412 2.85038 7.80682 3.3541C7.83564 3.55181 7.85725 3.75077 7.87886 3.92392V3.92581ZM4.86812 7.61553V7.06648C4.75154 7.06648 4.64217 7.06207 4.53411 7.06962C4.50594 7.07151 4.45879 7.10866 4.45748 7.1307C4.45093 7.29126 4.4542 7.45245 4.4542 7.61553H4.86812ZM4.45355 4.86461C4.45355 5.02265 4.44962 5.17188 4.45682 5.32047C4.45813 5.35133 4.50136 5.4017 4.52952 5.40485C4.61859 5.41492 4.71094 5.41366 4.79935 5.4017C4.82817 5.39792 4.87074 5.34755 4.87271 5.31733C4.88057 5.16936 4.87598 5.02013 4.87598 4.86461H4.45355Z"
        fill={color}
      />
      <path
        d="M14.1243 8.46682C13.7307 8.72183 13.3404 8.97369 12.9507 9.22743C12.5636 9.48055 12.1811 9.73996 11.7895 9.98615C11.6873 10.0504 11.5747 10.1612 11.4286 10.0416C11.3284 9.95971 11.3055 9.85141 11.3991 9.762C11.4686 9.69526 11.5557 9.64426 11.6369 9.59011C11.9421 9.38736 12.2473 9.18525 12.5538 8.98502C12.8138 8.81501 13.0725 8.64249 13.3371 8.48004C13.4452 8.41393 13.5008 8.34215 13.4367 8.19104C13.3803 8.19859 13.2978 8.18915 13.2389 8.22063C13.028 8.33586 12.8217 8.45864 12.6213 8.5896C12.3842 8.74449 12.1582 8.91576 11.9205 9.06939C11.6055 9.27277 11.28 9.4604 10.9656 9.66377C10.7246 9.81993 10.498 9.99686 10.2563 10.153C9.93471 10.3608 9.60201 10.5535 9.27978 10.7612C9.06627 10.8985 8.86651 11.0559 8.65497 11.1957C8.188 11.5036 7.71775 11.8077 7.24686 12.1099C7.15647 12.1679 7.0805 12.1779 7.00256 12.0615C6.72487 11.6446 6.43146 11.2373 6.14525 10.8255C6.12888 10.8022 6.12168 10.7738 6.10596 10.7386C6.38823 10.5629 6.66855 10.391 6.94493 10.2141C7.12569 10.0982 7.2986 9.9723 7.47805 9.85456C7.72103 9.69463 7.96597 9.53848 8.20961 9.37981C8.38382 9.2671 8.55738 9.15439 8.73094 9.04106C8.87568 8.94661 9.01518 8.84335 9.16516 8.75772C9.4815 8.57701 9.76836 8.35537 10.0768 8.16333C10.4069 7.95807 10.7298 7.74273 11.0553 7.53054C11.3769 7.3215 11.6971 7.11057 12.0181 6.90153C12.2263 6.76552 12.4385 6.63519 12.6442 6.49541C12.7247 6.44063 12.7739 6.46393 12.8177 6.53004C13.0607 6.89775 13.2998 7.26798 13.546 7.6338C13.7307 7.90833 13.9233 8.17845 14.1237 8.46682H14.1243Z"
        fill={color}
      />
      <path
        d="M3.6015 4.50942V7.9372C3.49409 7.9372 3.39061 7.9372 3.28778 7.9372C3.01598 7.9372 2.74353 7.92398 2.47304 7.94161C2.24971 7.95672 2.09907 7.79994 2.10235 7.58838C2.10693 7.25466 2.10366 6.92095 2.10366 6.58661C2.10366 6.0256 2.10366 5.46396 2.10366 4.90295C2.10366 4.61394 2.2091 4.50879 2.50513 4.50879C2.866 4.50879 3.22687 4.50879 3.60281 4.50879L3.6015 4.50942Z"
        fill={color}
      />
      <path
        d="M7.24414 9.19793C6.95269 9.38746 6.70316 9.55305 6.44905 9.71235C6.41106 9.73628 6.35343 9.73754 6.30496 9.73754C5.02194 9.73943 3.73827 9.73943 2.45525 9.73943C2.26924 9.73943 2.13564 9.62924 2.1245 9.46742C2.11337 9.3119 2.23191 9.18849 2.3963 9.18849C2.99491 9.18786 3.59418 9.18849 4.19279 9.18723C5.1287 9.18597 6.0646 9.18471 7.00116 9.18408C7.06796 9.18408 7.13476 9.19101 7.24479 9.19667L7.24414 9.19793Z"
        fill={color}
      />
      <path
        d="M5.76532 11.0054C5.95722 11.2793 6.13995 11.5387 6.32071 11.7994C6.45039 11.9864 6.57417 12.1778 6.70909 12.3616C6.75755 12.4271 6.751 12.4536 6.67503 12.4699C6.1203 12.587 5.56557 12.7023 5.01084 12.82C4.90932 12.8414 4.81108 12.876 4.70891 12.8912C4.66175 12.8981 4.59626 12.8874 4.56417 12.8584C4.54387 12.8402 4.55566 12.7659 4.57792 12.7312C4.68795 12.5562 4.80388 12.3843 4.92045 12.213C5.07436 11.9876 5.23024 11.7628 5.38677 11.5387C5.50793 11.3655 5.63106 11.1943 5.76467 11.006L5.76532 11.0054Z"
        fill={color}
      />
      <path
        d="M5.59575 10.2731C5.3829 10.4589 5.19951 10.6188 5.01613 10.7787L5.04691 10.8033C4.98338 10.7976 4.92051 10.7863 4.85698 10.7863C4.07171 10.785 3.2871 10.7856 2.50183 10.7863C2.4252 10.7863 2.33941 10.8108 2.27457 10.785C2.14227 10.7315 2.07154 10.5741 2.13245 10.4438C2.18026 10.3418 2.26933 10.263 2.41538 10.2637C3.41154 10.2681 4.4077 10.2656 5.40385 10.2656C5.45297 10.2656 5.50144 10.2693 5.59444 10.2738L5.59575 10.2731Z"
        fill={color}
      />
      <path
        d="M4.69198 11.3394C4.55641 11.5339 4.44835 11.6964 4.3298 11.8525C4.31015 11.879 4.2499 11.8872 4.20864 11.8872C3.62051 11.8891 3.03172 11.891 2.44359 11.8872C2.19995 11.8859 2.06569 11.7228 2.12922 11.5239C2.16917 11.3986 2.27723 11.3444 2.38726 11.3432C3.14044 11.335 3.89427 11.3388 4.69198 11.3388V11.3394Z"
        fill={color}
      />
      <path
        d="M3.81902 12.963C3.34027 12.963 2.85299 12.963 2.36638 12.9624C2.35262 12.9624 2.33952 12.9491 2.32512 12.946C2.19282 12.9158 2.08934 12.8106 2.10571 12.6891C2.12078 12.5795 2.16073 12.47 2.31202 12.4568C2.40043 12.4492 2.48754 12.4158 2.5753 12.4152C3.02721 12.4108 3.47977 12.4133 3.92316 12.4133C3.8891 12.594 3.85701 12.7659 3.81968 12.963H3.81902Z"
        fill={color}
      />
      <path
        d="M7.28557 12.9645C7.33927 12.8959 7.36219 12.8462 7.40149 12.8191C7.57898 12.6988 7.75974 12.5817 7.94378 12.4709C7.99028 12.4426 8.05446 12.4312 8.11079 12.43C8.35049 12.4237 8.59217 12.4105 8.83056 12.4268C8.97858 12.4369 9.05193 12.566 9.04342 12.7246C9.03687 12.8411 8.93208 12.9557 8.80436 12.9664C8.69565 12.9752 8.58496 12.9652 8.47559 12.9652C8.099 12.9652 7.72241 12.9652 7.28491 12.9652L7.28557 12.9645Z"
        fill={color}
      />
      <path
        d="M8.91821 11.8876C8.97519 11.8202 8.99811 11.7774 9.03479 11.7535C9.2221 11.6307 9.41662 11.5174 9.60065 11.3895C9.66418 11.3461 9.72378 11.3323 9.76504 11.3833C9.81875 11.4494 9.89472 11.5451 9.879 11.6099C9.81482 11.8769 9.78207 11.8889 9.4808 11.8889C9.39042 11.8889 9.30004 11.8882 9.20966 11.8876C9.12976 11.8876 9.0492 11.8876 8.91887 11.8876H8.91821Z"
        fill={color}
      />
    </g>
    <defs>
      <clipPath id="clip0_53_8638">
        <rect
          width={size}
          height={size}
          fill="white"
        />
      </clipPath>
    </defs>
  </svg>

);

export default Move;
