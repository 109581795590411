import classnames from "classnames";
import { SearchOutlined } from "@ant-design/icons";
import { Input } from "antd";
import { useRef } from "react";
// @utils
import useOutsideClick from "../../../utils/useOutsideClick";
//@ styles
import classes from "./styles.module.scss";

const InputSearch = ({
  byProject = false,
  className = "",
  handleSearch,
  onPressEnter,
  onBlur,
  placeholder,
  setGlobalSearch,
  setSearch,
  showSearch,
  value,
  searchClassName
}) => {
  const ref = useRef();

  useOutsideClick(ref, () => {
    if (showSearch) setGlobalSearch(false);
  });

  const clearSearch = () => {
    setSearch("");
  };

  return (
    <div
      className={classnames(classes.globalSearchContainer, className)}
      ref={ref}
      id="globalSearchContainer"
    >
      <Input
        className={classnames(
          searchClassName,
          classes.globalSearch,
          byProject && classes.projectSearch
        )}
        prefix={<SearchOutlined rotate={90} />}
        type="text"
        autoFocus
        placeholder={placeholder}
        onBlur={onBlur}
        onChange={handleSearch}
        value={value}
        onPressEnter={onPressEnter}
      />
    </div>
  );
};

export default InputSearch;
