import moment from "moment";

export function addViewport({ vieportWidth, clear = false }) {
  const vieports = document.querySelectorAll("meta[name=viewport]");
  if (clear) {
    vieports.forEach((vieport) => {
      vieport.remove();
    });
  }
  const metaTag = document.createElement("meta");
  metaTag.name = "viewport";
  metaTag.content = `width=${vieportWidth}, initial-scale=1`;
  document.getElementsByTagName("head")[0].appendChild(metaTag);
}

export function parseDate(record) {
  const dateString = record;
  const D = new Date(dateString);
  const dateGMTString = D.toLocaleString("en-US", {
    timeZone: "America/Los_Angeles",
  });
  const date = dateGMTString.replace(/:\d\d([ ap]|$)/, " ").toLowerCase();

  return record ? date.replace(/,/g, " at") : null;
}
