import classes from "./styles.scss";

const Loupe = ({
  className = "custom-icon",
  color = "#A5A5A5",
  size = 32,
  onClick = Function.prototype,
  pointer = false,
}) => (
  <svg
    className={className}
    fill="none"
    height={size}
    onClick={onClick}
    viewBox={`0 0 ${size} ${size}`}
    width={size}
    xmlns="http://www.w3.org/2000/svg"
    style={pointer ? { cursor: "pointer" } : {}}
    id="globalSearchContainerIcon"
  >
    <path
      d="M18.0051 27.9999C10.2759 27.9999 4.01015 21.7319 4.01015 13.9999C4.01015 6.26791 10.2759 0 18.0051 0C25.7343 0 32 6.26798 32 14C32 21.732 25.7342 27.9999 18.0051 27.9999ZM18.0051 4.00002C12.4842 4.00002 8.00872 8.47714 8.00872 14C8.00872 19.5229 12.4842 24 18.0051 24C23.5259 24 28.0014 19.5229 28.0014 14C28.0014 8.47714 23.5259 4.00002 18.0051 4.00002Z"
      fill={color}
    />
    <path
      d="M2.01087 32C1.47944 32.0031 0.968676 31.7943 0.591394 31.4199C-0.192595 30.6421 -0.197764 29.3758 0.579817 28.5916C0.583676 28.5877 0.587535 28.5838 0.591394 28.58L8.10865 21.06C8.9202 20.2757 10.2136 20.2981 10.9976 21.11C11.7816 21.9218 11.7592 23.2157 10.9477 24L3.43035 31.4199C3.05307 31.7943 2.54231 32.0031 2.01087 32Z"
      fill={color}
    />
  </svg>
);

export default Loupe;
