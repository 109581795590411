import Cookies from "universal-cookie";
import React, { useEffect, useRef, useState } from "react";
import axios from "axios";
import createAuthRefreshInterceptor from "axios-auth-refresh";
import { Helmet } from "react-helmet";
import { message, Spin } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useParams, withRouter } from "react-router-dom";
// @components
import { getUrlParams } from "../components/utils";
import {
  sendContract,
  getAdminTemplate,
} from "../store/reducers/templatesAdmin/actions";
import PageWrapper from "../components/PageWrapper";
import {
  THANK_YOU_COMPLETE_TEXT,
  THANK_YOU_WITH_SEND_TEXT,
} from "../constants/staticTexts";
import { PRODUCT_PAGE, START_PAGE } from "../constants/siteMap";
import { API, TOKEN_COOKIE, TOKEN_DOMAIN } from "../constants/variables";
import { getTokenCookies, setTokenCookies } from "../store/utils";
import CongratulationButtons from "../components/CongratulationButtons";
import { addViewport } from "../utils";
import classes from "../styled/pages.scss";

const ThankYouPage = (props) => {
  const isInitialMount = useRef(true);
  const isFinal =
    new URLSearchParams(window.location.search).get("final") === "1";
  const { ContractId } = useParams();
  const dispatch = useDispatch();
  const [userName, setUserName] = useState(null);
  const [userEmail, setUserEmail] = useState(null);
  const [downloadLoading, setDownloadLoading] = useState(false);
  const userLoading = useSelector((store) => store.user.loading);
  const userLoaded = useSelector((store) => store.user.loaded);
  const contractTitle = useSelector((store) => store.templatesAdmin.title);
  const contractLoading = useSelector(
    (store) => store.templatesAdmin.postLoading
  );
  const contractPostLoading = useSelector(
    (store) => store.templatesAdmin.postLoading
  );
  const error = useSelector((store) => store.templatesAdmin.error);
  const contractMessage = useSelector((store) => store.templatesAdmin.message);

  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
      const urlParams = getUrlParams(window.location.href);

      if (Object.entries(urlParams).length !== 0) {
        Object.entries(urlParams).map(([key, value], index) => {
          key === "name" && setUserName(value);
          key === "email" && setUserEmail(value);
        });
      }

      if (ContractId) {
        dispatch(getAdminTemplate(ContractId));
      }
    }
  });

  useEffect(() => {
    if (contractMessage) {
      message.destroy();

      contractMessage === "Contract has been sent"
        ? props.history.push({
            pathname: START_PAGE,
            search: "&send=done",
          })
        : message.info(contractMessage, 0);

      if (error && typeof error === "object") {
        if (error.hasOwnProperty("party_id")) {
          props.history.push({
            pathname: `${PRODUCT_PAGE}/${ContractId}/${error.wp_product_id}`,
            search: "?edit",
          });
        }
      }
    }
  }, [contractMessage]);

  useEffect(() => {
    addViewport({ vieportWidth: "device-width", clear: true });
  }, []);

  const handleSendContract = () => {
    if (!contractLoading) {
      dispatch(sendContract(ContractId));
    }
  };

  const downloadClickHandler = () => {
    if (!ContractId || !contractTitle) return;

    const cookies = new Cookies();
    const token = cookies.get(TOKEN_COOKIE);
    const docTitle = `${contractTitle}.pdf`;
    let config = {
      "Content-Type": "application/json",
      Accept: "application/pdf",
      "wp-token": `${token}`,
    };
    const instance = axios.create({
      withCredentials: true,
    });

    setDownloadLoading(true);

    // Use interceptor to inject the token to requests
    axios.interceptors.request.use((request) => {
      request.headers["wp-token"] = getTokenCookies();

      return request;
    });

    // Function that will be called to refresh authorization
    const refreshAuthLogic = (failedRequest) =>
      instance.post(TOKEN_DOMAIN).then((tokenRefreshResponse) => {
        const token = tokenRefreshResponse.data.token;

        setTokenCookies(token);
        // failedRequest.response.config.headers['wp-token'] = token

        return Promise.resolve();
      });

    createAuthRefreshInterceptor(axios, refreshAuthLogic);

    axios
      .get(`${API}template/${ContractId}/download_filled_template_pdf`, {
        responseType: "blob",
        headers: config,
      })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", docTitle);
        document.body.appendChild(link);
        link.click();

        setDownloadLoading(false);
      })
      .catch((error) => {
        console.log(error);

        setDownloadLoading(false);
      });
  };

  if (userLoading && !userLoaded) {
    return (
      <div className="loader-wrapper">
        <Spin size="middle" />
      </div>
    );
  }

  return (
    <PageWrapper isFullEditor className="thankYouPage">
      <div className="wrapper">
        <div className="wrapperContent">
          <Helmet>
            <title>
              {userName ? `Thank you, ${userName}!` : "Congratulations!"} -
              Creators Legal
            </title>
          </Helmet>
          <h1 className='thankYouTitle'>{userName ? `Thank you, ${userName}!` : "Congratulations!"}</h1>
          <br />
          <h3 className='thankYouDescription'>
            {" "}
            {isFinal ? THANK_YOU_WITH_SEND_TEXT : THANK_YOU_COMPLETE_TEXT}{" "}
          </h3>
        </div>
      </div>
      {!userName && (
        <>
          <CongratulationButtons
            id={ContractId}
            disabledLink={
              downloadLoading || contractPostLoading ? "disabled" : ""
            }
            linkTo={{ pathname: START_PAGE, search: "#general" }}
            onDownload={downloadClickHandler}
            onSendContract={handleSendContract}
          />
        </>
      )}
    </PageWrapper>
  );
};

export default withRouter(ThankYouPage);
