// @packages
import React, { useEffect, useState } from "react";
import classnames from "classnames";
import { Button, Input, Layout, Menu } from "antd";
import { useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { Draggable, Droppable } from "react-beautiful-dnd";
// @constants
import {
  ADMIN_SCREEN,
  NEW_PROJECT,
  SIDER_TITLE,
} from "../../../constants/staticTexts";
import { MOBILE_RESOLUTION, URL_ARCHIVED } from "../../../constants/variables";

// @styles
import classes from "./styles.module.scss";

// @ icons
import ChestIcon from "../../atoms/icons/Chest";
import CircleEditIcon from "../../atoms/icons/CircleEdit";
import DiagonalRocket from "../../atoms/icons/DiagonalRocket";
import HamburgerMenuIcon from "../../atoms/icons/HamburgerMenu";
import RocketIcon from "../../atoms/icons/Rocket";
import { CaretRightOutlined } from "@ant-design/icons";
import ArrowVIcon from "../../atoms/icons/ArrowV";

//@components
import MobileHeader from "../../molecules/MobileHeader";
import UserConfig from "../../atoms/icons/UserConfig";
import MenuGeneralItem from "../../molecules/MenuGeneralItem";

//@context
import { useContractsProvider } from "../../../context/contracts";
import { useProjectProvider } from "../../../context/projects";

// @utils
import { usePrevious } from "../../../utils/hooks";
import { useMediaQuery } from "../../utils";
import { renameFolder } from "../../../utils/contract";
import { filterByParam } from "../../../utils/table";

const { Sider } = Layout;

const SideBar = () => {
  const history = useHistory();
  const { hash } = useLocation();
  const foldersEntities = useSelector((store) =>
    store.templates.folders.valueSeq().toArray()
  );
  const [showInput, setShowInput] = useState({ show: false, item: null });
  const [projectName, setProjectName] = useState("");
  const previousFoldersEntities = usePrevious(foldersEntities);
  let isMobile = useMediaQuery(MOBILE_RESOLUTION);

  const isUserAdmin = useSelector((store) => store.user.isAdmin);
  const {
    projectMap,
    foldersSorted,
    setFoldersSorted,
    projectMapInfo,
    globalSearch,
    projectHover,
    setProjectMap,
    folders,
    setFolders,
    reloadFolders,
    setSearchValue,
    handleCreateFolder,
  } = useContractsProvider();

  const {
    newProject,
    setNewProject,
    setFoldersMapped,
    newProjectName,
    setNewProjecName,
    renameProject,
    setRenameProject,
    isSidebarCollapsed,
    setIsSidebarCollapsed,
  } = useProjectProvider();
  const [isColapsed, setIsColapsed] = useState(false);

  /**
   * DragAndDrop Functions
   */

  const handleAdminScreen = () => {
    history.push("/#admin-screen");
  };

  const handleArchived = () => {
    history.push(URL_ARCHIVED);
    handleColapsed();
  };

  const handleFolder = (folderId, template) => {
    history.push(`/#${folderId}`, {});
    setSearchValue("");
  };

  const handleColapsed = () => setIsColapsed(!isColapsed);

  const getUpDatedFolder = (arrFolders) => {
    if (arrFolders.length === 1) {
      return arrFolders[0];
    } else {
      let max = arrFolders[0];
      for (let i = 1; i < arrFolders.length; i++) {
        if (arrFolders[i] > max) {
          max = arrFolders[i];
        }
      }
      return max;
    }
  };

  useEffect(() => {
    try {
      let auxProjectMapInfo = { ...projectMapInfo };
      let auxFolder = [];
      folders.forEach(({ title, id }) => {
        if (!!projectMapInfo[title]) {
          auxFolder.push({
            title,
            id,
            is_archived: projectMapInfo[title]?.ids[0]?.is_archived,
            other: 1,
          });
          delete auxProjectMapInfo[title];
        }
      });

      Object.keys(auxProjectMapInfo).forEach((key) => {
        auxFolder.push({
          title: key,
          id: auxProjectMapInfo[key]?.ids[0]?.id,
          is_archived: auxProjectMapInfo[key]?.ids[0]?.is_archived,
          templates: auxProjectMapInfo[key]?.templates,
          status: auxProjectMapInfo[key]?.ids[0].status,
          updated_at: getUpDatedFolder(
            auxProjectMapInfo[key]?.ids?.map((item) => item.updated_at)
          ),
        });
      });

      auxFolder.sort(filterByParam("updated_at", -1));

      if (!renameProject) setFoldersSorted(auxFolder);
    } catch (error) {
      console.log(error);
    }
  }, [folders, projectMapInfo, projectMap]);

  useEffect(() => {
    if (
      previousFoldersEntities &&
      previousFoldersEntities.length !== foldersEntities.length &&
      foldersEntities.length
    ) {
      const foldersParsed = JSON.parse(JSON.stringify(foldersEntities));
      setFolders(foldersParsed);
    }
  }, [foldersEntities]);

  const handleRenameProject = async (e, project) => {
    e.preventDefault();

    if (!!projectMapInfo[newProjectName]) {
      setShowInput({ ...showInput, show: false });
      setNewProject(false);
      setRenameProject(false);
      setProjectName("");
      setNewProjecName("");
      return;
    }
    setShowInput({ ...showInput, show: true, item: project.id });
    setProjectName(project?.title);
    setNewProjecName(project?.title);
    setRenameProject(true);

    if (showInput.show) {
      setProjectMap((prevState) => ({
        ...prevState,
        [project.id]: { title: newProjectName, is_archived: 0 },
      }));
      const newProjects = foldersSorted.map((folder) => {
        if (folder.id === project.id) folder.title = projectName;
        return folder;
      });
      const folderName = projectMap[project.id].title;
      const ids = projectMapInfo[folderName].ids;
      const resp = await Promise.all(
        ids.map(async ({ id }) => await renameFolder(id, projectName))
      );
      if (resp[0].success) reloadFolders();
      setShowInput({ ...showInput, show: false });
      setFoldersSorted(newProjects);
      setNewProject(false);
      setRenameProject(false);
      setProjectName("");
      setNewProjecName("");
    }
  };

  const handleChangeProjectName = ({ target }) => {
    setProjectName(target.value);
    setNewProjecName(target.value);
  };

  const toggleSidebarCollapse = () => {
    setIsSidebarCollapsed((prevState) => !prevState);
  };

  const validateItemDesign = (hash, me) => {
    const name = hash.substring(1);
    if (
      (name === "general" && me === "general") ||
      (hash === "" && me === "general")
    ) {
      return true;
    } else if (name === "archived" && me === "archived") {
      return true;
    } else {
      return false;
    }
  };

  const handleCreateProject = async (e) => {
    try {
      e.preventDefault();
      if (!!projectMapInfo[projectName]) {
        setNewProject(false);
        setProjectName("");
        setNewProjecName("");
        return;
      }

      const folderId = await handleCreateFolder(projectName, setFoldersMapped);
      setNewProject(false);
      setProjectName("");
      setNewProjecName("");
      reloadFolders();
      history.push(`/#${folderId}`);
    } catch (e) {
      console.log(e.message);
    }
  };

  const onHandleNewProject = () => {
    setNewProject(true);
  };

  return !isMobile ? (
    <Sider
      className={classnames(
        "container sider",
        !isSidebarCollapsed
          ? classes.siderBarContainer
          : classes.siderBarContainerCollapsed
      )}
    >
      <div className={classes.siderBar}>
        <div className={classes.siderTitle}>
          <span className={classes.icon}>
            <DiagonalRocket size={28} />
          </span>
          {!isSidebarCollapsed && <h2>{SIDER_TITLE}</h2>}
          <ArrowVIcon
            className={classnames(
              classes.arrow,
              !isSidebarCollapsed ? classes.arrowDown : classes.arrowRight
            )}
            onClick={toggleSidebarCollapse}
            pointer
            color="var(--white)"
          />
        </div>
        {!isSidebarCollapsed ? (
          <div className="siderContainer">
            <div className={classes.siderButton}>
              <Button
                icon={<RocketIcon color="white" />}
                size="large"
                onClick={onHandleNewProject}
                disabled={newProject}
              >
                {NEW_PROJECT}
              </Button>
            </div>
            <Menu
              className={classes.menu}
              defaultOpenKeys={["1"]}
              defaultSelectedKeys={["1"]}
              mode="inline"
            >
              {isUserAdmin && (
                <Menu.Item
                  key="adminTab"
                  role="admin-title"
                  onClick={handleAdminScreen}
                  className={classnames(
                    hash !== "#admin-screen" && classes.adminItem
                  )}
                >
                  <UserConfig className={classes.iconSpace} />
                  {ADMIN_SCREEN}
                </Menu.Item>
              )}

              <MenuGeneralItem validateItemDesign={validateItemDesign} />

              {newProject && (
                <form onSubmit={(e) => handleCreateProject(e)}>
                  <div className={classes.newProjecItem}>
                    <Input
                      autoFocus
                      className={classes.inputNewProject}
                      onChange={handleChangeProjectName}
                      placeholder="Write your project name"
                      onBlur={() => setNewProject(false)}
                      value={newProjectName}
                      onChangeCapture={(e) => setNewProjecName(e.target.value)}
                    />
                  </div>
                </form>
              )}

              <Droppable droppableId="projectDroppable" type="projectList">
                {(provided, snapshot) => (
                  <div
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    className={classes.contractContainer}
                  >
                    {Boolean(foldersSorted.length) &&
                      foldersSorted.map((project, index) => {
                        // Avoiding Archived Contracts
                        if (project?.is_archived === 1) {
                          return null;
                        }
                        const projectId = project?.id?.toString();

                        // Listing contract names
                        return (
                          <div
                            key={projectId}
                            onClick={() => handleFolder(project?.id)}
                            className={classes.contractContainerItem}
                          >
                            <Droppable
                              droppableId={projectId}
                              key={index}
                              type="contractList"
                            >
                              {(provided, snapshot) => {
                                const localClass =
                                  projectMap[hash.substring(1)]?.title ===
                                  project?.title
                                    ? "project-item-selected"
                                    : "project-item";
                                return (
                                  <div
                                    ref={provided.innerRef}
                                    {...provided.droppableProps}
                                    key={projectId}
                                    role="project"
                                    className={classnames(
                                      projectHover.folder_title ===
                                        project?.title && globalSearch === true
                                        ? "project-item-selected"
                                        : localClass,
                                      snapshot.isDraggingOver &&
                                        classes.itemDrop
                                    )}
                                  >
                                    <Draggable
                                      key={projectId}
                                      draggableId={projectId}
                                      index={index}
                                      type="contractList"
                                    >
                                      {(provided, snapshot) => {
                                        return (
                                          <div
                                            ref={provided.innerRef}
                                            {...provided.draggableProps}
                                            className="container-items"
                                          >
                                            <div className="container-icons">
                                              <div
                                                {...provided.dragHandleProps}
                                                className="dnd-icon"
                                              >
                                                <HamburgerMenuIcon />
                                              </div>
                                              <div
                                                onClick={(e) =>
                                                  handleRenameProject(
                                                    e,
                                                    project
                                                  )
                                                }
                                              >
                                                <CircleEditIcon className="edit-icon" />
                                              </div>
                                            </div>
                                            <div>
                                              {showInput.show &&
                                              showInput.item === project.id ? (
                                                <form
                                                  onSubmit={(e) =>
                                                    handleRenameProject(
                                                      e,
                                                      project
                                                    )
                                                  }
                                                >
                                                  <Input
                                                    autoFocus
                                                    className={
                                                      classes.inputProject
                                                    }
                                                    onChange={
                                                      handleChangeProjectName
                                                    }
                                                    placeholder="Write your project name"
                                                    value={projectName}
                                                    onFocus={(e) =>
                                                      e.target.select()
                                                    }
                                                    onBlur={() => {
                                                      setShowInput({
                                                        ...showInput,
                                                        show: false,
                                                      });
                                                      setRenameProject(false);
                                                    }}
                                                  />
                                                </form>
                                              ) : (
                                                <p className="project-title">
                                                  {project?.title}
                                                </p>
                                              )}
                                            </div>
                                          </div>
                                        );
                                      }}
                                    </Draggable>
                                    {provided.placeholder}
                                  </div>
                                );
                              }}
                            </Droppable>
                          </div>
                        );
                      })}
                  </div>
                )}
              </Droppable>
              <Menu.Item
                key="2"
                role="archived"
                onClick={handleArchived}
                className={
                  validateItemDesign(hash, "archived")
                    ? "projectMainItemSelected"
                    : "projectMainItemNotSelected"
                }
              >
                <ChestIcon className={classes.iconSpace} />
                Archived
                <CaretRightOutlined
                  className={classnames(
                    classes.arrow,
                    !isColapsed && classes.arrowDown
                  )}
                />
              </Menu.Item>
              {isColapsed && (
                <div className={classes.contractContainer}>
                  {Boolean(foldersSorted.length) &&
                    foldersSorted.map((project, index) => {
                      // Avoiding Archived Contracts
                      if (project?.is_archived === 0) {
                        return null;
                      }
                      const localClass =
                        projectMap[hash.substring(1)]?.title === project?.title
                          ? "project-item-selected"
                          : "project-item";
                      // Listing contract names
                      return (
                        <div
                          role="project"
                          className={
                            projectHover.folder_title === project?.title &&
                            globalSearch === true
                              ? "project-item-selected"
                              : localClass
                          }
                          onClick={() => handleFolder(project?.id)}
                          key={index}
                        >
                          <p className="project-title-archive">
                            {project.title}
                          </p>
                        </div>
                      );
                    })}
                </div>
              )}
            </Menu>
          </div>
        ) : null}
      </div>
    </Sider>
  ) : (
    <MobileHeader />
  );
};

export default SideBar;
