import React from "react";
import { Modal } from "antd";

import "./styles.module.scss";

const FullEditorConfirmationModal = ({ open, onOk, onClose }) => (
  <Modal
    cancelText="Go Back"
    className="modal"
    okText="Yes, Use Full Editor"
    onCancel={onClose}
    onOk={onOk}
    title="Are you sure?"
    visible={open}
  >
    If you use the Full Editor, you can change all text, and add your company logo,
    however you can no longer use the Formbuilder.
  </Modal>
);

FullEditorConfirmationModal.defaultProps = {
  onOk: Function.prototype,
};

export default FullEditorConfirmationModal;
