// @Packages
import React from "react";
import classnames from "classnames";
import { Button, Layout } from "antd";
import { useHistory } from "react-router-dom";
// @components
import FooterComponent from "../Footer";
import HeaderBar from "../organisms/HeaderBar";
import HeaderComponent from "../Header";
import SideBar from "../organisms/SideBar";
// @styles
import classes from "./styles.module.scss";
// @utils
import { useMediaQuery } from "../utils";
// @Context
import { NewCategoryProvider } from "../../context/newCategory";
// @constants
import { MOBILE_RESOLUTION } from "../../constants/variables";
import { MobileHeaderProvider } from "../../context/mobileHeader";

const { Content } = Layout;

const PageWrapper = ({
                       isFullEditor,
                       className = "",
                       withClickHandler,
                       children,
                     }) => {
  const isMobile = useMediaQuery(MOBILE_RESOLUTION);
  const { location } = useHistory();

  if (location.pathname.includes("/contract")) {
    return (
      <Layout className={classnames(classes.wrapper, className)}>
        <HeaderComponent withClickHandler={withClickHandler} />
        <Layout className={classnames(classes.layout, classes.background)}>
          <NewCategoryProvider>
            <Content className={classes.container}>{children}</Content>
          </NewCategoryProvider>
        </Layout>

        <div className={classes.footer}>
          {isMobile && <Button className={classes.helpButton}>Help?</Button>}
          <FooterComponent withClickHandler={withClickHandler} />
        </div>
      </Layout>
    );
  }
  return (
    <Layout className={classnames(classes.wrapper, className)}>
      <MobileHeaderProvider>
        <HeaderComponent withClickHandler={withClickHandler} />
        <Layout className={classes.layout}>
          {!isFullEditor && <SideBar />}
          <NewCategoryProvider>
            <Content >
              {!isFullEditor && <HeaderBar />}
              {children}
              {!isFullEditor && (
                <div className={classes.helpContainer}>
                  <a
                    href='https://creatorslegal.tawk.help/'
                    className={classes.helpButton}
                    target='_blank' rel="noreferrer"
                  >
                    Help?
                  </a>
                </div>
              )}
            </Content>
          </NewCategoryProvider>
        </Layout>
      </MobileHeaderProvider>

      <FooterComponent withClickHandler={withClickHandler} />
    </Layout>
  );
};

export default PageWrapper;
