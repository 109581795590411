//@packages
import { createContext, useContext, useState } from "react";
import { DEFAULT_TITLE_ARCHIVED, DEFAULT_TITLE_GENERAL } from "../constants/variables";

const initialState = {
  toggleArchived: false,
}

const Context = createContext(initialState)
const { Provider, Consumer } = Context;

const MobileHeaderProvider = ({ children }) => {
  const [toggleArchived, setToggleArchived] = useState(false);
  const [toggleGeneral, setToggleGeneral] = useState(true);
  const [showGeneralOptions, setShowGeneralOptions] = useState(false);
  const [showArchivedOptions, setShowArchivedOptions] = useState(false);
  const [titleGeneral, setTitleGeneral] = useState(DEFAULT_TITLE_GENERAL);
  const [titleArchived, setTitleArchived] = useState(DEFAULT_TITLE_ARCHIVED);
  const [isItemGeneralSelected, setIsItemGeneralSelected] = useState(false);
  const [isItemArchivedSelected, setIsItemArchivedSelected] = useState(false);
  const [showAllOptions, setShowAllOptions] = useState(false);
  const [revert, setRevert] = useState(false);
  const [isGlobalSearching, setIsGlobalSearching] = useState(false);

  const value = {
    toggleArchived,
    setToggleArchived,
    toggleGeneral,
    setToggleGeneral,
    showGeneralOptions,
    setShowGeneralOptions,
    showArchivedOptions,
    setShowArchivedOptions,
    titleGeneral,
    setTitleGeneral,
    titleArchived,
    setTitleArchived,
    isItemGeneralSelected,
    setIsItemGeneralSelected,
    isItemArchivedSelected,
    setIsItemArchivedSelected,
    revert,
    setRevert,
    showAllOptions,
    setShowAllOptions,
    isGlobalSearching,
    setIsGlobalSearching
  }

  return <Provider value={value}>{children}</Provider>
};

const useMobileHeaderProvider = () => useContext(Context);

export { MobileHeaderProvider, Context, useMobileHeaderProvider, Consumer }
