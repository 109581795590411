const ArrowClose = ({
  className = "custom-icon",
  color = "#FFFFFF",
  width = 11,
  height = 7
}) => (
  <svg
    className={className}
    width={width}
    height={height}
    viewBox={`0 0 ${width} ${height}`}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.610596 1.1575C0.610596 0.738247 1.09556 0.50516 1.42294 0.767065L4.51054 3.23714C4.76074 3.43731 4.76074 3.81785 4.51054 4.01801L1.42294 6.48809C1.09556 6.75 0.610596 6.51691 0.610596 6.09766L0.610596 1.1575Z"
      fill={color}
    />
  </svg>

);

export default ArrowClose;
