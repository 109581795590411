// @Packages
import { createContext, useContext, useEffect, useRef, useState } from "react";
import { DragDropContext } from "react-beautiful-dnd";

// @Utils
import {
  deleteProject,
  updateProjectPosition,
  updateProjectState,
  archiveProject,
  unarchiveProject,
  archiveContractById,
  unarchiveContractById,
  deleteContractById,
  signContract,
  recallContract,
  getDrafts,
  getInProgress,
  getSent,
  cloneContract,
  moveContract,
  moveSentContract,
  moveProgressContract,
  addContractToFolder,
  createProject, sendForSignatureContract,
} from "../utils/contract";
import { filterByParam } from "../utils/table";

// @Services
import { ContractService } from "../services/contract";

const initialState = {
  data: [],
};

const Context = createContext(initialState);
const { Provider, Consumer } = Context;

const contractService = new ContractService();

const getItemStyle = (draggableStyle, isDragging) => ({
  userSelect: "none",
  background: "var(--white)",
  boxShadow: isDragging && "0px 1px 28px rgba(0, 0, 0, 0.12)",
  ...draggableStyle,
});

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const move = (source, destination, droppableSource, droppableDestination) => {
  const sourceClone = Array.from(source);
  const destClone = Array.from(destination);
  const [removed] = sourceClone.splice(droppableSource.index, 1);

  destClone.splice(droppableDestination.index, 0, removed);

  const result = {};
  result[ droppableSource.droppableId ] = sourceClone;
  result[ droppableDestination.droppableId ] = destClone;

  return result;
};

const ContractsProvider = ({ children }) => {
  const [draftData, setDraftData] = useState(null);
  const [inProgressData, setInProgressData] = useState(null);
  const [sentData, setSentData] = useState(null);
  const [contracts, setContracts] = useState(initialState.data);
  const [projects, setProjects] = useState([]);
  const [preloadedGenCont, setPreloadedGenCont] = useState([]);
  const [preloadedProjects, setPreloadedProjects] = useState([]);
  const [templateHistory, setTemplateHistory] = useState({});
  const [visible, setVisible] = useState({ item: null, visible: false });
  const [searchValue, setSearchValue] = useState("");

  // Archived variables
  const [archivedContracts, setArchivedContracts] = useState([]);

  const [contractName, setContractName] = useState("");
  const [isSearching, setIsSearching] = useState(false);
  const [showInput, setShowInput] = useState({ item: 0, show: false });
  const [foldersSorted, setFoldersSorted] = useState([]);
  const [loadedTypes, setLoadedTypes] = useState({
    drafts: false,
    inProgress: false,
    sent: false,
  });
  const [projectMap, setProjectMap] = useState({});
  const [projectMapInfo, setProjectMapInfo] = useState({});
  const [refreshData, setRefreshData] = useState(false);
  // Contracts global Search
  const [globalSearch, setGlobalSearch] = useState(false);
  const [projectHover, setProjectHover] = useState({});
  const [deletedContracts, setDeletedContracts] = useState({});
  const contractInputRef = useRef(null);
  const [isDataInitialized, setIsDataInitialized] = useState(false);

  /**
   * Get data
   */

  useEffect(() => {
    if (!isDataInitialized) {
      loadGeneralData("Initial");
      setIsDataInitialized(true);
    }
  }, []);

  const loadGeneralData = async (caller = "Default") => {
    try {
      // Getting data from server
      const promise = await Promise.all([
        getDrafts(),
        getInProgress(),
        getSent(),
      ]);

      const [drafts, inProgress, sent] = promise;
      const [Drafts, InProgress, Sent] = standardizeData(
        drafts,
        inProgress,
        sent
      );

      let general = [
        ...Drafts.templates,
        ...InProgress.templates,
        ...Sent.templates,
      ];

      // preloadedProjectGenCont
      general = general.sort(filterByParam("updated_at", -1));
      setPreloadedGenCont(general);

      // ProjectMap, ProjectMapInfo, PreloadedProjects
      loadProjectData(Drafts, InProgress, Sent);

      // templateHistory
      loadInProgressActions(InProgress);

      setDraftData(Drafts);
      setInProgressData(InProgress);
      setSentData(Sent);
    } catch (error) {
      console.log(error);
    }
  };

  const reloadFolders = async () => {
    // Getting data from server
    const promise = await Promise.all([
      getDrafts(),
      getInProgress(),
      getSent(),
    ]);

    const [drafts, inProgress, sent] = promise;

    loadProjectData(drafts, inProgress, sent, { onlyFolders: true });
  };

  const standardizeData = (Draft, InProgress, Sent) => {
    // Archived
    let archivedContracts = [];
    // Adding Status
    let draft = {
      templates: Draft.templates.map((item) => ({ ...item, status: 0 })),
    };
    let inProgress = {
      templates: InProgress.templates.map((item) => {
        const { available_actions, ...rest } = item;
        return {
          ...rest,
          status: -1,
          actions: available_actions,
        };
      }),
    };
    let sent = { templates: [] };

    Sent.templates.map((item) => {
      if (Boolean(item.is_archived)) {
        archivedContracts.push({ ...item, status: 4 });
      } else {
        sent.templates.push({ ...item, status: 4 });
      }
    });

    setArchivedContracts(archivedContracts);

    // Changing key value
    draft.folders = Draft.folders;
    inProgress.folders = InProgress.folders.map((item) => {
      const { envelopes, ...rest } = item;
      return {
        ...rest,
        templates: envelopes.map((env) => ({ ...env, status: -1 })),
      };
    });
    sent.folders = Sent.folders.map((item) => {
      const { envelopes, ...rest } = item;
      return { ...rest, templates: envelopes };
    });

    return [draft, inProgress, sent];
  };

  const loadInProgressActions = (InProgress) => {
    const { templates } = InProgress;
    loadHistory(templates);
  };

  async function loadHistory(data) {
    const dataFiltered = data.filter((item) => !templateHistory[ item.id ]);
    const promises = await Promise.all(
      dataFiltered.map(
        async ({ id }) => await contractService.getHistoryById(id)
      )
    );

    let newHistoryItems = {};

    for (let i in dataFiltered) {
      newHistoryItems[ dataFiltered[ i ].id ] = promises[ i ];
    }

    const newTemplateHistory = { ...templateHistory, ...newHistoryItems };
    setTemplateHistory(newTemplateHistory);
  }

  const loadProjectData = (
    Draft,
    InProgress,
    Sent,
    rules = { onlyFolders: false }
  ) => {
    const auxFolders = Draft.folders;
    const auxFoldersInProgress = InProgress.folders;
    const auxFoldersSent = Sent.folders;
    const allAuxFolders = [auxFolders, auxFoldersInProgress, auxFoldersSent];

    let auxProjectMap = {};

    let auxProjectMapInfo = {};

    let allContracts = [];

    let type = "templates";

    try {
      allAuxFolders.forEach((_auxFolders, count_a) => {
        if (rules.onlyFolders) {
          // reloadFolders doesn't need to load all contracts, and it doesn't need to standardize data
          // We use this to simulate the same behavior as standardizeData
          type = count_a === 0 ? "templates" : "envelopes";
        }

        for (let i in _auxFolders) {
          // Functionality for projectMapInfo

          // - Creating Structure
          auxProjectMapInfo[ _auxFolders[ i ].title ] = auxProjectMapInfo[
            _auxFolders[ i ].title
            ] || {
            ids: auxProjectMapInfo[ _auxFolders[ i ].title ]?.ids || [],
            templates: auxProjectMapInfo[ _auxFolders[ i ].title ]?.templates || [],
            ...(count_a === 0 && { order: i }),
          };

          // - Adding data
          auxProjectMapInfo[ _auxFolders[ i ].title ].ids.push({
            id: _auxFolders[ i ].id,
            type: count_a === 0 ? "template" : "envelope",
            status: count_a,
            is_archived: _auxFolders[ i ].is_archived,
            updated_at: _auxFolders[ i ].updated_at,
          });

          // - Adding templates
          const prevTemplates =
            auxProjectMapInfo[ _auxFolders[ i ].title ][ type ] || [];

          auxProjectMapInfo[ _auxFolders[ i ].title ].templates = [
            ...prevTemplates,
            ..._auxFolders[ i ][ type ],
          ];

          if (count_a === 2) {
            try {
              let _auxTemplates = auxProjectMapInfo[
                _auxFolders[ i ].title
                ].templates.map(({ status, ...rest }) => ({
                status: status.toString() === "completed" ? 4 : status,
                ...rest,
              }));
              auxProjectMapInfo[ _auxFolders[ i ].title ].templates = _auxTemplates;
            } catch (error) {
              console.log("Completed contract error", error);
            }
          }

          // Functionality for projectMap
          auxProjectMap = {
            ...auxProjectMap,
            [ _auxFolders[ i ].id ]: {
              title: _auxFolders[ i ].title,
              is_archived: _auxFolders[ i ].is_archived,
              updated_at: _auxFolders[ i ].updated_at,
            },
          };

          // Functionality for preloadedProjects
          // allContracts: It is necessary to have the information of all the templates for the global search
          if (!rules?.onlyFolders) {
            allContracts = [
              ...allContracts,
              ...Object.values(_auxFolders[ i ].templates).map((obj) => ({
                ...obj,
                folder_title: _auxFolders[ i ].title,
              })),
            ];
          }
        }
      });

      setProjectMap(auxProjectMap);
      setProjectMapInfo(auxProjectMapInfo);
      if (!rules?.onlyFolders) {
        setPreloadedProjects(allContracts);
      }
    } catch (error) {
      console.log("allContracts", error);
    }
  };

  /**
   * Sign functions
   */

  const signContractById = async (id, status) => {
    const resp = await signContract(id, status);
    return resp;
  };

  /**
   * Recall functions
   */
  const recallContractById = async (id, status, folder_id) => {
    const resp = await recallContract(id, status);
    return resp;
  };

  /**
   * Delete Functions
   */

  const deleteContract = async (id, status) => {
    const { success } = await deleteContractById(id, status);

    if (success) {
      setDeletedContracts((prevState) => ({
        ...prevState,
        [ id ]: !status ? 0 : 1,
      }));
      return { success, contractDelete: id };
    } else {
      return { success, contractDelete: id };
    }
  };

  const deleteContractsFromProject = async (contracts) => {
    let deletedContracts = [];
    let failed = [];

    if (contracts.length === 0) {
      return { success: true, deletedContracts, failed };
    }

    await Promise.all(
      contracts.map(async ({ id, status }) => {
        const resp = await deleteContract(id, status);
        if (resp.success) {
          deletedContracts.push(resp);
        } else {
          failed.push(resp);
        }
      })
    );

    if (deleteContract.length === contracts.length) {
      return { success: true, deletedContracts, failed };
    } else if (failed.length > 0) {
      return { success: false, deletedContracts, failed };
    } else {
      return { success: false, deletedContracts, failed };
    }
  };

  /**
   * Archived Functions
   */

  const archiveFolder = async (hash) => {
    const projectId = hash.toString().substr(1);
    const projectName = projectMap[ projectId ]?.title;
    const sameProjects = Object.keys(projectMap).filter(
      (key) => projectMap[ key ].title === projectName
    );
    let _success = 0;
    await Promise.all(
      sameProjects.map(async (id) => {
        const { success } = await archiveProject(id);
        if (success) {
          _success += 1;
        }
      })
    );

    if (_success > 0) {
      setRefreshData(true);
      updatePreloadedProjects(sameProjects, 1);
    }
  };

  const unarchiveFolder = async (hash, archiveFnStructure) => {
    const projectId = hash.toString().substr(1);
    const projectName = projectMap[ projectId ]?.title;
    const sameProjects = Object.keys(projectMap).filter(
      (key) => projectMap[ key ].title === projectName
    );

    let _success = 0;
    await Promise.all(
      sameProjects.map(async (id) => {
        const { success } = await unarchiveProject(id);
        if (success) {
          _success += 1;
        }
      })
    );

    if (_success > 0) {
      setRefreshData(true);
      updatePreloadedProjects(sameProjects, 0);
    }
  };

  const updatePreloadedProjects = (projects, archived) => {
    // Updating projectMap
    let newProjectMap = { ...projectMap };

    projects.forEach((id) => {
      newProjectMap[ id ].is_archived = archived;
    });

    // Updating ProjectMapInfo
    let newProjectMapInfo = {};
    let name = projectMap[ projects[ 0 ] ]?.title;
    const auxIdsInfo = projectMapInfo[ name ].ids.map((item) => ({
      ...item,
      is_archived: archived,
    }));
    newProjectMapInfo = {
      ...projectMapInfo,
      [ name ]: { ...projectMapInfo[ name ], ids: auxIdsInfo },
    };

    setProjectMap(newProjectMap);
    setProjectMapInfo(newProjectMapInfo);
  };

  const archiveContract = async (contractId, status) => {
    const { success } = await archiveContractById(contractId, status);

    if (success) {
      updatePreloadedContracts(contractId, 1);
    }
  };

  const unarchiveContract = async (contractId, status) => {
    const { success } = await unarchiveContractById(contractId, status);
    if (success) {
      updatePreloadedContracts(contractId, 0);
    }
  };

  const updatePreloadedContracts = (contractId, archived) => {
    // archived = 1
    if (archived === 1) {
      // Take out general contract and add to archivedContracts
      let newPreloadedGenCont = [];
      let contractArchived = {};

      preloadedGenCont.forEach((contract) => {
        if (contract.id === contractId) {
          contractArchived = { ...contract, is_archived: archived };
        } else {
          newPreloadedGenCont.push(contract);
        }
      });
      setArchivedContracts((prevState) => [contractArchived, ...prevState]);
      setPreloadedGenCont(newPreloadedGenCont);
      setContracts(newPreloadedGenCont);
    } else if (archived === 0) {
      // Take out contract from archivedContacts  and add to general
      let newArchivedContract = [];
      let contractUnarchive = {};

      archivedContracts.forEach((contract) => {
        if (contract.id === contractId) {
          contractUnarchive = { ...contract, is_archived: archived };
        } else {
          newArchivedContract.push(contract);
        }
      });
      setArchivedContracts(newArchivedContract);
      setPreloadedGenCont((prevState) => [contractUnarchive, ...prevState]);
      setContracts(newArchivedContract);
    }
  };

  const deleteProjectById = async (id) => {
    const { success } = await deleteProject(id);

    if (success) {
      updateDeletedContracts(id);
      return true;
    }
  };

  const updateDeletedContracts = (idFolder, contracts = []) => {
    const folderName = projectMap[ idFolder ]?.title;
    let newProjectMap = { ...projectMap };
    let newProjectMapInfo = { ...projectMapInfo };

    projectMapInfo[ folderName ]?.ids.forEach((folderInfo) => {
      delete newProjectMap[ folderInfo?.id ];
    });

    delete newProjectMapInfo[ folderName ];

    setProjectMap(newProjectMap);
    setProjectMapInfo(newProjectMapInfo);
  };

  const cloneContracts = async (id) => {
    const { success } = await cloneContract(id);
    if (success) await loadGeneralData();
  };

  const moveDraftContracts = async (data) => {
    const { success } = await moveContract(data);
    if (success) await loadGeneralData();
  };

  const moveSentContracts = async (data) => {
    const { success } = await moveSentContract(data);
    if (success) await loadGeneralData();
  };

  const moveProgressContracts = async (data) => {
    const { success } = await moveProgressContract(data);
    if (success) await loadGeneralData();
  };

  const sendForSignature = async (id) => {
    const { success } = await sendForSignatureContract(id);
    if (success) await loadGeneralData();
  }

  const handleCreateFolder = async (projectName, setFoldersMapped) => {
    try {
      const newFolderData = {
        title: projectName,
        chapter: "TEMPLATE",
        parent_id: 0,
        position: 0,
      };

      const resp = await createProject(newFolderData);

      if (resp.success) {
        const folderId = resp.data.data.folder_id;
        setProjectMap((prevState) => ({
          ...prevState,
          [ folderId ]: { title: projectName, is_archived: 0 },
        }));
        setFoldersMapped((prevState) => [
          ...prevState,
          { id: folderId, title: projectName, is_archived: 0 },
        ]);
        setFoldersSorted((prevState) => [
          {
            id: folderId,
            title: projectName,
            is_archived: 0,
            label: projectName,
            value: folderId,
          },
          ...prevState,
        ]);
        return folderId;
      }
    } catch (e) {
      console.log(e.message);
    }
  };

  const getList = (id) => {
    const id2List = {
      [ id ]: "foldersSorted",
      contractsDrop: "contracts",
    };

    const stateList = {
      contracts,
      foldersSorted,
    };

    return stateList[ id2List[ id ] ];
  };

  const handleDragEnd = async (result) => {
    const { source, destination } = result;

    if (!destination) {
      return;
    }

    const items = reorder(
      getList(source.droppableId),
      source.index,
      destination.index
    );

    let state = { items };

    if (source.droppableId === destination.droppableId) {
      if (source.droppableId === "projectDroppable") {
        state = { projects: items };
        setProjects(state.projects);
        const projectsToSend = state.projects.map((project) => ({
          id: project.id,
          type: "folder",
        }));
        await updateProjectState(Number(result.draggableId), { state: 1 });
        await updateProjectPosition(0, [
          ...projectsToSend,
          { id: result.draggableId, type: "folder" },
        ]);
      }
    } else {
      const resultMove = move(
        getList(source.droppableId),
        getList(destination.droppableId),
        source,
        destination
      );

      let folder;
      let folderTemplates;
      const contract = contracts.find(
        (c) => c.id === Number(result.draggableId)
      );

      const folderTitle = resultMove[ destination.droppableId ].find(
        (p) => p.id === Number(destination.droppableId)
      );

      let folderId;
      let folderIdList = folderTitle
        ? projectMapInfo[ folderTitle.title ].ids
        : [];

      let type;

      if (!contract.status) {
        folder = folderIdList[ 0 ];
        folderId = folderIdList[ 0 ]?.id;
        type = "template";
      } else if (contract.status === 4) {
        folder = folderIdList[ 2 ];
        folderId = folderIdList[ 2 ]?.id;
        type = "envelope";
      } else {
        folder = folderIdList[ 1 ];
        folderId = folderIdList[ 1 ]?.id;
        type = "envelope";
      }

      folder = {
        ...folder,
        templates: folder
          ? projectMapInfo[ folderTitle.title ].templates
          : contracts,
      };

      folderTemplates = folder.templates.map((t) => {
        return {
          id: t.id,
          type,
        };
      });

      const contractStatus =
        contract.status === "completed" || contract.status === 4
          ? 4
          : contract.status;

      await addContractToFolder(
        folderId ?? 0,
        contract.id,
        contractStatus,
        folderId ? folderTemplates : []
      );

      await loadGeneralData();
      if (!folderId || !folderTitle.id) window.location.href = `/#general'`;
      else window.location.href = `/#${folderTitle.id}`;
    }
  };

  const value = {
    loadGeneralData,
    reloadFolders,
    draftData,
    inProgressData,
    sentData,
    contractInputRef,
    projectData: contracts,
    setProjectData: setContracts,
    folders: projects,
    setFolders: setProjects,
    preloadedGenCont,
    setPreloadedGenCont,
    contractName,
    setContractName,
    showInput,
    setShowInput,
    preloadedProjects,
    setPreloadedProjects,
    loadedTypes,
    setLoadedTypes,
    isSearching,
    setIsSearching,
    projectMap,
    setProjectMap,
    getItemStyle,
    archiveFolder,
    unarchiveFolder,
    archiveContract,
    unarchiveContract,
    archivedContracts,
    setArchivedContracts,
    projectMapInfo,
    setProjectMapInfo,
    refreshData,
    setRefreshData,
    globalSearch,
    setGlobalSearch,
    projectHover,
    setProjectHover,
    deleteProjectById,
    foldersSorted,
    deleteContract,
    deleteContractsFromProject,
    deletedContracts,
    setDeletedContracts,
    setFoldersSorted,
    recallContractById,
    signContractById,
    templateHistory,
    setTemplateHistory,
    loadHistory,
    cloneContracts,
    moveDraftContracts,
    moveSentContracts,
    moveProgressContracts,
    visible,
    setVisible,
    searchValue,
    setSearchValue,
    handleCreateFolder,
    sendForSignature
  };
  return (
    <Provider value={value}>
      <DragDropContext onDragEnd={handleDragEnd}>{children}</DragDropContext>
    </Provider>
  );
};

const useContractsProvider = () => useContext(Context);

export { ContractsProvider, Context, Consumer, useContractsProvider };
