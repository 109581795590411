//@packages
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

//@icons
import StackFoldersIcon from "../../atoms/icons/StackFolders";
import ChestIcon from "../../atoms/icons/Chest";
import ArrowDown from "../../atoms/icons/ArrowDown";
import ArrowClose from "../../atoms/icons/ArrowClose";

//@components
import MobileProjectHeaderItems from "../MobileProjectHeaderItems";
import { Input } from "antd";

//@context
import { useProjectProvider } from "../../../context/projects";
import { useMobileHeaderProvider } from "../../../context/mobileHeader";

//@constants
import {
  DEFAULT_TITLE_ARCHIVED,
  DEFAULT_TITLE_GENERAL,
  TITLE_SEARCH,
} from "../../../constants/variables";

//@utils
import { renameFolder } from "../../../utils/contract";

//@scripts
import { usePrevious } from "../../../utils/hooks";
import { useHistory } from "react-router-dom";
import { useContractsProvider } from "../../../context/contracts";

//@styles
import classes from "./styles.module.scss";
import classNames from "classnames";
import MobileProjectHeaderTitle from "../MobileProjectHeaderTitle";

const MobileProjectHeader = ({ title }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation();

  const [templates, setTemplates] = useState([]);

  const {
    toggleArchived,
    setToggleArchived,
    toggleGeneral,
    setToggleGeneral,
    showGeneralOptions,
    setShowGeneralOptions,
    showArchivedOptions,
    setShowArchivedOptions,
    setTitleGeneral,
    setTitleArchived,
    isItemGeneralSelected,
    setIsItemGeneralSelected,
    isItemArchivedSelected,
    setIsItemArchivedSelected,
    revert,
    setRevert,
    setShowAllOptions,
    isGlobalSearching,
  } = useMobileHeaderProvider();

  const {
    folders,
    setFolders,
    input,
    setInput,
    setNewProject,
    foldersMapped,
    setFoldersMapped,
  } = useProjectProvider();

  const foldersEntities = useSelector((store) =>
    store.templates.folders.valueSeq().toArray()
  );

  const templatesEntities = useSelector((store) =>
    store.templates.templates.valueSeq().toArray()
  );

  const previousFoldersEntities = usePrevious(foldersEntities);
  const {
    projectMap,
    projectData,
    isSearching,
    foldersSorted,
    reloadFolders,
    handleCreateFolder,
    projectMapInfo,
  } = useContractsProvider();

  useEffect(() => {
    if (
      previousFoldersEntities &&
      previousFoldersEntities.length !== foldersEntities.length &&
      foldersEntities.length
    ) {
      const foldersConverted = JSON.parse(JSON.stringify(foldersEntities));
      const foldersMapped = foldersConverted.map((folder) => {
        folder.label = folder.title;
        folder.value = folder.id;
        return folder;
      });
      setFolders(foldersMapped);
      setTemplates(templatesEntities);
    }
  }, [foldersEntities, previousFoldersEntities, dispatch]);

  useEffect(() => {
    const projectId = location.hash.replace("#", "");
    const project = foldersEntities.find(
      (project) => project.id === Number(projectId)
    );
    if (project) setInput({ ...input, value: project.title });
  }, [folders, templates, location, dispatch]);

  useEffect(() => {
    const newFolders = foldersSorted.map((folder) => {
      folder.value = folder.id;
      folder.label = folder.title;
      return folder;
    });
    setFoldersMapped(newFolders);
  }, [foldersSorted]);

  const handleFolder = (folderId, template) => {
    if (folderId) {
      history.push(`/#${folderId}`, {
        template,
      });
    } else history.push("/");
  };

  const handleChange = ({ target }) =>
    setInput({ ...input, value: target.value });

  const handleCreateProject = async (e) => {
    try {
      e.preventDefault();
      const projectName = input.value;
      const folderId = await handleCreateFolder(projectName, setFoldersMapped);
      setNewProject(false);
      reloadFolders();
      handleFolder(folderId, {});
      setTitleGeneral(input.value);
      setIsItemGeneralSelected(true);
      setToggleGeneral(false);
      setToggleArchived(false);
      setInput({ ...input, showInput: false });
      setNewProject(true);
    } catch (e) {
      console.log(e.message);
    }
  };

  const handleRenameProject = async (e) => {
    e.preventDefault();
    const newFolders = foldersMapped.map((f) => {
      if (f.id === input.item.id) {
        f.title = input.value;
        f.label = input.value;
      }
      return f;
    });
    if (!input.item.is_archived) setTitleGeneral(input.item.title);
    setFoldersMapped(newFolders);

    setInput({ ...input, showInput: false, value: input.value });
    setTitleGeneral(input.value);
    setShowAllOptions(false);
    setToggleArchived(false);
    setToggleGeneral(false);
    setShowGeneralOptions(false);
    setShowArchivedOptions(false);

    const project = input.item;
    const folderName = projectMap[ project.id ].title;
    const ids = projectMapInfo[ folderName ].ids;
    const resp = await Promise.all(
      ids.map(async ({ id }) => await renameFolder(id, input.value))
    );
    if (resp[ 0 ].success) reloadFolders();
  };

  useEffect(() => {
    const contract = projectData[ 0 ];
    if (isSearching && contract && Object.keys(contract)) {
      setInput({ ...input, value: contract.folder_title });
    }
  }, [isSearching, projectData]);

  const foldersGeneral = foldersMapped.filter((f) => !f.is_archived);
  const foldersArchive = foldersMapped.filter((f) => Boolean(f.is_archived));

  const handleOpenGeneral = () => {
    setShowGeneralOptions(true);
    setTitleArchived(DEFAULT_TITLE_ARCHIVED);
    if (!showGeneralOptions) setToggleArchived(true);
  };

  const handleOpenArchive = () => {
    setShowArchivedOptions(true);
    if (!showArchivedOptions) setToggleGeneral(true);
    if (revert) setShowGeneralOptions(true);
  };

  const handleCloseGeneral = () => {
    setShowGeneralOptions(false);
    if (!showArchivedOptions) {
      setToggleArchived(false);
      setRevert(false);
    }
    if (
      (isItemGeneralSelected || isItemArchivedSelected) &&
      !showArchivedOptions
    ) {
      setToggleGeneral(false);
      setToggleArchived(false);
      setShowAllOptions(false);
    }
    if (!showArchivedOptions && revert) {
      setRevert(true)
      setToggleArchived(true);
      setToggleGeneral(false);
    }
  };

  const handleCloseArchived = () => {
    setShowArchivedOptions(false);
    if (!showGeneralOptions && !revert) setToggleArchived(false);
    if (!showArchivedOptions && showGeneralOptions && !revert) {
      setToggleArchived(false);
      setShowGeneralOptions(false);
    }
  };

  const handleSelectItem = (project, type) => {
    const folder = foldersMapped.find((folder) => folder.id === project.id);
    if (folder) {
      handleFolder(project.id, folder?.templates ?? {});
      setInput({ ...input, value: folder.title, item: folder });
    } else handleFolder("general", {});
    if (type === "general") {
      setTitleGeneral(project.title);
      setIsItemGeneralSelected(true);
      setIsItemArchivedSelected(false);
      setTitleArchived(DEFAULT_TITLE_ARCHIVED);
      setRevert(false);
    } else {
      setTitleArchived(project.title);
      setIsItemArchivedSelected(true);
      setIsItemGeneralSelected(false);
      setTitleGeneral(DEFAULT_TITLE_GENERAL);
    }
    setToggleGeneral(false);
    setToggleArchived(false);
    setShowGeneralOptions(false);
    setShowArchivedOptions(false);
    setShowAllOptions(false);
  };

  const handleSelectArrow = (e) => {
    e.stopPropagation();
    setShowArchivedOptions(!showArchivedOptions);
  }

  useEffect(() => {
    if (location.hash === '#archived') {
      setRevert(true);
      setToggleGeneral(false)
      setToggleArchived(true)
    }
  }, [location])


  return (
    <div className={classes.container}>
      {(isItemArchivedSelected || isItemGeneralSelected) &&
        !input.showInput && (
          <MobileProjectHeaderTitle
            selectorClassName={classes.selector}
            iconContainerClassName={classes.iconContainer}
          />
        )}

      <div className={classNames(revert && classes.revert)}>
        {input.showInput && (
          <div className={classes.inputContainer}>
            <form
              className={classes.form}
              onSubmit={(e) =>
                !input.isEdit ? handleCreateProject(e) : handleRenameProject(e)
              }
            >
              <Input
                type="text"
                autoFocus
                placeholder="Untitled Project"
                className={classes.input}
                onChange={handleChange}
                onFocus={(e) => e.target.select()}
                onBlur={(e) => {
                  setInput({ ...input, showInput: false });
                  return !input.isEdit
                    ? handleCreateProject(e)
                    : handleRenameProject(e);
                }}
                value={input.value}
              />
            </form>
          </div>
        )}

        {!input.showInput && (
          <>
            {toggleGeneral && (
              <div className={classes.selector}>
                <MobileProjectHeaderItems
                  type={DEFAULT_TITLE_GENERAL}
                  handleClose={handleCloseGeneral}
                  handleOpen={handleOpenGeneral}
                  showOption={showGeneralOptions}
                  handleSelectItem={(project) =>
                    handleSelectItem(project, "general")
                  }
                  options={foldersGeneral}
                  containerClassName={classes.generalContainer}
                  title={
                    <span className={classes.title}>
                      <StackFoldersIcon color="var(--white)" />
                      {!isGlobalSearching
                        ? DEFAULT_TITLE_GENERAL
                        : TITLE_SEARCH}
                    </span>
                  }
                />
              </div>
            )}

            {toggleArchived ? (
              <div className={classes.selector}>
                <MobileProjectHeaderItems
                  type={DEFAULT_TITLE_ARCHIVED}
                  handleClose={handleCloseArchived}
                  handleOpen={handleOpenArchive}
                  showOption={showArchivedOptions}
                  handleSelectItem={(project) =>
                    handleSelectItem(project, "archived")
                  }
                  options={foldersArchive}
                  containerClassName={classes.archiveContainer}
                  title={
                    <span className={classes.title}>
                      <ChestIcon color="var(--white)" />
                      {DEFAULT_TITLE_ARCHIVED}
                      <span onClick={handleSelectArrow}>
                        {!showArchivedOptions ? (
                          <ArrowClose className={classes.arrowIcon} />
                        ) : (
                          <ArrowDown className={classes.arrowIcon} />
                        )}
                     </span>
                    </span>
                  }
                />
              </div>
            ) : null}
          </>
        )}
      </div>
    </div>
  );
};

export default MobileProjectHeader;
