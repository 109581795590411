// @components
import Logo from "../Logo";
import User from "../User";
import HeaderNav from "./HeaderNav";
// @styles
import styles from "./header.module.scss";

const Header = ({ withClickHandler, ...props }) => {
  return (
    <header className={styles.header}>
      <div className="container">
        <HeaderNav withClickHandler={withClickHandler} />
        <Logo withClickHandler={withClickHandler} />
        <User withClickHandler={withClickHandler} />
      </div>
    </header>
  );
};

export default Header;
