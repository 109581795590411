const StackFolders = ({
  className = "custom-icon",
  color = "#A5A5A5",
  size = 25,
}) => (
  <svg
    className={className}
    fill="none"
    height={size}
    viewBox={`0 0 ${size} ${size}`}
    width={size}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M24.0276 7.13476C24.0185 7.43297 23.8739 7.62876 23.6268 7.77033C22.9397 8.15589 22.2556 8.54748 21.5716 8.93605C18.5259 10.6691 15.4803 12.4011 12.4346 14.1321C12.1303 14.3098 11.856 14.3128 11.5456 14.1351C7.84305 12.0165 4.13746 9.90296 0.428843 7.79443C0.0792768 7.59563 -0.0533172 7.30646 0.0340743 6.94198C0.0853038 6.71908 0.232965 6.58052 0.425829 6.47208L3.29167 4.84249L11.5607 0.128417C11.853 -0.0402651 12.1182 -0.0432772 12.4075 0.122393L23.6268 6.5022C23.8799 6.64679 24.0245 6.8486 24.0276 7.13476ZM21.6921 7.13476L21.5324 7.03837L12.1363 1.69174C12.0278 1.62849 11.9464 1.6315 11.838 1.69475L2.43583 7.05644C2.39426 7.08035 2.35402 7.10649 2.31529 7.13476L2.45391 7.22211C5.57389 8.9973 8.69085 10.7745 11.8048 12.5537C11.9404 12.629 12.0338 12.632 12.1694 12.5567C14.7389 11.0867 17.3125 9.62182 19.89 8.16192L21.6921 7.13476Z"
      fill={color}
    />
    <path
      d="M0.00304604 16.8943C0.00304604 16.2256 0.608759 15.8762 1.13311 16.1654C1.58513 16.4154 2.03113 16.6774 2.48014 16.9365C5.60615 18.7297 8.73114 20.524 11.8551 22.3193C11.9606 22.3765 12.0299 22.3705 12.1264 22.3132L22.764 16.2527C22.981 16.1292 23.1979 16.0178 23.4601 16.075C23.6082 16.1071 23.7433 16.1828 23.8481 16.2923C23.9528 16.4018 24.0223 16.5401 24.0478 16.6895C24.102 17.0028 23.9574 17.301 23.659 17.4757C23.1377 17.7769 22.6133 18.0721 22.089 18.3703C18.8706 20.2017 15.6521 22.0351 12.4337 23.8705C12.1264 24.0453 11.8431 24.0422 11.5387 23.8675C7.83814 21.7409 4.13757 19.6173 0.43699 17.4968C0.165775 17.3431 0.00907303 17.1263 0.00304604 16.8943Z"
      fill={color}
    />
    <path
      d="M24.0598 11.9964C24.0478 12.3247 23.8941 12.5386 23.6289 12.6892L21.5345 13.882C18.5311 15.593 15.5266 17.3059 12.5212 19.0208C12.1475 19.2347 11.828 19.2317 11.4544 19.0148C7.78393 16.9003 4.11048 14.7907 0.434009 12.6862C0.102524 12.4964 -0.0511645 12.1952 0.0151325 11.8639C0.114578 11.3488 0.669062 11.0988 1.13917 11.3639C1.74488 11.7042 2.34155 12.0506 2.94425 12.397C5.90954 14.0959 8.87281 15.7978 11.8341 17.5027C11.9546 17.569 12.036 17.5629 12.1475 17.4997C15.7275 15.4574 19.3086 13.4182 22.8906 11.3819C23.3758 11.1078 23.9272 11.3307 24.0418 11.8488C24.0513 11.8975 24.0574 11.9468 24.0598 11.9964Z"
      fill={color}
    />
  </svg>
);

export default StackFolders;
