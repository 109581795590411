import { createContext, useContext, useState } from "react";
import useFocus from "../utils/useFocus";

const initialState = {
    form: null,
    addCategory: false
}

const Context = createContext(initialState)
const { Provider, Consumer } = Context;

const NewCategoryProvider = ({ children }) => {
    const [form, setForm] = useState(initialState.form);
    const [addCategory, setAddCategory] = useState(initialState.addCategory)
    const [inputRef, setInputFocus] = useFocus()

    const value = {
        form,
        setForm,
        addCategory,
        setAddCategory,
        inputRef,
        setInputFocus,
    }

    return <Provider value={value}>{children}</Provider>
};

const useNewCategoryProvider = () => useContext(Context);

export { NewCategoryProvider, Context, useNewCategoryProvider, Consumer }
