// @packages
import { Quill } from "react-quill";

const Base = Quill.import("blots/block/embed");
const Parchment = Quill.import("parchment");
const Embed = Quill.import("blots/inline");

export class Strong extends Embed {
  static blotName = "strong";
  static tagName = "strong";
  static className = "ql-strong";

  static create(data) {
    const node = super.create();
    node.setAttribute("id", data.id);
    node.innerHTML = data.text;
    return node;
  }
}

// Non editable tag
export class NoEditable extends Parchment.Embed {
  static blotName = "no-editable";
  static className = "ql-no-editable";
  static tagName = "span";

  static create(data) {
    const node = super.create(data);
    node.setAttribute("id", data.id);
    node.innerText = data.text;
    node.contentEditable = "false";
    return node;
  }

  deleteAt(index, length) {}
}

export class NoEditableTableBlot extends Base {
  static blotName = "table";
  static className = "quill-table";
  static tagName = "table";

  static create(value) {
    const node = super.create();
    let valueToReturn = value;

    if (!value.includes("assignedTableId")) {
      const tableId = `assignedTableId-${Date.now()}`;

      valueToReturn = value
        .replace("#tableId", `#${tableId}`)
        .replace("table-layout: fixed;", "");
      node.setAttribute("id", tableId);
    } else {
      const existedId = valueToReturn.match(/#assignedTableId-(\d+)/i)[1];

      node.setAttribute("id", `assignedTableId-${existedId}`);
    }

    node.innerHTML = this.transformValue(valueToReturn);
    node.contentEditable = "false";

    return node;
  }

  static transformValue(value) {
    let handleArr = value.split("\n");

    handleArr = handleArr.map((e) =>
      e.replace(/^[\s]+/, "").replace(/[\s]+$/, "")
    );

    return handleArr.join("");
  }

  static value(node) {
    const regex = /\s*?style="(.*?)"/g;
    const content = node.innerHTML.replaceAll(regex, "");

    return content; // node.innerHTML
  }

  deleteAt(index, length) {}
}
