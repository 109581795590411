const Add = ({
  className = "custom-icon",
  color = "#A087F1",
  size = 24
                }) => (
  <svg
    width={size}
    height={size}
    viewBox={`0 0 ${size} ${size}`}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.55078 18.2919H11.0852V11.1705H18.2067V7.63601H11.0852V0.527698H7.55078V7.63601H0.442472V11.1705H7.55078V18.2919Z"
      fill={color}
    />
  </svg>

);

export default Add;
