const Forward = ({ className = "custom-icon", color = "#000000", size = 15 }) => (
  <svg
    className={className}
    width={size}
    height={size}
    viewBox="0 0 15 9"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.01514 5.96103C7.29335 5.94642 5.65514 5.97565 4.09216 6.50163C2.81335 6.93263 1.86052 7.71428 1.10828 8.70048C1.03396 8.80979 0.944094 8.91044 0.84082 9C1.30888 7.27597 2.14469 5.71997 3.58231 4.45617C5.01992 3.19237 6.87545 2.56412 8.99007 2.55682V0L14.8408 4.60957L9.01514 8.88311V5.96103Z"
      fill={color}
    />
  </svg>

);

export default Forward;
