import moment from "moment";
import { EditOutlined, PlusOutlined } from "@ant-design/icons";
import { Form, Input, DatePicker, Button } from "antd";
import { useState } from "react";
// @constants
import {
  MISSING_DESCRIPTION,
  MISSING_THE_DATE,
} from "../../constants/staticErrors";
// @styles
import styles from "./product.module.scss";

const ProductDynamicForm = ({ form, item, disabled, onFocus, isEdit }) => {
  const { TextArea } = Input;
  const [descriptionText, setDescriptionText] = useState("");
  const [openItemId, setOpenItemId] = useState(null);
  const [initValues, setInitValues] = useState(
    item.val
      ? JSON.parse(item.val).map((item) => {
          return {
            date: moment(item.date, "MM/DD/YYYY"),
            description: item.description,
          };
        })
      : []
  );

  const toggleOpenItem = (id) => setOpenItemId(id === openItemId ? null : id);

  const onDescriptionChange = (e) => {
    console.log(e.target.value);
    console.log(descriptionText);
  };

  const handleCancelAction = (id, key) => {
    const deliverableValues = form.getFieldValue(`deliverable-${item.id}`);
    console.log(id);
    console.log(key);
    console.log(deliverableValues);

    toggleOpenItem(id);

    return false;
  };

  return (
    <Form.List
      name={`deliverable-${item.id}`}
      initialValue={initValues.length > 0 && initValues}
    >
      {(fields, { add, remove }) => {
        return (
          <div className={styles.dynamicWrapper}>
            {fields.map(({ key, name, fieldKey, ...restField }, i) => {
              const deliverableValues = form.getFieldValue(
                `deliverable-${item.id}`
              );
              let date = null;
              let fieldDate = null;

              if (deliverableValues && deliverableValues[name]) {
                if (deliverableValues[name].date) {
                  date = deliverableValues[name].date._d;
                  fieldDate =
                    date.getMonth() +
                    1 +
                    "/" +
                    date.getDate() +
                    "/" +
                    date.getFullYear();
                } else {
                  date = null;
                  fieldDate = null;
                }
              }

              return (
                <div
                  key={key}
                  className={
                    openItemId === i ? styles.column : styles.disabledColumn
                  }
                >
                  <h3>
                    Deliverable {i + 1}{" "}
                    {fieldDate && <span>– Due {fieldDate}</span>}
                  </h3>
                  <Form.Item
                    {...restField}
                    name={[name, "date"]}
                    fieldKey={[fieldKey, "date"]}
                    rules={[
                      {
                        required: true,
                        message: MISSING_THE_DATE,
                      },
                    ]}
                    className={styles.productPicker}
                  >
                    <DatePicker
                      format="MM/DD/YYYY"
                      placeholder="Due Date"
                      disabled={disabled || openItemId !== i}
                    />
                  </Form.Item>
                  <Form.Item
                    {...restField}
                    name={[name, "description"]}
                    fieldKey={[fieldKey, "description"]}
                    rules={[
                      {
                        required: true,
                        message: MISSING_DESCRIPTION,
                      },
                    ]}
                    className={styles.productTextArea}
                  >
                    <TextArea
                      rows={4}
                      placeholder="Description"
                      disabled={disabled || openItemId !== i}
                      // value={descriptionText}
                      // onChange={onDescriptionChange}
                    />
                  </Form.Item>
                  <div className={styles.dynamicNav}>
                    {openItemId === i ? (
                      <>
                        {/*<Button*/}
                        {/*  type='text'*/}
                        {/*  onClick={() => handleCancelAction(i, fieldKey)}*/}
                        {/*>*/}
                        {/*  Cancel*/}
                        {/*</Button>*/}
                        <Button
                          type="text"
                          style={{ color: "var(--blue-main)" }}
                          onClick={() => toggleOpenItem(i)}
                        >
                          Save
                        </Button>
                        <Button
                          type="text"
                          style={{ color: "var(--danger)" }}
                          onClick={() => remove(name)}
                        >
                          Delete
                        </Button>
                      </>
                    ) : (
                      <Button
                        type="text"
                        style={{ color: "var(--blue-main)" }}
                        icon={<EditOutlined />}
                        onClick={() => toggleOpenItem(i)}
                      >
                        Edit
                      </Button>
                    )}
                  </div>
                </div>
              );
            })}
            <Form.Item>
              <Button
                type="primary"
                icon={<PlusOutlined />}
                onClick={() => {
                  setOpenItemId(fields.length);
                  add();
                  onFocus(isEdit ? item.original_id : item.id);
                }}
              >
                Add New
              </Button>
            </Form.Item>
          </div>
        );
      }}
    </Form.List>
  );
};

export default ProductDynamicForm;
