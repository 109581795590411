import { createContext, useContext, useState } from "react";
import useFocus from "../utils/useFocus";

const initialState = {
  input: {
    showInput: false,
    value: null,
  },
};

const Context = createContext(initialState);
const { Provider, Consumer } = Context;

const ProjectProvider = ({ children }) => {
  const [input, setInput] = useState(initialState.input);
  const [inputRef, setInputFocus] = useFocus();
  const [newProject, setNewProject] = useState(false);
  const [renameProject, setRenameProject] = useState(false);
  const [folders, setFolders] = useState([]);
  const [foldersMapped, setFoldersMapped] = useState([]);
  const [newProjectName, setNewProjecName] = useState("");
  const [isSidebarCollapsed, setIsSidebarCollapsed] = useState(false);

  const value = {
    input,
    renameProject,
    setRenameProject,
    setInput,
    inputRef,
    setInputFocus,
    newProject,
    setNewProject,
    folders,
    setFolders,
    foldersMapped,
    setFoldersMapped,
    newProjectName,
    setNewProjecName,
    isSidebarCollapsed,
    setIsSidebarCollapsed
  };

  return <Provider value={value}>{children}</Provider>;
};

const useProjectProvider = () => useContext(Context);

export { ProjectProvider, Context, useProjectProvider, Consumer };
