// @packages
import classnames from "classnames";
import { BsPencilSquare } from "react-icons/bs";
import { Button, Col, Modal, Row, Spin } from "antd";
import { ExportOutlined, SaveOutlined } from "@ant-design/icons";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { useState } from "react";
// @components
import { SITE_DOMAIN } from "../../../../constants/variables";
import { TERMS_TITLE } from "../../../../constants/staticTexts";
// @styles
import classes from "./styles.module.scss";

const FooterContainer = ({ onSave, withTerms }) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [submitLoading] = useState(false);
  const history = useHistory();

  const contractLoading = useSelector((store) => store.templatesAdmin.loading);
  const contractPostLoading = useSelector(
    (store) => store.templatesAdmin.postLoading
  );

  const hideModal = () => setIsModalVisible(false);

  const handleSave = () => {
    if (withTerms) setIsModalVisible(true);
    else onSave();
  };

  const handleExit = () => {
    history.push("#general");
    window.scrollTo(0, 0);
  };

  const handleSaveDraft = () => {
    onSave(handleExit);
  };

  const goBack = () => {
    history.goBack();
  };

  return (
    <>
      <div className={classes.buttonContainer}>
        <Row
          justify={withTerms ? "space-between" : "end"}
          className={classes.firstRow}
        >
          {withTerms && (
            <Col md={12} xs={24} className={classes.saveContainer}>
              <Button className={classes.exitButton} onClick={goBack}>
                <BsPencilSquare />
                Continue editing
              </Button>
            </Col>
          )}
          <Col
            md={12}
            xs={24}
            className={classnames(classes.saveContainer, classes.second)}
          >
            <Button
              type="primary"
              className={classes.save}
              onClick={handleSave}
            >
              Save & finish
            </Button>
          </Col>
        </Row>
        <Button onClick={handleSaveDraft}>
          <SaveOutlined /> Save & finish later
        </Button>
        <Button className={classes.exitButton} onClick={handleExit}>
          <ExportOutlined />
          Exit
        </Button>
      </div>
      <Modal
        visible={isModalVisible}
        title={TERMS_TITLE}
        centered={true}
        keyboard={true}
        maskClosable={true}
        className="ant-modal-terms"
        onCancel={hideModal}
        footer={[
          <Button
            key="cancel"
            className={classes.btnModal}
            disabled={submitLoading}
            onClick={hideModal}
          >
            Cancel
          </Button>,
          <Button
            key="agree"
            type="primary"
            className={classes.btnModal}
            loading={submitLoading}
            onClick={onSave}
          >
            Agree
          </Button>,
        ]}
      >
        <Spin size="small" spinning={contractPostLoading || contractLoading}>
          <p className={classes.text_modal}>
            <span>
              Creators Legal is not a law firm and cannot provide legal advice.
              We are a self-help online and software service and do not
              participate in any legal representation.
            </span>
            <span>
              We are not a substitute for a lawyer, law firm or professional
              legal advice. We are not a ‘lawyer referral service’. Use of
              CreatorsLegal.com is subject to our{" "}
              <a href={`${SITE_DOMAIN}/terms-and-conditions/`} target="_blank">
                Terms and Conditions
              </a>{" "}
              and{" "}
              <a href={`${SITE_DOMAIN}/privacy-policy/`} target="_blank">
                Privacy Policy
              </a>
              .
            </span>
          </p>
        </Spin>
      </Modal>
    </>
  );
};

export default FooterContainer;
