const Delete = ({ className = "custom-icon", color = "#000000", size = 17 }) => (
  <svg
    className={className}
    width={size}
    height={size}
    viewBox="0 0 13 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.35265 3.31755H0.558881C0.218694 3.33414 0 3.16825 0 2.81989C0 2.47152 0.234892 2.33881 0.54268 2.33881H3.88785C4.33333 0.787744 5.2567 -0.00852244 6.52835 0.00806647C7.8 0.0246554 8.72336 0.862395 9.09595 2.33881H10.9751C11.4773 2.33881 11.9794 2.33051 12.4735 2.3471C12.7894 2.3554 13.0081 2.52958 13 2.85306C12.9919 3.17655 12.7732 3.33414 12.4492 3.32585H11.6393C11.5907 3.89817 11.5421 4.43731 11.5016 4.98474L10.9913 12.3336C10.9346 13.0801 10.886 13.8266 10.8293 14.5648C10.7483 15.5353 10.2947 15.9832 9.33894 15.9915C7.45441 16.0136 5.56719 16.0164 3.67726 15.9998C2.6567 15.9832 2.23551 15.5021 2.15452 14.4155C1.91962 10.907 1.66044 7.39843 1.41745 3.88987C1.40125 3.6991 1.37695 3.52492 1.35265 3.31755ZM2.33271 3.34244C2.38131 3.98941 2.4299 4.60319 2.4785 5.21698L3.13458 14.4321C3.17508 14.9381 3.23178 15.0044 3.71776 15.0044H9.26605C9.76013 15.0044 9.82492 14.9381 9.85732 14.4404C10.1003 11.1779 10.3379 7.91545 10.5701 4.65296C10.6025 4.22165 10.6268 3.79034 10.6511 3.34244H2.33271ZM8.05919 2.30563C7.881 1.50936 7.24922 0.986812 6.49595 0.995106C6.12831 0.994059 5.77183 1.12438 5.48794 1.36361C5.20406 1.60284 5.01057 1.93598 4.94081 2.30563H8.05919Z"
      fill={color}
    />
    <path
      d="M3.80668 7.29854C3.79048 6.84234 3.75808 6.39444 3.74188 5.93825C3.73378 5.62306 3.83908 5.38252 4.17926 5.35764C4.51945 5.33275 4.67335 5.52353 4.69765 5.8553L5.01354 11.6946C5.03784 12.0264 4.93254 12.2669 4.58425 12.3167C4.23596 12.3665 4.07397 12.1342 4.04967 11.7278L3.85528 8.00357C3.84718 7.77132 3.83098 7.53078 3.82288 7.29854H3.80668Z"
      fill={color}
    />
    <path
      d="M9.21751 6.59429L8.95022 11.6871C8.91782 12.1599 8.68293 12.3921 8.33465 12.3009C7.98636 12.2096 7.96206 11.9857 7.97826 11.6954L8.19695 7.59792C8.22935 7.01731 8.26985 6.44499 8.30225 5.86438C8.31845 5.5326 8.48044 5.34183 8.81253 5.35842C9.14462 5.37501 9.25801 5.62384 9.24991 5.93074C9.24181 6.23763 9.22561 6.37864 9.20941 6.59429H9.21751Z"
      fill={color}
    />
    <path
      d="M6.97383 8.82484V11.6781C6.98193 12.0265 6.88473 12.2919 6.50405 12.3168C6.12336 12.3417 6.02617 12.1094 6.02617 11.6947C6.01807 9.7953 6.01807 7.88757 6.01807 5.98814C6.01807 5.64807 6.09906 5.37435 6.48785 5.34947C6.87663 5.32458 6.97383 5.55683 6.97383 6.01302C6.98193 6.9503 6.97383 7.88757 6.97383 8.82484Z"
      fill={color}
    />
  </svg>

);

export default Delete;
