import React, { useEffect, useState } from "react";
import { Form, Input, Button, Spin } from "antd";
import { useHistory } from "react-router-dom";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";

import styles from "./product.module.scss";
import { createContractUserDraft } from "../../store/reducers/templatesAdmin/actions";
import { getTokenCookies, setTokenCookies } from "../../store/utils";
import { FORM_VALIDATE_MESSAGES } from "../../constants/staticErrors";
import {
  PRODUCT_STEP_TITLE,
  PRODUCT_STEP_DESCRIPTION,
  PRODUCT_STEP_DESCRIPTION_NOTE,
} from "../../constants/staticTexts";
import { TOKEN_DOMAIN } from "../../constants/variables";

const ProductIntro = ({ handleNextStep, templateId, productId, ...props }) => {
  const [form] = Form.useForm();
  const history = useHistory();
  const dispatch = useDispatch();
  const [submitLoading, setSubmitLoading] = useState(false);
  const userEmail = useSelector((store) => store.user.userEmail);
  const userLoading = useSelector((store) => store.user.loading);
  const contractTitle = useSelector((store) => store.templatesAdmin.title);
  const contractLoading = useSelector((store) => store.templatesAdmin.loading);

  const handleGoBack = () => {
    history.goBack();
  };

  const handleSaveData = () => {
    form
      .validateFields()
      .then((values) => {
        const token = getTokenCookies();
        const formData = {
          title: values.title,
          wp_product_id: productId,
        };

        setSubmitLoading(true);

        if (userEmail && !token) {
          const instance = axios.create({
            withCredentials: true,
          });

          instance
            .post(TOKEN_DOMAIN)
            .then((tokenRefreshResponse) => {
              const token = tokenRefreshResponse.data.token;

              setTokenCookies(token);
              return dispatch(createContractUserDraft(formData, templateId));
            })
            .catch((error) => {
              console.log({ error });
            });
        } else {
          dispatch(createContractUserDraft(formData, templateId));
        }

        // setSubmitLoading(false)
        handleNextStep();
      })
      .catch((info) => {
        setSubmitLoading(false);
        console.log("Validate Failed:", info);
      });
  };

  useEffect(() => {
    form.setFieldsValue({
      title: contractTitle,
    });
  }, [contractTitle]);

  return (
    <div className={styles.intro}>
      <Spin size="small" spinning={contractLoading || userLoading}>
        <h1>{PRODUCT_STEP_TITLE}</h1>
        <p>
          {PRODUCT_STEP_DESCRIPTION}
          <small>{PRODUCT_STEP_DESCRIPTION_NOTE}</small>
        </p>

        <Form
          form={form}
          layout="vertical"
          name="template--title"
          className={styles.introForm}
          validateMessages={FORM_VALIDATE_MESSAGES}
          initialValues={{
            title: contractTitle ? contractTitle : null,
          }}
        >
          <Form.Item
            name="title"
            label="Contract Name"
            rules={[
              {
                required: true,
                whitespace: true,
                max: 80,
              },
            ]}
          >
            <Input placeholder="Enter Contract Name" disabled={submitLoading} />
          </Form.Item>
          <div className={styles.introNav}>
            <Button
              size="small"
              disabled={submitLoading}
              onClick={() => handleGoBack()}
            >
              Cancel
            </Button>
            <Button
              type="primary"
              htmlType="submit"
              disabled={submitLoading}
              onClick={() => handleSaveData()}
              loading={submitLoading}
            >
              Save & continue
            </Button>
          </div>
        </Form>
      </Spin>
    </div>
  );
};

export default ProductIntro;
