export const GET_CONTRACT = 'GET_CONTRACT'
export const GET_CONTRACTS_LIST = 'GET_CONTRACTS_LIST'
export const GET_CONTRACT_TITLE = 'GET_CONTRACT_TITLE'
export const GET_TEMPLATES_NOTIFICATIONS = 'GET_TEMPLATES_NOTIFICATIONS'
export const GET_CONTRACT_CATEGORIES = 'GET_CONTRACT_CATEGORIES'
export const GET_CONTRACT_STEPS = 'GET_CONTRACT_STEPS'
export const GET_CONTRACT_FIELDS = 'GET_CONTRACT_FIELDS'
export const GET_CONTRACT_FIELD_DATA = 'GET_CONTRACT_FIELD_DATA'
export const GET_CONTRACT_SIGN_DATA = 'GET_CONTRACT_SIGN_DATA'
export const UPDATE_CONTRACT_SIGN_STATUS = 'UPDATE_CONTRACT_SIGN_STATUS'

export const CREATE_CONTRACT_DRAFT = 'CREATE_CONTRACT_DRAFT'
export const CREATE_CONTRACT_USER_DRAFT = 'CREATE_CONTRACT_USER_DRAFT'
export const UNDRAFT_CONTRACT = 'UNDRAFT_CONTRACT'
export const CREATE_CONTRACT = 'CREATE_CONTRACT'
export const CLONE_CONTRACT = 'CLONE_CONTRACT'
export const POST_CONTRACT_STEP = 'POST_CONTRACT_STEP'
export const POST_CONTRACT_FIELD = 'POST_CONTRACT_FIELD'
export const POST_USER_FIELDS = 'POST_USER_FIELDS'
export const SEND_FOR_REVIEW = 'SEND_FOR_REVIEW'
export const SEND_CONTRACT = 'SEND_CONTRACT'

export const UPDATE_CONTRACT = 'UPDATE_CONTRACT'
export const UPDATE_CONTRACT_CONTENT = 'UPDATE_CONTRACT_CONTENT'
export const UPDATE_CONTRACT_CONTENT_ADMIN = 'UPDATE_CONTRACT_CONTENT_ADMIN'
export const UPDATE_CONTRACT_STEP = 'UPDATE_CONTRACT_STEP'
export const UPDATE_CONTRACT_FIELD = 'UPDATE_CONTRACT_FIELD'
export const UPDATE_CONTRACT_STEP_POSITION = 'UPDATE_CONTRACT_STEP_POSITION'
export const UPDATE_CONTRACT_FIELD_POSITION = 'UPDATE_CONTRACT_FIELD_POSITION'
export const MOVE_CONTRACT_FIELD_POSITION = 'MOVE_CONTRACT_FIELD_POSITION'

export const DELETE_CONTRACT = 'DELETE_CONTRACT'
export const DELETE_CONTRACT_STEP = 'DELETE_CONTRACT_STEP'
export const DELETE_CONTRACT_FIELD = 'DELETE_CONTRACT_FIELD'
export const DELETE_CONTRACT_BLANK_PDF = 'DELETE_CONTRACT_BLANK_PDF'

export const POST_HELLOSIGN_FIELD = 'POST_HELLOSIGN_FIELD'
export const DELETE_HELLOSIGN_FIELD = 'DELETE_HELLOSIGN_FIELD'
export const GET_FIELD_TYPES = 'GET_FIELD_TYPES'

export const GET_CONTRACT_VALIDATION = 'GET_CONTRACT_VALIDATION'
export const PUBLISH_CONTRACT = 'PUBLISH_CONTRACT'
export const ATTACH_CONTRACT = 'ATTACH_CONTRACT'

export const RESET_ERRORS = 'RESET_ERRORS'
