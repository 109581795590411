// @Packages
import React, { Fragment, useEffect, useState } from "react";
import classnames from "classnames";
import { Button, Input, Spin } from "antd";
import { useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { Draggable, Droppable } from "react-beautiful-dnd";

// @Components
import InputSearch from "../../atoms/InputSearch";
import Table from "../../molecules/Table";
import { PROJECT_SEARCH } from "../../../constants/staticTexts";
import Popover from "../../atoms/Popover";
import MenuContentPopover from "../../molecules/MenuContentPopover";

//@Icons
import {
  CaretDownOutlined,
  CaretRightOutlined,
  CheckCircleOutlined,
  EllipsisOutlined,
  EyeOutlined,
} from "@ant-design/icons";
import HamburgerMenuIcon from "../../atoms/icons/HamburgerMenu";
import CircleEditIcon from "../../atoms/icons/CircleEdit";
import ActionIcon from "../../atoms/icons/Action";
import PencilWritingIcon from "../../atoms/icons/PencilWriting";
import SheetPencilIcon from "../../atoms/icons/SheetPencil";
import ClockIcon from "../../atoms/icons/Clock";
import DoubleCheckIcon from "../../atoms/icons/DoubleCheck";
import EyeIcon from "../../atoms/icons/Eye";

// @Utils
import { parseDate } from "../../../utils";
import { useMediaQuery } from "../../utils";
import { formatDate } from "../../../utils/date";
import { searchBarFilter } from "../../../utils/serarchBar";
import { filterByParam } from "../../../utils/table";
import {
  renameDraftContract,
  renameNoDraftContract,
} from "../../../utils/contract";

//@context
import { useContractsProvider } from "../../../context/contracts";
import { useProjectProvider } from "../../../context/projects";

// @Styles
import classes from "./styles.module.scss";
import classNames from "classnames";

// @Constant
import { PRODUCT_PAGE } from "../../../constants/siteMap";
import {
  MENU9,
  MENU10,
  MENU12,
  MENU8,
  MENU1,
  MENU2,
  MENU5,
  MENU6,
  MENU4,
  MENU3,
  MENU7,
} from "../../../constants/actionsMenu";
import {
  DEFAULT_TITLE_GENERAL,
  LOGIN_PAGE,
  MOBILE_RESOLUTION,
  URL_GENERAL,
} from "../../../constants/variables";
import ChestIcon from "../../atoms/icons/Chest";

const desktopHeadCells = [
  { title: " " },
  { title: "Contract" },
  { title: "Status" },
  { title: "Last Updated" },
  { title: "Actions" },
  { title: " " },
];

const mobileHeadCells = [
  { title: "Contract" },
  { title: "Status" },
  { title: "Actions" },
];

const iconColor = "var(--white)";

const handleStatus = (isMobile = false) => ({
  0: {
    name: "Draft",
    titleColor: "var(--common-intro-bg)",
    backgroundColor: "var(--draft)",
    icon: <SheetPencilIcon color={iconColor} isMobile={isMobile} />,
    dropDown: false,
  },
  1: {
    name: "Sent",
    titleColor: "var(--common-intro-bg)",
    backgroundColor: "var(--sent)",
    icon: <ClockIcon color={iconColor} isMobile={isMobile} />,
    dropDown: true,
    textStatus: "invitation was sent to",
  },
  2: {
    name: "Viewed",
    titleColor: "var(--common-intro-bg)",
    backgroundColor: "var(--viewed)",
    icon: <EyeIcon color={iconColor} isMobile={isMobile} />,
    dropDown: true,
    textStatus: "invitation was sent to",
    textStatusViewed: "Envelope viewed by",
  },
  3: {
    name: "Pt. Signed",
    titleColor: "var(--common-intro-bg)",
    backgroundColor: "var(--signed)",
    icon: <PencilWritingIcon color={iconColor} isMobile={isMobile} />,
    dropDown: true,
    textStatus: "Envelope Signed by",
  },
  4: {
    name: "Signed",
    titleColor: "var(--common-intro-bg)",
    backgroundColor: "var(--executed)",
    icon: <DoubleCheckIcon color={iconColor} isMobile={isMobile} />,
    dropDown: false,
  },
  5: {
    name: "Archived",
    titleColor: "var(--archived-status)",
    backgroundColor: "transparent",
    icon: (
      <ChestIcon
        className={classes.archiveIcon}
        color="var(--archived-status)"
      />
    ),
    dropDown: false,
    marginLeft: -15,
  },
});

const historyGetStatus = (action1, action2 = "") => {
  const statusMap = {
    Draft: 0,
    Sent: 1,
    "Sent Invitation": 1,
    Viewed: 2,
    Signed: 3,
    Executed: 4,
    Completed: 4,
    Archived: 5,
  };
  if (statusMap[ action1 ] === 0) {
    return 0;
  }

  return Math.max(statusMap[ action1 ], statusMap[ action2 ] || 1);
};

let PageSize = 10;

const ProjectTable = () => {
  const history = useHistory();
  const [openStatusList, setOpenStatusList] = useState({});
  const [openActionsList, setOpenActionsList] = useState({});

  const { isSidebarCollapsed, newProject } = useProjectProvider();

  const [headCells, setHeadCells] = useState(desktopHeadCells);
  const [itemActions, setItemActions] = useState([]);

  const {
    loadGeneralData,
    draftData,
    inProgressData,
    sentData,
    projectData,
    setProjectData,
    preloadedGenCont,
    preloadedProjects,
    setPreloadedProjects,
    setSelectArchived,
    contractName,
    setContractName,
    showInput,
    setShowInput,
    projectMap,
    getItemStyle,
    archivedContracts,
    projectMapInfo,
    setProjectHover,
    globalSearch,
    templateHistory,
    loadHistory,
    visible,
    setVisible,
    searchValue,
    setSearchValue,
  } = useContractsProvider();

  const { hash, search } = useLocation();

  useEffect(() => {
    if (!!search) {
      loadGeneralData();
      history.push(`/#general`);
    }
  }, [search]);

  const user = useSelector((store) => store.user.toJS());

  const isMobile = useMediaQuery(MOBILE_RESOLUTION);

  const getUpdateFolderStructure = (folderId, contractId) => {
    let contracts = [];
    let folders = [];
    let contractType = "";
    let folderType = "folder";
    let status = -1;

    const typesArr = projectMapInfo[ projectMap[ folderId ]?.title ].ids;
    const typesFind = typesArr.find((item) => item.id === folderId);
    status = typesFind.status;

    if (status === 0) {
      contracts = draftData.templates;
      folders = draftData.folders;
      contractType = "template";
    } else if (status === 1) {
      contracts = inProgressData.templates;
      folders = inProgressData.folders;
      contractType = "envelope";
    } else if (status === 2) {
      contracts = sentData.templates;
      folders = sentData.folders;
      contractType = "envelope";
    } else {
      return [];
    }

    const contractsFormated = contracts.map((item) => ({
      id: item.id,
      type: contractType,
    }));

    const foldersFormated = folders.map((item) => {
      if (item.id === contractId) return null;
      return {
        id: item.id,
        type: folderType,
      };
    });

    return {
      status,
      structure: [...foldersFormated, ...contractsFormated],
    };
  };

  const statusHandleClick = (project) => {
    const currentStatus = openStatusList[ project.id ];
    setOpenStatusList((prevState) => ({
      ...prevState,
      [ project.id ]: !currentStatus,
    }));
  };

  const handleChangeContractName = ({ target }) =>
    setContractName(target.value);

  const handleArchiveContract = () => setSelectArchived(true);

  const addGeneralOption = (folder) => {
    folder.folders.unshift({
      title: "General",
      id: 0,
      templates: folder.templates,
    });
  };

  const handleShowMenu = (project, status, folderArchived) => {
    try {
      if (draftData.folders[ 0 ]?.title !== DEFAULT_TITLE_GENERAL)
        addGeneralOption(draftData);
      if (inProgressData.folders[ 0 ]?.title !== DEFAULT_TITLE_GENERAL)
        addGeneralOption(inProgressData);
      if (sentData.folders[ 0 ]?.title !== DEFAULT_TITLE_GENERAL)
        addGeneralOption(sentData);
      const { is_form_builder_completed, full_editor_flag } = project;
      const isSubscriber = user.isSubscribed;
      const isParty1 = true;

      const archiveContract = () => handleArchiveContract();
      let foldersParam;
      if (!status)
        foldersParam = draftData.folders.filter((f) => !f.is_archived);
      else if (!!status && status !== 4)
        foldersParam = inProgressData.folders.filter((f) => !f.is_archived);
      else foldersParam = sentData.folders.filter((f) => !f.is_archived);

      const _menu1 = () => MENU1(project, foldersParam);
      const _menu2 = () => MENU2(project, foldersParam);
      const _menu3 = () => MENU3(foldersParam, project);
      const _menu4 = () => MENU4(foldersParam);
      const _menu5 = () => MENU5(project.actions, foldersParam, project.status);
      const _menu6 = () => MENU6(project.actions, foldersParam);
      const _menu7 = () => MENU7(project.actions, foldersParam, project.status);
      const _menu8 = () => MENU8(project.actions, foldersParam);
      const _menu9 = () => MENU9(foldersParam, archiveContract, project.status);
      const _menu10 = () => MENU10(foldersParam);

      if (folderArchived || project.is_archived) return setItemActions(MENU12);

      switch (status) {
        case 0: {
          if (is_form_builder_completed) {
            if (!isSubscriber) setItemActions(_menu3);
            else setItemActions(_menu4);
          } else {
            if (!isSubscriber) setItemActions(_menu1);
            else setItemActions(_menu2);
          }
          break;
        }
        case 1: {
          if (isParty1) {
            if (!isSubscriber) setItemActions(_menu5);
            else setItemActions(_menu6);
          } else {
            if (!isSubscriber) setItemActions(_menu7);
            else setItemActions(_menu8);
          }
          break;
        }
        case 2: {
          if (!isSubscriber) setItemActions(_menu5);
          else setItemActions(_menu6);
          break;
        }
        case 3: {
          if (!isSubscriber) setItemActions(_menu5);
          else setItemActions(_menu6);
          break;
        }
        case 4: {
          if (!isSubscriber) setItemActions(_menu9);
          else setItemActions(_menu10);
          break;
        }
        case "completed": {
          if (!isSubscriber) setItemActions(_menu9);
          else setItemActions(_menu10);
          break;
        }
        default:
          return setItemActions(MENU12);
      }
    } catch (error) {
      console.log("handleShowMenu error", error);
    }
  };

  const actionsHandleClick = (project, status) => {
    try {
      let folderArchived = false;
      if (project.folder_id > 0) {
        folderArchived = !!projectMap[ project.folder_id ]?.is_archived;
      }
      handleShowMenu(project, status, folderArchived);
      const currentState = openActionsList[ project.id ];
      let auxActionList = {};
      Object.keys(openActionsList).forEach((item) => {
        auxActionList[ item.id ] = false;
      });
      setOpenActionsList(() => ({
        [ project.id ]: !currentState || false,
      }));
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    let withOutHistory = [];
    projectData.forEach(({ id, status }) => {
      if (!templateHistory[ id ] && status === -1) {
        withOutHistory.push({ id });
      }
    });
    if (Boolean(withOutHistory.length)) {
      loadHistory(withOutHistory);
    }
  }, [projectData]);

  /**
   * Redux actions when are loaded
   */

  useEffect(() => {
    if (hash !== "#general" && hash !== "" && hash !== "#archived") {
      try {
        setCurrentPage(1);
        if (!!projectMap[ hash.substring(1) ]) {
          const dataProject = projectMap[ hash.substring(1) ];

          const auxProjectInfo = projectMapInfo[ dataProject?.title ];

          let _projectData = auxProjectInfo?.templates || [];
          _projectData = _projectData.sort(filterByParam("updated_at", -1));

          let auxOpenStatusList = {};
          let auxOpenActionsList = {};

          let history = [];

          _projectData.forEach((template) => {
            if (template.status === -1) {
              history.push({ id: template?.id });
              auxOpenStatusList[ template?.id ] = false;
            }
            auxOpenActionsList[ template?.id ] = false;
          });
          loadHistory(history);

          setOpenStatusList(auxOpenStatusList);
          setOpenActionsList(auxOpenActionsList);

          setProjectData(_projectData);
        } else {
          history.push(URL_GENERAL);
        }
      } catch (error) {
        console.log(error);
      }
    } else if (hash === "#archived") {
      setProjectData(archivedContracts);
    } else {
      setProjectData(preloadedGenCont);
    }
  }, [hash, preloadedGenCont]);

  useEffect(() => {
    if (isMobile) setHeadCells(mobileHeadCells);
    else setHeadCells(desktopHeadCells);
  }, [isMobile]);

  const onSearchBarChange = (ev) => {
    const val = ev.target.value;
    setSearchValue(val);
    if (val === "") {
      setProjectData(preloadedGenCont);
    } else {
      const auxFiltered = searchBarFilter(preloadedGenCont, val);
      setProjectData(auxFiltered);
    }
    setCurrentPage(1);
  };

  const handleRenameContract = async (e, project) => {
    e.preventDefault();
    if (!project.status) await renameDraftContract(project.id, contractName);
    else await renameNoDraftContract(project.id, contractName);

    const contractUpdated = projectData.map((contract) => {
      if (contract.id === project.id) contract.title = contractName;
      return contract;
    });
    setProjectData(contractUpdated);
    setShowInput({ ...showInput, show: false });

    // Update preloadedGenCont
    const contractUpdated2 = preloadedProjects.map((contract) => {
      if (contract.id === project.id) {
        contract.title = contractName;
      }
      return contract;
    });
    setPreloadedProjects(contractUpdated2);
  };

  /**
   * Row hover for global search
   */

  const contractHover = (project) => {
    if (globalSearch) {
      setProjectHover({
        folder_id: project.folder_id,
        folder_title:
          project.folder_id === 0 ? "General" : project.folder_title,
      });
    }
  };

  const contractHoverLeave = () => {
    setProjectHover({});
  };

  const handleShowInput = (e, project) => {
    setContractName(project.title);
    setShowInput({
      item: project.id,
      show: true,
    });
  };

  const handleCloseInput = async (e, project) => {
    setShowInput({
      ...showInput,
      show: false,
    });
    await handleRenameContract(e, project);
  };

  const [currentPage, setCurrentPage] = useState(1);
  const firstPageIndex = (currentPage - 1) * PageSize;
  const lastPageIndex = firstPageIndex + PageSize;

  return (
    <>
      <div className={classes.table}>
        <Table>
          <thead>
          <Table.Row>
            {headCells.map((header, index) => (
              <Table.Header key={"table" + index}>
                <div
                  className={classnames(
                    header.title === "Contract" && classes.tableFind,
                    isMobile && classes.headerMobile
                  )}
                  style={{
                    textAlign:
                      isMobile && header.title === "Actions" && "center",
                  }}
                >
                  {header.title === "Status" ||
                  header.title === "Last Updated" ? (
                    <div className={classes.headerIcon}>{header.title}</div>
                  ) : (
                    header.title
                  )}
                  {header.title === "Contract" && !isMobile && (
                    <InputSearch
                      byProject
                      value={searchValue}
                      className={classes.search}
                      placeholder={PROJECT_SEARCH}
                      handleSearch={onSearchBarChange}
                    />
                  )}
                </div>
              </Table.Header>
            ))}
          </Table.Row>
          </thead>
          {Boolean(projectData.length) ? (
            <Droppable
              droppableId="contractsDrop"
              type="contractList"
              isDropDisabled={isMobile}
            >
              {(provided, snapshot) => (
                <tbody
                  ref={provided.innerRef}
                  {...provided.droppableProps}
                  className={classes.tablePosition}
                >
                {projectData.length
                  ? projectData
                    .slice(firstPageIndex, lastPageIndex)
                    .map((project, index) => {
                      let _history = [];
                      let status = project.status;
                      if (status === -1) {
                        _history = templateHistory[ project.id ];
                        status = historyGetStatus(
                          _history?.history[ 0 ]?.action,
                          _history?.history[ 1 ]?.action
                        );
                      }
                      if (!!projectMap[ project.folder_id ]?.is_archived || project.is_archived)
                        status = 5;

                      return (
                        <Draggable
                          key={project.id.toString()}
                          draggableId={project.id.toString()}
                          index={index}
                          type="projectList"
                          isDragDisabled={isMobile}
                        >
                          {(provided, snapshot) => (
                            <Fragment key={index}>
                              <tr
                                className={classes.tableRow}
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                style={getItemStyle(
                                  provided.draggableProps.style,
                                  snapshot.isDragging
                                )}
                                onMouseEnter={() => contractHover(project)}
                                onMouseLeave={() => contractHoverLeave()}
                              >
                                {!isMobile && (
                                  <Table.Cell>
                                    <div className={classes.containerIcons}>
                                      {!isSidebarCollapsed && (
                                        <div {...provided.dragHandleProps}>
                                          <HamburgerMenuIcon
                                            className={classes.dndIcon}
                                          />
                                        </div>
                                      )}
                                      <div
                                        className={classes.renameIcon}
                                        onClick={(e) =>
                                          handleShowInput(e, project)
                                        }
                                      >
                                        <CircleEditIcon />
                                      </div>
                                    </div>
                                  </Table.Cell>
                                )}
                                {showInput.item === project.id &&
                                showInput.show ? (
                                  <Table.Cell className={classes.cellInput}>
                                    <div className={classes.inputDotUp} />
                                    <form
                                      onSubmit={(e) =>
                                        handleRenameContract(e, project)
                                      }
                                    >
                                      <Input
                                        autoFocus
                                        className={classes.inputContract}
                                        onChange={handleChangeContractName}
                                        value={contractName}
                                        onFocus={(e) => e.target.select()}
                                        onBlur={(e) =>
                                          handleCloseInput(e, project)
                                        }
                                      />
                                    </form>
                                    <div
                                      className={
                                        classes.inputDotDownContainer
                                      }
                                    >
                                      <div
                                        className={classes.inputDotDown}
                                      />
                                    </div>
                                  </Table.Cell>
                                ) : (
                                  <Table.Cell>
                                    {project.status === 0 ? (
                                      <div
                                        className={classes.link}
                                        onClick={() => {
                                          window.location.href = `${window.location.origin}${PRODUCT_PAGE}/${project.id}/${project.wp_product_id}?edit`;
                                        }}
                                      >
                                        <p className={classes.title}>
                                          {project.title}
                                        </p>
                                      </div>
                                    ) : (
                                      <p className={classes.title}>
                                        {project.title}
                                      </p>
                                    )}
                                  </Table.Cell>
                                )}
                                <Table.Cell>
                                  <div
                                    className={classnames(
                                      handleStatus(isMobile)[ status ]
                                        ?.dropDown && classes.tableCursor,
                                      classes.tableStatus
                                    )}
                                    onClick={() =>
                                      statusHandleClick({ ...project })
                                    }
                                    style={{
                                      backgroundColor:
                                      handleStatus(isMobile)[ status ]
                                        ?.backgroundColor,
                                      color:
                                      handleStatus(isMobile)[ status ]
                                        ?.titleColor,
                                      margin:
                                      handleStatus(isMobile)[ status ]
                                        ?.marginLeft,
                                    }}
                                  >
                                    {handleStatus(isMobile)[ status ]?.icon}
                                    <p>
                                      {handleStatus(isMobile)[ status ]?.name}
                                    </p>
                                    <div className={classes.grow} />
                                    {handleStatus(isMobile)[ status ]
                                        ?.dropDown &&
                                      (!openStatusList[ project.id ] ? (
                                        <CaretRightOutlined />
                                      ) : (
                                        <CaretDownOutlined />
                                      ))}
                                  </div>
                                </Table.Cell>
                                {!isMobile && (
                                  <Table.Cell>
                                    {parseDate(project.updated_at)}
                                  </Table.Cell>
                                )}
                                <Table.Cell
                                  className={classNames(
                                    isMobile && classes.mobileCell
                                  )}
                                >
                                  <Popover
                                    visible={
                                      visible.item === project.id &&
                                      visible.visible
                                    }
                                    handleVisibleChange={(visible) =>
                                      setVisible({
                                        item: project.id,
                                        visible,
                                      })
                                    }
                                    content={
                                      <MenuContentPopover
                                        items={itemActions}
                                        project={project}
                                        archiveFnStructure={
                                          getUpdateFolderStructure
                                        }
                                      />
                                    }
                                    icon={
                                      !isMobile ? (
                                        <Button
                                          className={classes.actionButtom}
                                          onClick={() =>
                                            actionsHandleClick(
                                              project,
                                              status
                                            )
                                          }
                                        >
                                          Actions <CaretRightOutlined />
                                        </Button>
                                      ) : (
                                        <span
                                          onClick={() =>
                                            actionsHandleClick(
                                              project,
                                              status
                                            )
                                          }
                                        >
                                              <ActionIcon />
                                            </span>
                                      )
                                    }
                                  />
                                </Table.Cell>
                                {!isMobile && <Table.Cell />}
                              </tr>
                              {openStatusList[ project.id ] &&
                                handleStatus(isMobile)[ status ]
                                  ?.dropDown && (
                                  <tr>
                                    <td
                                      colSpan="6"
                                      className={classes.statusViewCtn}
                                    >
                                      <div
                                        className={classes.tableDivPosition}
                                      >
                                        {_history.history &&
                                          Boolean(
                                            _history.history.length
                                          ) &&
                                          _history?.history?.map(
                                            (invitation, i) => {
                                              const localStatus =
                                                historyGetStatus(
                                                  invitation?.action
                                                );
                                              return (
                                                <span
                                                  key={"_history" + i}
                                                  className={
                                                    classes.tableDropdown
                                                  }
                                                >
                                                      <p
                                                        className={
                                                          classes.dateText
                                                        }
                                                      >
                                                        {`${formatDate(
                                                          new Date(
                                                            invitation?.date
                                                          )
                                                        )}`}
                                                        {localStatus === 3 && (
                                                          <span
                                                            className={classnames(
                                                              status !== 3 &&
                                                              classes.iconDisplay
                                                            )}
                                                          >
                                                            <CheckCircleOutlined
                                                              className={classnames(
                                                                classes.iconMargin,
                                                                classes.iconSigned
                                                              )}
                                                            />
                                                          </span>
                                                        )}
                                                        {localStatus === 2 && (
                                                          <EyeOutlined
                                                            className={
                                                              classes.iconMargin
                                                            }
                                                          />
                                                        )}
                                                      </p>
                                                      <p
                                                        className={
                                                          classes.subText
                                                        }
                                                      >
                                                        {localStatus === 1 && (
                                                          <EllipsisOutlined
                                                            className={
                                                              classes.iconMargin
                                                            }
                                                          />
                                                        )}
                                                        {invitation.activity}
                                                      </p>
                                                    </span>
                                              );
                                            }
                                          )}
                                      </div>
                                    </td>
                                  </tr>
                                )}
                            </Fragment>
                          )}
                        </Draggable>
                      );
                    })
                  : isMobile && (
                  <tr className={classes.emptyMessage}>
                    <td
                      colSpan="6"
                      style={{
                        border: "none",
                        padding: "40px 0 150px 0",
                      }}
                    >
                      {!draftData.templates.length ? (
                        <div className="loader-wrapper">
                          <Spin size="middle" />
                        </div>
                      ) : (
                        <>
                          {!newProject ? (
                            <p style={{ lineHeight: 2 }}>
                              Your Briefcase is empty, you <br />
                              can click in the + icon <br />
                              button above to start your <br />
                              first contract.
                            </p>
                          ) : (
                            <p style={{ lineHeight: 2 }}>
                              To add contracts to this new project, go{" "}
                              <br />
                              to GENERAL bin CLICK THE 3 DOTS on <br />
                              each contract and select MOVE.
                            </p>
                          )}
                        </>
                      )}
                    </td>
                  </tr>
                )}
                {provided.placeholder}
                </tbody>
              )}
            </Droppable>
          ) : null}
        </Table>
        {!Boolean(projectData.length) &&
          (hash === "#general" ? (
            <div className={classes.emptyTableBody}>
              <span>
                Your Briefcase is empty, you can click in New Contract button
                above to start your first contract.
              </span>
            </div>
          ) : (
            <div className={classes.emptyProjectBody}>
              <span>
                {!isMobile
                  ? "Drag a contract from the General bin and drop it into your project."
                  : "To add contracts to this new project, go to GENERAL bin CLICK THE 3 DOTS on each contract and select MOVE."}
              </span>
            </div>
          ))}
        {projectData.length > 0 && (
          <Table.Pagination
            currentPage={currentPage}
            totalCount={projectData.length}
            pageSize={PageSize}
            onPageChange={(page) => setCurrentPage(page)}
          />
        )}
      </div>
    </>
  );
};

export default ProjectTable;
