const Clock = ({
  className = "custom-icon",
  color = "#FFA800",
  size = 15,
  isMobile = false,
}) => {
  if (isMobile) {
    return (
      <svg
        className={className}
        width="8"
        height="8"
        viewBox="0 0 8 8"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M4 0.985299V0C5.44564 0.0623607 6.58595 0.648552 7.34616 1.86458C8.20607 3.24276 8.21853 4.68952 7.37732 6.08017C6.62957 7.33362 5.47057 7.93851 4 8.00087V7.02181C5.88806 6.73495 6.99098 5.60622 6.97852 3.97237C6.96606 2.33853 5.83821 1.22851 4 0.985299Z"
          fill={color}
        />
        <path
          d="M5.46462 4.74561C4.86642 4.74561 4.28692 4.75808 3.71365 4.74561C3.42701 4.73937 3.24631 4.53358 3.24007 4.22801C3.23384 3.5857 3.24007 2.94338 3.24007 2.27612H4.23084V3.74783H5.46462V4.74561Z"
          fill={color}
        />
        <path
          d="M2.73506 0.997776C2.73672 1.06299 2.72541 1.12789 2.7018 1.1887C2.6782 1.24951 2.64276 1.30502 2.59754 1.35201C2.55233 1.399 2.49824 1.43654 2.43841 1.46244C2.37858 1.48834 2.31421 1.50209 2.24903 1.5029C2.18198 1.50376 2.11545 1.49107 2.05341 1.4656C1.99138 1.44012 1.93511 1.40239 1.88799 1.35465C1.84087 1.30691 1.80386 1.25014 1.77917 1.18775C1.75447 1.12536 1.74262 1.05863 1.7443 0.99154C1.74909 0.861914 1.80353 0.739101 1.89633 0.648547C1.98913 0.557993 2.11319 0.50664 2.24279 0.505127C2.37285 0.506744 2.49712 0.559167 2.58909 0.651209C2.68106 0.74325 2.73344 0.86762 2.73506 0.997776Z"
          fill={color}
        />
        <path
          d="M1.48943 2.24509C1.49109 2.30979 1.47999 2.37419 1.45677 2.43459C1.43355 2.495 1.39867 2.55024 1.35411 2.59714C1.30955 2.64405 1.2562 2.68172 1.19709 2.70798C1.13798 2.73424 1.07428 2.74859 1.00963 2.75021C0.88008 2.7553 0.753756 2.70904 0.658089 2.62147C0.562421 2.5339 0.505134 2.4121 0.49867 2.28251C0.495323 2.21514 0.505491 2.14779 0.528574 2.08442C0.551657 2.02105 0.587187 1.96295 0.633069 1.91355C0.678951 1.86414 0.734254 1.82443 0.795717 1.79676C0.85718 1.76909 0.923559 1.75402 0.990936 1.75244C1.12159 1.75402 1.24653 1.80623 1.3395 1.89811C1.43247 1.98999 1.48622 2.11437 1.48943 2.24509Z"
          fill={color}
        />
        <path
          d="M0.479804 4.50242C0.350309 4.49601 0.228243 4.43998 0.138914 4.34594C0.0495846 4.25189 -0.000157192 4.12705 9.89543e-07 3.9973C-0.000134054 3.92989 0.0135544 3.86318 0.0402182 3.80128C0.0668821 3.73938 0.105954 3.68362 0.155018 3.63744C0.204081 3.59126 0.262091 3.55565 0.325459 3.53281C0.388828 3.50997 0.456206 3.50038 0.523423 3.50465C0.653456 3.5127 0.775172 3.57137 0.862518 3.66811C0.949864 3.76485 0.995898 3.89196 0.990764 4.02224C0.98432 4.15293 0.927458 4.27601 0.832151 4.36557C0.736845 4.45514 0.610531 4.50419 0.479804 4.50242Z"
          fill={color}
        />
        <path
          d="M1.48885 5.74338C1.48901 5.87314 1.43926 5.99798 1.34994 6.09202C1.26061 6.18606 1.13854 6.2421 1.00904 6.2485C0.941713 6.24934 0.874895 6.23669 0.812524 6.21128C0.750154 6.18588 0.693493 6.14825 0.645878 6.1006C0.598264 6.05294 0.560659 5.99624 0.535277 5.93382C0.509895 5.8714 0.497249 5.80453 0.498086 5.73715C0.501338 5.60701 0.55529 5.48329 0.648427 5.39242C0.741563 5.30154 0.866504 5.25069 0.996583 5.25073C1.12714 5.25073 1.25235 5.30264 1.34467 5.39503C1.43698 5.48742 1.48885 5.61272 1.48885 5.74338Z"
          fill={color}
        />
        <path
          d="M2.73553 6.9907C2.73569 7.12045 2.68594 7.24529 2.59661 7.33933C2.50728 7.43338 2.38522 7.48941 2.25572 7.49582C2.12602 7.49767 2.00058 7.44947 1.90544 7.36122C1.8103 7.27297 1.75276 7.15145 1.74476 7.02188C1.74138 6.95429 1.75178 6.88673 1.77531 6.82329C1.79884 6.75986 1.83503 6.70187 1.88166 6.65287C1.92829 6.60387 1.98439 6.56488 2.04656 6.53826C2.10872 6.51164 2.17565 6.49796 2.24326 6.49805C2.37382 6.49805 2.49903 6.54995 2.59135 6.64234C2.68366 6.73473 2.73553 6.86004 2.73553 6.9907Z"
          fill={color}
        />
      </svg>
    );
  }

  return (
    <svg
      className={className}
      fill="none"
      height={size}
      viewBox={`0 0 ${size} ${size}`}
      width={size}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.37402 2.04547V0.28125C9.9625 0.392909 12.0043 1.44251 13.3655 3.61986C14.9051 6.08753 14.9275 8.67802 13.4212 11.168C12.0824 13.4124 10.0071 14.4955 7.37402 14.6071V12.8541C10.7547 12.3404 12.7295 10.3194 12.7072 7.39394C12.6849 4.46847 10.6654 2.48094 7.37402 2.04547Z"
        fill={color}
      />
      <path
        d="M9.9959 8.77913C8.92481 8.77913 7.88719 8.80146 6.86072 8.77913C6.34749 8.76796 6.02393 8.39948 6.01277 7.85235C6.00161 6.70226 6.01277 5.55217 6.01277 4.35742H7.78677V6.99258H9.9959V8.77913Z"
        fill={color}
      />
      <path
        d="M5.10875 2.06765C5.11172 2.18443 5.09148 2.30062 5.04921 2.4095C5.00694 2.51838 4.94348 2.61778 4.86252 2.70192C4.78156 2.78606 4.68471 2.85327 4.57758 2.89965C4.47046 2.94603 4.3552 2.97065 4.23849 2.97209C4.11844 2.97363 3.99931 2.95092 3.88823 2.90531C3.77716 2.8597 3.67642 2.79213 3.59204 2.70665C3.50767 2.62116 3.44139 2.51952 3.39719 2.40781C3.35298 2.2961 3.33174 2.17661 3.33475 2.05649C3.34333 1.82439 3.44081 1.60449 3.60697 1.44235C3.77314 1.28021 3.99527 1.18826 4.22733 1.18555C4.4602 1.18844 4.68272 1.28231 4.84739 1.44711C5.01207 1.61192 5.10586 1.8346 5.10875 2.06765Z"
        fill={color}
      />
      <path
        d="M2.87716 4.30008C2.88013 4.41593 2.86026 4.53122 2.81869 4.63939C2.77711 4.74755 2.71465 4.84645 2.63487 4.93044C2.55508 5.01443 2.45955 5.08187 2.35371 5.1289C2.24788 5.17592 2.13382 5.20162 2.01806 5.20452C1.7861 5.21363 1.55991 5.13079 1.38861 4.97399C1.21732 4.8172 1.11474 4.5991 1.10317 4.36707C1.09717 4.24645 1.11538 4.12585 1.15671 4.01239C1.19804 3.89893 1.26166 3.79489 1.34381 3.70643C1.42597 3.61797 1.52499 3.54687 1.63504 3.49733C1.74509 3.44779 1.86395 3.4208 1.98459 3.41797C2.21852 3.42079 2.44224 3.51429 2.60871 3.6788C2.77517 3.84331 2.8714 4.06601 2.87716 4.30008Z"
        fill={color}
      />
      <path
        d="M1.07005 8.343C0.838178 8.33153 0.619616 8.2312 0.459668 8.06281C0.299721 7.89442 0.210656 7.6709 0.210939 7.43856C0.210697 7.31787 0.235207 7.19841 0.28295 7.08758C0.330692 6.97675 0.400653 6.87691 0.488503 6.79422C0.576353 6.71154 0.680222 6.64777 0.793685 6.60687C0.907148 6.56598 1.02779 6.54882 1.14815 6.55646C1.38097 6.57087 1.59891 6.67592 1.75531 6.84914C1.91171 7.02235 1.99413 7.24995 1.98494 7.48323C1.9734 7.71722 1.87159 7.9376 1.70094 8.09797C1.53029 8.25834 1.30412 8.34618 1.07005 8.343Z"
        fill={color}
      />
      <path
        d="M2.87758 10.5657C2.87787 10.798 2.7888 11.0216 2.62885 11.19C2.46891 11.3583 2.25034 11.4587 2.01848 11.4701C1.89792 11.4716 1.77828 11.449 1.6666 11.4035C1.55492 11.358 1.45347 11.2906 1.36821 11.2053C1.28296 11.12 1.21562 11.0185 1.17018 10.9067C1.12473 10.7949 1.10209 10.6752 1.10359 10.5545C1.10941 10.3215 1.20601 10.1 1.37278 9.93728C1.53954 9.77456 1.76325 9.68352 1.99616 9.68359C2.22993 9.68359 2.45412 9.77653 2.61942 9.94196C2.78472 10.1074 2.87758 10.3318 2.87758 10.5657Z"
        fill={color}
      />
      <path
        d="M5.1091 12.7981C5.10938 13.0305 5.02032 13.254 4.86037 13.4224C4.70042 13.5908 4.48186 13.6911 4.24999 13.7026C4.01775 13.7059 3.79314 13.6196 3.62279 13.4616C3.45244 13.3035 3.34941 13.086 3.3351 12.854C3.32905 12.7329 3.34766 12.612 3.38979 12.4984C3.43193 12.3848 3.49672 12.281 3.58021 12.1932C3.66371 12.1055 3.76416 12.0357 3.87547 11.988C3.98678 11.9404 4.10661 11.9159 4.22768 11.916C4.46145 11.916 4.68564 12.009 4.85094 12.1744C5.01624 12.3398 5.1091 12.5642 5.1091 12.7981Z"
        fill={color}
      />
    </svg>
  );
};

export default Clock;
